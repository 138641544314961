import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../company/service/company.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { filter } from 'rxjs/operators';
import * as $ from "jquery";
import { BehaviorSubject, Observable, Subscription } from 'rxjs';




@Component({
  selector: 'app-add-company',
  templateUrl: './add-company.component.html',
  styleUrls: ['./add-company.component.css']
})
export class AddCompanyComponent implements OnInit {
  model: any = {};
  errors: any = '';
  result: any;
  msg: string = null;
  //msg: string = null;
  public imagePath;
  imgURL: any = "/assets/images/placeholder.png";
  logo: any;
  public message1: string;
  resultCountry: any;
  states: any = [];
  resultState: any;
  resultCity: any;
  filedata: any;
  countryOptions: any;
  stateOptions: any;
  cityOptions: any;
  // public errors = {

  //   country_id :  "",
  //   state_id : "",
  //   city_id:""
  // };
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }


  constructor(private _flashMessagesService: FlashMessagesService, public companyService: CompanyService, private route: ActivatedRoute, private router: Router) { }

  async  ngOnInit() {
    this.countryOptions = { placeholder: { id: '', text: 'Select Country' } };
    this.stateOptions = { placeholder: { id: '', text: 'Select State' } };
    this.cityOptions = { placeholder: { id: '', text: 'Select City' } };
    await this.countryData();
  }

  onSubmit(form?: NgForm) {
    if (this.filedata == undefined && this.filedata == null) {
      this.message1 = "Image Required";
    }
    let f = form.value;
    f.country_id = this.model.country_id;
    f.state_id = this.model.state_id;
    f.city_id = this.model.city_id;
    console.log(f);
    let formdata = new FormData();
    console.log(formdata);
    formdata.append("logo", this.filedata);
    formdata.append("name", f.name);
    formdata.append("city_id", f.city_id);
    formdata.append("state_id", f.state_id);
    formdata.append("country_id", f.country_id);
    formdata.append("address1", f.address1);
    formdata.append("contact1", f.contact1);
    formdata.append("email1", f.email1);
    formdata.append("email2", f.email2);
    formdata.append("password", f.password);
    formdata.append("contact2", f.contact2);
    formdata.append("address2", f.address2);
    formdata.append("website", f.website);


    this.companyService.company_add(formdata).subscribe((res: any) => {
      console.log(res);

      this.result = res;

      if (this.result.success == 1) {
        this._flashMessagesService.show('Company created successfully', { cssClass: 'alert-success' });

        setTimeout(() => {
          this.router.navigate(['/companies']);
        }, 2000);

      } else {
        this.errors = res.message;
        setTimeout(() => {
          this.errors.company_id = false;
        }, 3000);

      }

    }, (err) => {

    });

  }

  //company
  async countryData() {
    await this.companyService.country_list().subscribe((res: any) => {
      this.result = res.data;
      var rObj = [];
      rObj.push({ "id": "", "text": "" });
      for (let i = 0; i < res.data.length; i++) {

        rObj.push({ "id": res.data[i].id, "text": res.data[i].country_name });
      }
      this.resultCountry = rObj;

      console.log(this.resultCountry);
    }, (err) => {

    });
  }

  countryChanged(event) {
    console.log(event);
    if (event.value != 'select') {
      this.model.country_id = event.value;
      let catBrand = this.model.country_id;

      var result = [];
      this.companyService.state_list(catBrand).subscribe((res: any) => {
        this.result = res.data;
        var rObj = [];
        rObj.push({ "id": "", "text": "" });
        for (let i = 0; i < res.data.length; i++) {

          rObj.push({ "id": res.data[i].id, "text": res.data[i].state_name });
        }
        this.resultState = rObj;
      }, (err) => {

      });
    }
  }

  stateChanged(event) {
    console.log(event);
    if (event.value != 'select') {
      this.model.state_id = event.value;
      let catVar = this.model.state_id;
      var result = [];
      this.companyService.city_list(catVar).subscribe((res: any) => {

        this.result = res.data;
        var rObj = [];
        rObj.push({ "id": "", "text": "" });
        for (let i = 0; i < res.data.length; i++) {

          rObj.push({ "id": res.data[i].id, "text": res.data[i].city_name });
        }
        this.resultCity = rObj;
      }, (err) => {

      });

    }

  }

  cityChanged(event) {
    console.log(event);
    this.model.city_id = event.value;
  }

  // countryData(){
  //   this.companyService.country_list().subscribe((res:any)=>{
  //     this.resultCountry =res.data;

  //   }, (err) => {                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    

  //   });
  // }

  // onSelect(countryid) {

  //   this.companyService.state_list(countryid).subscribe((res:any)=>{

  //     this.resultState =res.data;
  //     console.log(this.resultState);

  //   }, (err) => {                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    

  //   });
  // }

  // onsateSelect(stateid) {

  //   this.companyService.city_list(stateid).subscribe((res:any)=>{
  //     this.resultCity =res.data;
  //     console.log(this.resultCity);

  //   }, (err) => {                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    

  //   });
  // }


  onFileChange(e) {
    this.filedata = e.target.files[0];
    console.log("this.filedata");
    console.log(this.filedata);
  }

  preview(files) {
    if (files.length === 0)
      return;
    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message1 = "Only images are supported.";
      return;
    }

    var reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    }
  }


}
