import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../../company/service/company.service';
declare var $ :any; 
import { OrderPipe } from 'ngx-order-pipe';
import { FlashMessagesService } from 'angular2-flash-messages';

@Component({
  selector: 'app-skulist',
  templateUrl: './skulist.component.html',
  styleUrls: ['./skulist.component.css']
})
export class SkulistComponent implements OnInit {

	data : any;
	searchText  = "";
	sortedCollection: any[];
	order: string = 'result.id';
	reverse: boolean = false;
	result : any = [];
	arr : any = [];
	errors : any = '';
	deletedMsg: string = null;
	p : number=1;
	deleteId:any;
	currentLimit:any = 10;
	pageLimits = [
	{'id': 2, 'name': '2'},
	{'id': 5, 'name': '5'},
	{'id': 10, 'name': '10'},
	{'id': 15, 'name': '15'},
	];
	loader:boolean = false;


	constructor( 
		private _flashMessagesService: FlashMessagesService,
		public companyService: CompanyService,
		private route: ActivatedRoute, 
		private router: Router,
		private orderPipe: OrderPipe) {  
		this.sortedCollection = orderPipe.transform(this.result, 'result.id');
		console.log(this.sortedCollection); 
	}

	/**
	 * [ngOnInit description]
	 * @author Suresh Suthar
	 * @Created Date 2019-04-12
	 */
	ngOnInit() {

		this.skuData();
	}

	/**
	 * [skuData description]
	 * @author Suresh Suthar
	 * @Created Date 2019-04-12
	 */
	skuData(){
		this.loader=true;
		this.companyService.inventoryList().subscribe((res:any)=>{
		    this.loader=false;
			console.log(res.data);
			this.result =res.data;
			console.log(this.result);

		}, (err) => {

		});
	}

	/**
	 * [setOrder description]
	 * @author Suresh Suthar
	 * @Created Date     2019-04-12
	 * @param   {string} value      [description]
	 */
	setOrder(value: string) {
		if (this.order === value) {
			this.reverse = !this.reverse;
		}

		this.order = value;
	}

}
