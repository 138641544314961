import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { CompanyService } from '../../company/service/company.service';
import * as $ from "jquery";

@Component({
  selector: 'app-customer-care',
  templateUrl: './customer-care.component.html',
  styleUrls: ['./customer-care.component.css']
})
export class CustomerCareComponent implements OnInit {
   customerCareDetails: any = {};

  registerForm: FormGroup;
  submitted = false;

  loader = false;
  msg: String;

  constructor(
  	private apiService: CompanyService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {

  	this.registerForm = this.formBuilder.group({
      marketedBy: ["", Validators.required],
      email: ["", [Validators.email, Validators.required]],
      phoneNo: ["", Validators.required],
      address: ["", Validators.required],
    });

    this.getCustomerCareDetails();
  }

  get f() {
    return this.registerForm.controls;
  }

  onSave() {
     this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }

    this.loader = true;

    this.apiService
      .customerCareUpdate(this.registerForm.value)
      .subscribe(
        (res: any) => {
          this.loader = false;
          if (res.success == 1) {
          } else {
            this.msg = res.message;
            setTimeout(() => {
              this.msg = null;
            }, 7000);
          }
        },
        (err) => {
          this.loader = false;
          this.msg =
            "Due to internal server issue, you can't edit customer care details!";
          setTimeout(() => {
            this.msg = null;
          }, 7000);
        }
      );
  }

  getCustomerCareDetails() {
    this.loader = true;
    this.apiService.customerCareDetail()
      .subscribe(
        (res: any) => {
          this.loader = false;
          if (res.success == 1 && res.data) {
            this.customerCareDetails = res.data;
            this.registerForm.patchValue({
              marketedBy: this.customerCareDetails.marketed_by,
              email: this.customerCareDetails.email,
              phoneNo: this.customerCareDetails.phone_no,
              address: this.customerCareDetails.address,
            });
          } else {
          }
        },
        (err) => {
          this.loader = false;
        }
      );
  }

}
