import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../company/service/company.service';
declare var $ :any; 
import { OrderPipe } from 'ngx-order-pipe';
import { FlashMessagesService } from 'angular2-flash-messages';
import { Globals } from './../../globals';

@Component({
  selector: 'app-options',
  templateUrl: './options.component.html',
  styleUrls: ['./options.component.css']
})
export class OptionsComponent implements OnInit {
    data : any;
	searchText  = "";
	sortedCollection: any[];
	order: string = 'result.id';
	reverse: boolean = false;
	result : any = [];
	arr : any = [];
	errors : any = '';
	deletedMsg: string = null;
	p =this.globals.p;
    currentLimit=this.globals.currentLimit;
	deleteId:any;
	pageLimits = this.globals.pageLimits;
    loader:boolean = false;

	unitData:any;
	constructor( private _flashMessagesService: FlashMessagesService,public companyService: CompanyService,private route: ActivatedRoute, private router: Router,private orderPipe: OrderPipe,private globals: Globals) {  this.sortedCollection = orderPipe.transform(this.result, 'result.id');
	console.log(this.sortedCollection); }

  ngOnInit() {


  	this.optionData();
  	this.unitData = this.globals.unitData;


  }


  optionData(){
  		this.loader=true;
		this.companyService.option_list().subscribe((res:any)=>{
			this.loader=false;
			console.log(res.data);
			this.result =res.data;
			console.log(this.result);
			

			for (let i=0; i< this.result.length; i++){

				for (let j=0; j< this.unitData.length; j++){

					if(this.result[i].unit==this.unitData[j].id){

						this.result[i].unit_value = this.unitData[j].text;
					}
				}
			}

		}, (err) => {

		});
	}

	optionDelete(id){
		this.companyService.deleteOption(id).subscribe((res:any)=>{
			console.log(res);
			this.result=res.data;
			if (res['success'] == 1 ) {
				this._flashMessagesService.show('Size  deleted successfully', { cssClass: 'alert-success' } );
				this.optionData();

				setTimeout(() => {
					this.router.navigate(['/size']);
					this.optionData();
				}, 2000); 

			} else {
				this.errors = res['message'];
			}

		}, (err) => {


		});

	}
	setOrder(value: string) {
		if (this.order === value) {
			this.reverse = !this.reverse;
		}

		this.order = value;
	}

	 opendeleteModal(id){
         this.deleteId = id;
        $('#exampleModal').modal('show');
        
    }
}
