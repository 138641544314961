import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../../company/service/company.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as $ from "jquery";
import { Globals } from './../../../globals';

@Component({
	selector: 'app-edit-options',
	templateUrl: './edit-options.component.html',
	styleUrls: ['./edit-options.component.css']
})
export class EditOptionsComponent implements OnInit {
	resultList: any;
	resultData : any;
	variantdataList : any ;
	resultVariant : any;
	msg: string = null;
	errors : any = '';
	result: any = {};
	Editid : any ;
	currentUserId : any = localStorage.getItem('id');
	variantList:any;
	categoryId:any;
	brandId: any;
	variantId:any;
	unitData:any;
	submit:boolean=false;
	loader:boolean = false;


    numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }


	constructor(private _flashMessagesService: FlashMessagesService,public companyService: CompanyService,private route: ActivatedRoute, private router: Router, private globals: Globals) {  }


	ngOnInit() {  	
		this.route.params.subscribe(params => {
			console.log(params);
			this.Editid=params['id']; 
			this.editoptionData();
			
		});
	}

	editoptionData(){
		this.loader=true;
		this.companyService.option_edit(this.Editid).subscribe((res:any)=>{
			this.loader=false;
			this.result=res.data;
			this.categoryId = res.data.category_id;
			this.brandId = res.data.brand_id;
			this.variantId = res.data.varient_id;
			//this.unitData = res.data.unit;
			this.brandData(this.brandId);
			//this.categoryData(this.categoryId);
			//this.variantData(this.variantId);
			this.setUnitData(res.data.unit);
			console.log(this.result);
		}, (err) => {

		});
	}

	onSubmit(form?:NgForm) {
		let f = form.value;
		f.category_id =this.result.category_id;
		f.brand_id    =this.result.brand_id;  
		f.varient_id  =this.result.varient_id;
		
		this.companyService.option_update(this.Editid,f).subscribe((res: any)=>{
			//console.log(res);
			this.resultData = res;
			if (this.resultData.success == 1 ) {
				this.submit=true;   
				this._flashMessagesService.show('Size updated successfully', { cssClass: 'alert-success' } );

				setTimeout(() => {
					this.router.navigate(['/size']);
				}, 2000); 
			} else {
				this.errors = res.message;
					 setTimeout(() => {
	            this.errors.size_id = false;
	          }, 3000); 
			}

		}, (err) => {

		});
	}

	//Unit Changed Event
	unitChanged(event){
		console.log(event);
		this.result.unit=event.value;
	}
	
	//Brand Data Start
	brandChanged(event,categoryId){
		this.result.brand_id=event.value;
	    let catBrand = this.result.brand_id;
	    var result = [];
		this.companyService.categorybrand_list(catBrand).subscribe((res:any)=>{
			result = res.data;
			
			let categoryData = res.data.filter(function(obj){

				return obj.brand_id = catBrand;
			});

			console.log(categoryData);
			this.resultList = categoryData.map(function(obj) {
				var rObj = {};
				rObj['id'] = obj.id;
				rObj['text'] = obj.name;
				rObj['selected'] = (obj.id==categoryId) ? true : false;
				return rObj;
			});
			console.log(this.resultList);
		}, (err) => {

		});
	}

	async brandData(brandId){
		var result = [];
		await this.companyService.brand_list().subscribe((res:any)=>{
			result = res.data;
			this.variantdataList = result.map(function(obj) {
				var rObj = {};
				rObj['id'] = obj.id;
				rObj['text'] = obj.name;
				rObj['selected'] = (obj.id==brandId) ? true : false;

				return rObj;
			});
			console.log(this.variantdataList);
		}, (err) => {

		});
	}
	//Brand Data Finish

	//Category Data Start

	// async categoryData(categoryId){
	// 	this.resultList = [];
	// 	var result = [];
	// 	await this.companyService.category_list().subscribe((res:any)=>{
	// 		result = res.data;
	// 		this.resultList = result.map(function(obj) {
	// 			var rObj = {};
	// 			rObj['id'] = obj.id;
	// 			rObj['text'] = obj.name;
	// 			rObj['selected'] = (obj.id==categoryId) ? true : false;

	// 			return rObj;
	// 		});

	// 	}, (err) => {

	// 	});
	// 	console.log(this.resultList);
	// }

	categoryChanged(event,variantId){
		console.log(event);
		this.result.category_id=event.value;

		let varCat = this.result.category_id;
	    var result = [];
		this.companyService.variantCat_list(varCat).subscribe((res:any)=>{
			result = res.data;
			
			let categoryData = res.data.filter(function(obj){

				return obj.brand_id = varCat;
			});

			console.log(categoryData);
			this.variantList = categoryData.map(function(obj) {
				var rObj = {};
				rObj['id'] = obj.id;
				rObj['text'] = obj.name;
				rObj['selected'] = (obj.id==variantId) ? true : false;
				return rObj;
			});
			console.log(this.variantList);
		}, (err) => {

		});

	}

	//Categoty Data Finish

	//Variant Data Start
	variantChanged(event){
		console.log(event);
		this.result.varient_id=event.value;
	}

	// async variantData(variantId){
	// 	var result = [];
	// 	await this.companyService.variant_list().subscribe((res:any)=>{
	// 		result = res.data;
	// 		this.variantList = result.map(function(obj) {
	// 			var rObj = {};
	// 			rObj['id'] = obj.id;
	// 			rObj['text'] = obj.name;
	// 			rObj['selected'] = (obj.id==variantId) ? true : false;

	// 			return rObj;
	// 		});
	// 		console.log(this.variantList);
	// 	}, (err) => {

	// 	});
	// }

	//Varinat Data Finish
	setUnitData(unitId){
		this.unitData = this.globals.unitData;
		this.unitData.filter(function(unit){

			unit.id == unitId ? unit.selected=true : unit.selected =false;  
		});
	}


}
