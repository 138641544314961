import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Config } from '../../../config';
import { Observable } from 'rxjs';


const TOKEN = 'TOKEN';

const httpOptions = {
    headers: new HttpHeaders({
        //'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': "*",
        // 'Access-Control-Allow-Methods' :"GET, POST, DELETE, PUT",
        // 'Access-Control-Allow-Headers' :"Origin, X-Requested-With, Content-Type, Accept",
        'x-key': localStorage.getItem('companyRandom_id'),
        'x-access-token': localStorage.getItem('access_token')
    })
};

@Injectable({
  providedIn: 'root'
})
export class BusinessService {
    apiURL: string = Config.API_URL + "company/";
    apiURL2: string = Config.API_URL + "businessrole/";
    apiURL3: string = Config.API_URL + "businessuser/";

    constructor(private httpClient: HttpClient) { }

    businessRole_add(data) {
        return this.httpClient.post(`${Config.API_URL}businessrole`, data, httpOptions);
    }

    businessRole_list() {
        return this.httpClient.get(`${Config.API_URL}businessrole`, httpOptions);
    }

    deleteBusinessRole(id) {
        return this.httpClient.delete(`${this.apiURL2}${id}`, httpOptions);
    }

    businessRole_edit(id) {
        return this.httpClient.get(`${Config.API_URL}businessrole/${id}`, httpOptions);
    }

    businessRole_update(id, data) {
        return this.httpClient.put(`${Config.API_URL}businessrole/${id}`, data, httpOptions);
    }

    businessUser_add(data) {
        return this.httpClient.post(`${Config.API_URL}businessuser`, data, httpOptions);
    }

    businessUser_list() {
        return this.httpClient.get(`${Config.API_URL}businessuser`, httpOptions);
    }

    deleteBusinessUser(id, roleId) {
        return this.httpClient.delete(`${this.apiURL3}${id}?roleId=${roleId}`, httpOptions);
    }

    businessUser_edit(id) {
        return this.httpClient.get(`${Config.API_URL}businessuser/${id}`, httpOptions);
    }

    businessUser_update(id, data) {
        return this.httpClient.put(`${Config.API_URL}businessuser/${id}`, data, httpOptions);
    }

    //business parent role
      businessRole_parent(id) {
        return this.httpClient.get(`${Config.API_URL}getParentUserByRoleId/${id}`, httpOptions);
    }

    //get role user parent
     businessRole_parentdata(role_id,user_id) {
        return this.httpClient.get(`${Config.API_URL}getChildUserByRoleId/${role_id}/${user_id}`, httpOptions);
    }

    businessRole_parentdatadd(data) {
        return this.httpClient.post(`${Config.API_URL}allocateDeallocateChildUsers`, data, httpOptions);
    }    

    getBusinessConsignment(pageNo, pageSize) {
        return this.httpClient.get(`${Config.API_URL}getBusinessConsignment/${pageNo}/${pageSize}`, httpOptions);
    } 

    getBusinessConsignmentSKUDetails(id) {
        return this.httpClient.get(`${Config.API_URL}getBusinessConsignmentSKUDetails/${id}`, httpOptions);
    }   

    searchConsignment(pageNo,pageSize,data){
        return this.httpClient.post(`${Config.API_URL}getBusinessConsignmentSearch/${pageNo}/${pageSize}`, data,httpOptions);
    }
}
