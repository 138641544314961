import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../../company/service/company.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as $ from "jquery";
import { Observable} from 'rxjs';

@Component({
	selector: 'app-edit-variant',
	templateUrl: './edit-variant.component.html',
	styleUrls: ['./edit-variant.component.css']
})
export class EditVariantComponent implements OnInit {
	resultList: any;
	resultData : any;
	variantdataList : any;
	resultVariant : any;
	msg: string = null;
	errors : any = '';
	result: any = {};
	Editid : any;
	currentUserId : any = localStorage.getItem('id');
	categoryId: number;
	brandId:number;
	submit:boolean=false;
	loader:boolean = false;


	constructor(private _flashMessagesService: FlashMessagesService,public companyService: CompanyService,private route: ActivatedRoute, private router: Router) {  }

	ngOnInit() {  	
		this.route.params.subscribe(params => {
			console.log(params);
			this.Editid=params['id']; 
			this.editvariantData();
		});
	}
	editvariantData(){
		this.loader=true;
		this.companyService.variant_edit(this.Editid).subscribe((res:any)=>{
			this.loader=false;
			this.result=res.data;
			console.log(this.result);
			this.categoryId = res.data.category_id;
			this.brandId = res.data.brand_id;
			this.brandData(this.brandId);
			console.log(this.result);
		}, (err) => {

		});
	}

	onSubmit(form?:NgForm) {
		let f = form.value;
		f.category_id=this.result.category_id;
		f.brand_id=this.result.brand_id;
		this.companyService.variant_update(this.Editid,f).subscribe((res: any)=>{
			this.resultData = res;
			if (this.resultData.success == 1 ) {
				this.submit=true; 
				this._flashMessagesService.show('Variant updated successfully', { cssClass: 'alert-success' } );
				setTimeout(() => {
					this.router.navigate(['/variant']);
				}, 2000); 	
			} else {
				this.errors = res.message;
				 setTimeout(() => {
			          this.errors.varient_id = false;
			        }, 3000); 
			}

		}, (err) => {

		});
	}
	
	
	brandChanged(event,categoryId){
		this.result.brand_id=event.value;
	    let catBrand = this.result.brand_id;
	    var result = [];
		this.companyService.categorybrand_list(catBrand).subscribe((res:any)=>{
			result = res.data;
			
			let categoryData = res.data.filter(function(obj){

				return obj.brand_id = catBrand;
			});

			console.log(categoryData);
			this.resultList = categoryData.map(function(obj) {
				var rObj = {};
				rObj['id'] = obj.id;
				rObj['text'] = obj.name;
				rObj['selected'] = (obj.id==categoryId) ? true : false;
				return rObj;
			});
			console.log(this.resultList);
		}, (err) => {

		});
	}

	async brandData(brandId){
		var result = [];
		await this.companyService.brand_list().subscribe((res:any)=>{
			result = res.data;
			this.variantdataList = result.map(function(obj) {
				var rObj = {};

				rObj['id'] = obj.id;
				rObj['text'] = obj.name;
				rObj['selected'] = (obj.id == brandId) ? true : false;
				return rObj;
			});
			console.log(this.variantdataList);

		}, (err) => {

		});
	}

	// async categoryData(brandId,categoryId){
	// 	var result = [];
	// 	await this.companyService.categorybrand_list(brandId).subscribe((res:any)=>{
	// 		result = res.data;
			
	// 		let categoryData = res.data.filter(function(obj){

	// 			return obj.brand_id = brandId;
	// 		});

	// 		console.log(categoryData);
	// 		this.resultList = categoryData.map(function(obj) {
	// 			var rObj = {};
	// 			rObj['id'] = obj.id;
	// 			rObj['text'] = obj.name;
	// 			rObj['selected'] = (obj.id==categoryId) ? true : false;
	// 			return rObj;
	// 		});
	// 		console.log(this.resultList);
	// 	}, (err) => {

	// 	});
	// }


	categoryChanged(event){
		this.result.category_id=event.value;
		//console.log(this.result.category_id);
	}

}
