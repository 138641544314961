import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../../company/service/company.service';
declare var $: any;
import { OrderPipe } from 'ngx-order-pipe';
import { FlashMessagesService } from 'angular2-flash-messages';
import { Globals } from './../../../globals';

@Component({
  selector: 'app-detail-role-menu-permissions',
  templateUrl: './detail-role-menu-permissions.component.html',
  styleUrls: ['./detail-role-menu-permissions.component.css']
})
export class DetailRoleMenuPermissionsComponent implements OnInit {

    data: any;
    searchText = "";
    sortedCollection: any[];
    order: string = 'result.id';
    reverse: boolean = false;
    result: any = [];
    arr: any = [];
    errors: any = '';
    deletedMsg: string = null;
    p = this.globals.p;
    currentLimit = this.globals.currentLimit;
    deleteId: any;
    pageLimits = this.globals.pageLimits;
    loader: boolean = false;
    Roleid: any;

    constructor(private globals: Globals,
        private _flashMessagesService: FlashMessagesService,
        public companyService: CompanyService,
        private route: ActivatedRoute,
        private router: Router,
        private orderPipe: OrderPipe) {
        this.sortedCollection = orderPipe.transform(this.result, 'result.id');
        console.log(this.sortedCollection);
    }

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.Roleid = params['id'];
            this.roleMenuPermissionData();
        });
    }

    roleMenuPermissionData() {
        this.loader = true;
        this.companyService.getRoleMenuPermission(this.Roleid).subscribe((res: any) => {
            this.loader = false;
            console.log(res.data);
            this.result = res.data;
        }, (err) => {
            console.log(err);
        });
    }

    setOrder(value: string) {
        if (this.order === value) {
            this.reverse = !this.reverse;
        }
        this.order = value;
    }

    opendeleteModal(id) {
        this.deleteId = id;
        $('#exampleModal').modal('show');
    }

    endCount(p, currentLimit, reportCount) {
        return Math.min((p * currentLimit), reportCount);
    }
}
