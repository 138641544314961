import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../../company/service/company.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as $ from "jquery";
import { Globals } from './../../../globals';


@Component({
  selector: 'app-add-sku',
  templateUrl: './add-sku.component.html',
  styleUrls: ['./add-sku.component.css']
})
export class AddSkuComponent implements OnInit {
  model : any = {};
  resultList: any;
  msg: string = null;
  errors : any = '';
  variantdataList : any;
  variantList: any;
  unitData: any;
  resultProduct:any;
  packageList:any;
  public imagePath;
  imgURL: any = "/assets/images/placeholder.png";
  main_image: any;
  message : any;
  filedata:any;
  brandOptions:any;
  categoryOptions:any;
  variantOptions:any;
  unitOptions:any;
  packagingOptions:any;
  result:any;
  sizeList:any;
  submit:boolean=false;

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31  && (charCode < 48 || charCode > 57)) {
      return false;
    }
    
    return true;

  }

  constructor(private globals: Globals,private _flashMessagesService: FlashMessagesService, public companyService: CompanyService,private route: ActivatedRoute, private router: Router) { }

  async  ngOnInit() {
    this.brandOptions    = { placeholder: { id: '', text: 'Select Brand' } };
    this.categoryOptions = { placeholder: { id: '', text: 'Select Category' } };
    this.variantOptions  = { placeholder: { id: '', text: 'Select Variant' } };
    this.unitOptions     = { placeholder:  { id: '', text: 'Select  Size' } };
    this.packagingOptions= { placeholder:  { id: '', text: 'Select  Packaging' } };
    await  this.brandData();
    //await   this.packageData();
    this.unitData = this.globals.unitData;

  }

  onSubmit(form?:NgForm) {
    if (this.filedata == undefined && this.filedata == null ) {
      this.message="Image Required";
    }    
    let f = form.value;
    console.log(f);
    f.category_id=this.model.category_id;
    f.brand_id=this.model.brand_id;
    f.varient_id=this.model.varient_id;
    f.size_id=this.model.size_id;
    f.packaging_id=this.model.packaging_id;
    
    let formdata = new FormData();
    console.log(formdata);
    formdata.append("main_image",this.filedata);
    formdata.append("category_id",f.category_id);
    formdata.append("varient_id",f.varient_id);
    formdata.append("brand_id",f.brand_id);
    formdata.append("size_id",f.size_id);
    formdata.append("packaging_id",f.packaging_id);
    formdata.append("shipper_size",f.shipper_size);
    formdata.append("product_mrp",f.product_mrp);
    formdata.append("sku",f.sku);
    
    this.companyService.product_add(formdata).subscribe((res : any)=>{
      console.log(res);

      this.resultProduct = res;
      if (this.resultProduct.success == 1 ) {
        this.submit=true; 
        this._flashMessagesService.show('Sku created successfully', { cssClass: 'alert-success' } );

        setTimeout(() => {
          this.router.navigate(['/sku']);
        }, 2000); 
        
      } else {
        this.errors = res.message;
      }

    }, (err) => {

    });

  }

  

  //Brand Data Start
  brandChanged(event){
    console.log(event);
    if(event.value!='select'){
      this.model.brand_id=event.value;
      let catBrand = this.model.brand_id;

      var result = [];
      this.companyService.categorybrand_list(catBrand).subscribe((res:any)=>{
        this.result = res.data;
        var rObj= [];
        rObj.push({"id":"","text":""});
        for(let i=0; i<res.data.length; i++){

          rObj.push({"id":res.data[i].id, "text":res.data[i].name});
        }      
        this.resultList = rObj;
      }, (err) => {

      });
    }
  }
  async brandData(){
    await this.companyService.brand_list().subscribe((res:any)=>{
      this.result = res.data;
      var rObj= [];
      rObj.push({"id":"","text":""});
      for(let i=0; i<res.data.length; i++){

        rObj.push({"id":res.data[i].id, "text":res.data[i].name});
      }      
      this.variantdataList = rObj;

      console.log(this.variantdataList);
    }, (err) => {

    });
  }
  //Brand Data Finish

  categoryChanged(event){
    console.log(event);
    if(event.value!='select'){
      this.model.category_id=event.value;
      let catVar = this.model.category_id;
      var result = [];
      this.companyService.variantCat_list(catVar).subscribe((res:any)=>{

        this.result = res.data;
        var rObj= [];
        rObj.push({"id":"","text":""});
        for(let i=0; i<res.data.length; i++){

          rObj.push({"id":res.data[i].id, "text":res.data[i].name});
        }      
        this.variantList = rObj;
      }, (err) => {

      });

    }

  }
  //Categoty Data Finish

  //Variant Data Start
  variantChanged(event){
    console.log(event);
    if(event.value!='select'){
      this.model.varient_id=event.value;

      let catVar = this.model.varient_id;

      var result = [];
      this.companyService.packaging_size(catVar).subscribe((res:any)=>{

        this.result = res.data;
        var rObj= [];
        rObj.push({"id":"","text":""});
        for(let i=0; i<res.data.length; i++){

          let unitName;
          for(let j=0; j< this.unitData.length; j++){
            if(res.data[i].unit==this.unitData[j].id){
              unitName = this.unitData[j].text;
            }
          }

          rObj.push(
          {
            "id":res.data[i].id, 
            "text":res.data[i].quantity+" "+unitName
          }
          );
        }      
        this.sizeList = rObj;
      }, (err) => {

      });

    }

  }

  //Unit Changed Event
  unitChanged(event){
    console.log(event);
   if(event.value!='select'){
    this.model.size_id=event.value;
    console.log(this.model.size_id);
     let catVar = this.model.size_id;
      var result = [];
      this.companyService.product_packbysize(catVar).subscribe((res:any)=>{

        this.result = res.data;
        var rObj= [];
        rObj.push({"id":"","text":""});
        for(let i=0; i<res.data.length; i++){

          rObj.push({"id":res.data[i].id, "text":res.data[i].packaging_type});
        }      
        this.packageList = rObj;
      }, (err) => {

      });

    }


  }


  //Varinat Data Finish

  //packaging Data Start
  packagingChanged(event){
    if(event.value!='select'){
      console.log(event);
      this.model.packaging_id=event.value;
    }
  }
  // async packageData(){
  //   await this.companyService.packaging_list().subscribe((res:any)=>{
  //     this.result = res.data;
  //     var rObj= [];
  //     rObj.push({"id":"","text":""});
  //     for(let i=0; i<res.data.length; i++){

  //       rObj.push({"id":res.data[i].id, "text":res.data[i].packaging_type});
  //     }      
  //     this.packageList = rObj;
  //     console.log(this.packageList);

  //     // result = res.data;
  //     // console.log(result);
  //     // this.packageList = result.map(function(obj) {
  //       //   var rObj = {};
  //       //   rObj['id'] = obj.id;
  //       //   rObj['text'] = obj.packaging_type;
  //       //   return rObj;
  //       // });
  //     }, (err) => {

  //     });
  // }

  //packaging Data Finish


  onFileChange(e){
    this.filedata=e.target.files[0];
    console.log("this.filedata");
    console.log(this.filedata);
  }
  
  preview(files) {
    if (files.length === 0)
      return;
    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }

    var reader = new FileReader();
    this.imagePath = files;
    console.log(this.imagePath);

    reader.readAsDataURL(files[0]); 
    reader.onload = (_event) => { 
      this.imgURL = reader.result;
      
    }
  }    

}
