import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../../../company/service/company.service';
import { FlashMessagesService } from 'angular2-flash-messages';

@Component({
  selector: 'app-edit-companyrole',
  templateUrl: './edit-companyrole.component.html',
  styleUrls: ['./edit-companyrole.component.css']
})
export class EditCompanyroleComponent implements OnInit {
 
    result: any = [];
    resultList: any;
    errors: any = '';
    Editid: any;
    resultData: any;
    msg: string = null;
    public message: string;
    filedata: any;
    submit:boolean=false;

  constructor(private _flashMessagesService: FlashMessagesService, public companyService: CompanyService,private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
     this.route.params.subscribe(params => {
            console.log(params);
            this.Editid = params['id'];
            this.editRoleData();
        });

  }

    editRoleData() {
        this.companyService.companyrole_edit(this.Editid).subscribe((res: any) => {
            this.result = res.data;
            console.log(this.result);

        }, (err) => {


        });
    }

    onSubmit(form?: NgForm) {
        let f = form.value;
        let formdata = new FormData();
        formdata.append("name", f.name);

        this.companyService.companyrole_update(this.Editid, formdata).subscribe((res: any) => {

            this.resultData = res;

            if (this.resultData.success == 1) {
                 this.submit=true; 
                this._flashMessagesService.show('Company Role Updated Successfully!', { cssClass: 'alert-success' });

                setTimeout(() => {
                    this.router.navigate(['/companyrole']);
                }, 3000);

            } else {
                this.errors = res.message;
                  setTimeout(() => {
                this.errors.companyrole_id = false;
              }, 3000); 
            }

        }, (err) => {

        });

    }


}
