import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../../company/service/company.service';
import { OrderPipe } from 'ngx-order-pipe';
import { NgForm } from "@angular/forms";
import { FlashMessagesService } from 'angular2-flash-messages';

@Component({
  selector: 'app-edit-category',
  templateUrl: './edit-category.component.html',
  styleUrls: ['./edit-category.component.css']
})
export class EditCategoryComponent implements OnInit {
  
    result : any = [];
	resultList: any;
	errors : any = '';
	Editid : any ;
	resultData : any;
	msg: string = null;
	public imagePath;
	imgURL: any = "/assets/images/placeholder.png";
	image: any;
	public message: string;
	brandId:number;
	submit:boolean=false;
    loader:boolean = false;

	constructor(private _flashMessagesService: FlashMessagesService,public companyService: CompanyService,private route: ActivatedRoute, private router: Router,private orderPipe: OrderPipe) {  }

  ngOnInit() {

  	//this.brandData();

		this.route.params.subscribe(params => {
			console.log(params);
			this.Editid=params['id']; 
		  this.editcategoryData();
			console.log(this.editcategoryData());
		});


  }

   editcategoryData(){
   	     this.loader=true;
		this.companyService.category_edit(this.Editid).subscribe((res:any)=>{
			 this.loader=false;
			this.result=res.data;
			this.brandId = res.data.brand_id;
			this.brandData(this.brandId);
			console.log(this.result);

		}, (err) => {


		});
	}

	onSubmit(form?:NgForm) {
		let f = form.value;
		
		this.companyService.category_update(this.Editid,f).subscribe((res: any)=>{
			
			this.resultData = res;

			if (this.resultData.success == 1 ) {
				  this.submit=true;   
				  this._flashMessagesService.show('Category updated successfully', { cssClass: 'alert-success' } );

				setTimeout(() => {
					this.router.navigate(['/category']);
				}, 2000); 
				
			} else {
				this.errors = res.message;
				   setTimeout(() => {
		            this.errors.category_id = false;
		          }, 3000); 
			}

		}, (err) => {

			
		});

	}

	brandChanged(event){
		console.log(event);
		this.result.brand_id=event.value;
	}

	async brandData(brandId){
		var result = [];
		await this.companyService.brand_list().subscribe((res:any)=>{
			result = res.data;
			this.resultList = result.map(function(obj) {
				var rObj = {};

				rObj['id'] = obj.id;
				rObj['text'] = obj.name;
				rObj['selected'] = (obj.id==brandId) ? true : false;
				return rObj;
			});
			console.log(this.resultList);

		}, (err) => {

		});
	}
}
