import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../../company/service/company.service';
declare var $ :any; 
import { OrderPipe } from 'ngx-order-pipe';
import { FlashMessagesService } from 'angular2-flash-messages';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv'
import { Globals } from './../../../globals';

@Component({
  selector: 'app-batchdetails',
  templateUrl: './batchdetails.component.html',
  styleUrls: ['./batchdetails.component.css']
})
export class BatchdetailsComponent implements OnInit {

	public batchId:string;
  unitData:any;
  loader:boolean = false;
	public result = {

		role_details : [],
		shipper_details :[{random_id:"",cnt:"",scancnt:""}],
		packaging_qrcode_details:[{random_id:"",cnt:"",scancnt:""}],
		product_details : {
			brand :{brand_name:""},
			category:{category_name:""},
			varient:{varient_name:""},
			option:{ quantity:"",unit:""},
			packaging:{packaging_type:""},
			option_name : "",
      sku:"",
		},
		batch_details:{
      batch_no:"",
      id:"",
      manufacturing_date:"",
      expiry_date:"",
			production_unit:{plant_name:""},
			production_line:{line1_details:""}
		},
	};

	csvOptions = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      title: 'Qrcode List :',
      useBom: true,
      noDownload: false,
      headers: []  //headers: ["QrCode"]
    };

	public totalQrcode: number=0;
	public size:any;
	constructor( 
		private _flashMessagesService: FlashMessagesService,
		public companyService: CompanyService,
		private route: ActivatedRoute, 
		private router: Router,
		private orderPipe: OrderPipe,
		private globals: Globals) {  
     }

	async ngOnInit() {
    this.loader=true;
    this.unitData = this.globals.unitData;
		await this.route.params.subscribe(params => {
			this.batchId=params['id']; 
		});
		
		await this.companyService.getBatchAllDetails(this.batchId).subscribe((res:any)=>{
      this.loader=false;
			if(res.success=="1"){

				this.result = res;
				console.log(this.result);
				for(let i=0; i< this.result.role_details.length; i++){

					this.totalQrcode = this.totalQrcode +  parseInt(this.result.role_details[i].cnt);
				}


        for (let j=0; j< this.unitData.length; j++){

          if(this.result.product_details.option.unit==this.unitData[j].id){

            this.size = this.unitData[j].text;
          }

        }

				// this.size = this.globals.unitData.filter(function(obj){

				// 	return this.result.product_details.option.unit === obj.id;
				// });
        //console.log(this.result.product_details.option.unit); 

				this.result.product_details.option_name = this.result.product_details.option.quantity +  this.size;

				console.log(this.result);
			}
		});
	}

	/**
	 * [exportRoleQrcode description]
	 * @author Suresh Suthar
	 * @Created Date 2019-04-12
	 */
	exportQrRolecode(roleUniqueId){

		console.log(roleUniqueId);
        this.companyService.getQrcodeList(roleUniqueId).subscribe((res:any)=>{

            if(res.success==1){

              let qrCode = res.data;
              let random = Math.random().toString(36).substring(7);
              let fileName = roleUniqueId;
              new  AngularCsv(qrCode, fileName, this.csvOptions);
            }
            else{
                this._flashMessagesService.show(res.message, { cssClass: 'alert-success' } );
                
                  setTimeout(() => {
                    this.router.navigate(['/batch']);
              }, 3000); 
            }
        });
    }

    /**
     * [exportShipperRolecode description]
     * @author Suresh Suthar
     * @Created Date     2019-04-12
     * @param   {[type]} shipperId  [description]
     */
    exportShipperCode(shipperId){
    	this.companyService.getShipperQrcodeList(shipperId).subscribe((res:any)=>{

            if(res.success==1){

              let qrCode = res.data;
              let random = Math.random().toString(36).substring(7);
              let fileName = shipperId;
              new  AngularCsv(qrCode, fileName, this.csvOptions);
            }
            else{
                this._flashMessagesService.show(res.message, { cssClass: 'alert-success' } );
                
                  setTimeout(() => {
                    this.router.navigate(['/batch']);
              }, 3000); 
            }
        });
    }

    /**
     * [exportPackgingRolecode description]
     * @author Suresh Suthar
     * @Created Date     2019-04-12
     * @param   {[type]} id         [description]
     */
    exportPackgingcode(id){

        this.companyService.getPackagingQrcodeList(id).subscribe((res:any)=>{

            if(res.success==1){

              let qrCode = res.data;
              let random = Math.random().toString(36).substring(7);
              let fileName = id;
              new  AngularCsv(qrCode, fileName, this.csvOptions);
            }
            else{
                this._flashMessagesService.show(res.message, { cssClass: 'alert-success' } );
                
                  setTimeout(() => {
                    this.router.navigate(['/batch']);
              }, 3000); 
            }
        });
    }

    /**
     * [exportSkuQRcode description]
     * @author Suresh Suthar
     * @Created Date     2019-04-12
     * @param   {[type]} batchId    [description]
     */
    exportSkuQRcode(batchId){

    	this.companyService.getBatchQRCode(batchId).subscribe((res:any)=>{

            if(res.success==1){

              let qrCode = res.data;
              let random = Math.random().toString(36).substring(7);
              let fileName = batchId;
              new  AngularCsv(qrCode, fileName, this.csvOptions);
            }
            else{
                this._flashMessagesService.show(res.message, { cssClass: 'alert-success' } );
                
                  setTimeout(() => {
                    this.router.navigate(['/batch']);
              }, 3000); 
            }
        });
    }
}
