export const Config = Object.freeze({

	//Live server
 	API_URL: "https://kcapps.trusttags.in/company/",
	API_LOGIN_URL : "https://kcapps.trusttags.in/"
	
	//LocalHost
	//API_URL: "http://localhost:3000/company/",
	//API_LOGIN_URL : "http://localhost:3000/"

	//Staging
	// API_URL: "https://kc-dev-api.trusttags.in/company/",
	// API_LOGIN_URL : "https://kc-dev-api.trusttags.in/"
	
});