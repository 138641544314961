import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../../company/service/company.service';
import { OrderPipe } from 'ngx-order-pipe';
import { NgForm } from "@angular/forms";
import { FlashMessagesService } from 'angular2-flash-messages';

@Component({
  selector: 'app-edit-brand',
  templateUrl: './edit-brand.component.html',
  styleUrls: ['./edit-brand.component.css']
})
export class EditBrandComponent implements OnInit {

    result : any = [];
	resultList: any;
	errors : any = '';
	Editid : any ;
	resultData : any;
	msg: string = null;
	public imagePath;
	imgURL: any = "/assets/images/placeholder.png";
	image: any;
	public message: string;
	companyId:number;
	mainlogo: any;
    filedata:any;
    submit:boolean=false;
    loader:boolean = false;



	constructor(private _flashMessagesService: FlashMessagesService,public companyService: CompanyService,private route: ActivatedRoute, private router: Router,private orderPipe: OrderPipe) {  }

  ngOnInit() {
 
		this.route.params.subscribe(params => {
			console.log(params);
			this.Editid=params['id']; 
		  this.editbrandData();
		});
  }
  editbrandData(){
  	    this.loader=true;
		this.companyService.brand_edit(this.Editid).subscribe((res:any)=>{
			this.loader=false;
			this.result=res.data;
			this.companyId = res.data.company_id;
			this.imgURL=res.data.mainlogo;
			console.log(this.imgURL);
			this.companyData(this.companyId);
			console.log(this.result);

		}, (err) => {


		});
	}

	onSubmit(form?:NgForm) {
		let f = form.value;
		let formdata = new FormData();
		console.log(this.filedata);
        formdata.append("mainlogo",this.filedata);
        formdata.append("name",f.name);


		this.companyService.brand_update(this.Editid,formdata).subscribe((res: any)=>{
			
			this.resultData = res;

			if (this.resultData.success == 1 ) {
				  this.submit=true;
				  this._flashMessagesService.show('Brand updated successfully', { cssClass: 'alert-success' } );

				setTimeout(() => {
					this.router.navigate(['/brand']);
				}, 3000); 
				
			} else {
				this.errors = res.message;
				setTimeout(() => {
			          this.errors.brand_id = false;
			        }, 3000); 
			}

		}, (err) => {
		
		});

	}

   async companyData(companyId){
      var result = [];
   await  this.companyService.company_list().subscribe((res:any)=>{
        result = res.data;
       this.resultList = result.map(function(obj) {
              var rObj = {};
              rObj['id'] = obj.id;
              rObj['text'] = obj.name;
              rObj['selected'] = (obj.id==companyId) ? true : false;
              return rObj;
          });
        console.log(this.resultList);
      }, (err) => {

      });
    }
    companyChanged(event){
		console.log(event);
		this.result.company_id=event.value;
	}

	 onFileChange(e){
        this.filedata=e.target.files[0];
        console.log("this.filedata");
        console.log(this.filedata);
    }
  
  preview(files) {
    if (files.length === 0)
      return;
    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }

    var reader = new FileReader();
    this.imagePath = files;
    console.log(this.imagePath);

    reader.readAsDataURL(files[0]); 
    reader.onload = (_event) => { 
    this.imgURL = reader.result;
 
    }
  }    
  
}
