import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BusinessService } from '../../service/business.service';
import { CompanyService } from '../../../../company/service/company.service';
import { OrderPipe } from 'ngx-order-pipe';
import { FlashMessagesService } from 'angular2-flash-messages';
import { Globals } from './../../../../globals';

@Component({
  selector: 'app-detail-consignment',
  templateUrl: './detail-consignment.component.html',
  styleUrls: ['./detail-consignment.component.css']
})
export class DetailConsignmentComponent implements OnInit {

    data: any;
    searchText = "";
    sortedCollection: any[];
    order: string = 'result.id';
    reverse: boolean = false;
    result: any = [];
    arr: any = [];
    errors: any = '';
    deletedMsg: string = null;
    p = this.globals.p;
    currentLimit = this.globals.currentLimit;
    deleteId: any;
    pageLimits = this.globals.pageLimits;
    loader: boolean = false;
    ConsignmentId: any;
   ConsignmentResult:any =[];

    constructor(private globals: Globals,
        private _flashMessagesService: FlashMessagesService,
        public businessService: BusinessService,
        private route: ActivatedRoute,
        private router: Router,
        public companyService: CompanyService,
        private orderPipe: OrderPipe) {
        this.sortedCollection = orderPipe.transform(this.result, 'result.id');
        console.log(this.sortedCollection);
    }

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.ConsignmentId = params['id'];
            //console.log(this.ConsignmentId);
            this.ConsignmentSKUData();
        });
    }

    ConsignmentSKUData() {
        this.loader = true;
        this.businessService.getBusinessConsignmentSKUDetails(this.ConsignmentId).subscribe((res: any) => {
           // console.log(res);
            this.loader = false;
            console.log(res.data);
            this.result = res.data;
        }, (err) => {
            console.log(err);
        });
    }

    setOrder(value: string) {
        if (this.order === value) {
            this.reverse = !this.reverse;
        }
        this.order = value;
    }

    selected(event,id){
        console.log(event,id);
        //console.log();
       this.companyService.getconsignmentDetais(this.ConsignmentId,id).subscribe((res: any) => {
            console.log(res.data);
            this.ConsignmentResult = res.data;
        }, (err) => {
            console.log(err);
        });

        //console.log(this.ConsignmentResult);
    }


}
