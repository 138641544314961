import { Component, OnInit ,NgZone,ElementRef,ViewChild } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../../company/service/company.service';
import { BusinessService } from '../../business/service/business.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import * as $ from "jquery";

@Component({
  selector: 'app-add-supplychain',
  templateUrl: './add-supplychain.component.html',
  styleUrls: ['./add-supplychain.component.css']
})
export class AddSupplychainComponent implements OnInit {

  resultList: any;
  msg: string = null;
  errors : any = '';
  model:any= {};
  chain_unit_ids_arr: { [index:string]:any } = [];
  submit:boolean=false;
  roleOptions:any;
  result:any;
  isCurrentPlant:boolean = false;
  isCurrentUnit:boolean=false;
  ParentRoles:any =[];
  plantList:any;
  plantOptions:any;
  unitOptions:any;
  roles:any;
  unitList:any=[];
  supplyChain:any;
 
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  constructor(public businessService: BusinessService,private _flashMessagesService: FlashMessagesService, public companyService: CompanyService,private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {

    this.roleOptions    = { placeholder: { id: '', text: 'Select  Role' } };
    this.plantOptions   = { multiple: true,placeholder: { id: '', text: 'Select Plants' } };
    this.unitOptions    = { multiple: true,placeholder: { id: '', text: 'Select Units' } };
    this.roleData();
   
  }


  async  roleData() {
        var result = [];
        await this.businessService.businessRole_list().subscribe((res: any) => {
            this.result = res.data;
            var rObj= [];
            rObj.push({"id":"","text":"select"});
            for(let i=0; i<res.data.length; i++){

                rObj.push({"id":res.data[i].id, "text":res.data[i].name});
            }      
            this.resultList = rObj;

            }, (err) => {

            });
    }
     roleChanged(event) {
       this.isCurrentPlant=false;
       this.isCurrentUnit=false; 
       if(event.value!='select'){
        console.log(event);
        this.model.role_id = event.value;
        let Parentrole=this.model.role_id;
        // Check Plant Exist Or Not
        this.companyService.getParentBasedRole(Parentrole).subscribe((res:any)=>{
              this.result = res;
              //console.log(this.result);
              if(this.result.plants.length > 0){
                var rObj= [];
                rObj.push({"id":"","text":"select"});
                 for(let i=0; i<res.plants.length; i++){
                   rObj.push({"id":res.plants[i].id, "text":res.plants[i].plant_name});
              }      
              this.plantList = rObj;
              this.isCurrentPlant=true;
              }
              else{
               this.isCurrentPlant=false;

               if(this.result.units.length > 0){
                 
                  this.roles = this.result.businessrole_parent_ids;
                  let roleData=[];
                  let roleRes;
                  for (let i = 0; i < this.result.businessrole_parent_ids.length; i++) {
                      
                      roleRes = [];
                      for (let j = 0; j < res.units.length; j++) {
                          
                         if(this.roles[i]==res.units[j].role_id){

                            roleRes.push({"id":res.units[j].id, "text":res.units[j].location});
                         }
                      }
                      roleData.push({role_id:this.result.businessrole_parent_ids[i],roles:roleRes});
                  } 
                 
                  this.roles = roleData;
                  console.log(roleData);
                  //Finish Select data for units
                  this.isCurrentUnit =true;
               }
               else{
                 this.isCurrentUnit =false;
               }

              }
                
            }, (err) => {

            });
     }
   }

    plantChanged(event) {
        console.log(event);
        this.model.plant_ids = event.value;
        console.log(this.model.plant_ids);
   }

     unitChanged(event,id,i) {
         console.log(event);
        // console.log(id); 
        this.chain_unit_ids_arr[i]=event.value;
        console.log(this.chain_unit_ids_arr);
       
     }

     onSubmit(form?: NgForm) {


         let f = form.value;
         f.plant_ids=[0];
          //f.chain_unit_ids=[0];
         // console.log(f);
         // console.log(f.chain_unit_ids);
       // return false;

        //  if(f.chain_unit_ids == undefined || f.chain_unit_ids == null || f.chain_unit_ids == '' || f.chain_unit_ids == [""] ){
        //    console.log("Data For plants");
        //     f.chain_unit_ids=["0"];
           
        // }
        // else{
        //   f.plant_ids=["0"];
          
        // }

        
        let data = this.chain_unit_ids_arr.join(',');
        console.log(data);
          f.chain_unit_ids=data.split(',');

         console.log(f.chain_unit_ids);
         console.log(f.plant_ids);
         console.log(f);
         
        this.companyService.supplychain_add(f).subscribe((res: any) => {
            console.log(res);
            this.supplyChain = res;

            if (this.supplyChain.success == 1) {
                this.submit=true; 
                this._flashMessagesService.show(' Supply Chain created successfully', { cssClass: 'alert-success' });
                setTimeout(() => {
                    this.router.navigate(['/supplychain']);
                }, 2000);

            } else {
                this.errors = res.message;
                 setTimeout(() => {
                 this.errors.chain_unit_ids = false;
                 this.errors.role_id = false;
                 this.errors.plant_ids = false;
              }, 3000); 

            }

        }, (err) => {

        });
    }

}
