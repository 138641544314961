import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../../company/service/company.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as $ from "jquery";
import { Globals } from './../../../globals';

@Component({
	selector: 'app-add-options',
	templateUrl: './add-options.component.html',
	styleUrls: ['./add-options.component.css']
})
export class AddOptionsComponent implements OnInit {
	resultOption : any;
	resultList : any;
	msg: string = null;
	errors : any = '';
	model: any = {};
	currentUserId : any = localStorage.getItem('id');
	variantdataList : any;
	variantList: any;
	unitData: any;
	brandOptions:any;
	categoryOptions:any;
	variantOptions:any;
	unitOptions:any;
	result:any;
	submit:boolean=false;

	
	numberOnly(event): boolean {
		const charCode = (event.which) ? event.which : event.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;

	}
	constructor(private _flashMessagesService: FlashMessagesService, public companyService: CompanyService,private route: ActivatedRoute, private router: Router, private globals: Globals) { }

	async ngOnInit() {
		this.brandOptions 	 = { placeholder: { id: '', text: 'Select Brand' } };
		this.categoryOptions = { placeholder: { id: '', text: 'Select Category' } };
		this.variantOptions  = { placeholder: { id: '', text: 'Select Variant' } };
		this.unitOptions 	 = { placeholder: { id: '', text: 'Select Unit' } };
		await this.brandData();

		this.unitData = this.globals.unitData;


	}

	onSubmit(form?:NgForm) {
		let f = form.value;
		f.category_id=this.model.category_id;
		f.brand_id=this.model.brand_id;
		f.varient_id=this.model.varient_id;
		f.unit=this.model.unit;
		console.log(f);
		
		this.companyService.option_add(f).subscribe((res : any)=>{
			console.log(res);
			this.resultOption = res;
			if (this.resultOption.success == 1 ) {
				this.submit=true;   
				this._flashMessagesService.show('Size created successfully', { cssClass: 'alert-success' } );
				
				setTimeout(() => {
					this.router.navigate(['/size']);
				}, 2000); 

			} else {
				this.errors = res.message;
				 setTimeout(() => {
            this.errors.size_id = false;
          }, 3000); 
			}

		}, (err) => {

		});
	}

	//Unit Changed Event
	unitChanged(event){
		console.log(event);
		this.model.unit=event.value;
	}
	//Brand Data Start
	brandChanged(event){
		console.log(event);
		if(event.value!='select'){
			this.model.brand_id=event.value;
			let catBrand = this.model.brand_id;

			var result = [];
			this.companyService.categorybrand_list(catBrand).subscribe((res:any)=>{
				this.result = res.data;
				var rObj= [];
				rObj.push({"id":"","text":""});
				for(let i=0; i<res.data.length; i++){

					rObj.push({"id":res.data[i].id, "text":res.data[i].name});
				}			
				this.resultList = rObj;
			}, (err) => {

			});
		}
	}

	async brandData(){
		await this.companyService.brand_list().subscribe((res:any)=>{
			this.result = res.data;
			var rObj= [];
			rObj.push({"id":"","text":"select"});
			for(let i=0; i<res.data.length; i++){

				rObj.push({"id":res.data[i].id, "text":res.data[i].name});
			}			
			this.variantdataList = rObj;

			console.log(this.variantdataList);
		}, (err) => {

		});
	}
	
	categoryChanged(event){
		console.log(event);
		if(event.value!='select'){
		this.model.category_id=event.value;

		let catVar = this.model.category_id;

		var result = [];
		this.companyService.variantCat_list(catVar).subscribe((res:any)=>{

			this.result = res.data;
				var rObj= [];
				rObj.push({"id":"","text":""});
				for(let i=0; i<res.data.length; i++){

					rObj.push({"id":res.data[i].id, "text":res.data[i].name});
				}			
			this.variantList = rObj;
		}, (err) => {

		});

	}

	}

	//Categoty Data Finish

	//Variant Data Start
	variantChanged(event){
		console.log(event);
		this.model.varient_id=event.value;
	}



}
