import { Component, OnInit } from '@angular/core';
import { FlashMessagesService } from 'angular2-flash-messages';
import { CompanyService } from '../../company/service/company.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderPipe } from 'ngx-order-pipe';
import { Globals } from './../../globals';

@Component({
  selector: 'app-trackandtrace',
  templateUrl: './trackandtrace.component.html',
  styleUrls: ['./trackandtrace.component.css']
})
export class TrackandtraceComponent implements OnInit {

    data: any;
    searchText = "";
    sortedCollection: any[];
    order: string = 'result.id';
    reverse: boolean = false;
    result: any = [];
    list: any = [];
    arr: any = [];
    errors: any = '';
    deletedMsg: boolean = false;
    p =this.globals.p;
    currentLimit=this.globals.currentLimit;
    display: string = 'none';
    pageLimits = this.globals.pageLimits;
    deleteId: any;
    loader: boolean = false;

    constructor(private globals: Globals,private _flashMessagesService: FlashMessagesService, public companyService: CompanyService, private route: ActivatedRoute, private router: Router, private orderPipe: OrderPipe) {
        this.sortedCollection = orderPipe.transform(this.result, 'result.id');
        console.log(this.sortedCollection);
    }

    ngOnInit() {
        this.supplychainrole();
        this.trackandtraceData();
    }

    trackandtraceData() {
        this.loader = true;
        this.companyService.trackandtrace_list().subscribe((res: any) => {
            this.loader = false;
            this.result = res.data;
            console.log(this.result);
        }, (err) => {

        });
    }

    supplychainrole(){
        this.companyService.supplychainRoleList().subscribe((res: any)=>{
            this.loader = false;
            this.list = res.data;
            console.log(this.list);
        });
    }

    setOrder(value: string) {
        if (this.order === value) {
            this.reverse = !this.reverse;
        }
        this.order = value;
    }
}
