import { Component, OnInit } from "@angular/core";
import { CompanyService } from "../../company/service/company.service";
import { Globals } from "./../../globals";
import { FlashMessagesService } from "angular2-flash-messages";
import { OrderPipe } from "ngx-order-pipe";

@Component({
  selector: "app-register-product",
  templateUrl: "./register-product.component.html",
  styleUrls: ["./register-product.component.css"],
})
export class RegisterProductComponent implements OnInit {
  data: any;
  searchText = "";
  sortedCollection: any[];
  order: string = "result.id";
  reverse: boolean = false;
  result: any = [];
  arr: any = [];
  errors: any = "";
  deletedMsg: string = null;
  p = this.globals.p;
  currentLimit = this.globals.currentLimit;
  deleteId: any;
  pageLimits = this.globals.pageLimits;
  loader: boolean = false;

  constructor(
    private globals: Globals,
    private _flashMessagesService: FlashMessagesService,
    public companyService: CompanyService,
    private orderPipe: OrderPipe
  ) {
    orderPipe.transform(this.result, this.order)
  }

  ngOnInit() {
    this.customerByApp();
  }

  customerByApp() {
    this.loader = true;
    this.companyService.registerByApp().subscribe((res: any) => {
      this.loader = false;
      console.log(res.data);
      this.result = res.data;
    }, err => {
      this.loader = false;
    });
  }

  setOrder(value: string) {
    console.log(value);
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }
}
