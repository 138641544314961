import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../../company/service/company.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as $ from "jquery";
import { Globals } from './../../../globals';




@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.css']
})
export class EditProductComponent implements OnInit {
    resultList: any;
	resultData : any;
	variantdataList : any ;
	resultVariant : any;
	msg: string = null;
	errors : any = '';
	result: any = {};
	Editid : any ;
	currentUserId : any = localStorage.getItem('id');
	variantList:any;
	categoryId:any;
	brandId: any;
	variantId:any;
	unitData:any;
	sizeList:any;
	sizeId:any;
	submit:boolean=false;
	loader:boolean = false;
	numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

constructor(private globals: Globals,private _flashMessagesService: FlashMessagesService,public companyService: CompanyService,private route: ActivatedRoute, private router: Router) {  }

  ngOnInit() {
  	this.unitData = this.globals.unitData;
  	this.route.params.subscribe(params => {
			console.log(params);
			this.Editid=params['id']; 
			this.editpackagingData();
			
		});

  }

  editpackagingData(){
  	    this.loader=true;
		this.companyService.packaging_edit(this.Editid).subscribe((res:any)=>{
		    this.loader=false;
			this.result=res.data;
			this.categoryId = res.data.category_id;
			this.brandId = res.data.brand_id;
			this.variantId = res.data.varient_id;
			this.sizeId = res.data.size_id;
			this.brandData(this.brandId);
			console.log(this.result);
		}, (err) => {

		});
	}


	onSubmit(form?:NgForm) {
		let f = form.value;
		f.category_id =this.result.category_id;
		f.brand_id    =this.result.brand_id;  
		f.varient_id  =this.result.varient_id;
		f.size_id  =this.result.size_id;
		this.companyService.packaging_update(this.Editid,f).subscribe((res: any)=>{
			this.resultData = res;
			console.log(this.resultData);
			if (this.resultData.success == 1 ) {
                this.submit=true;   
				this._flashMessagesService.show('Packaging updated successfully', { cssClass: 'alert-success' } );

				setTimeout(() => {
					this.router.navigate(['/packaging']);
				}, 2000); 
			} else {
				this.errors = res.message;
				  setTimeout(() => {
                    this.errors.packaging_id = false;
                  }, 3000); 
			}

		}, (err) => {

		});
	}
	
	//Brand Data Start
	brandChanged(event,categoryId){
		this.result.brand_id=event.value;
	    let catBrand = this.result.brand_id;
	    var result = [];
		this.companyService.categorybrand_list(catBrand).subscribe((res:any)=>{
			result = res.data;
			
			let categoryData = res.data.filter(function(obj){

				return obj.brand_id = catBrand;
			});

			console.log(categoryData);
			this.resultList = categoryData.map(function(obj) {
				var rObj = {};
				rObj['id'] = obj.id;
				rObj['text'] = obj.name;
				rObj['selected'] = (obj.id==categoryId) ? true : false;
				return rObj;
			});
			console.log(this.resultList);
		}, (err) => {

		});
	}

	async brandData(brandId){
		var result = [];
		await this.companyService.brand_list().subscribe((res:any)=>{
			result = res.data;
			this.variantdataList = result.map(function(obj) {
				var rObj = {};
				rObj['id'] = obj.id;
				rObj['text'] = obj.name;
				rObj['selected'] = (obj.id==brandId) ? true : false;

				return rObj;
			});
			console.log(this.variantdataList);
		}, (err) => {

		});
	}
	
	categoryChanged(event,variantId){
		console.log(event);
		this.result.category_id=event.value;

		let varCat = this.result.category_id;
	    var result = [];
		this.companyService.variantCat_list(varCat).subscribe((res:any)=>{
			result = res.data;
			
			let categoryData = res.data.filter(function(obj){

				return obj.category_id = varCat;
			});

			console.log(categoryData);
			this.variantList = categoryData.map(function(obj) {
				var rObj = {};
				rObj['id'] = obj.id;
				rObj['text'] = obj.name;
				rObj['selected'] = (obj.id==variantId) ? true : false;
				return rObj;
			});
			console.log(this.variantList);
		}, (err) => {

		});

	}
	//Categoty Data Finish

	//Variant Data Start
	variantChanged(event,sizeId){
		console.log(event);
		this.result.varient_id=event.value;
		let varCat = this.result.varient_id;
	    var result = [];

	    this.companyService.packaging_size(varCat).subscribe((res:any)=>{

                  result = res.data;
                  var rObj= [];
                  rObj.push({"id":"","text":""});
                  for(let i=0; i<res.data.length; i++){

                    let unitName;
                    for(let j=0; j< this.unitData.length; j++){
                        if(res.data[i].unit==this.unitData[j].id){
                            unitName = this.unitData[j].text;
                        }
                    }

                    rObj.push(
                                {
                                  "id":res.data[i].id, 
                                  "text":res.data[i].quantity+" "+unitName,
                                  'selected' : (res.data[i].id==sizeId) ? true : false
                                }
                             );
                  }      
                  this.sizeList = rObj;
                }, (err) => {

                });
	}

	//Unit Changed Event
	unitChanged(event){
		console.log(event);
		this.result.size_id=event.value;
	}

}
