import { Injectable } from '@angular/core';
import { LoginService } from './../login/service/login.service';
import { CompanyLoginService } from './../companylogin/service/companylogin.service';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/do';
import { HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse, HttpInterceptor } from '@angular/common/http';

@Injectable()

export class AuthGuard implements CanActivate, HttpInterceptor {


	constructor(private router: Router, private _loginService: LoginService, private companyloginService: CompanyLoginService, private _router: Router) {
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		return next.handle(request).do((event: HttpEvent<any>) => {
			if (event instanceof HttpResponse) {
				// do stuff with response if you want
			}
		}, (err: any) => {
			if (err instanceof HttpErrorResponse) {
				if (err.status === 401) {
					localStorage.clear();
					this.router.navigate(["./companylogin"]);
					// redirect to the login route
					// or show a modal
				}
			}
		});
	}

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {


		let res: boolean = false;
		let currentUserId = localStorage.getItem('id');
		let role = localStorage.getItem('role_id');

		if (currentUserId != undefined && currentUserId != null) {

			if (typeof next.data.role != 'undefined') {

				if (next.data.role.indexOf(role) !== -1) {
					return true;
				}
				else {
					this.router.navigate(['/not_found']);
				}
			}
			else {
				return true;
			}
		}
		else {
			this.router.navigate(['/login']);
		}
	}

}