import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../../company/service/company.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { OrderPipe } from 'ngx-order-pipe';

@Component({
  selector: 'app-edit-menu',
  templateUrl: './edit-menu.component.html',
  styleUrls: ['./edit-menu.component.css']
})
export class EditMenuComponent implements OnInit {
    parentMenuList: any;
    parentMenuOptions: any;
    result: any = [];
    response: any;
    msg: string = null;
    errors: any = '';
    submit: boolean = false;
    Editid: any;
    public message: string;
    parentMenuId: number;
    loader: boolean = false;

    numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    constructor(private _flashMessagesService: FlashMessagesService,
        public companyService: CompanyService,
        private route: ActivatedRoute,
        private router: Router,
        private orderPipe: OrderPipe) {
    }

    async  ngOnInit() {
        this.route.params.subscribe(params => {
            this.Editid = params['id'];
            this.editMenuData();
            this.parentMenuOptions = { placeholder: { id: '', text: 'Select Parent Menu' } };
        });
    }

    editMenuData() {
        this.loader = true;
        this.companyService.editMenu(this.Editid).subscribe((res: any) => {
            this.loader = false;
            this.result = res.data;
            this.parentMenuId = res.data.parent_menu_id;
            this.parentMenu(this.parentMenuId);
            console.log(this.result);
        }, (err) => {
        });
    }

    async parentMenu(parent_menu_id) {
        let result = [];
        await this.companyService.getMenus().subscribe((res: any) => {
            result = res.data;

            result.splice(0, 0, { "id": 0, "title": "Select Parent Menu" });
            
            this.parentMenuList = result.map(function (obj) {
                var rObj = {};
                rObj['id'] = obj.id;
                rObj['text'] = obj.title;
                rObj['selected'] = (obj.id == parent_menu_id) ? true : false;
                return rObj;
            });
            console.log(this.parentMenuList);

            //if (this.parentMenuId == 0 || this.parentMenuId == null || this.parentMenuId == undefined) {
            //    var ObjParentMenu = [];
            //    ObjParentMenu.push({ "id": "", "text": "select" });
            //    for (let i = 0; i < result.length; i++) {
            //        ObjParentMenu.push({ "id": result[i].id, "text": result[i].title });
            //    }
            //    this.parentMenuList = ObjParentMenu;
            //}
            //else {
            //        this.parentMenuList = result.map(function (obj) {
            //            var rObj = {};
            //            rObj['id'] = obj.id;
            //            rObj['text'] = obj.title;
            //            rObj['selected'] = (obj.id == parent_menu_id) ? true : false;
            //            return rObj;
            //        });
            //        console.log(this.parentMenuList);
            //}
        }, (err) => {
            console.log(err);
        });
        
    } 

    parentMenuChanged(event) {
        console.log(event);
        console.log(event.value);
        this.result.parent_menu_id = event.value;
    }

    onSubmit(form?: NgForm) {

        let f = form.value;
        if (f.parent_menu_id == undefined || f.parent_menu_id == '' || f.parent_menu_id == null || f.parent_menu_id == 'select') {
            f.parent_menu_id = 0;
        }
        console.log(f);

        this.companyService.updateMenu(this.Editid,f).subscribe((res: any) => {
            console.log(res);

            this.response = res;

            if (this.response.success == 1) {
                this.submit = true;
                this._flashMessagesService.show('Menu updated successfully', { cssClass: 'alert-success' });

                setTimeout(() => {
                    this.router.navigate(['/menu']);
                }, 3000);

            } else {
                this.errors = res.message;
                setTimeout(() => {
                    this.errors.menu_id = false;
                }, 2000);
            }
        }, (err) => {
        });

    }
}
