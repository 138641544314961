import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { BusinessService } from '../service/business.service';
declare var $: any;
import { OrderPipe } from 'ngx-order-pipe';
import { FlashMessagesService } from 'angular2-flash-messages';
import { Globals } from './../../../globals';

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.css']
})
export class RoleComponent implements OnInit {

    data: any;
    searchText = "";
    sortedCollection: any[];
    order: string = 'result.id';
    reverse: boolean = false;
    result: any = [];
    arr: any = [];
    errors: any = '';
    deletedMsg: boolean = false;
    p =this.globals.p;
    currentLimit=this.globals.currentLimit;
    display: string = 'none';
    pageLimits = this.globals.pageLimits;
    deleteId: any;
    loader:boolean = false;


    constructor(private globals: Globals,private _flashMessagesService: FlashMessagesService, public businessService: BusinessService, private route: ActivatedRoute, private router: Router, private orderPipe: OrderPipe) {
    this.sortedCollection = orderPipe.transform(this.result, 'result.id');
        console.log(this.sortedCollection);
    }

    ngOnInit() {
        this.roleData();
    }

    roleData() {
        this.loader=true;
        this.businessService.businessRole_list().subscribe((res: any) => {
            this.loader=false;
            this.result = res.data;
            console.log(this.result);

        }, (err) => {

        });
    }

    roleDelete(id) {

        this.businessService.deleteBusinessRole(id).subscribe((res: any) => {
            console.log(res);

            this.result = res.data;
            // this.deletedMsg =true;
            if (res['success'] == 1) {
                this._flashMessagesService.show('Role deleted successfully', { cssClass: 'alert-success' });
                this.roleData();
                setTimeout(() => {
                    this.router.navigate(['/role']);
                    this.roleData();
                }, 2000);

            } else {
                this.errors = res['message'];
            }

        }, (err) => {

        });
    }

    setOrder(value: string) {
        if (this.order === value) {
            this.reverse = !this.reverse;
        }
        this.order = value;
    }

    opendeleteModal(id) {
        this.deleteId = id;
        $('#exampleModal').modal('show');

    }
}
