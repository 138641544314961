import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../../company/service/company.service';
declare var $ :any; 
import { OrderPipe } from 'ngx-order-pipe';
import { FlashMessagesService } from 'angular2-flash-messages';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv';
import { Globals } from './../../../globals';



@Component({
  selector: 'app-listpackagingqrcode',
  templateUrl: './listpackagingqrcode.component.html',
  styleUrls: ['./listpackagingqrcode.component.css']
})
export class ListpackagingqrcodeComponent implements OnInit {

	data : any;
	searchText  = "";
	sortedCollection: any[];
	order: string = 'result.id';
	reverse: boolean = false;
	result : any = [];
	arr : any = [];
	errors : any = '';
	deletedMsg: string = null;
	p =this.globals.p;
    currentLimit=this.globals.currentLimit;
	deleteId: any;
    pageLimits = this.globals.pageLimits;

    csvOptions = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      title: 'Qrcode List :',
      useBom: true,
      noDownload: false,
      headers: []  //headers: ["QrCode"]
    };
    loader:boolean=false;
	constructor( 
			private _flashMessagesService: FlashMessagesService,
			public companyService: CompanyService,
			private route: ActivatedRoute, 
			private router: Router,
			private orderPipe: OrderPipe,
			private globals: Globals
			) {  
				this.sortedCollection = orderPipe.transform(this.result, 'result.id');
	}

	/**
	 * [ngOnInit description]
	 * @author Suresh Suthar
	 * @Created Date 2019-04-09
	 */
	ngOnInit() {
		this.loader=true;
		this.companyService.listPackagingQrcode().subscribe((res:any)=>{
			
			this.loader=false;	
			if(res.success=="1"){

                this.result = res.data;
                console.log(this.result);
				//for(let i=0; i< res.data.length; i++){

				//	this.result[i].product_batch = (res.data[i].product_batch!==null)?res.data[i].product_batch.batch_no:"-";
				//}
			}
		});
	}

	/**
	 * [exportQrcode description]
	 * @author Suresh Suthar
	 * @Created Date     2019-04-09
	 * @param   {[type]} randomUniqueId BatchNo [description]
	 */
	exportQrcode(id,batch_no){

        this.companyService.getPackagingQrcodeList(id).subscribe((res:any)=>{

            if(res.success==1){

              let qrCode = res.data;
              let random = Math.random().toString(36).substring(7);
			  	let fileName = batch_no + "Sleeve";
              new  AngularCsv(qrCode, fileName, this.csvOptions);
            }
            else{
                this._flashMessagesService.show(res.message, { cssClass: 'alert-success' } );
                
                  setTimeout(() => {
                    this.router.navigate(['/packaging_qrcode']);
              }, 3000); 
            }
        });
    }

	/**
	 * [setOrder description]
	 * @author Suresh Suthar
	 * @Created Date     2019-04-09
	 * @param   {string} value      [description]
	 */
	setOrder(value: string) {
      if (this.order === value) {
        this.reverse = !this.reverse;
      }

      this.order = value;
    }
}
