import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { CompanyService } from '../../../company/service/company.service';
import { AngularCsv } from "angular7-csv/dist/Angular-csv";
import { DOCUMENT } from "@angular/common";
import { Globals } from './../../../globals';

import * as $ from "jquery";
import * as XLSX from "xlsx";
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})
export class HistoryComponent implements OnInit {

  p =this.globals.p;
  currentLimit=this.globals.currentLimit;
  pageLimits = this.globals.pageLimits;
  order: string = 'rewardHistories.createdAt';
  reverse: boolean = true;
  searchText: string = '';
  loader:boolean = false;

  rewardHistories = [];

  scannedHistory = [];
  EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  EXCEL_EXTENSION = '.xlsx';

  csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalseparator: ".",
    showLabels: true,
    showTitle: false,
    title: "History List :",
    useBom: true,
    noDownload: false,
    headers: ['Customer Name', 'Customer FirmName', 'Customer Mobile', 'Address','City', 'State','Pincode','Reward Name','Point','Is Verified', "Reason"],
  };

  constructor(
    private apiService: CompanyService,
    private globals: Globals,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document) {
  }

  ngOnInit() {
    this.loadHistory();
  }

  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }

  loadHistory() {
    this.loader = true;
    this.apiService.rewardhistory().subscribe(
      (response: any) => {
        if (response.success == 1) {
          this.loader = false;      
          this.rewardHistories = response.data.filter(item => {
            return item.is_verified != 0;
          });

          //this.loadDataTableScript("dataTables-example");
        }
      },
      err => {
        console.error(err);
      }
    );
  }

  loadDataTableScript(id) {
    let script = this._renderer2.createElement("script");
    script.type = `text/javascript`;
    script.text = `
    $(document).ready(function() {
      
        $('#${id}').DataTable({ 
          searching: true,
          paging: true,
          destroy: true,    
          retrive: true                  
        });
      });
    `;

    this._renderer2.appendChild(this._document.body, script);
  }

  exportcsv(){
    this.apiService.exportCsvHistory().subscribe(
      (response: any) => {
        console.log(response);
        if (response.success == 1) {
          this.scannedHistory = response.data;
          //var x = new AngularCsv(this.scannedHistory, "History-List", this.csvOptions);
            let objectMaxLength = []; 
            for (let i = 0; i < this.scannedHistory.length; i++) {
              let value = <any>Object.values(this.scannedHistory[i]);
              for (let j = 0; j < value.length; j++) {
                if (typeof value[j] == "number") {
                  objectMaxLength[j] = 6;
                } else {
                  if(!value[j]){
                    value[j] = '';
                  }
                  objectMaxLength[j] =
                    objectMaxLength[j] >= value[j].length
                      ? objectMaxLength[j]
                      : value[j].length;
                }
              }
            }

            console.log(objectMaxLength)
    
            var wscols = [
            { width: 20},  // first column
            { width: 15 }, // second column
            { width: 40 }, //...
            { width: 10 }, 
            { width: 20 },
            { width: 10 }, 
            { width: 10 },
            { width: 30 },
            { width: 20 },/* 
            { width: objectMaxLength[7]+1 }, 
            { width: objectMaxLength[8]+1 },
            { width: objectMaxLength[9]+1 }*/
          ];
            const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.scannedHistory);
            worksheet["!cols"] = wscols;
            const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };


               /*worksheet['A1'].v = 'Customer Name';
                worksheet['B1'].v = 'Customer Firmname';
                worksheet['C1'].v = 'Customer Mobile';
                worksheet['D1'].v = 'Address';
                worksheet['E1'].v = 'City';
                worksheet['F1'].v = 'State';
                worksheet['G1'].v = 'Pincode';
                worksheet['H1'].v = 'Reward Name';
                worksheet['I1'].v = 'Point';
                worksheet['J1'].v = 'Is Verified';
*/
                worksheet['A1'].v = 'Customer Name';
                worksheet['B1'].v = 'Customer Mobile';
                worksheet['C1'].v = 'Address';
                worksheet['D1'].v = 'Pincode';
                worksheet['E1'].v = 'Reward Name';
                worksheet['F1'].v = 'Point';
                worksheet['G1'].v = 'Is Verified';
                worksheet['H1'].v = 'Comments';
                worksheet['I1'].v = 'Redeem On';
                
            const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
            this.saveAsExcelFile(excelBuffer, 'RewardHistoryList');
         
        }
        //this.loadDataTableScript("dataTables-example");
    },
      err => {
        console.error(err);
      }
    );
  }
  saveAsExcelFile(buffer: any, fileName: string): void {
      const data: Blob = new Blob([buffer], {
        type: this.EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + this.EXCEL_EXTENSION);
    
  }

}
