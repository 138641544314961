import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../../company/service/company.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { OrderPipe } from 'ngx-order-pipe';

@Component({
  selector: 'app-add-menu',
  templateUrl: './add-menu.component.html',
  styleUrls: ['./add-menu.component.css']
})
export class AddMenuComponent implements OnInit {

    parentMenuList: any;
    parentMenuOptions: any;
    result: any;

    msg: string = null;
    errors: any = '';
    model: any = {};
    submit: boolean = false;

    numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    constructor(private _flashMessagesService: FlashMessagesService,
        public companyService: CompanyService,
        private route: ActivatedRoute,
        private router: Router,
        private orderPipe: OrderPipe) {
    }

    async  ngOnInit() {
        this.parentMenuOptions = { placeholder: { id: '', text: 'Select Parent Menu' } };
        await this.parentMenu();
    }

    onSubmit(form?: NgForm) {

        let f = form.value;
        if (f.parent_menu_id == undefined || f.parent_menu_id == '' || f.parent_menu_id == null || f.parent_menu_id == 'select') {
            f.parent_menu_id = 0;
        }
        console.log(f);

        this.companyService.addMenu(f).subscribe((res: any) => {
            console.log(res);

            this.result = res;

            if (this.result.success == 1) {
                this.submit = true;
                this._flashMessagesService.show('Menu created sucessfully', { cssClass: 'alert-success' });

                setTimeout(() => {
                    this.router.navigate(['/menu']);
                }, 3000);

            } else {
                this.errors = res.message;
                setTimeout(() => {
                    this.errors.menu_id = false;
                }, 2000);
            }
        }, (err) => {
        });

    }

    async  parentMenu() {
        let result = [];
        await this.companyService.getMenus().subscribe((res: any) => {
            result = res.data;

            result.splice(0, 0, { "id": 0, "title": "Select Parent Menu" });

            this.parentMenuList = result.map(function (obj) {
                var rObj = {};
                rObj['id'] = obj.id;
                rObj['text'] = obj.title;
                rObj['selected'] = (obj.id == 0) ? true : false;
                return rObj;
            });
            console.log(this.parentMenuList);

            //this.result = res.data;
            //var ObjParentMenu = [];
            //ObjParentMenu.push({ "id": "", "text": "select" });
            //for (let i = 0; i < res.data.length; i++) {
            //    ObjParentMenu.push({ "id": res.data[i].id, "text": res.data[i].title });
            //}
            //this.parentMenuList = ObjParentMenu;
        }, (err) => {
            console.log(err);
        });
    }

    parentMenuChanged(event) {
            console.log(event);
            this.model.parent_menu_id = event.value;
        //if (event.value != 'select') {
        //    console.log(event);
        //    this.model.parent_menu_id = event.value;
        //}
    }
}
