import { Component, OnInit, ViewChild } from '@angular/core';
import { companylogin } from './companylogin';
import { CompanyLoginService } from './service/companylogin.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../company/service/company.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, NgForm } from "@angular/forms";
import { Config } from './../config';
import * as $ from "jquery";
import { FlashMessagesService } from 'angular2-flash-messages';
declare var Swiper: any;   // not required
import {
  SwiperComponent, SwiperDirective, SwiperConfigInterface,
  SwiperScrollbarInterface, SwiperPaginationInterface
} from 'ngx-swiper-wrapper';
import { FormGroup } from '@angular/forms';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-companylogin',
  templateUrl: './companylogin.component.html',
  styleUrls: ['./companylogin.component.css']
})
export class CompanyloginComponent implements OnInit {
  @ViewChild('ngOtpInput') ngOtpInputRef: any;
  model: any = {};
  result: any = {};
  errors: any = '';
  newPassword: string = '';
  loader: boolean = false;
  isSignin: boolean = false;
  isOtp: boolean = false;
  isPassword: boolean = false;
  email1: string = "";
  companyId: string = "";
  currentUserId: any = localStorage.getItem('id');

  forgetPassEmailForm: FormGroup;
  forgetPassEmailSubmitted = false;

  resetPasswordForm: FormGroup;
  resetPasswordSubmitted = false;

  configs = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    containerStyles: {
      'display': 'block',
      'padding': '10px'
    },
    inputStyles: {
      'width': '30px',
      'height': '30px'
    }
  };

  public images = [{
    'src': '../../assets/images/sb-banner.jpg',
  }];

  public config: SwiperConfigInterface = {
    a11y: true,
    direction: 'horizontal',
    slidesPerView: 1,
    keyboard: true,
    mousewheel: true,
    scrollbar: false,
    pagination: true,
    autoplay: false,
    speed: 90000,
    //navigation: true,
  };

  private scrollbar: SwiperScrollbarInterface = {
    el: '.swiper-scrollbar',
    hide: false,
    draggable: true
  };

  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true,
    hideOnClick: false
  };

  constructor(
    public toastr: ToastrService,
    private formBuilder: FormBuilder,
    private _flashMessagesService: FlashMessagesService,
    private httpClient: HttpClient,
    public companyservice: CompanyService,
    public companyloginService: CompanyLoginService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    if (this.currentUserId != undefined && this.currentUserId != null) {
      this.router.navigate(['/kc/dashboard']);
    }

    this.forgetPassEmailForm = this.formBuilder.group({
      email: ["", [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")
        , Validators.required]],
    });

    this.resetPasswordForm = this.formBuilder.group({
      password: ["", [Validators.minLength(6)
        , Validators.required]],
    });
  }

  get forgetPassEmailF() {
    return this.forgetPassEmailForm.controls;
  }

  get resetPasswordF() {
    return this.resetPasswordForm.controls;
  }

  onSubmit(form?: NgForm) {
    let f = form.value;
    this.loader = true;
    this.companyloginService.companylogin(f).subscribe((res: any) => {
      // console.log(res);
      this.loader = false;
      this.result = res.data;
      console.log(this.result);
      if (res['success'] == 1) {
        localStorage.setItem('id', this.result.id);
        localStorage.setItem('companyRandom_id', this.result.random_id);
        localStorage.setItem('x-key', this.result.random_id);
        localStorage.setItem('phone_number', this.result.contact1);
        localStorage.setItem('status', this.result.status);
        localStorage.setItem('name', this.result.first_name);
        localStorage.setItem('access_token', res.access_token);
        localStorage.setItem('role_id', this.result.role_id);

        window.location.href = '/dashboard';
        //this.router.navigate(['/dashboard']);
      } else {

        this.errors = res['message'];
        //    setTimeout(() => {
        //   this.errors = false;
        // }, 2000); 


        //this._flashMessagesService.show(res['message'], { cssClass: 'alert-danger',timeout: 1000});
      }

    }, (err) => {
      this.loader = false;
    });



    //alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.model))
  }

  forgotPassword() {
    this.isSignin = true;
    this.forgetPassEmailForm.reset();
    this.forgetPassEmailSubmitted = false;
  }

  sigin() {
    this.isSignin = false;
    this.isOtp = false;
    this.isPassword = false;
  }

  submit_otp() {
    this.forgetPassEmailSubmitted = true;
    if (this.forgetPassEmailForm.invalid) {
      return;
    }

    this.loader = true;
    this.httpClient.get(`${Config.API_LOGIN_URL}getcompanyuser/${this.forgetPassEmailForm.value.email}`,).toPromise().then(res => {
      console.log(res, res['message']);
      this.loader = false;
      if (res['success'] == 1) {
        this.isOtp = true;
        this.email1 = this.forgetPassEmailForm.value.email;
        this.toastr.success(res['message']);
      } else {
        this.toastr.error(res['message']);
      }
    })
    /*this.companyservice.companyuser(f.email1).subscribe((res : any)=>{
      // console.log(res);
      this.loader=false;
      if (res['success'] == 1 ) {
        this.isOtp = true;
        this._flashMessagesService.show(res['message'], { cssClass: 'alert-success',timeout: 1000});
      }else {
         this.errors = res['message'];
      }
    })*/

  }

  resetPassword() {

    this.resetPasswordSubmitted = true;
    if (this.resetPasswordForm.invalid) {
      return;
    }
 
    if (!this.companyId) {
      this.toastr.error('Please enter OTP');
      return;
    }

    this.loader = true;
    this.httpClient.post(`${Config.API_LOGIN_URL}resetpassword`, { id: this.companyId, password: this.resetPasswordForm.value.password }).toPromise().then(res => {
      console.log(res);
      this.loader = false;
      if (res['success'] == 1) {
        this.isSignin = false;
        this.isOtp = false;
        this.isPassword = false;
        this.toastr.success(res['message']);
      } else {
        this.toastr.error(res['message']);
      }
    })

  }

  onOtpChange(e) {
    if (e.length == 6) {
      this.loader = true;
      this.httpClient.post(`${Config.API_LOGIN_URL}verifyOtp`, { email: this.email1, otp: e }
      ).toPromise().then(res => {
        console.log(res);
        this.loader = false;
        if (res['success'] == 1) {
          this.isPassword = true;
          this.companyId = res['id'];
        } else {
          this.isPassword = false;
          this.toastr.error(res['message']);
        }
      })
    }
  }
}
