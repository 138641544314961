import { Component, OnInit ,NgZone,ElementRef,ViewChild } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../../company/service/company.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import * as $ from "jquery";
import { AgmCoreModule, MapsAPILoader } from '@agm/core';
import {} from '@agm/core/services/google-maps-types';

@Component({
  selector: 'app-add-production',
  templateUrl: './add-production.component.html',
  styleUrls: ['./add-production.component.css']
})
export class AddProductionComponent implements OnInit {

  resultList: any;
  resultProduction : any;
  msg: string = null;
  errors : any = '';
  model: any = {};
  submit:boolean=false;
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  public latitude: number;
  public longitude: number;
  public zoom: number;
  @ViewChild("address1")
  public searchElementRef: ElementRef;

  constructor(private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,private _flashMessagesService: FlashMessagesService, public companyService: CompanyService,private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {

    this.zoom = 4;
    this.latitude = 39.8282;
    this.longitude = -98.5795;
    console.log(this.longitude);
    this.setCurrentPosition();

     this.mapsAPILoader.load().then(() => {
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ["address"]
      });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
        });
      });
    });
  
  }

  onSubmit(form?:NgForm) {
    let f = form.value;
    f.latitude=1;
    f.longitude=1;

    console.log(f);
    this.companyService.production_add(f).subscribe((res : any)=>{
      console.log(res);
      this.resultProduction = res;

      if (this.resultProduction.success == 1 ) {
        this.submit=true;   
        this._flashMessagesService.show('Plant created successfully', { cssClass: 'alert-success' } );

        setTimeout(() => {
          this.router.navigate(['/plant']);
        }, 1000); 
      } else {
        this.errors = res.message;
         setTimeout(() => {
            this.errors.plant_id = false;
          }, 3000); 
      }

    }, (err) => {

    });
  }


   public setCurrentPosition() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 12;
      });
    }
  }

}
