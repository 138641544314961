import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../../company/service/company.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as $ from "jquery";
import { Select2OptionData } from 'ng2-select2';



@Component({
	selector: 'app-add-variant',
	templateUrl: './add-variant.component.html',
	styleUrls: ['./add-variant.component.css']
})
export class AddVariantComponent implements OnInit {

	resultList: any;
	variantdataList : any ;
	resultVariant : any;
	msg: string = null;
	errors : any = '';
	model: any = {};
	currentUserId : any = localStorage.getItem('id');
	resultcategoryList : any;
	result:any;
	brandOptions:any;
	categoryOptions:any;
	submit:boolean=false;

	

	constructor(private _flashMessagesService: FlashMessagesService, public companyService: CompanyService,private route: ActivatedRoute, private router: Router) { }

async ngOnInit() {
		this.brandOptions 	     = { placeholder: { id: '', text: 'Select Brand' } };
		this.categoryOptions 	 = { placeholder: { id: '', text: 'Select Category' } };
	await this.brandData();
	}



	onSubmit(form?:NgForm) {
		
		let f = form.value;
		f.category_id=this.model.category_id;
		f.brand_id=this.model.brand_id;
		console.log(f);

		this.companyService.variant_add(f).subscribe((res : any)=>{
			console.log(res);
			this.resultVariant = res;
			if (this.resultVariant.success == 1 ) {
				 this.submit=true; 
				this._flashMessagesService.show('Variant  created successfully', { cssClass: 'alert-success' } );
				setTimeout(() => {
					this.router.navigate(['/variant']);
				}, 2000); 

			} else {
				this.errors = res.message;
				     setTimeout(() => {
			          this.errors.varient_id = false;
			        }, 3000); 
		
			}

		}, (err) => {

		});
	}


	// async categoryData(catBrand){

		// 	 this.resultList = [];
		// 	 var result = [];
		// 	await this.companyService.categorybrand_list(catBrand).subscribe((res:any)=>{
			// 		result = res.data;
			// 		console.log(result);
			// 		 this.resultList = result.map(function(obj) {
				// 	          var rObj = {};
				// 	          rObj['id'] = obj.id;
				// 	          rObj['text'] = obj.name;
				// 	          return rObj;
				// 	      });

				// 	}, (err) => {

					// 	});
					//  	console.log(this.resultList);
					// }

					categoryChanged(event){
						this.model.category_id=event.value;
					}

					brandChanged(event){
						console.log(event);
					  if(event.value!='select'){
					  	this.model.brand_id=event.value;
						let catBrand = this.model.brand_id;

						var result = [];

						this.companyService.categorybrand_list(catBrand).subscribe((res:any)=>{
							this.result = res.data;
							var rObj= [];
								rObj.push({"id":"","text":""});
								for(let i=0; i<res.data.length; i++){

									rObj.push({"id":res.data[i].id, "text":res.data[i].name});
								}
								
								this.resultList = rObj;
								
							// this.resultList = result.map(function(obj) {
							// 	var rObj = {};
							// 	rObj['id'] = obj.id;
							// 	rObj['text'] = obj.name;
							// 	return rObj;
							// });

						}, (err) => {

						});
					}
					}

					async brandData(){
						await this.companyService.brand_list().subscribe((res:any)=>{
								this.result = res.data;
								var rObj= [];
								rObj.push({"id":"","text":"select"});
								for(let i=0; i<res.data.length; i++){

									rObj.push({"id":res.data[i].id, "text":res.data[i].name});
								}			
								this.variantdataList = rObj;
							
							console.log(this.variantdataList);
						}, (err) => {

						});
					}
				}
