import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../../company/service/company.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import * as $ from "jquery";
import { Select2OptionData } from 'ng2-select2';



@Component({
  selector: 'app-add-brand',
  templateUrl: './add-brand.component.html',
  styleUrls: ['./add-brand.component.css']
})
export class AddBrandComponent implements OnInit {
  resultList: any;
  resultBrand : any;
  msg: string = null;
  errors : any = '';
  model: any = {};
  public imagePath;
  imgURL: any = "/assets/images/placeholder.png";
  mainlogo: any;
  message : any;
  filedata:any;
  submit:boolean=false;
  loader:boolean = false;


  constructor(private _flashMessagesService: FlashMessagesService, public companyService: CompanyService,private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.companyData();
   
  }
  onSubmit(form?:NgForm) {
     this.loader=true;
        if (this.filedata == undefined && this.filedata == null ) {
        this.loader=false;
           this.message="Image Required";
       }    
      let f = form.value;
      let formdata = new FormData();
        formdata.append("mainlogo",this.filedata);
        formdata.append("name",f.name);

      this.companyService.brand_add(formdata).subscribe((res : any)=>{
       console.log(res);
         this.resultBrand = res;
         this.loader=false;
          if (this.resultBrand.success == 1 ) { 
              this.submit=true;   
              this._flashMessagesService.show('Brand created successfully', { cssClass: 'alert-success' } );
              setTimeout(() => {
            this.router.navigate(['/brand']);
          }, 2000); 

       } else {
         this.errors = res.message;
             setTimeout(() => {
            this.errors.brand_id = false;
          }, 3000); 

       }

      }, (err) => {

    });   
  }
  
  companyChanged(event){
    console.log(event);
    this.model.company_id=event.value;
  }

  async companyData(){
      var result = [];
   await  this.companyService.company_list().subscribe((res:any)=>{
        result = res.data;
       this.resultList = result.map(function(obj) {
              var rObj = {};
              rObj['id'] = obj.id;
              rObj['text'] = obj.name;
              return rObj;
          });
        console.log(this.resultList);
      }, (err) => {

      });
    }

     onFileChange(e){
        this.filedata=e.target.files[0];
        console.log("this.filedata");
        console.log(this.filedata);
    }
  
  preview(files) {
    if (files.length === 0)
      return;
    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }

    var reader = new FileReader();
    this.imagePath = files;
    console.log(this.imagePath);

    reader.readAsDataURL(files[0]); 
    reader.onload = (_event) => { 
    this.imgURL = reader.result;
 
    }
  }    
}
