import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../../company/service/company.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import * as $ from "jquery";


@Component({
   selector: 'app-add-productionline',
   templateUrl: './add-productionline.component.html',
   styleUrls: ['./add-productionline.component.css']
})
export class AddProductionlineComponent implements OnInit {
   resultList: any;
   sitedataList : any ;
   resultProductionline : any;
   msg: string = null;
   errors : any = '';
   model: any = {};
   plantOptions:any;
   submit:boolean=false;
   result:any;

   numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
   constructor(private _flashMessagesService: FlashMessagesService, public companyService: CompanyService,private route: ActivatedRoute, private router: Router) { }

   ngOnInit() {
      this.plantOptions         = { placeholder: { id: '', text: 'Select Plant' } };
      this.plantData();
   }
   onSubmit(form?:NgForm) {
      let f = form.value;
      f.plant_id=this.model.plant_id;   
      this.companyService.productionline_add(f).subscribe((res : any)=>{
         this.resultProductionline = res;
         if (this.resultProductionline.success == 1 ) {
           this.submit=true;  
            this._flashMessagesService.show('Line created successfully', { cssClass: 'alert-success' } );
            setTimeout(() => {
               this.router.navigate(['/line']);
            }, 1000); 

         } else {
            this.errors = res.message;
             setTimeout(() => {
            this.errors.productionLine_id = false;
          }, 3000); 
            
         }

      }, (err) => {

      });

   }

   plantChanged(event){
      if(event.value!='select'){
      console.log(event);
      this.model.plant_id=event.value;
    }
   }

   async plantData(){
      await  this.companyService.production_list().subscribe((res:any)=>{
      this.result = res.data;
      var rObj= [];
      rObj.push({"id":"","text":"select"});
      for(let i=0; i<res.data.length; i++){

        rObj.push({"id":res.data[i].id, "text":res.data[i].plant_name});
      }      

      this.resultList = rObj;
        console.log(this.resultList);
      }, (err) => {

      });
      //    result = res.data;
      //    console.log(result);
      //    this.resultList = result.map(function(obj) {
      //       var rObj = {};
      //       rObj['id'] = obj.id;
      //       rObj['text'] = obj.plant_name;
      //       return rObj;
      //    });
      //    console.log(this.resultList);
      // }, (err) => {

      // });
   }
}
