import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../../company/service/company.service';
declare var $ :any; 
import { OrderPipe } from 'ngx-order-pipe';
import { FlashMessagesService } from 'angular2-flash-messages';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv';
import { Globals } from './../../../globals';


@Component({
  selector: 'app-listqrcode',
  templateUrl: './listqrcode.component.html',
  styleUrls: ['./listqrcode.component.css']
})
export class ListqrcodeComponent implements OnInit {

	  data : any;
	  searchText  = "";
	  sortedCollection: any[];
	  order: string = 'result.id';
	  reverse: boolean = false;
	  result : any = [];
	  arr : any = [];
	  errors : any = '';
	  deletedMsg: string = null;
	  p =this.globals.p;
    currentLimit=this.globals.currentLimit;
	  deleteId: any;
    pageLimits = this.globals.pageLimits;
    loader:boolean = false;
    endCount=this.globals.endCount;

    csvOptions = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      title: 'Qrcode List :',
      useBom: true,
      noDownload: false,
      headers: []  //headers: ["QrCode"]
    };

	constructor( 
			private _flashMessagesService: FlashMessagesService,
			public companyService: CompanyService,
			private route: ActivatedRoute, 
			private router: Router,
			private orderPipe: OrderPipe,
      private globals: Globals
			) {  
				this.sortedCollection = orderPipe.transform(this.result, 'result.id');
	}

	/**
	 * [ngOnInit description]
	 * @author Suresh Suthar
	 * @Created Date 2019-04-05
	 */
	ngOnInit() {
   
     this.loader=true;
		this.companyService.listQrcode().subscribe((res:any)=>{
       this.loader=false;

			if(res.success=="1"){

                this.result = res.data;
                console.log(this.result);
			}
		});
	}

	  /**
     * [exportQrcode description]
     * @author Suresh Suthar
     * @Created Date     2019-03-29
     * @param   {[type]} batchId    [description]
     */
    exportQrcode(roleUniqueId, sku, noOfQRcode){
      console.log(roleUniqueId);
        this.companyService.getSkuList(roleUniqueId).subscribe((res:any)=>{
            console.log(res.data);
            // return false;
            if(res.success==1){

              let qrCode = res.data;
              let random = Math.random().toString(36).substring(7);
              let fileName = `${sku}-${noOfQRcode}-codes`;
              new  AngularCsv(qrCode, fileName, this.csvOptions);
            }
            else{
                this._flashMessagesService.show(res.message, { cssClass: 'alert-success' } );
                
                  setTimeout(() => {
                    this.router.navigate(['/qrcode']);
              }, 3000); 
            }
        });
    }

    setOrder(value: string) {
      if (this.order === value) {
        this.reverse = !this.reverse;
      }

      this.order = value;
    }


  //   exportSKUcode(roleUniqueId){
  //   this.companyService.getSkuList(roleUniqueId).subscribe((res:any)=>{
  //     console.log(res);
      
  //     if(res.success==1){
  //           let qrCode = res.data;
        
  //           let random = Math.random().toString(36).substring(7);
  //           let fileName = roleUniqueId;
  //           new AngularCsv(qrCode, fileName, this.csvOptions);
  //     }
  //     else{
  //       this._flashMessagesService.show(res.message, { cssClass: 'alert-success' } );
        
  //       setTimeout(() => {
  //         this.router.navigate(['/qrcode']);
  //       }, 2000); 
  //     }
  //   });
  // }



    // endCount(p,currentLimit,reportCount){

    //   return Math.min((p * currentLimit), reportCount);
    // }
}
