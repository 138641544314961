import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { FlashMessagesService } from "angular2-flash-messages";
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/service/api/api.service';

declare var jQuery: any;

@Component({
  selector: 'app-add-luckydraw',
  templateUrl: './add-luckydraw.component.html',
  styleUrls: ['./add-luckydraw.component.css']
})
export class AddLuckydrawComponent implements OnInit {
  loader = true;
  submitted = false;
  types = [{
    id: 1,
    name: "One time"
  }, {
    id: 2,
    name: "Frequent"
  }]
  total_draws = 0;
  registerForm: FormGroup;
  selectedDType = '';
  ftype = '';
  showFreq = false;
  showDateRange = false;
  rewards = [];
  skus = [];
  image_file;
  image_file_name;
  // lvl_types = [{
  //   id: 1,
  //   name: 'Product'
  // }, {
  //   id: 4,
  //   name: 'Shipper'
  // }];

  // consumer_types = [{
  //   id: 0,
  //   name: 'Customer'
  // }, {
  //   id: 1,
  //   name: 'Distributor'
  // }, {
  //   id: 2,
  //   name: 'Wholesaler'
  // }, {
  //   id: 3,
  //   name: 'Retailer'
  // }];
  dropdownList: any = [];
  days = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28]
  public weekDays = [{
    day: "Sunday",
    value: 0
  },
  {
    day: "Monday",
    value: 1
  }, {
    day: "Tuesday",
    value: 2
  }, {
    day: "Wednesday",
    value: 3
  }, {
    day: "Thursday",
    value: 4
  }, {
    day: "Friday",
    value: 5
  }, {
    day: "Saturday",
    value: 6
  }]
  dropdownSettings;
  
  constructor(
    private formBuilder: FormBuilder, 
    private apiService: ApiService,
    private router: Router, 
    public toastr: ToastrService,
    private _flashMessagesService: FlashMessagesService) {
    this.getSkus()
  }

  ngOnInit() {
    this.dropdownSettings = {
      idField: 'item_id',
      textField: 'item_text',
      enableCheckAll: true,
    };
    this.registerForm = this.formBuilder.group({
      // consumer_type: [0],
      draw_type: ["0"],
      // lvl_type: [1],
      // freq_type: ["", Validators.required],
      //week_day:["",Validators.required],
      // ot_date: ["", Validators.required],
      start_date: ["", Validators.required],
      end_date: ["", Validators.required],
      draw_name: ["", [Validators.required, Validators.maxLength(20)]],
      no_of_winners: ["", [Validators.required, Validators.maxLength(10), Validators.pattern("^[0-9]*$")]],
      reward_id: ["", Validators.required],
      req_sku: ["", Validators.required],
      min_scanned: ["", [Validators.required, Validators.maxLength(10), Validators.pattern("^[0-9]*$")]],
      draw_desc: ["", [Validators.required, Validators.maxLength(200)]],
      card_image: ["", Validators.required],
      termsandconditions: ["", Validators.required]
    })
    this.getLuckyDrawReward();
    // this.getSkus();
    this.loadDatePickerScript();
  }

  drawTypeChange(type) {
    type = this.registerForm.value.draw_type
    this.ftype = "0";
    if (this.registerForm.contains('freq_type')) {
      this.registerForm.removeControl('freq_type')
    }
    if (this.registerForm.contains('week_day')) {
      this.registerForm.removeControl('week_day');
    }

    // if (this.registerForm.contains('month_date')) {
    //   this.registerForm.removeControl('month_date');
    // }


    if (type == 0) {
      this.showFreq = false;

    } else if (type == 1) {
      this.registerForm.addControl('freq_type', new FormControl("", Validators.required))
      this.showFreq = true;
    } 
    this.dateChange();
  }


  onTypeChange(ftype) {
    this.dateChange();
    this.ftype = ftype;

    console.log('ftype -- ', ftype)
    if (this.registerForm.contains('week_day')) {
      this.registerForm.removeControl('week_day');
    }
    if (this.registerForm.contains('month_date')) {
      this.registerForm.removeControl('month_date');
    }
    
    if (ftype == 0) {

    }
    if (ftype == 1) {
      this.registerForm.addControl('week_day', new FormControl('', Validators.required));
    }
    if (ftype == 2) {
      this.registerForm.addControl('month_date', new FormControl('', Validators.required));
    }
  }

  getLuckyDrawReward() {
    this.apiService
      .get("luckydraw-rewards", true)
      .subscribe(
        (response: any) => {
          this.loader = false;
          if (response.status == 200 && response.body.success == 1) {
            this.rewards = response.body.data.filter(item => {
              return item.stock != 0
            })
          }
        },
        (error) => {
          console.error(error);
          this.loader = false;

        }
      );
  }

  getSkus() {
    this.apiService
      .get("product", true)
      .subscribe(
        (response: any) => {
          this.loader = false;
          if (response.status == 200 && response.body.success == 1) {
            this.skus = response.body.data
            let temp = []
            for (let i = 0; i < this.skus.length; i++) {
              let obj = {
                'item_id': this.skus[i].id,
                'item_text': this.skus[i].sku
              }
              temp.push(obj)
            }
            this.dropdownList = temp
          }
        },
        (error) => {
          console.error(error);
          this.loader = false;

        }
      );
  }

  imagechange(event) {
    this.image_file = event.target.files[0];
    this.image_file_name = event.target.files[0].name;
    
    if (event.target.files.length > 0) {
      if (!String(event.target.files[0].type).includes("image/")) {
        this.toastr.error("Please select images");
        $('#img').val('');
        return;
      }

      if (event.target.files[0].size > 512000) {
        this.toastr.error("Please select file less then 500 kb");
        $('#img').val('');
        return;
      } else {
        this.image_file = event.target.files[0];
      }
    }else {
      this.image_file = null;
      this.image_file_name = null;
    }

  }

  onAddLuckyDraw() {
    this.submitted = true;
    if(this.total_draws<=0){
      this._flashMessagesService.show("Total Draw Must be Greater Than 0",{cssClass:"alert-danger"})
      return;
    }
    // console.log("tttttt", this.registerForm.value, document.getElementById("startDate")["value"]);
    // this.registerForm.patchValue({ start_date: document.getElementById("startDate")["value"] });
    // this.registerForm.patchValue({ end_date: document.getElementById("endDate")["value"] });
    console.log("rrr", this.registerForm.controls);

    if (this.registerForm.invalid) {
      return
    }

    this.loader = true;

    let formData = new FormData();
    let keys = Object.keys(this.registerForm.value);

    for (let key of keys) {
      let temp = this.registerForm.get([key]).value;
      console.log("temp", temp, key);
      if (key == 'req_sku') {
        formData.append(key, JSON.stringify(temp));
      } else {
        formData.append(key, temp);
      }
    }

    formData.append("consumer_type", "0");
    // formData.append("draw_type", "0");
    formData.append("lvl_type", "1");
    formData.append('image', this.image_file, this.image_file.name)

    this.apiService
      .post("add-luckydraw", formData, true)
      .subscribe(
        (response: any) => {
          this.loader = false;
          console.log("add res", response.body);

          if (response.status == 200 && response.body.success == 0) {
            this._flashMessagesService.show(
              response.body.message,
              { cssClass: "alert-danger", timeout: 5000 }
            );
            window.scrollTo(0,0);
          }
          if (response.status == 200 && response.body.success == 1) {
            console.log("add", response.body.data);
            this.router.navigate(['/lucky-draw'])
          }
        },
        (error) => {
          console.error(error);
          this.loader = false;

        }
      );
  }

  resetForm(){
    this.total_draws = 0;
    this.selectedDType = '';
    this.ftype = '';
    this.showFreq = false;
    this.showDateRange = false;
    this.rewards = [];
    this.image_file;
    this.image_file_name;
    this.dropdownList = [];
    this.getSkus();
    // this.getSkus();    

    this.registerForm.reset();
    this.getLuckyDrawReward();
    this.loadDatePickerScript();
    // this.ngOnInit();
  }

  loadDatePickerScript() {

    jQuery(document).ready(() => {
      jQuery("#start_date").daterangepicker(
        {
          singleDatePicker: true,
          showDropdowns: true,
          locale: {
            format: "YYYY-MM-DD",
          },
          //maxDate: new Date(),
          minDate: new Date(),
          autoclose: true,
          //minYear: 1901,
        },
        (start: any, end: any, label: any) => {
          console.log("s: ", start, "e: ", end, "l: ", label);

          jQuery("#end_date").daterangepicker(
            {
              singleDatePicker: true,
              showDropdowns: true,
              locale: {
                format: "YYYY-MM-DD",
              },
              //maxDate: new Date(),
              minDate: start,
              autoclose: true,
              minYear: 1901,
            },
            (start: any, end: any, label: any) => {
              console.log(start, end, label);
            }
          );
        }
      );

      jQuery("#end_date").daterangepicker(
        {
          singleDatePicker: true,
          showDropdowns: true,
          locale: {
            format: "YYYY-MM-DD",
          },
          //maxDate: new Date(),
          minDate: new Date(),
          autoclose: true,
          minYear: 1901,
        },
        (start: any, end: any, label: any) => {
          console.log(start, end, label);
        }
      );
      // setTimeout(() => {
      //   if (this.pointForm) {
      //     jQuery("#startDate").val(this.pointForm.value.startDate);
      //     jQuery("#endDate").val(this.pointForm.value.endDate);
      //   }
      // }, 2000)
    });
  }

  dateChange() {
    console.log("date change22", this.registerForm.value.end_date);

    this.registerForm.patchValue({
      start_date: document.getElementById("start_date")['value'],
      end_date: document.getElementById("end_date")['value']
    });
    this.total_draws = 0;
    let startDate = this.registerForm.value.start_date;
    let endDate = this.registerForm.value.end_date;
    if (this.registerForm.value.start_date == '' || this.registerForm.value.end_date == '' || this.registerForm.value.start_date == undefined || this.registerForm.value.end_date == undefined) {
      return;
    }
    let date1 = new Date(startDate);
    let date2 = new Date(endDate);
    if (this.registerForm.value.draw_type == 1) {
      let fre = this.registerForm.value.freq_type;
      console.log("ftype",fre)
      if (fre == 0 && fre !='') {
        console.log("in f1");
        
        let Time = date2.getTime() - date1.getTime();
        let Days = Time / (1000 * 3600 * 24);
        this.total_draws = Days+1;
        if(this.total_draws<0){
          this.total_draws = 0;
        }
      }
      if(fre ==1){        
        if(this.registerForm.value.week_day == ''){
          return;
        }
        let count =0;
        let day = date1.getDay();
        while(date1<=date2){          
          if(day == this.registerForm.value.week_day){
            count++;            
          }
          date1.setDate( date1.getDate() + 1 );
          day = date1.getDay();

        }
        this.total_draws = count;
      }
      if(fre==2){
        if(this.registerForm.value.month == ''){
          return;
        }
        let count =0;
        let day = date1.getDate();        
        while(date1<=date2){          
          if(day == this.registerForm.value.month_date){
            count++;            
          }
          date1.setDate( date1.getDate() + 1 );
          day = date1.getDate();

        }
        this.total_draws = count;
      }
    }else{
      this.total_draws = 1;
    }
  }

  changeWeekDay(e){
    this.dateChange()
  }

  changeMonthDay(e){
    this.dateChange()
  }
  get f() {
    return this.registerForm.controls;
  }

}
