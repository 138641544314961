import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { companylogin } from './../companylogin';
import { Config } from './../../config';
import { Observable } from 'rxjs';

const TOKEN = 'TOKEN';

@Injectable({
	providedIn: 'root'
})

export class CompanyLoginService {

	apiURL: string = Config.API_LOGIN_URL+"companylogin/";

	constructor( private httpClient: HttpClient) { }
	
	public companylogin(f): Observable<companylogin>{

		return this.httpClient.post<companylogin>(`${this.apiURL}`, f);

	}
	logout() {

			localStorage.clear();

	}
}