import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { Globals } from 'src/app/globals';
import { ApiService } from 'src/app/service/api/api.service';
declare var jQuery: any;

@Component({
  selector: 'app-luckydraw-gift-claim',
  templateUrl: './luckydraw-gift-claim.component.html',
  styleUrls: ['./luckydraw-gift-claim.component.css']
})
export class LuckydrawGiftClaimComponent implements OnInit {
  @ViewChild('closeapp') closeApproveModal: ElementRef;
  @ViewChild('remarkmodal') reamarkmodal: ElementRef;


  loader = false;
  msg: string = "test";
  errors: any = "";
  submitted: boolean = false;
  reverse: boolean = false;
  p = this.globals.p;
  currentLimit = this.globals.currentLimit;
  pageLimits = this.globals.pageLimits;
  order: string = "createdAt";
  requests = [];
  reasonRequired = false;
  rejectionForm: FormGroup;
  approvedForm: FormGroup;
  rejectionForm_submitted = false;
  approvedForm_submitted = false;
  giftClaims = [
    { name: "user_1", mobile: "00999999", email: "anurag@xyz.com", address: "1st street", reward: "1kg gold", createdAt: "2021-07-02 16:38:34" },
    { name: "user_2", mobile: "2772763", email: "anurag2@xyz.com", address: "2nd street", reward: "1kg silver", createdAt: "2021-07-02 16:40:34" }

  ];
  constructor(private globals: Globals, private apiService: ApiService, private formBuilder: FormBuilder, public toastr: ToastrService,) { }

  ngOnInit() {
    this.getClaimRequests();
    this.rejectionForm = this.formBuilder.group({
      request_id: ["", Validators.required],
      status: ["", Validators.required],
      reason: ["", Validators.required]
    });
    this.approvedForm = this.formBuilder.group({
      request_id: ["", Validators.required],
      status: ["", Validators.required],
      partner_name: ["", Validators.required],
      transaction_id: ["", Validators.required],
      is_delivered: [false, Validators.required]
    })
  }
  get f() {
    return this.rejectionForm.controls;
  }

  setOrder(value: string) {
    console.log(value);
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    this.order = value;
  }

  getClaimRequests() {
    this.apiService.get("redeemedRequests?for=ld", true).subscribe(
      (response: any) => {
        if (response.status == 200 && response.body.success == 1) {
          console.log("data", response.body.data);
          this.requests = response.body.data;
        }
      },
      (error: any) => {
        console.error(error);
      }
    );
  }
  onRequestReject(id) {
    this.rejectionForm_submitted = false;
    this.rejectionForm.reset();
    this.rejectionForm.patchValue({ request_id: id });
  }
  onRequestApprove(id) {
    this.approvedForm_submitted = false;
    this.resetApprovedForm();
    this.approvedForm.patchValue({ request_id: id })
  }

  updateClaimRequest(status) {
    this.loader = true
    let reason;
    let data;
    if (status == false) {
      this.rejectionForm.patchValue({ status: status })
      this.rejectionForm_submitted = true;
      if (this.rejectionForm.invalid) {
        this.loader = false
        return
      }
      data = this.rejectionForm.value
      this.reamarkmodal.nativeElement.click();

    }
    if (status == true) {
      this.approvedForm_submitted = true;
      this.approvedForm.patchValue({ status: status })
      if (this.approvedForm.invalid) {
        this.loader = false
        return
      }
      data = this.approvedForm.value;
      this.closeApproveModal.nativeElement.click();
    }
    this.apiService.put("redeemedRequests?for=ld", data, true).subscribe(
      (response: any) => {
        if (response.status == 200 && response.body.success == 1) {
          console.log("data", response.body.data);
          this.approvedForm_submitted = false;
          this.rejectionForm_submitted = false;
          this.getClaimRequests();
        }
        this.resetApprovedForm();
        this.rejectionForm.reset();
        this.loader = false
      },
      (error: any) => {
        this.loader = false;
        console.error(error);
      }
    );
  }

  resetApprovedForm() {
    this.approvedForm = this.formBuilder.group({
      request_id: ["", Validators.required],
      status: ["", Validators.required],
      partner_name: ["", Validators.required],
      transaction_id: ["", Validators.required],
      is_delivered: [false, Validators.required]
    })
  }
  get f1() {
    return this.rejectionForm.controls;
  }
  get f2() {
    return this.approvedForm.controls;
  }

}
