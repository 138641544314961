import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AgmCoreModule } from '@agm/core';
import { MapsModule } from '@syncfusion/ej2-angular-maps';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import * as $ from "jquery";
import { QuillModule } from 'ngx-quill';
import { NgxPaginationModule } from 'ngx-pagination';
import { Ng2OrderModule } from 'ng2-order-pipe';
import { NgOtpInputModule } from 'ng-otp-input';
import { OrderModule } from 'ngx-order-pipe';
import { ToastrModule } from 'ngx-toastr';
import { SwiperModule, SwiperConfigInterface, SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { Select2Module } from 'ng2-select2';
import { FlashMessagesModule } from 'angular2-flash-messages';
import { ConfirmationPopoverModule } from "angular-confirmation-popover";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FilterPipe } from './common/filter.pipe';

import { LoginComponent } from './login/login.component';
import { LayoutComponent } from './layout/layout.component';
import { HeaderComponent } from './layout/header/header.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { FooterComponent } from './layout/footer/footer.component';
import { AuthGuard } from './guards/auth-guard.service';
import { Globals } from './globals';
import { NotfoundComponent } from './notfound/notfound.component';

import { DashboardComponent } from './dashboard/dashboard.component';
import { BrandComponent } from './company/brand/brand.component';
import { ProductionComponent } from './company/production/production.component';
import { AddProductionComponent } from './company/production/add-production/add-production.component';
import { EditProductionComponent } from './company/production/edit-production/edit-production.component';
import { CompanyloginComponent } from './companylogin/companylogin.component';
import { CompanyHeaderComponent } from './layout/company-header/company-header.component';
import { AddBrandComponent } from './company/brand/add-brand/add-brand.component';
import { EditBrandComponent } from './company/brand/edit-brand/edit-brand.component';
import { ProductionlineComponent } from './company/productionline/productionline.component';
import { AddProductionlineComponent } from './company/productionline/add-productionline/add-productionline.component';
import { EditProductionlineComponent } from './company/productionline/edit-productionline/edit-productionline.component';
import { CategoryComponent } from './company/category/category.component';
import { AddCategoryComponent } from './company/category/add-category/add-category.component';
import { EditCategoryComponent } from './company/category/edit-category/edit-category.component';
import { VariantComponent } from './company/variant/variant.component';
import { AddVariantComponent } from './company/variant/add-variant/add-variant.component';
import { EditVariantComponent } from './company/variant/edit-variant/edit-variant.component';
import { OptionsComponent } from './company/options/options.component';
import { AddOptionsComponent } from './company/options/add-options/add-options.component';
import { EditOptionsComponent } from './company/options/edit-options/edit-options.component';
import { ProductComponent } from './company/product/product.component';
import { AddProductComponent } from './company/product/add-product/add-product.component';
import { EditProductComponent } from './company/product/edit-product/edit-product.component';
import { BatchlistComponent } from './company/batch/batchlist/batchlist.component';
import { AddbatchComponent } from './company/batch/addbatch/addbatch.component';
import { SkuComponent } from './company/sku/sku.component';
import { AddSkuComponent } from './company/sku/add-sku/add-sku.component';
import { EditSkuComponent } from './company/sku/edit-sku/edit-sku.component';
import { EditbatchComponent } from './company/batch/editbatch/editbatch.component';
import { RoleComponent } from './company/business/role/role.component';
import { AddRoleComponent } from './company/business/role/add-role/add-role.component';
import { EditRoleComponent } from './company/business/role/edit-role/edit-role.component';
import { UserComponent } from './company/business/user/user.component';
import { AddUserComponent } from './company/business/user/add-user/add-user.component';
import { EditUserComponent } from './company/business/user/edit-user/edit-user.component';
import { AddqrcodeComponent } from './company/qrcode/addqrcode/addqrcode.component';
import { ListqrcodeComponent } from './company/qrcode/listqrcode/listqrcode.component';
import { AddpackagingqrcodeComponent } from './company/packagingqrcode/addpackagingqrcode/addpackagingqrcode.component';
import { ListpackagingqrcodeComponent } from './company/packagingqrcode/listpackagingqrcode/listpackagingqrcode.component';
import { AddshipperqrcodeComponent } from './company/shipperqrcode/addshipperqrcode/addshipperqrcode.component';
import { ListshipperqrcodeComponent } from './company/shipperqrcode/listshipperqrcode/listshipperqrcode.component';
import { BatchdetailsComponent } from './company/batch/batchdetails/batchdetails.component';
import { SkulistComponent } from './company/inventory/skulist/skulist.component';
import { SkubatchlistComponent } from './company/inventory/skubatchlist/skubatchlist.component';
import { EmployeeuserComponent } from './company/employeeuser/employeeuser.component';
import { AddEmployeeuserComponent } from './company/employeeuser/add-employeeuser/add-employeeuser.component';
import { EditEmployeeuserComponent } from './company/employeeuser/edit-employeeuser/edit-employeeuser.component';
import { CompanyroleComponent } from './company/companyuser/companyrole/companyrole.component';
import { AddCompanyroleComponent } from './company/companyuser/companyrole/add-companyrole/add-companyrole.component';
import { EditCompanyroleComponent } from './company/companyuser/companyrole/edit-companyrole/edit-companyrole.component';
import { CompanyusersComponent } from './company/companyuser/companyusers/companyusers.component';
import { AddCompanyusersComponent } from './company/companyuser/companyusers/add-companyusers/add-companyusers.component';
import { EditCompanyusersComponent } from './company/companyuser/companyusers/edit-companyusers/edit-companyusers.component';
import { CustomerlistComponent } from './customer/customerlist/customerlist.component';
import { ProductComplainComponent } from './product-complain/product-complain.component';
import { SkudetailsComponent } from './company/sku/skudetails/skudetails.component';
import { TrackandtraceComponent } from './company/trackandtrace/trackandtrace.component';
import { RoledetailsComponent } from './company/qrcode/roledetails/roledetails.component';
import { SupplychainComponent } from './company/supplychain/supplychain.component';
import { AddSupplychainComponent } from './company/supplychain/add-supplychain/add-supplychain.component';
import { EditSupplychainComponent } from './company/supplychain/edit-supplychain/edit-supplychain.component';
import { DetailshipperqrcodeComponent } from './company/shipperqrcode/detailshipperqrcode/detailshipperqrcode.component';
import { DetailpackagingqrcodeComponent } from './company/packagingqrcode/detailpackagingqrcode/detailpackagingqrcode.component';
import { ImportSkuComponent } from './company/sku/import-sku/import-sku.component';
import { AddMenuComponent } from './company/menu/add-menu/add-menu.component';
import { ListMenuComponent } from './company/menu/list-menu/list-menu.component';
import { EditMenuComponent } from './company/menu/edit-menu/edit-menu.component';
import { AddRoleMenuPermissionsComponent } from './company/role-menu-permissions/add-role-menu-permissions/add-role-menu-permissions.component';
import { EditRoleMenuPermissionsComponent } from './company/role-menu-permissions/edit-role-menu-permissions/edit-role-menu-permissions.component';
import { ListRoleMenuPermissionsComponent } from './company/role-menu-permissions/list-role-menu-permissions/list-role-menu-permissions.component';
import { DetailRoleMenuPermissionsComponent } from './company/role-menu-permissions/detail-role-menu-permissions/detail-role-menu-permissions.component';
import { ImportshipperqrcodeComponent } from './company/shipperqrcode/importshipperqrcode/importshipperqrcode.component';
import { ListConsignmentComponent } from './company/business/consignment/list-consignment/list-consignment.component';
import { DetailConsignmentComponent } from './company/business/consignment/detail-consignment/detail-consignment.component';
import { InvoiceComponent } from './company/invoice/invoice.component';
import { ProductiondashboardComponent } from './company/productiondashboard/productiondashboard.component';
import { CompanyDashboardComponent } from "./company/company-dashboard/company-dashboard.component";
import { AddCompanyComponent } from './company/add-company/add-company.component';
import { EditCompanyComponent } from './company/edit-company/edit-company.component';
import { RegisterbyappComponent } from './company/customer/registerbyapp/registerbyapp.component';
import { ScanbyappComponent } from './company/customer/scanbyapp/scanbyapp.component';
import { ChartsModule } from 'ng2-charts';
import { CustomerCareComponent } from './company/customer-care/customer-care.component';
import { QuillInitializeService } from 'src/assets/services/quillInitialize.service';
import { GiftClaimComponent } from "./company/rewards/gift-claim/gift-claim.component";
import { HistoryComponent } from "./company/rewards/history/history.component";

import { CoinAllocationComponent } from "./company/rewards/coin-allocation/coin-allocation.component";
import { CoinAllocationListComponent } from "./company/rewards/coin-allocation-list/coin-allocation-list.component";
// FilterPipe
import { RewardManagmentComponent } from "./company/rewards/reward-managment/reward-managment.component";
import { CounterfeitDashboardComponent } from './company/counterfeit-dashboard/counterfeit-dashboard.component';
import { RegisterProductComponent } from './customer/register-product/register-product.component';
import { ScanProductComponent } from './customer/scan-product/scan-product.component';
import { DatePipe } from '@angular/common';
import { ApiService } from './service/api/api.service';

import { AddLuckydrawComponent } from './company/lucky-draw/add-luckydraw/add-luckydraw.component';
import { LuckyDrawComponent } from './company/lucky-draw/lucky-draw.component';
import { LuckydrawDetailsComponent } from './company/lucky-draw/luckydraw-details/luckydraw-details.component'
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { LuckydrawRewardsManagementComponent } from './company/lucky-draw/luckydraw-rewards-management/luckydraw-rewards-management.component';
import { LuckydrawRewardsAddComponent } from './company/lucky-draw/luckydraw-rewards-add/luckydraw-rewards-add.component';
import { LuckydrawGiftClaimComponent } from './company/lucky-draw/luckydraw-gift-claim/luckydraw-gift-claim.component';
import { LuckydrawRewardHistoryComponent } from './company/lucky-draw/luckydraw-reward-history/luckydraw-reward-history.component';
import { PaytmTransactionReportComponent } from './company/paytm/paytm-transaction-report/paytm-transaction-report.component';
import { ConsumerDataExportComponent } from './company/consumer-data-export/consumer-data-export.component';



const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  observer: true,
  direction: 'horizontal',
  threshold: 50,
  spaceBetween: 5,
  slidesPerView: 1,
  centeredSlides: true
};

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    DashboardComponent,
    LoginComponent,
    BrandComponent,
    FilterPipe,
    ProductionComponent,
    AddProductionComponent,
    EditProductionComponent,
    CompanyloginComponent,
    CompanyHeaderComponent,
    AddBrandComponent,
    EditBrandComponent,
    ProductionlineComponent,
    AddProductionlineComponent,
    EditProductionlineComponent,
    CategoryComponent,
    AddCategoryComponent,
    EditCategoryComponent,
    VariantComponent,
    AddVariantComponent,
    EditVariantComponent,
    OptionsComponent,
    AddOptionsComponent,
    EditOptionsComponent,
    ProductComponent,
    AddProductComponent,
    EditProductComponent,
    BatchlistComponent,
    AddbatchComponent,
    SkuComponent,
    AddSkuComponent,
    EditSkuComponent,
    EditbatchComponent,
    RoleComponent,
    AddRoleComponent,
    EditRoleComponent,
    UserComponent,
    AddUserComponent,
    EditUserComponent,
    NotfoundComponent,
    AddqrcodeComponent,
    ListqrcodeComponent,
    AddpackagingqrcodeComponent,
    ListpackagingqrcodeComponent,
    AddshipperqrcodeComponent,
    ListshipperqrcodeComponent,
    BatchdetailsComponent,
    SkulistComponent,
    SkubatchlistComponent,
    EmployeeuserComponent,
    AddEmployeeuserComponent,
    EditEmployeeuserComponent,
    CompanyroleComponent,
    AddCompanyroleComponent,
    EditCompanyroleComponent,
    CompanyusersComponent,
    AddCompanyusersComponent,
    EditCompanyusersComponent,
    CustomerlistComponent,
    ProductComplainComponent,
    SkudetailsComponent,
    TrackandtraceComponent,
    RoledetailsComponent,
    SupplychainComponent,
    AddSupplychainComponent,
    EditSupplychainComponent,
    DetailshipperqrcodeComponent,
    DetailpackagingqrcodeComponent,
    ImportSkuComponent,
    AddMenuComponent,
    ListMenuComponent,
    EditMenuComponent,
    AddRoleMenuPermissionsComponent,
    EditRoleMenuPermissionsComponent,
    ListRoleMenuPermissionsComponent,
    DetailRoleMenuPermissionsComponent,
    ImportshipperqrcodeComponent,
    ListConsignmentComponent,
    DetailConsignmentComponent,
    InvoiceComponent,
    ProductiondashboardComponent,
    GiftClaimComponent,
    HistoryComponent,
    CoinAllocationComponent,
    RewardManagmentComponent,
    CounterfeitDashboardComponent,
    CompanyDashboardComponent,
    AddCompanyComponent,
    EditCompanyComponent,
    RegisterbyappComponent,
    ScanbyappComponent,
    CustomerCareComponent,
    RegisterProductComponent,
    ScanProductComponent,
    CoinAllocationListComponent,
    LuckyDrawComponent,
    AddLuckydrawComponent,
    LuckydrawDetailsComponent,
    LuckydrawRewardsManagementComponent,
    LuckydrawRewardsAddComponent,
    LuckydrawGiftClaimComponent,
    LuckydrawRewardHistoryComponent,
    PaytmTransactionReportComponent,
    ConsumerDataExportComponent
  ],
  imports: [
    NgMultiSelectDropDownModule.forRoot(),
    BrowserModule,
    FormsModule,
    ChartsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    NgxPaginationModule,
    NgOtpInputModule,
    Ng2OrderModule,
    ChartsModule,
    OrderModule,
    RouterModule,
    SwiperModule,
    MapsModule,
    FlashMessagesModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: "toast-top-right"
    }),
    BrowserAnimationsModule,
    NgbModule.forRoot(),
    ConfirmationPopoverModule.forRoot({
      confirmButtonType: "danger" // set defaults here
    }),
    QuillModule,
    Select2Module,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyAx66_O5KtHzLI05WYw99duzKpT_5qP6nY",
      // libraries: ["maps"]
    })
  ],
  providers: [
    DatePipe,
    QuillInitializeService,
    ApiService,
    AuthGuard,
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthGuard, multi: true },
    Globals
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
