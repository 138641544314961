import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../../company/service/company.service';
import { OrderPipe } from 'ngx-order-pipe';
import { NgForm } from "@angular/forms";
import { FlashMessagesService } from 'angular2-flash-messages';


@Component({
	selector: 'app-edit-production',
	templateUrl: './edit-production.component.html',
	styleUrls: ['./edit-production.component.css']
})
export class EditProductionComponent implements OnInit {
	result : any = [];
	resultList: any;
	errors : any = '';
	Editid : any ;
	resultData : any;
	msg: string = null;
	public imagePath;
	imgURL: any = "/assets/images/placeholder.png";
	image: any;
	public message: string;
	companyId:number;
	submit:boolean=false;
	loader:boolean = false;
	numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

	constructor(private _flashMessagesService: FlashMessagesService,public companyService: CompanyService,private route: ActivatedRoute, private router: Router,private orderPipe: OrderPipe) {  }

	ngOnInit() {
		
		this.route.params.subscribe(params => {
			console.log(params);
			this.Editid=params['id']; 
			this.editproductionData();
		});
	}
	editproductionData(){
		this.loader=true;
		this.companyService.production_edit(this.Editid).subscribe((res:any)=>{
			this.loader=false;
			this.result=res.data;
			console.log(this.result);
		}, (err) => {

		});
	}

	onSubmit(form?:NgForm) {
		let f = form.value;
		 f.latitude=1;
         f.longitude=1;   	
		this.companyService.production_update(this.Editid,f).subscribe((res: any)=>{
			this.resultData = res;
			if (this.resultData.success == 1 ) {
			    this.submit=true;   	
				this._flashMessagesService.show('Plant updated successfully', { cssClass: 'alert-success' } );
				setTimeout(() => {
					this.router.navigate(['/plant']);
				}, 3000); 
				
			} else {
				this.errors = res.message;
				 setTimeout(() => {
            	this.errors.plant_id = false;
          	}, 3000); 
			}

		}, (err) => {
		
		});

	}
}
