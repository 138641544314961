import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { Globals } from 'src/app/globals';
import { ApiService } from 'src/app/service/api/api.service';

@Component({
  selector: 'app-luckydraw-details',
  templateUrl: './luckydraw-details.component.html',
  styleUrls: ['./luckydraw-details.component.css']
})
export class LuckydrawDetailsComponent implements OnInit {
  loader = true;
  order: string = "";
  reverse: boolean = false;
  p1 = this.globals.p;
  currentLimit1 = this.globals.currentLimit;
  pageLimits1 = this.globals.pageLimits;
  p2 = this.globals.p;
  currentLimit2 = this.globals.currentLimit;
  pageLimits2 = this.globals.pageLimits;
  drawId;
  luckyDraw;
  luckyDrawHistory:any;
  selectedDate:any ='';
  selectedHistoryWinners:any;
  constructor(private apiService: ApiService,private globals:Globals, private router: Router,
    private route: ActivatedRoute) { 
    this.route.params.subscribe((params) => {
      console.log(params);
      this.drawId = params["id"];
    });
  }

  ngOnInit() {
    this.GetLuckyDraw_details();
    this.GetLuckyDrawHistory();
  }

  GetLuckyDraw_details(){
    this.apiService
      .get("luckydraws/details-by-id?drawId="+this.drawId, true)
      .subscribe(
        (response: any) => {
          this.loader = false;
          if (response.status == 200 && response.body.success == 1) {
            this.luckyDraw = response.body.data;
          }
        },
        (error) => {
          console.error(error);
          this.loader = false;
        }
      );
  }
  GetLuckyDrawHistory(){
    this.apiService
    .get("luckydraws/draw-history?drawId="+this.drawId, true)
    .subscribe(
      (response: any) => {
        this.loader = false;
        if (response.status == 200 && response.body.success == 1) {
          this.luckyDrawHistory = response.body.data
          if(this.luckyDrawHistory.length!=0){
            this.selectedHistoryWinners = this.luckyDrawHistory[0].winners;
            this.selectedDate = this.luckyDrawHistory[0].createdAt;
          }else{
            this.selectedHistoryWinners = [];
          }
        }
      },
      (error) => {
        console.error(error);
        this.loader = false;
      }
    );
  }

  statuschange(status){
    this.loader = true;
    this.apiService
    .post("luckydraws/change-status",{drawId:this.drawId,status:status}, true)
    .subscribe(
      (response: any) => {
        this.loader = false;
        console.log("rrrrrrrrrr",response);
        
        if (response.status == 200 && response.body.success == 1) {
          this.GetLuckyDraw_details()          
        }
      },
      (error) => {
        console.error(error);
        this.loader = false;
      }
    );
  }

  selectExecution(i){    
    this.selectedHistoryWinners = this.luckyDrawHistory[i].winners;
  }

}
