import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../../company/service/company.service';
declare var $: any;
import { OrderPipe } from 'ngx-order-pipe';
import { FlashMessagesService } from 'angular2-flash-messages';

@Component({
  selector: 'app-companyrole',
  templateUrl: './companyrole.component.html',
  styleUrls: ['./companyrole.component.css']
})
export class CompanyroleComponent implements OnInit {
    data: any;
    searchText = "";
    sortedCollection: any[];
    order: string = 'result.id';
    reverse: boolean = false;
    result: any = [];
    arr: any = [];
    errors: any = '';
    deletedMsg: boolean = false;
    p: number=1;
    currentLimit: any = 10;
    display: string = 'none';
    pageLimits = [
        { 'id': 2, 'name': '2' },
        { 'id': 5, 'name': '5' },
        { 'id': 10, 'name': '10' },
        { 'id': 15, 'name': '15' },
    ];
    deleteId: any;
    loader:boolean = false;
 

  constructor(private _flashMessagesService: FlashMessagesService, public companyService: CompanyService,private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
  	this.roleData();
  }


  roleData() {
        this.loader=true;
        this.companyService.companyrole_list().subscribe((res: any) => {
            this.loader=false;
            this.result = res.data;
            console.log(this.result);

        }, (err) => {

        });
    }

    roleDelete(id) {

        this.companyService.deleteCompanyrole(id).subscribe((res: any) => {
            console.log(res);

            this.result = res.data;
            // this.deletedMsg =true;
            if (res['success'] == 1) {
                this._flashMessagesService.show('Company Role Deleted Successfully!', { cssClass: 'alert-success' });
                this.roleData();
                setTimeout(() => {
                    this.router.navigate(['/companyrole']);
                    this.roleData();
                }, 2000);

            } else {
                this.errors = res['message'];
            }

        }, (err) => {

        });
    }

    setOrder(value: string) {
        if (this.order === value) {
            this.reverse = !this.reverse;
        }
        this.order = value;
    }

    opendeleteModal(id) {
        this.deleteId = id;
        $('#exampleModal').modal('show');

    }

}
