import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../../company/service/company.service';
declare var $ :any; 
import { OrderPipe } from 'ngx-order-pipe';
import { FlashMessagesService } from 'angular2-flash-messages';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv'
import { Globals } from './../../../globals';

@Component({
	selector: 'app-skudetails',
	templateUrl: './skudetails.component.html',
	styleUrls: ['./skudetails.component.css']
})
export class SkudetailsComponent implements OnInit {
	productId:any;
	result: any = {};
	unitData:any;
	result1:any;
	featureId:any=[];
	howtouseId:any=[];
	howresult:any;
	feature_value:any;
	howtouse:any;
	shipper_size:any;
	loader:boolean = false;

	constructor( 
		private _flashMessagesService: FlashMessagesService,
		public companyService: CompanyService,
		private route: ActivatedRoute, 
		private router: Router,
		private orderPipe: OrderPipe,
		private globals: Globals) {  
	}

	async ngOnInit() {

		await this.route.params.subscribe(params => {
			this.productId=params['id']; 
		   this.productData();
		});
		this.unitData = this.globals.unitData;
		await this.keyfeatureData();
		await this.howtouseData();
	}

	productData(){
		this.loader=true;
		this.companyService.productbyId_details(this.productId).subscribe((res:any)=>{
			this.loader=false;
			console.log(res);
			this.result=res.data;
			console.log(this.result);
			
				for (let j=0; j< this.unitData.length; j++){

					if(this.result.option.unit==this.unitData[j].id){

						this.result.unit_value = this.unitData[j].text;
					}

				}
				console.log(this.result);

		}, (err) => {


		});
	}

	keyfeatureData(){

		this.result1=[];
		this.companyService.productDetails_edit(this.productId).subscribe((res:any)=>{

			for(let k of res.data){
				if ( k.feature_id == 4 ) {
					this.result1.push(k);
				} 
			}
			let data1=this.result1;
			this.featureId =data1[0];
			this.feature_value=data1[0].feature_value;
		}, 
		(err) => {

		});   	
	}

	howtouseData(){

		this.howresult=[];
		this.companyService.howtouse_edit(this.productId).subscribe((res:any)=>{

			for(let k of res.data){
				if ( k.feature_id == 5 ) {
					this.howresult.push(k);
				} 
			}
			let data2=this.howresult;
			this.howtouseId =data2[0];
			this.howtouse=data2[0].feature_value;
			console.log(this.howtouse);
		}, 
		(err) => {

		});   	
	}

}

