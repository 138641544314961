import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../../company/service/company.service';
declare var $: any;
import { OrderPipe } from 'ngx-order-pipe';
import { FlashMessagesService } from 'angular2-flash-messages';
import { Globals } from './../../../globals';

@Component({
  selector: 'app-add-role-menu-permissions',
  templateUrl: './add-role-menu-permissions.component.html',
  styleUrls: ['./add-role-menu-permissions.component.css']
})
export class AddRoleMenuPermissionsComponent implements OnInit {

    Permissions: any = [];
    msg: string = null;
    errors: any = '';
    model: any = {};
    submit: boolean = false;
    response: any ;

    constructor(private globals: Globals,
        private _flashMessagesService: FlashMessagesService,
        public companyService: CompanyService,
        private route: ActivatedRoute,
        private router: Router,
        private orderPipe: OrderPipe) { }

    ngOnInit() {
        this.companyService.getNonParentMenus().subscribe((res: any) => {

            for (var i = 0; i < res.data.length; i++) {
                this.Permissions.push({
                    menu_id: res.data[i].id,
                    title: res.data[i].title,
                    can_read: false,
                    can_write: false,
                    can_update: false,
                    can_delete: false
                });
            }

            console.log(this.Permissions);
        }, (err) => {
            console.log(err);
        });
  }

    onSubmit(form?: NgForm) {
        let f = form.value;

        let requestValue = {
            role_title : f.role_title,
            role_menu_permission : this.Permissions
        };

        this.companyService.addRoleMenuPermission(requestValue).subscribe((res: any) => {

            this.response = res;

            if (this.response.success == 1) {
                this.submit = true;
                this._flashMessagesService.show('Role Menu Permission created sucessfully', { cssClass: 'alert-success' });

                setTimeout(() => {
                    this.router.navigate(['/rolemenupermission']);
                }, 3000);

            } else {
                this.errors = res.message;
                setTimeout(() => {
                    this.errors.role_menu_permission_id = false;
                }, 2000);
            }
        }, (err) => {
            console.log(err);
        });
    }
}
