import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../../company/service/company.service';
declare var $ :any; 
import { OrderPipe } from 'ngx-order-pipe';
import { FlashMessagesService } from 'angular2-flash-messages';
import { Globals } from '../../../globals';
import { ToastrService } from 'ngx-toastr';


@Component({
	selector: 'app-coin-allocation-list',
	templateUrl: './coin-allocation-list.component.html',
	styleUrls: ['./coin-allocation-list.component.css']
})
export class CoinAllocationListComponent implements OnInit {
	
	searchText  = "";
	order: string = 'pointAllocationLists.createdAt';
	reverse: boolean = false;
	result : any = [];
	p =this.globals.p;
    currentLimit=this.globals.currentLimit;
	pageLimits = this.globals.pageLimits;

	loader:boolean = false;
	pointAllocationLists:any = [];

	public popoverTitle = "Confirm";
	public popoverMessage = "Are you sure want to delete configuration?";
	public confirmClicked = false;
	public cancelClicked = false;

	constructor(private globals: Globals,private _flashMessagesService: FlashMessagesService,public companyService: CompanyService,private route: ActivatedRoute, private router: Router,private orderPipe: OrderPipe,public toastr: ToastrService) {};
	
	ngOnInit() {
		this.pointAllocationList();
	}

	setOrder(value: string) {
		if (this.order === value) {
			this.reverse = !this.reverse;
		}
		this.order = value;
	}

	pointAllocationList(){
		this.loader = true;
		this.companyService.point_allocation_list().subscribe(
	      (response: any) => {
	        if (response.success == 1) { 
	        	this.loader = false;     
	          this.pointAllocationLists = response.data;
	        }
	      },
	      err => {
	        console.error(err);
	      }
	    );
	}

	showToast(toastType, toastCaption, toastText) {
    if(toastType == "danger"){
      this.toastr.error(toastCaption, toastText);
    }else {
      this.toastr.success(toastCaption);
    }
    /*this.toaster.open({
      text: toastText,
      caption: toastCaption,
      type: toastType,
      position: "top-right",
      component: CustomToasterComponent
    });*/
  }


	onDeleteConfiguration(detail) {
		console.log("---0--")
		this.loader = true;
	    this.companyService.pointAllocationDelete(detail).subscribe(
	      (response: any) => {
	        if (response.success == 1) {
	        	this.loader = false;
	          this.showToast('success', 'Point configuration successfully deleted', 'Reward has deleted successfully!')
	           this.pointAllocationList();
	          //window.location.reload();
	        } else {
				this.loader = false;
	          this.showToast(
	            "danger",
	            "Failed - Delete point configuration",
	            response.message
	          );
	        }
	      },
	      err => {
	        this.showToast(
	          "danger",
			  "Failed - Delete point configuration",
	          "Due to some reason reward has not deleted!"
	        );
	      }
	    );
  	}
}
