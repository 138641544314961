import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../../../company/service/company.service';
import { FlashMessagesService } from 'angular2-flash-messages';

@Component({
  selector: 'app-add-companyrole',
  templateUrl: './add-companyrole.component.html',
  styleUrls: ['./add-companyrole.component.css']
})
export class AddCompanyroleComponent implements OnInit {

  constructor(private _flashMessagesService: FlashMessagesService, public companyService: CompanyService,private route: ActivatedRoute, private router: Router) { }

    resultList: any;
    resultRole: any;
    msg: string = null;
    errors: any = '';
    model: any = {};
    public imagePath;
    message: any;
    filedata: any;
    submit:boolean=false;

  ngOnInit() {
  }


   onSubmit(form?: NgForm) {
        
        let f = form.value;
        //let formdata = new FormData();
        //formdata.append("name", f.name);

        this.companyService.companyrole_add(f).subscribe((res: any) => {
            console.log(res);
            this.resultRole = res;

            if (this.resultRole.success == 1) {
                this.submit=true; 
                this._flashMessagesService.show('Role Created Successfully!', { cssClass: 'alert-success' });
                setTimeout(() => {
                    this.router.navigate(['/companyrole']);
                }, 2000);

            } else {
                this.errors = res.message;
                 setTimeout(() => {
                this.errors.companyrole_id = false;
              }, 3000); 

            }

        }, (err) => {

        });
    }

}
