import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../company/service/company.service';
import { FlashMessagesService } from 'angular2-flash-messages';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css']
})
export class InvoiceComponent implements OnInit {
  model: any = {};
  invoiceData:any = {};
  resultInvoice :any;
  errors : any = '';
  submit:boolean=false;
  loader:boolean = false;
  msg: string = null;

  constructor(private _flashMessagesService: FlashMessagesService, public companyService: CompanyService,private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.invoice();
  }

  async invoice(){
    var result = [];
    await this.companyService.getinvoiceno().subscribe((res:any)=>{ 
      this.invoiceData = res.invoice_no;
    })
  }
  
  onSubmit(form?:NgForm) {
    let f = form.value;

    this.companyService.updateInvoice(f).subscribe((res : any)=>{
      this.resultInvoice = res;

        if (this.resultInvoice.success == 1 ) {
          this._flashMessagesService.show('Invoice Update successfully', { cssClass: 'alert-success' } );
          
          setTimeout(() => {
            this.router.navigate(['/invoice']);
          }, 2000); 
        } else {
      this.errors = res.message;
    }
  }, (err) => {
  });
 
}
}
