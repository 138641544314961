import { Component, OnInit } from '@angular/core';
import { OrderPipe } from 'ngx-order-pipe';
import { Globals } from 'src/app/globals';
import { ApiService } from 'src/app/service/api/api.service';

declare var jQuery: any;

@Component({
  selector: 'app-paytm-transaction-report',
  templateUrl: './paytm-transaction-report.component.html',
  styleUrls: ['./paytm-transaction-report.component.css']
})
export class PaytmTransactionReportComponent implements OnInit {

  searchText = "";
  sortedCollection: any[];
  order: string = 'scanned_at';
  reverse: boolean = false;
  result: any = [];

  p = this.globals.p;
  currentLimit = this.globals.currentLimit;
  pageLimits = this.globals.pageLimits;

  loader: boolean = false;

  constructor(
    private globals: Globals,
    private orderPipe: OrderPipe,
    private apiService: ApiService) {
    this.sortedCollection = orderPipe.transform(this.result, 'scanned_at');
  }


  ngOnInit() {
    this.loadDatePickerScript();
  }

  onFindTransaction() {
    let startDate = document.getElementById("start_date")['value'];
    let endDate = document.getElementById("end_date")['value'];
    console.log(startDate, endDate)
    this.loader = true;
    this.apiService.get(`paytm/transaction-report?startDate=${startDate}&endDate=${endDate}`, true).subscribe((response: any) => {
      if(response.status == 200 && response.body.success == 1) {
        this.result = response.body.data;
      }
    })
    this.loader =false;
  }

  setOrder(value: string) {
		if (this.order === value) {
			this.reverse = !this.reverse;
		}
		this.order = value;
	}

  loadDatePickerScript() {

    jQuery(document).ready(() => {
      jQuery("#start_date").daterangepicker(
        {
          singleDatePicker: true,
          showDropdowns: true,
          locale: {
            format: "YYYY-MM-DD",
          },
          maxDate: new Date(),
          // minDate: new Date(),
          autoclose: true,
          //minYear: 1901,
        },
        (start: any, end: any, label: any) => {
          console.log("s: ", start, "e: ", end, "l: ", label);

          jQuery("#end_date").daterangepicker(
            {
              singleDatePicker: true,
              showDropdowns: true,
              locale: {
                format: "YYYY-MM-DD",
              },
              maxDate: new Date(),
              minDate: start,
              autoclose: true,
              minYear: 1901,
            },
            (start: any, end: any, label: any) => {
              console.log(start, end, label);
            }
          );
        }
      );

      jQuery("#end_date").daterangepicker(
        {
          singleDatePicker: true,
          showDropdowns: true,
          locale: {
            format: "YYYY-MM-DD",
          },
          //maxDate: new Date(),
          minDate: new Date(),
          autoclose: true,
          minYear: 1901,
        },
        (start: any, end: any, label: any) => {
          console.log(start, end, label);
        }
      );
      // setTimeout(() => {
      //   if (this.pointForm) {
      //     jQuery("#startDate").val(this.pointForm.value.startDate);
      //     jQuery("#endDate").val(this.pointForm.value.endDate);
      //   }
      // }, 2000)
    });
  }

}
