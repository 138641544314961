import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { BrandComponent } from './company/brand/brand.component';
import { ProductionComponent } from './company/production/production.component';
import { AddProductionComponent } from './company/production/add-production/add-production.component';
import { EditProductionComponent } from './company/production/edit-production/edit-production.component';
import { CompanyloginComponent } from './companylogin/companylogin.component';
import { AuthGuard } from './guards/auth-guard.service';
import { AddBrandComponent } from './company/brand/add-brand/add-brand.component';
import { EditBrandComponent } from './company/brand/edit-brand/edit-brand.component';
import { ProductionlineComponent } from './company/productionline/productionline.component';
import { AddProductionlineComponent } from './company/productionline/add-productionline/add-productionline.component';
import { EditProductionlineComponent } from './company/productionline/edit-productionline/edit-productionline.component';
import { CategoryComponent } from './company/category/category.component';
import { AddCategoryComponent } from './company/category/add-category/add-category.component';
import { EditCategoryComponent } from './company/category/edit-category/edit-category.component';
import { VariantComponent } from './company/variant/variant.component';
import { AddVariantComponent } from './company/variant/add-variant/add-variant.component';
import { EditVariantComponent } from './company/variant/edit-variant/edit-variant.component';
import { OptionsComponent } from './company/options/options.component';
import { AddOptionsComponent } from './company/options/add-options/add-options.component';
import { EditOptionsComponent } from './company/options/edit-options/edit-options.component';
import { ProductComponent } from './company/product/product.component';
import { AddProductComponent } from './company/product/add-product/add-product.component';
import { EditProductComponent } from './company/product/edit-product/edit-product.component';
import { BatchlistComponent } from './company/batch/batchlist/batchlist.component';
import { AddbatchComponent } from './company/batch/addbatch/addbatch.component';
import { SkuComponent } from './company/sku/sku.component';
import { AddSkuComponent } from './company/sku/add-sku/add-sku.component';
import { EditSkuComponent } from './company/sku/edit-sku/edit-sku.component';
import { EditbatchComponent } from './company/batch/editbatch/editbatch.component';
import { RoleComponent } from './company/business/role/role.component';
import { AddRoleComponent } from './company/business/role/add-role/add-role.component';
import { EditRoleComponent } from './company/business/role/edit-role/edit-role.component';
import { UserComponent } from './company/business/user/user.component';
import { AddUserComponent } from './company/business/user/add-user/add-user.component';
import { EditUserComponent } from './company/business/user/edit-user/edit-user.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { AddqrcodeComponent } from './company/qrcode/addqrcode/addqrcode.component';
import { ListqrcodeComponent } from './company/qrcode/listqrcode/listqrcode.component';
import { AddpackagingqrcodeComponent } from './company/packagingqrcode/addpackagingqrcode/addpackagingqrcode.component';
import { ListpackagingqrcodeComponent } from './company/packagingqrcode/listpackagingqrcode/listpackagingqrcode.component';
import { AddshipperqrcodeComponent } from './company/shipperqrcode/addshipperqrcode/addshipperqrcode.component';
import { ListshipperqrcodeComponent } from './company/shipperqrcode/listshipperqrcode/listshipperqrcode.component';
import { BatchdetailsComponent } from './company/batch/batchdetails/batchdetails.component';
import { SkulistComponent } from './company/inventory/skulist/skulist.component';
import { SkubatchlistComponent } from './company/inventory/skubatchlist/skubatchlist.component';
import { EmployeeuserComponent } from './company/employeeuser/employeeuser.component';
import { AddEmployeeuserComponent } from './company/employeeuser/add-employeeuser/add-employeeuser.component';
import { EditEmployeeuserComponent } from './company/employeeuser/edit-employeeuser/edit-employeeuser.component';
import { CompanyroleComponent } from './company/companyuser/companyrole/companyrole.component';
import { AddCompanyroleComponent } from './company/companyuser/companyrole/add-companyrole/add-companyrole.component';
import { EditCompanyroleComponent } from './company/companyuser/companyrole/edit-companyrole/edit-companyrole.component';
import { CompanyusersComponent } from './company/companyuser/companyusers/companyusers.component';
import { AddCompanyusersComponent } from './company/companyuser/companyusers/add-companyusers/add-companyusers.component';
import { EditCompanyusersComponent } from './company/companyuser/companyusers/edit-companyusers/edit-companyusers.component';
import { CustomerlistComponent } from './customer/customerlist/customerlist.component';
import { ProductComplainComponent } from './product-complain/product-complain.component';
import { SkudetailsComponent } from './company/sku/skudetails/skudetails.component';
import { TrackandtraceComponent } from './company/trackandtrace/trackandtrace.component';
import { RoledetailsComponent } from './company/qrcode/roledetails/roledetails.component';
import { SupplychainComponent } from './company/supplychain/supplychain.component';
import { AddSupplychainComponent } from './company/supplychain/add-supplychain/add-supplychain.component';
import { EditSupplychainComponent } from './company/supplychain/edit-supplychain/edit-supplychain.component';
import { DetailshipperqrcodeComponent } from './company/shipperqrcode/detailshipperqrcode/detailshipperqrcode.component';
import { DetailpackagingqrcodeComponent } from './company/packagingqrcode/detailpackagingqrcode/detailpackagingqrcode.component';
import { ImportSkuComponent } from './company/sku/import-sku/import-sku.component';
import { AddMenuComponent } from './company/menu/add-menu/add-menu.component';
import { ListMenuComponent } from './company/menu/list-menu/list-menu.component';
import { EditMenuComponent } from './company/menu/edit-menu/edit-menu.component';
import { AddRoleMenuPermissionsComponent } from './company/role-menu-permissions/add-role-menu-permissions/add-role-menu-permissions.component';
import { EditRoleMenuPermissionsComponent } from './company/role-menu-permissions/edit-role-menu-permissions/edit-role-menu-permissions.component';
import { ListRoleMenuPermissionsComponent } from './company/role-menu-permissions/list-role-menu-permissions/list-role-menu-permissions.component';
import { DetailRoleMenuPermissionsComponent } from './company/role-menu-permissions/detail-role-menu-permissions/detail-role-menu-permissions.component';
import { ImportshipperqrcodeComponent } from './company/shipperqrcode/importshipperqrcode/importshipperqrcode.component';
import { ListConsignmentComponent } from './company/business/consignment/list-consignment/list-consignment.component';
import { DetailConsignmentComponent } from './company/business/consignment/detail-consignment/detail-consignment.component';
import { InvoiceComponent } from './company/invoice/invoice.component';
import { RegisterbyappComponent } from './company/customer/registerbyapp/registerbyapp.component';
import { ScanbyappComponent } from './company/customer/scanbyapp/scanbyapp.component';
import { ProductiondashboardComponent } from './company/productiondashboard/productiondashboard.component';
import { CompanyDashboardComponent } from './company/company-dashboard/company-dashboard.component';
import { AddCompanyComponent } from './company/add-company/add-company.component';
import { EditCompanyComponent } from './company/edit-company/edit-company.component';
import { CustomerCareComponent } from './company/customer-care/customer-care.component';
import { CoinAllocationComponent } from './company/rewards/coin-allocation/coin-allocation.component';
import { CoinAllocationListComponent } from './company/rewards/coin-allocation-list/coin-allocation-list.component';
import { GiftClaimComponent } from './company/rewards/gift-claim/gift-claim.component';
import { HistoryComponent } from './company/rewards/history/history.component';
import { RewardManagmentComponent } from './company/rewards/reward-managment/reward-managment.component';
import { CounterfeitDashboardComponent } from "./company/counterfeit-dashboard/counterfeit-dashboard.component";
import { RegisterProductComponent } from './customer/register-product/register-product.component';
import { ScanProductComponent } from './customer/scan-product/scan-product.component';
import { LuckyDrawComponent } from './company/lucky-draw/lucky-draw.component';
import { AddLuckydrawComponent } from './company/lucky-draw/add-luckydraw/add-luckydraw.component';
import { LuckydrawDetailsComponent } from './company/lucky-draw/luckydraw-details/luckydraw-details.component';
import { LuckydrawRewardsAddComponent } from './company/lucky-draw/luckydraw-rewards-add/luckydraw-rewards-add.component';
import { LuckydrawGiftClaimComponent } from './company/lucky-draw/luckydraw-gift-claim/luckydraw-gift-claim.component';
import { LuckydrawRewardsManagementComponent } from './company/lucky-draw/luckydraw-rewards-management/luckydraw-rewards-management.component';
import { LuckydrawRewardHistoryComponent } from './company/lucky-draw/luckydraw-reward-history/luckydraw-reward-history.component';
import { PaytmTransactionReportComponent } from './company/paytm/paytm-transaction-report/paytm-transaction-report.component';
import { ConsumerDataExportComponent } from './company/consumer-data-export/consumer-data-export.component';

//role 1 =>Admin, 2=>Company
const routes: Routes = [
  { path: "", redirectTo: "/companylogin", pathMatch: "full" },
  // {
  //   path: "login",
  //   component: LoginComponent,
  //   pathMatch: "full",
  //   data: { title: "Trusttags::Login" },
  // },
  {
    path: "companylogin",
    component: CompanyloginComponent,
    pathMatch: "full",
    data: { title: "Trusttags::CompanyLogin" },
  },
  {
    path: "dashboard",
    component: DashboardComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
    data: { role: ["1", "2", "4"], title: "Trusttags::Dashboard" },
  },
  {
    path: "productiondashboard",
    component: ProductiondashboardComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
    data: { role: ["1", "2", "4"], title: "Trusttags::ProductionDashboard" },
  },
  {
    path: "companies",
    children: [
      {
        path: "",
        component: CompanyDashboardComponent,
        pathMatch: "full",
        data: { title: "Trusttags::Company" },
      },
      {
        path: "add",
        component: AddCompanyComponent,
        pathMatch: "full",
        data: { title: "Trusttags::AddCompany" },
      },
      {
        path: "edit/:id",
        component: EditCompanyComponent,
        pathMatch: "full",
        data: { title: "Trusttags::EditCompany" },
      },
    ],
    canActivate: [AuthGuard],
    data: { role: ["1"] },
  },
  {
    path: "counterfeit-dashboard",
    component: CounterfeitDashboardComponent,
    canActivate: [AuthGuard],
    data: { role: ["1", "2", "4"], title: "Trusttags::Counterfeit Dashboard" },
  },
  {
    path: "lucky-draw",
    children: [
      {
        path: "",
        component: LuckyDrawComponent,
        pathMatch: "full",
        canActivate: [AuthGuard],
        data: {
          roles: [1],
          title: "Trusttags::Lucky Draw",
        },
      },
      {
        path: "add",
        component: AddLuckydrawComponent,
        pathMatch: "full",
        canActivate: [AuthGuard],
        data: {
          roles: [1],
          title: "Trusttags::Add Lucky Draw",
        },
      },
      {
        path: "detail/:id",
        component: LuckydrawDetailsComponent,
        pathMatch: "full",
        canActivate: [AuthGuard],
        data: {
          roles: [1],
          title: "Trusttags::Lucky Draw Detail",
        },
      }
      
    ]
  },
  {
    path: "luckydraw-reward-history",
    children: [
      {
        path: "",
        component: LuckydrawRewardHistoryComponent,
        pathMatch: "full",
        canActivate: [AuthGuard],
        data: {
          roles: [1],
          title: "Trusttags::History",
        }
      }]
  },
  {
    path: "luckydraw-rewards",
    children: [
      {
        path: "",
        component: LuckydrawRewardsManagementComponent,
        pathMatch: "full",
        canActivate: [AuthGuard],
        data: {
          roles: [1],
          title: "Trusttags::Rewards",
        },
      }, {
        path: "add",
        component: LuckydrawRewardsAddComponent,
        pathMatch: "full",
        canActivate: [AuthGuard],
        data: {
          roles: [1],
          title: "Trusttags::Rewards",
        }
      }
    ],
  },
  {
    path: "gift-claim",
    children: [
      {
        path: "",
        component: GiftClaimComponent,
        pathMatch: "full",
        canActivate: [AuthGuard],
        data: {
          roles: [1],
          title: "Trusttags::Gift Claim",
        }
      }]
  },
  {
    path: "luckydraw-gift-claim",
    children: [
      {
        path: "",
        component: LuckydrawGiftClaimComponent,
        pathMatch: "full",
        canActivate: [AuthGuard],
        data: {
          roles: [1],
          title: "Trusttags::Gift Claim",
        }
      }]
  },
  {
    path: "customer",
    children: [
      {
        path: "",
        component: CustomerlistComponent,
        pathMatch: "full",
        data: { title: "Trusttags::Customer" },
      },
      {
        path: "registered-product",
        component: RegisterProductComponent,
        pathMatch: "full",
        data: { title: "Trusttags::Customer" },
      },
      {
        path: "scanned-product",
        component: ScanProductComponent,
        pathMatch: "full",
        data: { title: "Trusttags::Customer" },
      }
    ],
    canActivate: [AuthGuard],
    data: { role: ["1"] },
  },
  {
    path: "productComplain",
    children: [
      {
        path: "",
        component: ProductComplainComponent,
        pathMatch: "full",
        data: { title: "Trusttags::Product Report" },
      },
    ],
    canActivate: [AuthGuard],
    data: { role: ["1"] },
  },

  //plant as production
  // {
  //   path: "plant",
  //   children: [
  //     {
  //       path: "",
  //       component: ProductionComponent,
  //       pathMatch: "full",
  //       data: { title: "Trusttags::Plant" },
  //     },
  //     {
  //       path: "add",
  //       component: AddProductionComponent,
  //       pathMatch: "full",
  //       data: { title: "Trusttags::AddPlant" },
  //     },
  //     {
  //       path: "edit/:id",
  //       component: EditProductionComponent,
  //       pathMatch: "full",
  //       data: { title: "Trusttags::EditPlant" },
  //     },
  //   ],
  //   canActivate: [AuthGuard],
  //   data: { role: ["2", "1"] },
  // },
  // {
  //   path: "brand",
  //   children: [
  //     {
  //       path: "",
  //       component: BrandComponent,
  //       pathMatch: "full",
  //       data: { title: "Trusttags::Brand" },
  //     },
  //     {
  //       path: "add",
  //       component: AddBrandComponent,
  //       pathMatch: "full",
  //       data: { title: "Trusttags::AddBrand" },
  //     },
  //     {
  //       path: "edit/:id",
  //       component: EditBrandComponent,
  //       pathMatch: "full",
  //       data: { title: "Trusttags::EditBrand" },
  //     },
  //   ],
  //   canActivate: [AuthGuard],
  //   data: { role: ["2", "1"] },
  // },
  // {
  //   path: "line",
  //   children: [
  //     {
  //       path: "",
  //       component: ProductionlineComponent,
  //       pathMatch: "full",
  //       data: { title: "Trusttags::Line" },
  //     },
  //     {
  //       path: "add",
  //       component: AddProductionlineComponent,
  //       pathMatch: "full",
  //       data: { title: "Trusttags::AddLine" },
  //     },
  //     {
  //       path: "edit/:id",
  //       component: EditProductionlineComponent,
  //       pathMatch: "full",
  //       data: { title: "Trusttags::EditLine" },
  //     },
  //   ],
  //   canActivate: [AuthGuard],
  //   data: { role: ["2", "1"] },
  // },
  // {
  //   path: "category",
  //   children: [
  //     {
  //       path: "",
  //       component: CategoryComponent,
  //       pathMatch: "full",
  //       data: { title: "Trusttags::Category" },
  //     },
  //     {
  //       path: "add",
  //       component: AddCategoryComponent,
  //       pathMatch: "full",
  //       data: { title: "Trusttags::AddCategory" },
  //     },
  //     {
  //       path: "edit/:id",
  //       component: EditCategoryComponent,
  //       pathMatch: "full",
  //       data: { title: "Trusttags::EditCategory" },
  //     },
  //   ],
  //   canActivate: [AuthGuard],
  //   data: { role: ["2", "1"] },
  // },
  // {
  //   path: "variant",
  //   children: [
  //     {
  //       path: "",
  //       component: VariantComponent,
  //       pathMatch: "full",
  //       data: { title: "Trusttags::Variant" },
  //     },
  //     {
  //       path: "add",
  //       component: AddVariantComponent,
  //       pathMatch: "full",
  //       data: { title: "Trusttags::AddVariant" },
  //     },
  //     {
  //       path: "edit/:id",
  //       component: EditVariantComponent,
  //       pathMatch: "full",
  //       data: { title: "Trusttags::EditVariant" },
  //     },
  //   ],
  //   canActivate: [AuthGuard],
  //   data: { role: ["2", "1"] },
  // },

  //size master
  // {
  //   path: "size",
  //   children: [
  //     {
  //       path: "",
  //       component: OptionsComponent,
  //       pathMatch: "full",
  //       data: { title: "Trusttags::Size" },
  //     },
  //     {
  //       path: "add",
  //       component: AddOptionsComponent,
  //       pathMatch: "full",
  //       data: { title: "Trusttags::AddSize" },
  //     },
  //     {
  //       path: "edit/:id",
  //       component: EditOptionsComponent,
  //       pathMatch: "full",
  //       data: { title: "Trusttags::EditSize" },
  //     },
  //   ],
  //   canActivate: [AuthGuard],
  //   data: { role: ["2", "1"] },
  // },
  //sku generation

  // {
  //   path: "sku",
  //   children: [
  //     {
  //       path: "",
  //       component: SkuComponent,
  //       pathMatch: "full",
  //       data: { title: "Trusttags::Sku" },
  //     },
  //     {
  //       path: "add",
  //       component: AddSkuComponent,
  //       pathMatch: "full",
  //       data: { title: "Trusttags::AddSku" },
  //     },
  //     {
  //       path: "edit/:id",
  //       component: EditSkuComponent,
  //       pathMatch: "full",
  //       data: { title: "Trusttags::EditSku" },
  //     },
  //     {
  //       path: "details/:id",
  //       component: SkudetailsComponent,
  //       pathMatch: "full",
  //       data: { title: "Trusttags::Sku Details" },
  //     },
  //     {
  //       path: "import-sku",
  //       component: ImportSkuComponent,
  //       pathMatch: "full",
  //       data: { title: "Trusttags::Import Sku" },
  //     },
  //   ],
  //   canActivate: [AuthGuard],
  //   data: { role: ["2", "1"] },
  // },

  // {
  //   path: "packaging",
  //   children: [
  //     {
  //       path: "",
  //       component: ProductComponent,
  //       pathMatch: "full",
  //       data: { title: "Trusttags::Packaging" },
  //     },
  //     {
  //       path: "add",
  //       component: AddProductComponent,
  //       pathMatch: "full",
  //       data: { title: "Trusttags::AddPackaging" },
  //     },
  //     {
  //       path: "edit/:id",
  //       component: EditProductComponent,
  //       pathMatch: "full",
  //       data: { title: "Trusttags::EditPackaging" },
  //     },
  //   ],
  //   canActivate: [AuthGuard],
  //   data: { role: ["2", "1"] },
  // },
  {
    path: "batch",
    children: [
      {
        path: "",
        component: BatchlistComponent,
        pathMatch: "full",
        data: { title: "Trusttags::Batch" },
      },
      // {
      //   path: "add",
      //   component: AddbatchComponent,
      //   pathMatch: "full",
      //   data: { title: "Trusttags::AddBatch" },
      // },
      // {
      //   path: "edit/:id",
      //   component: EditbatchComponent,
      //   pathMatch: "full",
      //   data: { title: "Trusttags::EditBatch" },
      // },
      // {
      //   path: "details/:id",
      //   component: BatchdetailsComponent,
      //   pathMatch: "full",
      //   data: { title: "Trusttags::Batch Details" },
      // },
    ],
    canActivate: [AuthGuard],
    data: { role: ["2", "1"] },
  },
  // {
  //   path: "role",
  //   children: [
  //     {
  //       path: "",
  //       component: RoleComponent,
  //       pathMatch: "full",
  //       data: { title: "Trusttags::BusinessRole" },
  //     },
  //     {
  //       path: "add",
  //       component: AddRoleComponent,
  //       pathMatch: "full",
  //       data: { title: "Trusttags::AddRole" },
  //     },
  //     {
  //       path: "edit/:id",
  //       component: EditRoleComponent,
  //       pathMatch: "full",
  //       data: { title: "Trusttags::EditRole" },
  //     },
  //   ],
  //   canActivate: [AuthGuard],
  //   data: { role: ["2", "1"] },
  // },
  {
    path: "user",
    children: [
      {
        path: "",
        component: UserComponent,
        pathMatch: "full",
        data: { title: "Trusttags::BusinessUser" },
      },
      {
        path: "add",
        component: AddUserComponent,
        pathMatch: "full",
        data: { title: "Trusttags::AddUser" },
      },
      {
        path: "edit/:id",
        component: EditUserComponent,
        pathMatch: "full",
        data: { title: "Trusttags::EditUser" },
      },
    ],
    canActivate: [AuthGuard],
    data: { role: ["2", "1"] },
  },

  {
    path: "export-excel",
    children: [
      {
        path: "",
        component: ConsumerDataExportComponent,
        pathMatch: "full",
        data: { title: "Trusttags::Consumer-export" },
      }
    ],
    canActivate: [AuthGuard],
    data: { role: ["2", "1"] },
  },


  // {
  //   path: "employeeuser",
  //   children: [
  //     {
  //       path: "",
  //       component: EmployeeuserComponent,
  //       pathMatch: "full",
  //       data: { title: "Trusttags::FactoryUser" },
  //     },
  //     {
  //       path: "add",
  //       component: AddEmployeeuserComponent,
  //       pathMatch: "full",
  //       data: { title: "Trusttags::AddUser" },
  //     },
  //     {
  //       path: "edit/:id",
  //       component: EditEmployeeuserComponent,
  //       pathMatch: "full",
  //       data: { title: "Trusttags::EditUser" },
  //     },
  //   ],
  //   canActivate: [AuthGuard],
  //   data: { role: ["2", "1"] },
  // },

  // {
  //   path: "companyrole",
  //   children: [
  //     {
  //       path: "",
  //       component: CompanyroleComponent,
  //       pathMatch: "full",
  //       data: { title: "Trusttags::CompanyRole" },
  //     },
  //     {
  //       path: "add",
  //       component: AddCompanyroleComponent,
  //       pathMatch: "full",
  //       data: { title: "Trusttags::AddRole" },
  //     },
  //     {
  //       path: "edit/:id",
  //       component: EditCompanyroleComponent,
  //       pathMatch: "full",
  //       data: { title: "Trusttags::EditRole" },
  //     },
  //   ],
  //   canActivate: [AuthGuard],
  //   data: { role: ["2", "1"] },
  // },
  // {
  //   path: "companyuser",
  //   children: [
  //     {
  //       path: "",
  //       component: CompanyusersComponent,
  //       pathMatch: "full",
  //       data: { title: "Trusttags::CompanyUser" },
  //     },
  //     {
  //       path: "add",
  //       component: AddCompanyusersComponent,
  //       pathMatch: "full",
  //       data: { title: "Trusttags::AddUser" },
  //     },
  //     {
  //       path: "edit/:id",
  //       component: EditCompanyusersComponent,
  //       pathMatch: "full",
  //       data: { title: "Trusttags::EditUser" },
  //     },
  //   ],
  //   canActivate: [AuthGuard],
  //   data: { role: ["2", "1"] },
  // },

  {
    path: "not_found",
    children: [
      {
        path: "",
        component: NotfoundComponent,
        pathMatch: "full",
        data: { title: "Trusttags::Not Found" },
      },
    ],
  },

  {
    path: "qrcode",
    children: [
      {
        path: "",
        component: ListqrcodeComponent,
        pathMatch: "full",
        data: { title: "Trusttags::Qrcode" },
      },
      {
        path: "add",
        component: AddqrcodeComponent,
        pathMatch: "full",
        data: { title: "Trusttags::AddQrcode" },
      },
      {
        path: "details/:id",
        component: RoledetailsComponent,
        pathMatch: "full",
        data: { title: "Trusttags::Role Details" },
      },
    ],
    canActivate: [AuthGuard],
    data: { role: ["2", "4", "1"] },
  },
  {
    path: "packaging_qrcode",
    children: [
      {
        path: "",
        component: ListpackagingqrcodeComponent,
        pathMatch: "full",
        data: { title: "Trusttags::Packaging Qrcode" },
      },
      {
        path: "add",
        component: AddpackagingqrcodeComponent,
        pathMatch: "full",
        data: { title: "Trusttags::Add Packaging Qrcode" },
      },
      {
        path: "details/:id",
        component: DetailpackagingqrcodeComponent,
        pathMatch: "full",
        data: { title: "Trusttags::Detail Packaging Qrcode" },
      },
    ],
    canActivate: [AuthGuard],
    data: { role: ["2", "4", "1"] },
  },
  {
    path: "customercare_add",
    component: CustomerCareComponent,
    pathMatch: "full",
    data: {
      title: "Trusttags::Reports"
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'rewards/coin-allocation',
    component: CoinAllocationComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Trusttags::Point Allocation"
    }
  },
  {
    path: 'rewards/coin-allocation/:id',
    component: CoinAllocationComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Trusttags::Point Allocation"
    }
  },
  {
    path: 'rewards/point-allocation-list',
    component: CoinAllocationListComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Trusttags::Point Allocation"
    }
  },
  {
    path: 'rewards/gift-claim',
    component: GiftClaimComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Trusttags::Gift Claim"
    }
  },
  {
    path: 'rewards/history',
    component: HistoryComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Trusttags::Reward History"
    }
  },
  {
    path: 'rewards/manage',
    component: RewardManagmentComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Trusttags::Reward Management"
    }
  },
  {
    path: 'paytm/transaction-report',
    component: PaytmTransactionReportComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Trusttags::Paytm Transaction Report"
    }
  },
  {
    path: 'qrcode',
    children: [
      { path: '', component: ListqrcodeComponent, pathMatch: 'full', data: { title: 'Trusttags::Qrcode' } },
      { path: 'add', component: AddqrcodeComponent, pathMatch: 'full', data: { title: 'Trusttags::AddQrcode' } },
      { path: 'details/:id', component: RoledetailsComponent, pathMatch: 'full', data: { title: 'Trusttags::Role Details' } },
    ],
    canActivate: [AuthGuard],
    data: { role: ['2', '4'] }
  },
  {
    path: 'packaging_qrcode',
    children: [
      { path: '', component: ListpackagingqrcodeComponent, pathMatch: 'full', data: { title: 'Trusttags::Packaging Qrcode' } },
      { path: 'add', component: AddpackagingqrcodeComponent, pathMatch: 'full', data: { title: 'Trusttags::Add Packaging Qrcode' } },
      { path: 'details/:id', component: DetailpackagingqrcodeComponent, pathMatch: 'full', data: { title: 'Trusttags::Detail Packaging Qrcode' } }
    ],
    canActivate: [AuthGuard],
    data: { role: ['2', '4'] }
  },
  {
    path: "shipper_qrcode",
    children: [
      {
        path: "",
        component: ListshipperqrcodeComponent,
        pathMatch: "full",
        data: { title: "Trusttags::Shipper Qrcode" },
      },
      {
        path: "add",
        component: AddshipperqrcodeComponent,
        pathMatch: "full",
        data: { title: "Trusttags::Add Shipper Qrcode" },
      },
      {
        path: "details/:id",
        component: DetailshipperqrcodeComponent,
        pathMatch: "full",
        data: { title: "Trusttags::Detail Shipper Qrcode" },
      },
      {
        path: "import-shipper-qrcode",
        component: ImportshipperqrcodeComponent,
        pathMatch: "full",
        data: { title: "Trusttags::Import Shipper Qrcode" },
      },
    ],
    canActivate: [AuthGuard],
    data: { role: ["2", "4", "1"] },
  },

  {
    path: "inventory",
    children: [
      {
        path: "",
        component: SkulistComponent,
        pathMatch: "full",
        data: { title: "Trusttags::Inventory" },
      },
      {
        path: "sku_batch/:id",
        component: SkubatchlistComponent,
        pathMatch: "full",
        data: { title: "Trusttags::SKU Batch" },
      },
    ],
    canActivate: [AuthGuard],
    data: { role: ["2", "1"] },
  },
  // {
  //   path: "trackandtrace",
  //   children: [
  //     {
  //       path: "",
  //       component: TrackandtraceComponent,
  //       pathMatch: "full",
  //       data: { title: "Trusttags::Trackandtrace" },
  //     },
  //   ],
  //   canActivate: [AuthGuard],
  //   data: { role: ["2", "1"] },
  // },

  // {
  //   path: "supplychain",
  //   children: [
  //     { path: "", component: SupplychainComponent, pathMatch: "full" },
  //     { path: "add", component: AddSupplychainComponent, pathMatch: "full" },
  //     {
  //       path: "edit/:id",
  //       component: EditSupplychainComponent,
  //       pathMatch: "full",
  //     },
  //   ],
  //   canActivate: [AuthGuard],
  //   data: { role: ["2", "1"] },
  // },

  // {
  //   path: "menu",
  //   children: [
  //     {
  //       path: "",
  //       component: ListMenuComponent,
  //       pathMatch: "full",
  //       data: { title: "Trusttags::Menu" },
  //     },
  //     {
  //       path: "add",
  //       component: AddMenuComponent,
  //       pathMatch: "full",
  //       data: { title: "Trusttags::Add Menu" },
  //     },
  //     {
  //       path: "edit/:id",
  //       component: EditMenuComponent,
  //       pathMatch: "full",
  //       data: { title: "Trusttags::Edit Menu" },
  //     },
  //   ],
  //   canActivate: [AuthGuard],
  // },
  // {
  //   path: "rolemenupermission",
  //   children: [
  //     {
  //       path: "",
  //       component: ListRoleMenuPermissionsComponent,
  //       pathMatch: "full",
  //       data: { title: "Trusttags::RoleMenuPermissions" },
  //     },
  //     {
  //       path: "add",
  //       component: AddRoleMenuPermissionsComponent,
  //       pathMatch: "full",
  //       data: { title: "Trusttags::Add RoleMenuPermissions" },
  //     },
  //     {
  //       path: "edit/:id",
  //       component: EditRoleMenuPermissionsComponent,
  //       pathMatch: "full",
  //       data: { title: "Trusttags::Edit RoleMenuPermissions" },
  //     },
  //     {
  //       path: "details/:id",
  //       component: DetailRoleMenuPermissionsComponent,
  //       pathMatch: "full",
  //       data: { title: "Trusttags::Edit RoleMenuPermissions" },
  //     },
  //   ],
  //   canActivate: [AuthGuard],
  // },
  // {
  //   path: "consignment",
  //   children: [
  //     {
  //       path: "",
  //       component: ListConsignmentComponent,
  //       pathMatch: "full",
  //       data: { title: "Trusttags::Consignments" },
  //     },
  //     {
  //       path: "details/:id",
  //       component: DetailConsignmentComponent,
  //       pathMatch: "full",
  //       data: { title: "Trusttags::Consignment Details" },
  //     },
  //   ],
  //   canActivate: [AuthGuard],
  // },
  // {
  //   path: "invoice",
  //   children: [
  //     {
  //       path: "",
  //       component: InvoiceComponent,
  //       pathMatch: "full",
  //       data: { title: "Trusttags::Invoice" },
  //     },
  //   ],
  //   canActivate: [AuthGuard],
  // },
  {
    path: "regsiterProduct",
    children: [
      {
        path: "",
        component: RegisterbyappComponent,
        pathMatch: "full",
        data: { title: "Trusttags::Regsiter Product" },
      },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: "scanProduct",
    children: [
      {
        path: "",
        component: ScanbyappComponent,
        pathMatch: "full",
        data: { title: "Trusttags::Scan Product" },
      },
    ],
    canActivate: [AuthGuard],
  },

  { path: "**", redirectTo: "/dashboard", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
