import { Component, OnInit, Input } from '@angular/core';

import { Router, ActivatedRoute } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { ApiService } from 'src/app/service/api/api.service';
import { Globals } from 'src/app/globals';

declare var $: any;

@Component({
  selector: 'app-luckydraw-rewards-management',
  templateUrl: './luckydraw-rewards-management.component.html',
  styleUrls: ['./luckydraw-rewards-management.component.css']
})
export class LuckydrawRewardsManagementComponent implements OnInit {
  loader = false;
  msg: string = "";
  errors: any = "";
  submitted: boolean = false;
  rewardList = [];
  order: string = "";
  reverse: boolean = false;
  p = this.globals.p;
  currentLimit = this.globals.currentLimit;
  pageLimits = this.globals.pageLimits;
  popoverMessage = "Are you sure?"
  filterOptions = [{
    id: 0,
    name: "Customer"
  }, {
    id: 1,
    name: "Distributor"
  }, {
    id: 2,
    name: "Wholesaler"
  }, {
    id: 3,
    name: "Retailer"
  }
  ]
  filterOption = "";
  editForm: FormGroup;
  reward_id = null;
  image;
  image_name;

  constructor(private apiService: ApiService, private formBuilder: FormBuilder,
    private router: Router, private globals: Globals) { }

  ngOnInit() {
    this.getRewardsList();
    this.editForm = this.formBuilder.group({
      name: ["", [Validators.required, Validators.pattern(".*\\S.*"), Validators.maxLength(20)]],
      points: ["", [Validators.required, Validators.pattern("^[0-9]*$")]],
      stock: ["", [Validators.required,  Validators.max(100000), Validators.pattern("^[0-9]*$")]],
      image: [""]
    })
  }
  openModal(reward) {
    this.msg = ""
    this.reward_id = reward.id;
    this.editForm = this.formBuilder.group({
      name: [reward.reward_name, [Validators.required, Validators.pattern(".*\\S.*"), Validators.maxLength(20)]],
      // points: [reward.points, [Validators.required, Validators.pattern("^[0-9]*$")]],
      stock: [reward.stock, [Validators.required, Validators.max(100000), Validators.pattern("^[0-9]*$")]],
      image: [""]
    })
  }
  get f() {
    return this.editForm.controls;
  }

  getRewardsList() {
    this.loader = true;
    this.apiService
      .get("rewards/list?for=ld", true)
      .subscribe(
        (response: any) => {
          this.loader = false;
          if (response.status == 200 && response.body.success == 1) {
            this.rewardList = response.body.data;
          } else {
            this.msg = response.body.message;
          }
        },
        (error) => {
          console.error(error);
          this.loader = false;
          this.msg =
            "Due to server side issue you cannot add rewards!";
        }
      );
  }

  onImageChange(event) {
    this.image = null;
    this.msg = null
    if (event.target.files[0]) {
      if (event.target.files[0].type.includes("image")) {
        if (event.target.files[0].size <= 500000) {
          this.image = event.target.files[0];
          this.image_name = event.target.files[0].name;
        } else {
          console.log('called');
          this.image_name = "Upload Image"
          this.image = null;
          this.msg = "Reward Image File must be less than 500KB";
        }
      } else {
        this.image_name = "Upload Image"
        this.image = null;
        this.msg = "Reward Image File must be image";
      }
    }
  }
  updateReward() {
    this.submitted = true;
    if (this.editForm.invalid || this.reward_id == null || (this.image_name && this.image == null)) {
      return;
    }
    // console.log(this.image)

    // $('#editModal').modal('hide');

    // if(!this.image.files[0].type.includes("image")){
    //     this.image_name = "Upload Image"
    //     this.image = null;
    //     this.msg = "Reward image file must be image";
    //   return;
    // }

    let formdata = new FormData();
    if(this.image){
    formdata.append(
      "image", this.image, this.image_name
    );}
    formdata.append("name", this.editForm.value.name);
    formdata.append("stock", this.editForm.value.stock);
    formdata.append("id", this.reward_id);
    this.apiService
      .post("rewards/edit", formdata, true)
      .subscribe(
        (response: any) => {
          this.loader = false;
          if (response.status == 200 && response.body.success == 1) {
            this.getRewardsList();
            this.msg = response.body.message;
            this.editForm.reset();
            $('#editModal').modal('hide');
            this.reward_id = null;
          } else {
            this.msg = response.body.message;
          }          
        },
        (error) => {
          console.error(error);
          this.loader = false;
          this.msg =
            "Due to server side issue you cannot add rewards!";
        }
      );
    this.submitted = false;
  }
  deleteReward(id) {
    this.loader = true;
    this.apiService
      .get("rewards/delete?id=" + id, true)
      .subscribe(
        (response: any) => {
          this.loader = false;
          if (response.status == 200 && response.body.success == 1) {
            this.getRewardsList();
          } else {
            this.msg = response.body.message;
          }
        },
        (error) => {
          console.error(error);
          this.loader = false;
          this.msg =
            "Due to server side issue you cannot delete rewards!";
        }
      );

  }

  setOrder(value: string) {
    console.log(value);
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    this.order = value;
  }
}
