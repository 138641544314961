import {
  Component,
  ViewEncapsulation,
  OnInit,
  Inject,
  Renderer2,
} from "@angular/core";
import * as $ from "jquery";
import { AdminService } from "../service/admin.service";
import { CompanyService } from "../company/service/company.service";
//declare let Chart:any;
import { ChartOptions, ChartType, ChartDataSets } from "chart.js";
import { ChartsModule } from "ng2-charts";
import { Label } from "ng2-charts";
import { DOCUMENT } from "@angular/common";

import {
  MapsTheme,
  Maps,
  Marker,
  MapsTooltip,
  Legend,
  ILoadEventArgs,
} from "@syncfusion/ej2-angular-maps";
import { MapAjax, Arrow, timeout } from "@syncfusion/ej2-maps";
import { domRendererFactory3 } from "@angular/core/src/render3/interfaces/renderer";
import { ApiService } from '../service/api/api.service';

Maps.Inject(Marker, MapsTooltip, Legend);
declare var require: any;
let india: object[] = require("./../india.json");
let population: object[] = require("./../india-population.json");

declare var jQuery: any;
declare var moment: any;

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class DashboardComponent implements OnInit {
  
  dailyVerifiedProductCount = null;
  dailyRegisterProductsCount = null;
  WeeklyBrandVerifiedDemoLoader = false;
  WeeklyBrandVerifiedlineLoader = false;
  WeeklyBrandVerifiedLoader = false;
  WeeklyBrandVerifiedMapLoader = false;

  selectedBrand;

  selectedDurationTitle = "Weekly";
  //Line Chart Data Start
  public lineChartData: Array<any> = [];

  public lineChartLabels: Array<any> = [];
  public lineChartOptions: any = {
    responsive: true,
  };
  public lineChartColors: Array<any> = [
    {
      // grey
      backgroundColor: "#8226f5",
      borderColor: "#8226f5",
    },
    {
      backgroundColor: "#e625ea",
      borderColor: "#e625ea",
    },
  ];
  public lineChartLegend: boolean = true;
  public lineChartType: string = "line";

  //Line Chart Data Finish

  // Doughnut
  public doughnutChartLabels: string[] = [];
  public doughnutChartData: number[] = [];
  public doughnutChartType: string = "doughnut";
  private donutColors = [
    {
      backgroundColor: ["#8226f5", "#e625ea", "#007bff", "#00d0ff"],
    },
  ];

  //Bar Chart1
  public barChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true,
  };
  public barChartLabels: string[] = ["Week1", "Week2", "Week3", "Week4"];
  public barChartType: string = "bar";
  public barChartLegend: boolean = true;

  public barChartData: any[] = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: "Series A" },
    { data: [28, 48, 40, 19, 86, 27, 90], label: "Series B" },
  ];

  //Bar Chart 2
  public barChartOptions1: any = {
    scaleShowVerticalLines: false,
    responsive: true,
  };
  public barChartLabels1: string[] = [
    "Below or 25",
    "25+",
    "35+",
    "45+",
    "55 or Above",
  ];

  appliedBrand;
  appliedDuration;

  public barChartType1: string = "bar";
  public barChartLegend1: boolean = true;
  public barChart1Color = [
    {
      backgroundColor: "#8226f5",
    },
    {
      backgroundColor: "#e625ea",
    },
  ];

  public barChartData1: any[] = [
    { data: [], label: "Women" },
    { data: [], label: "Men" },
  ];

  //Heat Map Data Finish
  loader: boolean = false;
  skuCountRes: any = {};
  skuQrcodeCountRes: any = {};
  shipperQrcodeCountRes: any = {};
  procuctBatchesCountRes: any = {};
  weeklyData: any;
  mapdata: any;
  cityCount = null;

  filterStartDate;
  filterEndDate;

  brandsList = [];

  dailyVerifiedProductCountDemo = null;
  dailyVerifiedProductCountDemoPer = { m: 0, f: 0 };

  selectedFilter = "7d";

  queryString = "?duration=7d";


  constructor(
    public adminService: AdminService,
    private companyService: CompanyService,
    private apiService: ApiService,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document
  ) {
    //sourceFiles.files = [ 'india-population.json', 'india.json'];
  }

  async ngOnInit() {
    // await this.dailyScan();
    // await this.brandScan();
    // await this.agewiseScan();
    await this.getBrandsList();
    // await this.getWeeklyBrandReportInDoughnutChartLabels();
  }

  loadDatePickerScript() {
   
    jQuery(document).ready(function () {
      jQuery("#startDate").daterangepicker(
        {
          singleDatePicker: true,
          showDropdowns: true,
          locale: {
            format: "DD/MM/YYYY",
          },
          maxDate: new Date(),
          autoclose: true,
          minYear: 1901,
        },
        function (start, end, label) {
          console.log("s: ",start, "e: ", end,"l: ", label);

          jQuery("#endDate").daterangepicker(
            {
              singleDatePicker: true,
              showDropdowns: true,
              locale: {
                format: "DD/MM/YYYY",
              },
              maxDate: new Date(),
              minDate: start,
              autoclose: true,
              minYear: 1901,
            },
            function (start, end, label) {
              console.log(start, end, label);          
            }
          );
        }
      );

      jQuery("#endDate").daterangepicker(
        {
          singleDatePicker: true,
          showDropdowns: true,
          locale: {
            format: "DD/MM/YYYY",
          },
          maxDate: new Date(),
          minDate: new Date(),
          autoclose: true,
          minYear: 1901,
        },
        function (start, end, label) {
          console.log(start, end, label);          
        }
      );

    });
  }

  onFilterChange(event) {
    if (event.target.value === "c") {
      this.loadDatePickerScript();
    }
  }

  onFilterClick() {
    console.log(this.appliedDuration, this.selectedFilter);
    this.selectedFilter = this.appliedDuration || "7d";
    this.selectedBrand = this.appliedBrand || this.selectedBrand;
  }

  onApplyFilter() {
    this.appliedBrand = this.selectedBrand;
    this.appliedDuration = this.selectedFilter;

    console.log(">>", this.selectedBrand);
    if (this.selectedFilter === "7d") {
      this.selectedDurationTitle = "Weekly";
      this.queryString = `?duration=7d&brandId=${this.appliedBrand}`;
    } else if (this.selectedFilter === "m") {
      this.selectedDurationTitle = "Monthly";
      this.queryString = `?duration=m&brandId=${this.appliedBrand}`;
    } else if (this.selectedFilter === "y") {
      this.selectedDurationTitle = "Yearly";
      this.queryString = `?duration=y&brandId=${this.appliedBrand}`;
    } else {
      this.selectedDurationTitle = "Custom";
      this.filterStartDate = document.getElementById("startDate")["value"];
      this.filterEndDate = document.getElementById("endDate")["value"];
      let splitedStartDate = this.filterStartDate.split("/");
      let splitedEndDate = this.filterEndDate.split("/");
      let startDate =
        splitedStartDate[2] +
        "-" +
        splitedStartDate[1] +
        "-" +
        splitedStartDate[0];
      let endDate =
        splitedEndDate[2] + "-" + splitedEndDate[1] + "-" + splitedEndDate[0];
      this.queryString = `?duration=c&start=${startDate}&end=${endDate}&brandId=${this.appliedBrand}`;
    }

    this.loadCharts();
    // this.ngAfterViewInit();
  }

  getDailyCityCount() {
    this.apiService.get("cityCount" + this.queryString, true).subscribe(
      (response: any) => {
        console.log("==response.body,,", response.body);
        if (response.status === 200 && response.body.success == "1") {
          this.cityCount = response.body.data.count;
        }
      },
      (err) => {
        console.error(err);
      }
    );
  }

  getWeeklyVerifiedProductDemo() {
    this.WeeklyBrandVerifiedDemoLoader = true;
    this.apiService
      .get("demographicUser" + this.queryString, true)
      .subscribe((response: any) => {
        this.WeeklyBrandVerifiedDemoLoader = false;
        console.log("response -> ", response.body);
        if (response.status === 200 && response.body.success == "1") {
          let data = response.body.data;
          this.barChartData1 = [
            {
              data: [
                data["25"].m,
                data["35"].m,
                data["45"].m,
                data["55"].m,
                data["56"].m,
              ],
              label: "Male",
            },
            {
              data: [
                data["25"].f,
                data["35"].f,
                data["45"].f,
                data["55"].f,
                data["56"].f,
              ],
              label: "Female",
            },
          ];
        }
      }, err => {
        this.WeeklyBrandVerifiedDemoLoader = false;
      });
  }

  loadHeadMap(data) {
    jQuery("#mapindiawrap").empty();
    jQuery("#mapindiawrap").vectorMap({
      map: "in_mill",
      regionStyle: {
        initial: {
          fill: "#b7c8ff",
        },
      },
      series: {
        regions: [
          {
            values: data,
            scale: ["#4169E1", "#FF69B4"],
            normalizeFunction: "polynomial",
            legend: {
              vertical: true,
              labelRender: function (v) {
                return v.toString().indexOf(".") != -1 ? v.toFixed(1) : v;
              },
            },
          },
        ],
      },
      onRegionTipShow: function (e, el, code) {
        el.html(
          el.html() + " (No. of Verified Product - " + (data[code] || 0) + ")"
        );
      },
    });
  }

  async getWeeklyVerifiedProductGeo() {
    this.WeeklyBrandVerifiedMapLoader = true;
    this.apiService
      .get("scan-product-heat" + this.queryString, true)
      .subscribe(
        (response: any) => {
          this.WeeklyBrandVerifiedMapLoader = false;
          if (response.status === 200 && response.body.success == "1") {
            this.loadHeadMap(response.body.data);
          } else {
            this.loadHeadMap({});
          }
        },
        (err) => {
          this.loadHeadMap({});
          console.error(err);
          this.WeeklyBrandVerifiedMapLoader = false;
        }
      );
  }

  async ngAfterViewInit() {
    // await this.getWeeklyVerifiedProductGeo();
  }

  async getMFCount() {
    this.apiService
      .get("dailyUser" + this.queryString, true)
      .subscribe((response: any) => {
        console.log("response -> ", response.body);
        if (response.status === 200 && response.body.success == "1") {
          this.dailyVerifiedProductCountDemo = response.body.data;
          
          if (
            this.dailyVerifiedProductCountDemo.f >
            this.dailyVerifiedProductCountDemo.m
          ) {
            this.dailyVerifiedProductCountDemoPer.f = 60;
            this.dailyVerifiedProductCountDemoPer.m =
              (this.dailyVerifiedProductCountDemo.m * 60) /
              this.dailyVerifiedProductCountDemo.f;
          } else if (
            this.dailyVerifiedProductCountDemo.f ==
            this.dailyVerifiedProductCountDemo.m
          ) {
            let per = 0;
            console.log('--', this.dailyVerifiedProductCountDemoPer.f)
            if (this.dailyVerifiedProductCountDemo.f != 0) {
              console.log('++')
              per = 60;
            }
            this.dailyVerifiedProductCountDemoPer.f = per;
            this.dailyVerifiedProductCountDemoPer.m = per;
          } else {
            this.dailyVerifiedProductCountDemoPer.m = 60;
            this.dailyVerifiedProductCountDemoPer.f =
              (this.dailyVerifiedProductCountDemo.f * 60) /
              this.dailyVerifiedProductCountDemo.m;
          }

          console.log(this.dailyVerifiedProductCountDemoPer);
        }
      });
  }

  async getWeeklyBrandReportInLineChart() {
    this.WeeklyBrandVerifiedlineLoader = true;
    
    let dataSet = [];
    let labels = [];
    this.apiService
      .get("brandScanCount" + this.queryString, true)
      .subscribe((response: any) => {
        this.WeeklyBrandVerifiedlineLoader = false;
        console.log("response -> ", response.body);
        if (response.status === 200 && response.body.success == "1") {          
          let data = response.body.data;
          for (let i = data.length - 1; i >= 0; i--) {
            labels.push(data[i].date);
            if (i === data.length - 1) {
              data[i].sku.forEach((ele) => {
                dataSet.push({ label: ele.name, data: [ele.count] });
              });
            } else {
              data[i].sku.forEach((ele) => {
                dataSet.forEach((subItem, subIndex) => {
                  if (subItem.label == ele.name) {
                    dataSet[subIndex].data.push(ele.count);
                  }
                });
              });
            }
          }          

          this.lineChartLabels = labels;
          this.lineChartData = dataSet;
        }
      }, err => {
        this.WeeklyBrandVerifiedlineLoader = false;
      });
  }

  getBrandsList() {
    this.apiService
      .get("brand", true)
      .subscribe((response: any) => {
        console.log("response -> ", response.body);
        if (response.status === 200 && response.body.success == "1") {
          this.brandsList = response.body.data;
          this.selectedBrand = this.brandsList[0].id;
          this.queryString = `?duration=7d&brandId=${this.selectedBrand}`;

          this.loadCharts();
        }
      });
  }

  async loadCharts() {
    this.getWeeklyBrandReportInDoughnutChartLabels();
    await this.getDailyVerifiedProducts();
    await this.getDailyRegisterProductCount();
    await this.getDailyCityCount();
    await this.getMFCount();
    await this.getWeeklyVerifiedProductDemo();
    await this.getWeeklyBrandReportInLineChart();
    await this.getWeeklyVerifiedProductGeo();
  }

  getDailyVerifiedProducts() {
    this.apiService
      .get("dailyVerifyCount" + this.queryString, true)
      .subscribe((response: any) => {
        console.log("response -> ", response.body);
        if (response.status === 200 && response.body.success == "1") {
          this.dailyVerifiedProductCount = response.body.data.count;
        }
      });
  }

  getDailyRegisterProductCount() {
    this.apiService
      .get("dailyRegisterCount" + this.queryString, true)
      .subscribe((response: any) => {
        console.log("response -----> ", response.body);
        if (response.status === 200 && response.body.success == "1") {
          this.dailyRegisterProductsCount = response.body.data.count;
          console.log(
            "dailyRegisterProductsCount->",
            this.dailyRegisterProductsCount
          );
        }
      });
  }

  async getWeeklyBrandReportInDoughnutChartLabels() {
    await this.doughnutChartLabels.splice(0, this.doughnutChartLabels.length);
    await this.doughnutChartData.splice(0, this.doughnutChartData.length);
    this.WeeklyBrandVerifiedLoader = true;
    this.apiService
      .get(`getScanRatio${this.queryString}`, true)
      .subscribe((response: any) => {
        this.doughnutChartLabels.length = 0;
            this.doughnutChartData.length = 0;
        this.WeeklyBrandVerifiedLoader = false;
        console.log("response -> ", response.body);
        if (response.status === 200 && response.body.success == "1") {
          let brandName;
          response.body.data.forEach((item) => {
            brandName = Object.keys(item)[0];            
            this.doughnutChartLabels.push(brandName);
            this.doughnutChartData.push(item[brandName].count);
          });
        }
      }, err => {
        this.WeeklyBrandVerifiedLoader = false;
      });
  }

  agewiseScan() {
    this.companyService.getAgewiseData().subscribe((res: any) => {
      let data = res.data;
      let menData1 = data.men[0];
      let menData2 = data.men[1];
      let menData3 = data.men[2];
      let menData4 = data.men[3];
      let menData5 = data.men[4];
      let menData6 = data.men[5];

      let wData1 = data.women[0];
      let wData2 = data.women[1];
      let wData3 = data.women[2];
      let wData4 = data.women[3];
      let wData5 = data.women[4];
      let wData6 = data.women[5];
      this.barChartData1 = [
        {
          data: [menData1, menData2, menData3, menData4, menData5, menData6],
          label: "Men",
        },
        {
          data: [wData1, wData2, wData3, wData4, wData5, wData6],
          label: "Women",
        },
      ];
    });
  }
}
