import { Component, OnInit } from '@angular/core';
import { OrderPipe } from 'ngx-order-pipe';
import { Globals } from 'src/app/globals';
import { ApiService } from 'src/app/service/api/api.service';

@Component({
  selector: 'app-lucky-draw',
  templateUrl: './lucky-draw.component.html',
  styleUrls: ['./lucky-draw.component.css']
})
export class LuckyDrawComponent implements OnInit {
  loader = true;
  order: string = "";
  reverse: boolean = false;
  p = this.globals.p;
  currentLimit = this.globals.currentLimit;
  pageLimits = this.globals.pageLimits;
  sortedCollection: any[];

  luckyDraws = [];

  constructor(
    private apiService: ApiService,
    private globals: Globals,
    private orderPipe: OrderPipe) { 
      this.sortedCollection = orderPipe.transform(this.luckyDraws, 'id');

    }

  ngOnInit() {
    this.getLuckyDraws();
  }


  setOrder(value: string) {
    if (this.order === value) {
        this.reverse = !this.reverse;
    }
    this.order = value;
}

  getLuckyDraws() {
    this.apiService
      .get("luckydraws", true)
      .subscribe(
        (response: any) => {
          this.loader = false;
          if (response.status == 200 && response.body.success == 1) {
            this.luckyDraws = response.body.data
          }
        },
        (error) => {
          console.error(error);
          this.loader = false;
        }
      );
  }

}
