import { Component, OnInit } from '@angular/core';
import { AdminService } from '../service/admin.service';
declare var $ :any; 
import { Globals } from './../globals';


@Component({
  selector: 'app-product-complain',
  templateUrl: './product-complain.component.html',
  styleUrls: ['./product-complain.component.css']
})
export class ProductComplainComponent implements OnInit {


   	  data : any;
	  searchText  = "";
	  sortedCollection: any[];
	  order: string = 'data.id';
	  reverse: boolean = false;
	  arr : { [id: string]: any; } =[];
	  errors : any = '';
	  deletedMsg: string = null;
	  deleteId : any ;									   								  
	  loader:boolean = false;	
	  modalBody: any=[];							  
	  p =this.globals.p;
      currentLimit=this.globals.currentLimit;
      pageLimits = this.globals.pageLimits;


	result : any = [];



  constructor(private globals: Globals,public adminService: AdminService) { }

  ngOnInit() {
  		this.customer_complain_list();
  }

  	customer_complain_list(){
		this.loader=true;
	  	console.log("companyList");
	  	this.adminService.customer_complain_list().subscribe((res:any)=>{
	   	this.loader=false;
	    console.log("res.data");
	    this.result=res.data;
	    console.log(this.result);
	    
	  }, (err) => {
	      console.log(err);
	  });
	}


	productComplainView(ComplainId){
		//const modalBody =  this.result.filter()
		this.modalBody = this.result.filter(function (complain) {
			return complain.id == ComplainId;
		});		
		 $('#productViewModal').modal('show');
    }

	
	setOrder(value: string) {
      if (this.order === value) {
        this.reverse = !this.reverse;
      }

      this.order = value;
    }


}



