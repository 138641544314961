import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../../company/service/company.service';
declare var $ :any; 
import { OrderPipe } from 'ngx-order-pipe';
import { FlashMessagesService } from 'angular2-flash-messages';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv';
import { Globals } from './../../../globals';
//import * as jsPDF from 'jspdf'




@Component({
  selector: 'app-listshipperqrcode',
  templateUrl: './listshipperqrcode.component.html',
  styleUrls: ['./listshipperqrcode.component.css']
})
export class ListshipperqrcodeComponent implements OnInit {

	data : any;
	searchText  = "";
	sortedCollection: any[];
	order: string = 'result.id';
	reverse: boolean = false;
	result : any = [{}];   
	arr : any = [];
	errors : any = '';
	deletedMsg: string = null;
	p =this.globals.p;
    currentLimit=this.globals.currentLimit;
	deleteId: any;
    pageLimits = this.globals.pageLimits;
   	loader:boolean = false;
   	item:any=[];

    csvOptions = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      title: 'Qrcode List :',
      useBom: true,
      noDownload: false,
      headers: []  //headers: ["QrCode"]
    };

	constructor( 
			private _flashMessagesService: FlashMessagesService,
			public companyService: CompanyService,
			private route: ActivatedRoute, 
			private router: Router,
			private orderPipe: OrderPipe,
			private globals: Globals
			) {  
				this.sortedCollection = orderPipe.transform(this.result, 'result.id');
	}

 ngOnInit() {
		this.loader=true;
     this.companyService.getShipperQrCodeWithCount().subscribe((res:any)=>{
			this.loader=false;
			if(res.success=="1"){
        console.log(res.data);
				this.result = res.data;
			}
		});
	}

	/**
	 * [exportQrcode description]
	 * @author Suresh Suthar
	 * @Created Date     2019-04-09
	 * @param   {[type]} roleUniqueId [description]
	 */
	exportQrcode(id,batch_no){

        this.companyService.getShipperQrcodeList(id).subscribe((res:any)=>{

            if(res.success==1){

              let qrCode = res.data;
              let random = Math.random().toString(36).substring(7);
              let fileName = batch_no + "shipper";
              new  AngularCsv(qrCode, fileName, this.csvOptions);
            }
            else{
                this._flashMessagesService.show(res.message, { cssClass: 'alert-success' } );
                
                  setTimeout(() => {
                    this.router.navigate(['/packaging_qrcode']);
              }, 3000); 
            }
        });
    }

    //Pdf function
    pdfQrcode(id){

    	 this.companyService.getShipperQrcodeList(id).subscribe((res:any)=>{

            if(res.success==1){

              let qrCode1 = res.data[0];
              let d={};
              d['Name'] = 'Name';
              d['Roll'] = 'Roll';
              console.log(qrCode1);
              let qrCode= d['Name'] + ' : ' + qrCode1['unique_code'];
              let qrCode2= d['Roll'] + ' : ' + qrCode1['unique_code'];
              //qrCode['bob'] = '123';	
              console.log(this.item);
    
                //let doc = new jsPDF();
                /* doc.setProperties({title: 'DocumentTitle'});
				doc.text(qrCode,20, 20);
				doc.addPage();
				doc.text(qrCode2,20, 20);
				doc.save('a4.pdf'); */
            
            }
            else{
                this._flashMessagesService.show(res.message, { cssClass: 'alert-success' } );
                
                  setTimeout(() => {
                    this.router.navigate(['/packaging_qrcode']);
              }, 3000); 
            }
        });
   
    }


    setOrder(value: string) {
		if (this.order === value) {
			this.reverse = !this.reverse;
		}

		this.order = value;
	}

}
