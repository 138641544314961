import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../../company/service/company.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { OrderPipe } from 'ngx-order-pipe';
import { Globals } from './../../../globals';

@Component({
  selector: 'app-edit-role-menu-permissions',
  templateUrl: './edit-role-menu-permissions.component.html',
  styleUrls: ['./edit-role-menu-permissions.component.css']
})
export class EditRoleMenuPermissionsComponent implements OnInit {

    Permissions: any = [];
    msg: string = null;
    errors: any = '';
    model: any = {};
    submit: boolean = false;
    response: any;
    Editid: any;
    loader: boolean = false;

    constructor(private globals: Globals,
        private _flashMessagesService: FlashMessagesService,
        public companyService: CompanyService,
        private route: ActivatedRoute,
        private router: Router,
        private orderPipe: OrderPipe) { }

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.Editid = params['id'];
            this.editRoleMenuPermissionData();
        });
    }

    editRoleMenuPermissionData() {
        this.loader = true;
        this.companyService.editRoleMenuPermission(this.Editid).subscribe((res: any) => {
            console.log(res.data);

            this.loader = false;
            this.model = { role_title: res.data.name };

            for (var i = 0; i < res.NonParentMenus.length; i++) {

                let permission = res.data.role_menu_permission.find(x => x.menu_id == res.NonParentMenus[i].id);

                console.log(permission);

                if (permission) {
                    this.Permissions.push({
                        menu_id: res.NonParentMenus[i].id,
                        title: res.NonParentMenus[i].title,
                        can_read: permission.can_read,
                        can_write: permission.can_write,
                        can_update: permission.can_update,
                        can_delete: permission.can_delete
                    });
                } else {
                    this.Permissions.push({
                        menu_id: res.NonParentMenus[i].id,
                        title: res.NonParentMenus[i].title,
                        can_read: false,
                        can_write: false,
                        can_update: false,
                        can_delete: false
                    });
                }
                
            }

            //this.Permissions = res.data.role_menu_permission;

            console.log(this.Permissions);

        }, (err) => {
        });
    }

    onSubmit(form?: NgForm) {
        let f = form.value;

        let requestValue = {
            role_title: f.role_title,
            role_menu_permission: this.Permissions
        };

        console.log(requestValue);

        this.companyService.updateRoleMenuPermission(this.Editid,requestValue).subscribe((res: any) => {
            console.log(res);
            this.response = res;

            if (this.response.success == 1) {
                this.submit = true;
                this._flashMessagesService.show('Role Menu Permission updated sucessfully', { cssClass: 'alert-success' });

                setTimeout(() => {
                    this.router.navigate(['/rolemenupermission']);
                }, 3000);

            } else {
                this.errors = res.message;
                setTimeout(() => {
                    this.errors.role_menu_permission_id = false;
                }, 2000);
            }
        }, (err) => {
            console.log(err);
        });
    }
}
