import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../../company/service/company.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import * as $ from "jquery";

@Component({
  selector: 'app-add-category',
  templateUrl: './add-category.component.html',
  styleUrls: ['./add-category.component.css']
})
export class AddCategoryComponent implements OnInit {
  resultList: any;
  resultBrand : any;
  msg: string = null;
  errors : any = '';
  model: any = {};
  brandOptions:any;
  result:any;
  submit:boolean=false;

  constructor(private _flashMessagesService: FlashMessagesService, public companyService: CompanyService,private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.brandOptions    = { placeholder: { id: '', text: 'Select Brand' } };
  	this.brandData();
  }


  onSubmit(form?:NgForm) {
      let f = form.value;
   
      console.log(f);

      this.companyService.category_add(f).subscribe((res : any)=>{
       console.log(res);

         this.resultBrand = res;

          if (this.resultBrand.success == 1 ) {
              this.submit=true;   
              this._flashMessagesService.show('Category created successfully', { cssClass: 'alert-success' } );
            
              setTimeout(() => {
            this.router.navigate(['/category']);
        }, 2000); 

       } else {
         this.errors = res.message;
            setTimeout(() => {
            this.errors.category_id = false;
          }, 2000); 
       }
      }, (err) => {
    });
   
  }
  async  brandData(){
     await this.companyService.brand_list().subscribe((res:any)=>{
      this.result = res.data;
      var rObj= [];
      rObj.push({"id":"","text":"select"});
      for(let i=0; i<res.data.length; i++){

        rObj.push({"id":res.data[i].id, "text":res.data[i].name});
      }      

      this.resultList = rObj;
        console.log(this.resultList);
      }, (err) => {

      });
    }
    brandChanged(event){
    if(event.value!='select'){
    console.log(event);
    this.model.brand_id=event.value;
    }
  }

}
