import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Config } from './../../config';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  apiURL = Config.API_URL;
  tokenHeader: any;
  simpleHeader: object = { observe: "response" };
  
  constructor(private http: HttpClient) { }

  getHeaders(){
    this.tokenHeader = {
      observe: "response",
      headers: {        
        "x-access-token": localStorage.getItem("access_token"),
        "x-key": localStorage.getItem("x-key")
      }
    };    
  }

  get(path, token = null, isDownloadable = null) {
    this.getHeaders();

    if(isDownloadable) {
      this.tokenHeader.responseType = 'arraybuffer';
    }

    return this.http.get(
      this.apiURL + path,
      token ? this.tokenHeader : this.simpleHeader
    );
  }

  post(path, data, token = null) {
    this.getHeaders();

    return this.http.post(
      this.apiURL + path,
      data,
      token ? this.tokenHeader : this.simpleHeader
    );
  }

  put(path, data, token = null) {
    this.getHeaders();

    return this.http.put(
      this.apiURL + path,
      data,
      token ? this.tokenHeader : this.simpleHeader
    );
  }

  delete(path, token = null) {
    this.getHeaders();

    return this.http.delete(
      this.apiURL + path,
      token ? this.tokenHeader : this.simpleHeader
    );
  }

}
