import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../company/service/company.service';
declare var $ :any; 
import { OrderPipe } from 'ngx-order-pipe';
import { FlashMessagesService } from 'angular2-flash-messages';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Globals } from './../../globals';
import {QuillInitializeService} from 'src/assets/services/quillInitialize.service';
import 'quill-mention';
import 'quill-emoji';


@Component({
	selector: 'app-sku',
	templateUrl: './sku.component.html',
	styleUrls: ['./sku.component.css']
})
export class SkuComponent implements OnInit {

	data : any;
	searchText  = "";
	sortedCollection: any[];
	order: string = 'result.id';
	reverse: boolean = false;
	result : any = [];
	arr : any = [];
	errors : any = '';
	howtouseId:any;
	deletedMsg: string = null;
	deleteId:any;
	featureId:any=[];
	howtouse:any =[];
	featureValue:any;
	detailValue:any;
	howtouseValue:any;
	resultdetails:any;
	p =this.globals.p;
    currentLimit=this.globals.currentLimit;
	featureId1:any;
	unitData:any;
	result1:any={};
	howresult:any;
	submit:boolean=false;
	pageLimits = this.globals.pageLimits;

	loader:boolean = false;
	htmlText ="<p>Testing</p>"
  	hasFocus = false;


  	atValues = [
    { id: 1, value: 'Fredrik Sundqvist', link: 'https://google.com' },
    { id: 2, value: 'Patrik Sjölin' }
  ];
  hashValues = [
    { id: 3, value: 'Fredrik Sundqvist 2' },
    { id: 4, value: 'Patrik Sjölin 2' }
  ]

  quillConfig={
    //toolbar: '.toolbar',
     toolbar: {
       container: [
         ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
         [{'color': ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color']}],
         ['code-block'],
         [{ 'header': 1 }, { 'header': 2 }],               // custom button values
         [{ 'list': 'ordered'}, { 'list': 'bullet' }],
         [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
         [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
         [{ 'direction': 'rtl' }],                         // text direction

         [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
         [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

         [{ 'font': [] }],
         [{ 'align': [] }],


         ['clean'],                                         // remove formatting button

         //['link'],
         //['link', 'image', 'video']  
         //['emoji'], 
       ],
    //   handlers: {'emoji': function() {}}
     },
    autoLink: false,

    mention: {
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      mentionDenotationChars: ["@", "#"],
      source: (searchTerm, renderList, mentionChar) => {
        let values;

        if (mentionChar === "@") {
          values = this.atValues;
        } else {
          values = this.hashValues;
        }
        
        if (searchTerm.length === 0) {
          renderList(values, searchTerm);
        } else {
          const matches = [];
          for (var i = 0; i < values.length; i++)
            if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())) matches.push(values[i]);
          renderList(matches, searchTerm);
        }
      },
    },
    "emoji-toolbar": true,
    "emoji-textarea": false,
    "emoji-shortname": true,
    keyboard: {
      bindings: {
        // shiftEnter: {
        //   key: 13,
        //   shiftKey: true,
        //   handler: (range, context) => {
        //     // Handle shift+enter
        //     console.log("shift+enter")
        //   }
        // },
        enter:{
          key:13,
          handler: (range, context)=>{
            console.log("enter");
            return true;
          }
        }
      }
    }
	}

	constructor(private globals: Globals,private _flashMessagesService: FlashMessagesService,public companyService: CompanyService,private route: ActivatedRoute, private router: Router,private orderPipe: OrderPipe,private quillInitializeService: QuillInitializeService) {  this.sortedCollection = orderPipe.transform(this.result, 'result.id');
	console.log(this.sortedCollection); }

	ngOnInit() {

		this.skuData();
		this.unitData = this.globals.unitData;
	}


	test=(event)=>{
	    console.log(event.keyCode);
	  }

	  onSelectionChanged = (event) =>{
	    if(event.oldRange == null){
	      this.onFocus();
	    }
	    if(event.range == null){
	      this.onBlur();
	    }
	  }

	  onContentChanged = (event) =>{
	    console.log(event.html);
	  }

	  onFocus = () =>{
	    console.log("On Focus");
	  }
	  onBlur = () =>{
	    console.log("Blurred");
	  }

	skuData(){
		this.loader=true;
		this.companyService.product_list().subscribe((res:any)=>{
			this.loader=false;
			console.log(res.data);
			this.result =res.data;
			console.log(this.result);

			//size data

			for (let i=0; i< this.result.length; i++){

				for (let j=0; j< this.unitData.length; j++){

					if(this.result[i].option.unit==this.unitData[j].id){

						this.result[i].unit_value = this.unitData[j].text;
					}
				}
			}


		}, (err) => {

		});
	}


	lengthCheck(){
		if(this.featureId.feature_value){
			if(this.featureId.feature_value.length >=250) return false;
		}
	}

	productDelete(id){
		this.companyService.deleteProduct(id).subscribe((res:any)=>{
			console.log(res);
			this.result=res.data;
			if (res['success'] == 1 ) {
				this._flashMessagesService.show('Sku deleted successfully', { cssClass: 'alert-success' } );
				this.skuData();

				setTimeout(() => {
					this.router.navigate(['/sku']);
					this.skuData();
				}, 1000); 

			} else {
				this.errors = res['message'];
			}

		}, (err) => {

		});

	}

	setOrder(value: string) {
		if (this.order === value) {
			this.reverse = !this.reverse;
		}

		this.order = value;
	}

	opendeleteModal(id){
		this.deleteId = id;
		$('#exampleModal').modal('show');

	}

	async featureModal(id){
		this.featureValue=id;
		//this.featureId=[];
		 this.result1=[];
		this.companyService.productDetails_edit(id).subscribe((res:any)=>{

			  for(let k of res.data){
            if ( k.feature_id == 4 ) {
              this.result1.push(k);
            } 
        }
        let data1=this.result1;
        this.featureId =data1[0];
        console.log(this.featureId);
 		if ( data1[0] != undefined ) {
				this.featureId = data1[0];
			} else {
				this.featureId = {'product_id': this.featureValue};
			}

			//this.featureValue=res.data[0].feature_value;	
			//console.log(this.featureValue);
			// if ( res.data[0] != undefined ) {
			// 	this.featureId = res.data[0];
			// } else {
			// 	this.featureId = {'product_id': this.featureValue};
			// }
		}, (err) => {

		});

		await $('#featureModal').modal('show');
	}

	onSubmit(form?:NgForm){
		if(this.featureId.id == undefined){
		this.featureId.feature_id=4;
		
		this.companyService.productDetails(this.featureId).subscribe((res : any)=>{
			console.log(res);
			this.resultdetails = res;
			if (this.resultdetails.success == 1 ) {   
				
				$('#featureModal').modal('hide');
				this._flashMessagesService.show('Product Details created successfully', { cssClass: 'alert-success' } );
				   this.skuData();
				    setTimeout(() => {
					  this.router.navigate(['/sku']);
					this.skuData();
					}, 2000); 

				} else {
					this.errors = res.message;
				}

			}, (err) => {

			});   	

		}
		
		else
		{
		this.companyService.productDetails_add(this.featureId.id,this.featureId).subscribe((res : any)=>{
			console.log(res);
			this.resultdetails = res;
			if (this.resultdetails.success == 1 ) {  
			
				$('#featureModal').modal('hide');
				this._flashMessagesService.show('Product Detail updated successfully', { cssClass: 'alert-success' } );
				    this.skuData();
				    setTimeout(() => {
					  this.router.navigate(['/sku']);
					   this.skuData();
					}, 2000); 

				} else {
					this.errors = res.message;
				}

			}, (err) => {

			});   	
	    }
	}

	async howtouseModal(id){
		this.howtouseValue=id;
		//this.howtouseId=[];
		this.howresult=[];
		console.log(this.howtouseValue);
		this.companyService.howtouse_edit(id).subscribe((res:any)=>{

			  for(let k of res.data){
            if ( k.feature_id == 5 ) {
              this.howresult.push(k);
            } 
        }
        console.log(this.howresult);

        let data2=this.howresult;
        this.howtouse =data2[0];
        console.log(this.featureId);
 		if ( data2[0] != undefined ) {
				this.howtouse = data2[0];
			} else {
				this.howtouse = {'product_id': this.howtouseValue};
			}
			
		}, (err) => {
		});
		await $('#howtouseModal').modal('show');
	}

	//How To Use Data Form
	onSubmit1(form?:NgForm){
		if(this.howtouse.id == undefined){
		this.howtouse.feature_id=5;
		
		this.companyService.productDetails(this.howtouse).subscribe((res : any)=>{
			console.log(res);
			this.resultdetails = res;
			if (this.resultdetails.success == 1 ) {   
			  
				$('#howtouseModal').modal('hide');
				this._flashMessagesService.show('Product Details created successfully', { cssClass: 'alert-success' } );
				     this.skuData();
				    setTimeout(() => {
					  this.router.navigate(['/sku']);
					   this.skuData();
					}, 2000); 

				} else {
					this.errors = res.message;

				}

			}, (err) => {

			});   	

		}
		
		else
		{
		this.companyService.productDetails_add(this.howtouse.id,this.howtouse).subscribe((res : any)=>{
			console.log(res);
			this.resultdetails = res;
			if (this.resultdetails.success == 1 ) {    
              
				$('#howtouseModal').modal('hide');
				this._flashMessagesService.show('Product Details updated successfully', { cssClass: 'alert-success' } );
				     this.skuData();
				    setTimeout(() => {
					  this.router.navigate(['/sku']);
					   this.skuData();
					}, 2000); 

				} else {
					this.errors = res.message;

				}

			}, (err) => {

			});   	
	    }
	}


}
