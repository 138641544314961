import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/service/api/api.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { OrderPipe } from 'ngx-order-pipe';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv';
import { Globals } from './../../globals';
declare var jQuery: any;

@Component({
    selector: 'app-consumer-data-export',
    templateUrl: './consumer-data-export.component.html',
    styleUrls: ['./consumer-data-export.component.css']
})
export class ConsumerDataExportComponent implements OnInit {

    submit: boolean = false;
    loader: boolean = false;
    consumerData: any = [];
    searchText = "";
    sortedCollection: any[];
    result: any = [];
    order: string = 'result.createdAt';
    reverse: boolean = false;
    p =this.globals.p;
    currentLimit=this.globals.currentLimit;
    pageLimits = this.globals.pageLimits;
    totalCount : any = 0;
    csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalseparator: '.',
        showLabels: true,
        showTitle: false,
        title: 'Qrcode List :',
        useBom: true,
        noDownload: false,
        //headers: ['Date','No. of participants scanning QR code','No. of participants dropping after scanning QR but not providing details','No. of participants submitting responses (till answer stage)','No. of participants submitting correct answers']  //headers: ["QrCode"]
        headers: ['Name','Phone','Email','Age','Answer','Pincode','HouseName','Area','Landmark','city','State','IsVerified','CreatedAt']  //headers: ["QrCode"]
    };

    constructor(private globals: Globals,private apiService: ApiService, private _flashMessagesService: FlashMessagesService, private router: Router,private orderPipe: OrderPipe) {
        this.sortedCollection = orderPipe.transform(this.result, 'result.createdAt');
    }

    ngOnInit() {
        this.loadDatePickerScript();
        this.onFindTransaction(2);
    }

    setOrder(value: string) {
        if (this.order === value) {
            this.reverse = !this.reverse;
        }
        this.order = value;
    }

    onFindTransaction(number:any) {
        if(number == 2){
            let startDate = '';
            let endDate = '';
            console.log(startDate, endDate)
            this.loader = true;
            this.apiService.get(`weeklyDataReportSheet?startDate=${startDate}&endDate=${endDate}`, true).subscribe((response: any) => {
                this.loader = false;
                if (response.status == 200 && response.body.success == "1") {
                    console.log(response.body.data);
                    this.consumerData = response.body.data;
                    this.totalCount = response.body.count;
                    this.loadDatePickerScript();
                    
                } else {
                    this.loader = false;
                    this._flashMessagesService.show(response.body.message, { cssClass: 'alert-error' });
                }
            })
        }else if(number == 0){
            let startDate = document.getElementById("start_date")['value'];
            let endDate = document.getElementById("end_date")['value'];
            console.log(startDate, endDate)
            this.loader = true;
            this.apiService.get(`weeklyDataReportSheet?startDate=${startDate}&endDate=${endDate}`, true).subscribe((response: any) => {
                this.loader = false;
                if (response.status == 200 && response.body.success == "1") {
                    console.log(response.body.data);
                    this.consumerData = response.body.data;
                    this.totalCount = response.body.count;
                    
                } else {
                    this.loader = false;
                    this._flashMessagesService.show(response.body.message, { cssClass: 'alert-error' });
                }
            })
        }else if(number == 1){
            this.loader = true;
            let startDate = document.getElementById("start_date")['value'];
            let endDate = document.getElementById("end_date")['value'];
            let qrCode = this.consumerData;
            let random = Math.random().toString(36).substring(7);
            let fileName = `${startDate} to ${endDate}-report`;
            new AngularCsv(qrCode, fileName, this.csvOptions);
            this._flashMessagesService.show('Downloaded Successfully', { cssClass: 'alert-success' });
            this.loader = false;
        }
        
    }

    loadDatePickerScript() {

        jQuery(document).ready(() => {
            jQuery("#start_date").daterangepicker(
                {
                    singleDatePicker: true,
                    showDropdowns: true,
                    locale: {
                        format: "YYYY-MM-DD",
                    },
                    maxDate: new Date(),
                    // minDate: new Date(),
                    autoclose: true,
                    //minYear: 1901,
                },
                (start: any, end: any, label: any) => {
                    console.log("s: ", start, "e: ", end, "l: ", label);

                    jQuery("#end_date").daterangepicker(
                        {
                            singleDatePicker: true,
                            showDropdowns: true,
                            locale: {
                                format: "YYYY-MM-DD",
                            },
                            maxDate: new Date(),
                            minDate: start,
                            autoclose: true,
                            minYear: 1901,
                        },
                        (start: any, end: any, label: any) => {
                            console.log(start, end, label);
                        }
                    );
                }
            );

            jQuery("#end_date").daterangepicker(
                {
                    singleDatePicker: true,
                    showDropdowns: true,
                    locale: {
                        format: "YYYY-MM-DD",
                    },
                    //maxDate: new Date(),
                    minDate: new Date(),
                    autoclose: true,
                    minYear: 1901,
                },
                (start: any, end: any, label: any) => {
                    console.log(start, end, label);
                }
            );
            // setTimeout(() => {
            //   if (this.pointForm) {
            //     jQuery("#startDate").val(this.pointForm.value.startDate);
            //     jQuery("#endDate").val(this.pointForm.value.endDate);
            //   }
            // }, 2000)
        });
    }

}
