import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../../company/service/company.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as $ from "jquery";
import { Globals } from './../../../globals';


@Component({
	selector: 'app-edit-sku',
	templateUrl: './edit-sku.component.html',
	styleUrls: ['./edit-sku.component.css']
})
export class EditSkuComponent implements OnInit {

	resultList: any;
	resultData : any;
	variantdataList : any ;
	resultVariant : any;
	msg: string = null;
	errors : any = '';
	result: any = {};
	Editid : any ;
	currentUserId : any = localStorage.getItem('id');
	variantList:any;
	categoryId:any;
	brandId: any;
	variantId:any;
	unitData:any;
	packageList:any;
	packageId:any;
	public imagePath;
	imgURL: any = "/assets/images/placeholder.png";
	message : any;
	companyId:number;
	main_image: any;
	filedata:any;
	sizeList:any;
	sizeId:any;
	submit:boolean=false;
	loader:boolean = false;

	 numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }


	constructor(private globals: Globals,private _flashMessagesService: FlashMessagesService,public companyService: CompanyService,private route: ActivatedRoute, private router: Router) {  }

	ngOnInit() {
		this.route.params.subscribe(params => {
			console.log(params);
			this.Editid=params['id']; 
			this.editskuData();
			this.unitData = this.globals.unitData;
		});
	}

	editskuData(){
		this.loader=true;
		this.companyService.product_edit(this.Editid).subscribe((res:any)=>{
			this.loader=false;
			this.result     =res.data;
			this.categoryId = res.data.category_id;
			this.brandId    = res.data.brand_id;
			this.variantId  = res.data.varient_id;
			this.sizeId     = res.data.size_id;
			this.packageId  = res.data.packaging_id;
			this.imgURL     = res.data.main_image;
			this.brandData(this.brandId);
			// this.setUnitData(res.data.size_id);
			//this.packageData(this.packageId);
			console.log(this.result);
		}, (err) => {

		});
	}

	onSubmit(form?:NgForm) {
		// if (this.main_image == undefined && this.main_image == null ) {
	 //      this.message="Image Required";
	 //    }    
		let f = form.value;
		 f.category_id =this.result.category_id;
	     f.brand_id    =this.result.brand_id;
	     f.varient_id  =this.result.varient_id;
	     f.size_id     =this.result.size_id;
	     f.packaging_id=this.result.packaging_id;
	     // console.log(f);
	     // return false;
		let formdata = new FormData();
        //console.log(formdata);
          	formdata.append("main_image",this.filedata);
            formdata.append("category_id",f.category_id);
            formdata.append("varient_id",f.varient_id);
            formdata.append("brand_id",f.brand_id);
            formdata.append("size_id",f.size_id);
            formdata.append("packaging_id",f.packaging_id);
            formdata.append("shipper_size",f.shipper_size);
            formdata.append("product_mrp",f.product_mrp);
           

		this.companyService.product_update(this.Editid,formdata).subscribe((res: any)=>{
			
			this.resultData = res;
			console.log(this.resultData);
			if (this.resultData.success == 1 ) {
				  this.submit=true; 
				  this._flashMessagesService.show('Sku updated successfully', { cssClass: 'alert-success' } );

				setTimeout(() => {
					this.router.navigate(['/sku']);
				}, 1000); 
				
			} else {
				this.errors = res.message;
				 setTimeout(() => {
		            this.errors.main_image = false;
		          }, 3000); 
			}

		}, (err) => {
		
		});
	}


	
	
	//Brand Data Start
	brandChanged(event,categoryId){
		this.result.brand_id=event.value;
	    let catBrand = this.result.brand_id;
	    var result = [];
		this.companyService.categorybrand_list(catBrand).subscribe((res:any)=>{
			result = res.data;
			
			let categoryData = res.data.filter(function(obj){

				return obj.brand_id = catBrand;
			});

			console.log(categoryData);
			this.resultList = categoryData.map(function(obj) {
				var rObj = {};
				rObj['id'] = obj.id;
				rObj['text'] = obj.name;
				rObj['selected'] = (obj.id==categoryId) ? true : false;
				return rObj;
			});
			console.log(this.resultList);
		}, (err) => {

		});
	}

	async brandData(brandId){
		var result = [];
		await this.companyService.brand_list().subscribe((res:any)=>{
			result = res.data;
			this.variantdataList = result.map(function(obj) {
				var rObj = {};
				rObj['id'] = obj.id;
				rObj['text'] = obj.name;
				rObj['selected'] = (obj.id==brandId) ? true : false;

				return rObj;
			});
			console.log(this.variantdataList);
		}, (err) => {

		});
	}
	
	categoryChanged(event,variantId){
		console.log(event);
		this.result.category_id=event.value;

		let varCat = this.result.category_id;
	    var result = [];
		this.companyService.variantCat_list(varCat).subscribe((res:any)=>{
			result = res.data;
			
			let categoryData = res.data.filter(function(obj){

				return obj.category_id = varCat;
			});

			console.log(categoryData);
			this.variantList = categoryData.map(function(obj) {
				var rObj = {};
				rObj['id'] = obj.id;
				rObj['text'] = obj.name;
				rObj['selected'] = (obj.id==variantId) ? true : false;
				return rObj;
			});
			console.log(this.variantList);
		}, (err) => {

		});

	}

	//Categoty Data Finish

	//Variant Data Start
	variantChanged(event,sizeId){
		console.log(event);
		this.result.varient_id=event.value;
		let varCat = this.result.varient_id;
	    var result = [];

	    this.companyService.packaging_size(varCat).subscribe((res:any)=>{

                  result = res.data;
                  var rObj= [];
                  rObj.push({"id":"","text":""});
                  for(let i=0; i<res.data.length; i++){

                    let unitName;
                    for(let j=0; j< this.unitData.length; j++){
                        if(res.data[i].unit==this.unitData[j].id){
                            unitName = this.unitData[j].text;
                        }
                    }

                    rObj.push(
                                {
                                  "id":res.data[i].id, 
                                  "text":res.data[i].quantity+" "+unitName,
                                  'selected' : (res.data[i].id==sizeId) ? true : false
                                }
                             );
                  }      
                  this.sizeList = rObj;
                }, (err) => {

                });
	}

	//Unit Changed Event
	unitChanged(event,packageId){
		console.log(event);
		this.result.size_id=event.value;

		let varCat = this.result.size_id;
	    var result = [];
		this.companyService.product_packbysize(varCat).subscribe((res:any)=>{
			result = res.data;
			
			let categoryData = res.data.filter(function(obj){

				return obj.size_id = varCat;
			});

			console.log(categoryData);
			this.packageList = categoryData.map(function(obj) {
				var rObj = {};
				rObj['id'] = obj.id;
				rObj['text'] = obj.packaging_type;
				rObj['selected'] = (obj.id==packageId) ? true : false;
				return rObj;
			});
			console.log(this.packageList);
		}, (err) => {

		});

	}


	//packaging Data Start
	packagingChanged(event){
		console.log(event);
		this.result.packaging_id=event.value;
	}

	// async packageData(packageId){
	// 	var result = [];
	// 	await this.companyService.packaging_list().subscribe((res:any)=>{
	// 		result = res.data;
	// 		console.log(result);
	// 		this.packageList = result.map(function(obj) {
	// 			var rObj = {};
	// 			rObj['id'] = obj.id;
	// 			rObj['text'] = obj.packaging_type;
	// 			rObj['selected'] = (obj.id==packageId) ? true : false;

	// 			return rObj;
	// 		});
	// 		console.log(this.variantList);
	// 	}, (err) => {

	// 	});
	// }


	disabled(){
		
	}

	//packaging Data Finish

	onFileChange(e){
		this.filedata=e.target.files[0];
		console.log("this.filedata");
		console.log(this.filedata);
	}

	preview(files) {
		if (files.length === 0)
			return;
		var mimeType = files[0].type;
		if (mimeType.match(/image\/*/) == null) {
			this.message = "Only images are supported.";
			return;
		}

		var reader = new FileReader();
		this.imagePath = files;
		console.log(this.imagePath);

		reader.readAsDataURL(files[0]); 
		reader.onload = (_event) => { 
		this.imgURL = reader.result;
	

		}
	}    



}
