import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../../company/service/company.service';
declare var $ :any; 
import { OrderPipe } from 'ngx-order-pipe';
import { FlashMessagesService } from 'angular2-flash-messages';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv';
import { Globals } from './../../../globals';

@Component({
  selector: 'app-roledetails',
  templateUrl: './roledetails.component.html',
  styleUrls: ['./roledetails.component.css']
})
export class RoledetailsComponent implements OnInit {

  data : any;
  qrcode1:any;
  qrcode2:any;
  qrcode3:any;
  searchText  = "";
  sortedCollection: any[];
  order: string = 'result.id';
  reverse: boolean = false;
  result : any = [{}];
  arr : any = [];
  skuId:any;
  errors : any = '';
  deletedMsg: string = null;
  p =this.globals.p;
  currentLimit=this.globals.currentLimit;
  deleteId: any;
  pageLimits = this.globals.pageLimits;
  loader:boolean = false;

  csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: true,
    showTitle: false,
    title: 'Qrcode List :',
    useBom: true,
    noDownload: false,
    headers: []  //headers: ["QrCode"]
  };


  constructor( 
    private _flashMessagesService: FlashMessagesService,
    public companyService: CompanyService,
    private route: ActivatedRoute, 
    private router: Router,
    private orderPipe: OrderPipe,
    private globals: Globals
    ) {  
    this.sortedCollection = orderPipe.transform(this.result, 'result.id');
  }

  async  ngOnInit() {

  	await this.route.params.subscribe(params => {
      this.skuId=params['id']; 
      this.skuroleDetails();
    });
  }

  skuroleDetails(){

  	this.loader=true;
    this.companyService.detailsQrcode(this.skuId).subscribe((res:any)=>{
      this.loader=false;
      console.log(res);
      this.result=res.data;
      console.log(this.result);
      
    }, (err) => {


    });

  }

  exportQrcode(roleUniqueId){
    this.companyService.getQrcodeList(roleUniqueId).subscribe((res:any)=>{
      console.log(res);
      
      if(res.success==1){
            let qrCode = res.data;
        
            let random = Math.random().toString(36).substring(7);
            let fileName = roleUniqueId;
            new AngularCsv(qrCode, fileName, this.csvOptions);
      }
      else{
        this._flashMessagesService.show(res.message, { cssClass: 'alert-success' } );
        
        setTimeout(() => {
          this.router.navigate(['/qrcode']);
        }, 2000); 
      }
    });
  }


  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }

}
