import {
  Component,
  OnInit,
  Renderer2,
  Inject,
  Pipe,
  PipeTransform
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CompanyService } from '../../../company/service/company.service';
import { DOCUMENT } from "@angular/common";
import { Globals } from '../../../globals';
declare var jQuery: any;
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from "src/app/service/api/api.service";

// @Pipe({
//   name: "customfilter"
// })
// export class FilterPipe implements PipeTransform {
//   transform(items: Array<any>, searchText: string, properties): any {
//     if (!items) return [];
//     if (!searchText) return items;
//     debugger;
//     return items.filter(item => {
//       var itemFound: Boolean;
//       for (let i = 0; i < properties.length; i++) {
//         if (item[properties[i]].toString().toLowerCase().indexOf(searchText.toString().toLowerCase()) !== -1) {
//           itemFound = true;
//           break;
//         }
//       }
//       return itemFound;
//     });
//   }
// }

@Component({
  selector: "app-coin-allocation",
  templateUrl: "./coin-allocation.component.html",
  styleUrls: ["./coin-allocation.component.css"]
})
export class CoinAllocationComponent implements OnInit {
  pointForm: FormGroup;
  submitted = false;
  dataTableFlag = true;

  public popoverTitle = "Confirm";
  public popoverMessage = "Are you sure want to delete configuration?";
  public confirmClicked = false;
  public cancelClicked = false;

  math = Math;
  id: any;
  result: any = [];
  pointId: any = null;
  loader: boolean = false;
  searchText = "";
  p = this.global.p;
  currentLimit = this.global.currentLimit;
  pageLimits = this.global.pageLimits;

  // lvl_types = [{ id: 1, name: 'Product' }, { id: 4, name: "Shipper" }]

  consumer_types = [{
    id: 0,
    name: "Customer"
  }, {
    id: 1,
    name: "Distributor"
  }, {
    id: 2,
    name: "Wholesaler"
  }, {
    id: 3,
    name: "Retailer"
  }]

  constructor(
    private apiService: CompanyService,
    private API: ApiService,
    private formBuilder: FormBuilder,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    private global: Globals,
    public toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
  ) {

    this.route.params.subscribe(params => {
      console.log(params);
      this.pointId = params['id'];
      this.id = this.pointId;
    });
  }

  ngOnInit() {
    let date = new Date();
    this.pointForm = this.formBuilder.group({
      consumer_type: [0, Validators.required],
      selectType: [0, Validators.required],
      miniNumber: [1, Validators.min(1)],
      maxiNumber: [1, Validators.min(1)],
      selectMode: [0, Validators.required],
      sku_id: [null, Validators.required],
      lvl_type: [1, Validators.required],
      percentage: [1, [Validators.required, Validators.max(100), Validators.min(1)]],
      startDate: [date.getFullYear() + '-' + (Number(date.getMonth()) + 1) + '-' + date.getDate(), Validators.required],
      endDate: [date.getFullYear() + '-' + (Number(date.getMonth()) + 1) + '-' + date.getDate(), Validators.required],
    });

    let script = this._renderer2.createElement("script");
    script.type = `text/javascript`;
    script.src = `
        ../../../assets/vendor/EasyAutocomplete-1.3.5/jquery.easy-autocomplete.min.js
    `;

    this._renderer2.appendChild(this._document.body, script);
    this.setValidation();
    this.loadDatePickerScript();
    if (this.pointId) {
      this.getConfiguredDetails();
      // this.submitPointConfiguration(false);
    }

    this.skuData();
  }

  get f() {
    return this.pointForm.controls;
  }

  getConfiguredDetails(){
    this.API.get('point-allocation/details?id='+ this.pointId, true).subscribe((response: any) => {
      if(response.status == 200 && response.body.success == 1) {
        this.pointForm.patchValue({
          selectType: response.body.data.selectType,
          miniNumber: response.body.data.miniNumber,
          maxiNumber: response.body.data.maxiNumber,
          selectMode: response.body.data.selectMode,
          percentage: response.body.data.percentage,
          sku_id: response.body.data.sku_id,
          lvl_type: response.body.data.lvl_type,
          consumer_type: response.body.data.consumer_type
          // startDate: response.body.data.startDate ? new Date(response.body.data.startDate) : null,
          // endDate: response.body.data.endDate ? new Date(response.body.data.endDate) : null,
        });      
        jQuery("#startDate").data("daterangepicker").setStartDate(new Date(response.body.data.startDate));
        jQuery("#startDate").data("daterangepicker").setEndDate(new Date(response.body.data.startDate));
        jQuery("#endDate").data("daterangepicker").setStartDate(new Date(response.body.data.endDate));
        jQuery("#endDate").data("daterangepicker").setEndDate(new Date(response.body.data.endDate));
      }
    }, (err) => {

    });
  } 

  skuData() {
    this.loader = true;
    this.apiService.product_list().subscribe((res: any) => {
      this.loader = false;
      this.result = res.data;

    }, (err) => {
      console.log(err);
    });
  }

  setValidation() {
    console.log('--')
    this.pointForm.get('selectMode').valueChanges
      .subscribe(Category => {
        console.log(Category)
        if (Category == 0) {
          
          this.pointForm.get('percentage').setValidators([Validators.required, Validators.max(100), Validators.min(1)]);
          this.pointForm.get('miniNumber').setValidators(null);
          this.pointForm.get('maxiNumber').setValidators(null);
        }

        if (Category == 1) {
          this.pointForm.get('percentage').setValidators(null);
          this.pointForm.get('miniNumber').setValidators([Validators.required, Validators.min(1)]);
          this.pointForm.get('maxiNumber').setValidators([Validators.required, Validators.min(1)]);
        }

        this.pointForm.get('percentage').updateValueAndValidity();
        this.pointForm.get('miniNumber').updateValueAndValidity();
        this.pointForm.get('maxiNumber').updateValueAndValidity();
      });

    this.pointForm.get('selectType').valueChanges
      .subscribe(Type => {
        console.log(Type)
        if (Type == 0) {
          this.pointForm.get('startDate').setValidators([Validators.required]);
          this.pointForm.get('endDate').setValidators([Validators.required]);
          this.loadDatePickerScript();
        }

        if (Type == 1) {
          this.pointForm.get('startDate').setValidators(null);
          this.pointForm.get('endDate').setValidators(null);
        }

        this.pointForm.get('startDate').updateValueAndValidity();
        this.pointForm.get('endDate').updateValueAndValidity();
      });
  }

  submitPointConfiguration(bool: any) {
    // this.setValidation();
    if (bool)
      this.submitted = true;
    let sDate: any = document.getElementById("startDate");
    let eDate: any = document.getElementById("endDate");

    if ((this.pointForm.value.miniNumber > this.pointForm.value.maxiNumber) && this.pointForm.value.selectMode == 1) {
      return this.toastr.error('Minimum Number must be less than Maximum Number');
    }

    console.log(this.pointForm.invalid)
    if (bool && this.pointForm.invalid) {
      return;
    }

    this.loader = true;
    this.apiService
      .point_allocation_add(
        {
          consumerType: this.pointForm.value.consumer_type,
          lvlType: this.pointForm.value.lvl_type,
          selectType: this.pointForm.value.selectType,
          miniNumber: this.pointForm.value.miniNumber,
          maxiNumber: this.pointForm.value.maxiNumber,
          selectMode: this.pointForm.value.selectMode,
          percentage: this.pointForm.value.percentage,
          sku_id: this.pointForm.value.sku_id,
          startDate: sDate ? sDate.value : null,
          endDate: eDate ? eDate.value : null,
          pointConfigurationId: this.pointId,
          isNew: (this.pointId) ? false : true
        }
      )
      .subscribe(
        (response: any) => {
          this.loader = false;
          if (response.success == 1) {
            //let index = this.result.findIndex((x) => x.id == response.data.sku_id);
            // this.pointForm.patchValue({
            //   selectType: response.data.selectType,
            //   miniNumber: response.data.miniNumber,
            //   maxiNumber: response.data.maxiNumber,
            //   selectMode: response.data.selectMode,
            //   percentage: response.data.percentage,
            //   sku_id: response.data.sku_id,
            //   startDate: response.data.startDate ? response.data.startDate.split("T")[0] : null,
            //   endDate: response.data.endDate ? response.data.endDate.split("T")[0] : null,
            // })
            // setTimeout(() => {
            //   sDate = document.getElementById("startDate");
            //   eDate = document.getElementById("endDate");
            //   if (sDate && eDate) {
            //     if (response.data.startDate && response.data.endDate) {
            //       sDate.value = response.data.startDate.split("T")[0];
            //       eDate.value = response.data.endDate.split("T")[0];
            //     }
            //   }
            // }, 1000)
            this.id = response.data.id;
            if (bool) {
              this.showToast(
                "success",
                "Success - Point Configured",
                "Point has been configured!"
              );
              this.router.navigate(['rewards/point-allocation-list']);
            }

            //window.location.reload();
            // this.getConfiguredPoints();
            // this.onReset();
          } else {
            this.showToast(
              "danger",
              "Failed - Point Configuration",
              response.message
            );
          }
        },
        err => {
          console.log(err);
          this.loader = false;
          this.submitted = false;
          this.showToast(
            "danger",
            "Failed - Point Configuration",
            "Due to some reason point has not configured!"
          );
        }
      );


  }

  /*getMasterDetails() {
    this.apiService
      .point_allocation()
      .subscribe(
        (response: any) => {
          if (response.status === 200 && response.body.success === "1") {
            this.grade = response.body.data.gradeList;
            this.description = response.body.data.dnoList;
            this.segments = response.body.data.segmentList;
            this.loadDataInAutoComplete(
              this.description,
              this.segments,
              this.grade
            );
          }
        },
        err => {
          console.error(err);
        }
      );
  }

  getConfiguredPoints() {
    this.apiService.point_allocation_list().subscribe(
      (response: any) => {
        if (response.success == 1) {
          //this.configuredPoints = response.body.data;
          this.loadDataTableScript();
        }
      },
      err => {
        console.error(err);
      }
    );
  }*/

  loadDataTableScript() {

    let script = this._renderer2.createElement("script");
    script.type = `text/javascript`;
    script.id = "datatable-ref";
    script.text = `
      $(document).ready(function() {
        if ($.fn.dataTable.isDataTable('#dataTables-example')) {
          $('#dataTables-example').DataTable().destroy();
        }

          let table = $('#dataTables-example').DataTable({
            searching: true,
            responsive: true,
            paging: true,
            destroy: true,
            retrive: true,
            "order": [
              [3, "desc"]
            ],
            deferRender: true,
          });

        });
      `;

    this._renderer2.appendChild(this._document.body, script);

  }

  showToast(toastType, toastCaption, toastText) {
    if (toastType == "danger") {
      this.toastr.error(toastText, toastCaption);
    } else {
      this.toastr.success(toastText, toastCaption);
    }
  //  this.toastr.open({
  //     text: toastText,
  //     caption: toastCaption,
  //     type: toastType,
  //     position: "top-right",
  //     component: CustomToasterComponent
  //   });
  }

  onReset() {

  }


  loadDatePickerScript() {

    jQuery(document).ready(() => {
      jQuery("#startDate").daterangepicker(
        {
          singleDatePicker: true,
          showDropdowns: true,
          locale: {
            format: "YYYY-MM-DD",
          },
          //maxDate: new Date(),
          minDate: new Date(),
          autoclose: true,
          //minYear: 1901,
        },
        (start: any, end: any, label: any) => {
          console.log("s: ", start, "e: ", end, "l: ", label);

          jQuery("#endDate").daterangepicker(
            {
              singleDatePicker: true,
              showDropdowns: true,
              locale: {
                format: "YYYY-MM-DD",
              },
              //maxDate: new Date(),
              minDate: start,
              autoclose: true,
              minYear: 1901,
            },
            (start: any, end: any, label: any) => {
              console.log(start, end, label);
            }
          );
        }
      );

      jQuery("#endDate").daterangepicker(
        {
          singleDatePicker: true,
          showDropdowns: true,
          locale: {
            format: "YYYY-MM-DD",
          },
          //maxDate: new Date(),
          minDate: new Date(),
          autoclose: true,
          minYear: 1901,
        },
        (start: any, end: any, label: any) => {
          console.log(start, end, label);
        }
      );
      // setTimeout(() => {
      //   if (this.pointForm) {
      //     jQuery("#startDate").val(this.pointForm.value.startDate);
      //     jQuery("#endDate").val(this.pointForm.value.endDate);
      //   }
      // }, 2000)
    });
  }
}
