import { Component, OnInit } from '@angular/core';
import { CompanyService } from '../../../company/service/company.service';
import { Globals } from './../../../globals';
import { FlashMessagesService } from 'angular2-flash-messages';

@Component({
  selector: 'app-scanbyapp',
  templateUrl: './scanbyapp.component.html',
  styleUrls: ['./scanbyapp.component.css']
})
export class ScanbyappComponent implements OnInit {

  data : any;
  searchText  = "";
  sortedCollection: any[];
  order: string = 'result.id';
  reverse: boolean = false;
  result : any = [];
  arr : any = [];
  errors : any = '';
  deletedMsg: string = null;
  p =this.globals.p;
  currentLimit=this.globals.currentLimit;
  deleteId: any;
  pageLimits = this.globals.pageLimits;
  loader:boolean = false;

  constructor(private globals: Globals,private _flashMessagesService: FlashMessagesService,public companyService: CompanyService) { }

  ngOnInit() {
    this.customerByApp();
  }

  customerByApp(){
    this.companyService.scanByApp().subscribe((res:any)=>{
      console.log(res);
      this.result =res.data;
    });
  }

  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }

}
