import { Component, OnInit ,NgZone,ElementRef,ViewChild } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../../company/service/company.service';
import { BusinessService } from '../../business/service/business.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import * as $ from "jquery";

@Component({
	selector: 'app-edit-supplychain',
	templateUrl: './edit-supplychain.component.html',
	styleUrls: ['./edit-supplychain.component.css']
})
export class EditSupplychainComponent implements OnInit {

	resultList: any;
	msg: string = null;
	errors : any = '';
	chain_unit_ids_arr: { [index:string]:any } = [];
	submit:boolean=false;
	roleOptions:any;
	result : any = [];
	isCurrentPlant:boolean = false;
	isCurrentUnit:boolean=false;
	ParentRoles:any =[];
	plantList:any;
	plantOptions:any;
	unitOptions:any;
	roles:any;
	unitList:any=[];
	supplyChain:any;
	Editid:number;
	loader:boolean = false;
	roleId:any;
	resultPlantData:any =[];
	public SelectedPlant:any;
	resultData:any =[];
	SelectedUnit:any;

	numberOnly(event): boolean {
		const charCode = (event.which) ? event.which : event.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;

	}

	constructor(public businessService: BusinessService,private _flashMessagesService: FlashMessagesService, public companyService: CompanyService,private route: ActivatedRoute, private router: Router) { }


	ngOnInit() {

		this.route.params.subscribe(params => {
			console.log(params);
			this.Editid = params['id'];
			this.editchainData();     
		});
	}


	editchainData() {
		this.loader=true;
		this.isCurrentPlant=false;
		this.isCurrentUnit=false;
		this.companyService.supplychain_edit(this.Editid).subscribe((res: any) => {
			this.loader=false;
			this.result = res.data;
			this.roleId = res.data.role_id;
			this.chain_unit_ids_arr=res.data.chain_unit_mapping_unit_ids;
			//this.chain_unit_ids_arr=[26,27,29];
			console.log(this.chain_unit_ids_arr);
			this.SelectedPlant =res.data.chain_unit_mapping_plant_ids.join(',');
			this.SelectedUnit =res.data.business_role.businessrole_parent_ids.split(',');
			console.log(this.SelectedUnit);
			this.roleData(this.roleId);
			console.log(this.result);
			this.checkPlantorUnit(this.SelectedUnit);	

		}, (err) => {

		});
	}

	roleChanged(event) {
		// this.isCurrentPlant=false;
		// this.isCurrentUnit=false; 
		console.log(event);
		this.result.role_id = event.value;
		console.log(this.result.role_id);
		let Parentrole=this.result.role_id;
		let Data =this.SelectedPlant;
		let SetUnits=this.chain_unit_ids_arr;

		this.companyService.getParentBasedRole(Parentrole).subscribe((res:any)=>{
			this.resultData = res;
			if(this.resultData.plants.length  > 0 ){
				console.log("Plant Data Here");
				this.plantOptions   = { multiple: true };
				this.plantList = this.resultData.plants.map(function (obj) {
					var rObj = {};

					rObj['id']   = obj.id;
					rObj['text'] = obj.plant_name;

					let id = obj.id.toString();
					console.log(Data.indexOf(id));

					rObj['selected'] =Data.indexOf(id)>-1 ? true : false;

					return rObj;
				});
				console.log(this.plantList);    
				// this.SetPlantData(this.SelectedPlant);
				this.isCurrentPlant=true;
			}
			else{

				this.isCurrentPlant=false;
				console.log(SetUnits);
				if(this.resultData.units.length > 0){
					this.unitOptions   = { multiple: true };
					this.roles = this.resultData.businessrole_parent_ids;
					let roleData=[];
					let roleRes;
					for (let i = 0; i < this.resultData.businessrole_parent_ids.length; i++) {

						roleRes = [];
						for (let j = 0; j < res.units.length; j++) {

							if(this.roles[i]==res.units[j].role_id){

								if(SetUnits != undefined){
									roleRes.push({"id":res.units[j].id, "text":res.units[j].location,"selected":SetUnits.indexOf(res.units[j].id)>-1 ? true : false});

								}
								else{
									roleRes.push({"id":res.units[j].id, "text":res.units[j].location});
								}

							}
						}
						roleData.push({role_id:this.resultData.businessrole_parent_ids[i],roles:roleRes});
					} 

					this.roles = roleData;
					console.log(roleData);
					this.isCurrentUnit =true;
				}

				else{
					this.isCurrentUnit =false;
				}
			}

			//
		},(err) => {

		});




	}

	async roleData(roleId) {

		var result = [];
		await this.businessService.businessRole_list().subscribe((res:any)=>{
			result = res.data;
			this.resultList = result.map(function(obj) {
				var rObj = {};

				rObj['id'] = obj.id;
				rObj['text'] = obj.name;
				rObj['selected'] = (obj.id==roleId) ? true : false;
				return rObj;
			});
			console.log(this.resultList);

		}, (err) => {

		});
	}

	plantChanged(event) {
		
		console.log(event);
		this.result.plant_ids = event.value;
		console.log(this.result.plant_ids);
	}

	SetPlantData(SelectedPlant){

		let result:any;
		this.plantOptions    = {multiple: true };
		this.companyService.getParentBasedRole(this.roleId).subscribe((res:any)=>{
			result = res.plants;
			console.log(result);
			this.plantList = result.map(function (obj) {
				var rObj = {};

				rObj['id']   = obj.id;
				rObj['text'] = obj.plant_name;

				let id = obj.id.toString();
				console.log(SelectedPlant.indexOf(id));
				
				rObj['selected'] =SelectedPlant.indexOf(id)>-1 ? true : false;

				return rObj;
			});
			console.log(this.plantList);		
			
		}, (err) => {

		});
		
	}	

	SetUnitData(SelectedUnit){
		let SetUnits=this.chain_unit_ids_arr;
		console.log(SetUnits);
		let result:any;
		this.unitOptions    = {multiple: true };

		this.companyService.getParentBasedRole(this.roleId).subscribe((res:any)=>{
			result = res.units;
			console.log(result);
			this.roles=this.SelectedUnit;
			let roleData=[];
			let roleRes;
			for (let i = 0; i < SelectedUnit.length; i++) {

				roleRes = [];
				for (let j = 0; j < res.units.length; j++) {

					if(this.roles[i]==res.units[j].role_id){

						roleRes.push({"id":res.units[j].id, "text":res.units[j].location,"selected":this.chain_unit_ids_arr.indexOf(res.units[j].id)>-1 ? true : false});
					}
				}
				roleData.push({role_id:this.SelectedUnit[i],roles:roleRes});
			} 

			this.roles = roleData;
			console.log(this.roles);

		}, (err) => {

		});
		
	}

	unitChanged(event,id,i) {

		console.log(this.chain_unit_ids_arr);
		this.chain_unit_ids_arr[i]=event.value;
	    console.log(this.chain_unit_ids_arr); 

	}

	checkPlantorUnit(SelectedUnit){
		
		if(this.SelectedPlant.length > 0 ){
			console.log("Plant Data Here");
			this.SetPlantData(this.SelectedPlant);
			this.isCurrentPlant=true;
		}
		else{
			this.isCurrentPlant=false;
			this.SetUnitData(this.SelectedUnit)
			console.log("Data Here");
			this.isCurrentUnit=true;
		}
	}

	onSubmit(form?: NgForm) {

		let f = form.value;
		if(this.SelectedPlant.length <= 0 ){
			f.plant_ids=["0"];
			
		}
		else{

			f.chain_unit_ids=["0"];
			f.plant_ids=this.result.plant_ids;
		}
		f.role_id =this.result.role_id;

		let data = this.chain_unit_ids_arr.join(',');
		console.log(data);
	   f.chain_unit_ids=data.split(',');
	   if(f.chain_unit_ids.length == this.result.chain_unit_mapping_unit_ids.length ){
	   	f.chain_unit_ids
	   }
	   else{
	   	f.chain_unit_ids.splice(-1,1);
	   }	
	  // f.chain_unit_ids.splice(-1,1)
	   //f.chain_unit_ids = this.uniq(f.chain_unit_ids);
	   console.log(f.chain_unit_ids);
	   // if (f.chain_unit_ids){
	   //  console.log("Please Enter data here");
	   // 	return false;
	   // }

		console.log(f.chain_unit_ids);
	    console.log(f);
		// return false;
		this.companyService.supplychain_update(this.Editid,f).subscribe((res: any) => {
			console.log(res);
			this.supplyChain = res;
			if (this.supplyChain.success == 1) {
				this.submit=true; 
				// this._flashMessagesService.show(' Supply Chain created successfully', { cssClass: 'alert-success' });
				// setTimeout(() => {
					//     this.router.navigate(['/supplychain']);
					// }, 2000);

				} else {
					this.errors = res.message;
					setTimeout(() => {
						this.errors.chain_unit_ids = false;
						this.errors.role_id = false;
						this.errors.plant_ids = false;
					}, 3000); 

				}

			}, (err) => {

			});
	}


	// uniq(a) {
 //    return a.sort().filter(function(item, pos, ary) {
 //        return !pos || item != ary[pos - 1];
 //    })
	// }

	

}
