import { Component, ViewEncapsulation, OnInit, Inject } from '@angular/core';
import * as $ from "jquery";
import { AdminService } from '../../service/admin.service';
//declare let Chart:any;
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Color, Label, SingleDataSet, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip } from 'ng2-charts';
import { MapsTheme, Maps, Marker, MapsTooltip, Legend, ILoadEventArgs } from '@syncfusion/ej2-angular-maps';
import { MapAjax } from '@syncfusion/ej2-maps';
import { AgmCoreModule } from '@agm/core';
import { NgbDate, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { CompanyService } from '../../company/service/company.service';
declare var jQuery: any;



@Component({
    selector: 'app-productiondashboard',
    templateUrl: './productiondashboard.component.html',
    styleUrls: ['./productiondashboard.component.css']
})
export class ProductiondashboardComponent implements OnInit {

    //PRODUCTION GRAPH START
    public barChartLabels1: Label[] = ['1', '2', '3'];
    public barChartType1: ChartType = "bar";
    public barChartLegend1 = true;
    public barChartPlugins1 = [];
    public barChartData1: ChartDataSets[] = [];
    public barChartColors1: Color[] = [{
        backgroundColor: "#e625ea",
        borderColor: "#e625ea",
    }, {
        backgroundColor: "#4304b3",
        borderColor: "#4304b3",
    }, {
        backgroundColor: "#04b38b",
        borderColor: "#04b38b",
    }, {
        backgroundColor: "#0411b3",
        borderColor: "#0411b3",
    }, {
        backgroundColor: "#70b304",
        borderColor: "#70b304",
    }, {
        backgroundColor: "#8004b3",
        borderColor: "#8004b3",
    }];
    public barChartOptions1: ChartOptions = {
        responsive: true,
        scales: {
            xAxes: [
                {
                    gridLines: {
                        color: "rgba(0, 0, 0, 0)",
                    },
                },
            ],
            yAxes: [
                {
                    gridLines: {
                        color: "rgba(0, 0, 0, 0)",
                    },
                },
            ],
        },
    };
    // end production chart graph




    //SUPPLY CHAIN SLIPPAGE GRAPH START
    public pieChartOptions: ChartOptions = {
        responsive: true,
    };
    public pieChartLabels: Label[] = [];
    public pieChartData: SingleDataSet = [];
    public pieChartType: ChartType = "pie";
    public pieChartLegend = true;
    public pieChartPlugins = [];
    public pieChartColor: any = [
        {
            backgroundColor: ['#e625ea',
                '#4304b3',
                '#04b38b',
                '#0411b3',
                '#70b304',
                '#8004b3'
            ]
        }
    ]
    //SUPPLY CHAIN SLIPPAGE GRAPH END

    selectedDurationTitle = "Weekly";
    appliedDuration;
    selectedFilter = "7d";
    queryString = "?duration=7d";
    filterStartDate;
    filterEndDate;

    loader: boolean = false;

    supplyChainLoader = false;
    sortageLoader = false;
    damageLoader = false;
    productionLoader = false;
    intrasitLoader = false;



    constructor(
        public adminService: AdminService,
        public companyService: CompanyService,
    ) { }

    async ngOnInit() {
        this.loadCharts();
    }


    onApplyFilter() {
        this.appliedDuration = this.selectedFilter;

        if (this.selectedFilter === "7d") {
            this.selectedDurationTitle = "Weekly";
            this.queryString = `?duration=7d`;
        } else if (this.selectedFilter === "m") {
            this.selectedDurationTitle = "Monthly";
            this.queryString = `?duration=m`;
        } else if (this.selectedFilter === "y") {
            this.selectedDurationTitle = "Yearly";
            this.queryString = `?duration=y`;
        } else {
            this.selectedDurationTitle = "Custom";
            this.filterStartDate = document.getElementById("startDate")["value"];
            this.filterEndDate = document.getElementById("endDate")["value"];
            let splitedStartDate = this.filterStartDate.split("/");
            let splitedEndDate = this.filterEndDate.split("/");
            let startDate =
                splitedStartDate[2] +
                "-" +
                splitedStartDate[1] +
                "-" +
                splitedStartDate[0];
            let endDate =
                splitedEndDate[2] + "-" + splitedEndDate[1] + "-" + splitedEndDate[0];
            this.queryString = `?duration=c&start=${startDate}&end=${endDate}`;
        }

        this.loadCharts();
    }

    onFilterChange(event) {
        // this.selectedFilter = event.target.value;
        if (event.target.value === "c") {
            this.loadDatePickerScript();
        }
    }

    onFilterClick() {
        console.log(this.appliedDuration, this.selectedFilter);
        this.selectedFilter = this.appliedDuration || "7d";
    }

    loadDatePickerScript() {
        jQuery(document).ready(function () {
            jQuery("#startDate").daterangepicker(
                {
                    singleDatePicker: true,
                    showDropdowns: true,
                    locale: {
                        format: "DD/MM/YYYY",
                    },
                    maxDate: new Date(),
                    autoclose: true,
                    minYear: 1901,
                },
                function (start, end, label) {
                    console.log("s: ", start, "e: ", end, "l: ", label);

                    jQuery("#endDate").daterangepicker(
                        {
                            singleDatePicker: true,
                            showDropdowns: true,
                            locale: {
                                format: "DD/MM/YYYY",
                            },
                            maxDate: new Date(),
                            minDate: start,
                            autoclose: true,
                            minYear: 1901,
                        },
                        function (start, end, label) {
                            console.log(start, end, label);
                        }
                    );
                }
            );

            jQuery("#endDate").daterangepicker(
                {
                    singleDatePicker: true,
                    showDropdowns: true,
                    locale: {
                        format: "DD/MM/YYYY",
                    },
                    maxDate: new Date(),
                    minDate: new Date(),
                    autoclose: true,
                    minYear: 1901,
                },
                function (start, end, label) {
                    console.log(start, end, label);
                }
            );
        });
    }

    async loadCharts() {
        await this.productionchart();
        await this.supplychainchart();
    }


    // Weekly consumption data start
    async productionchart() {

        this.productionLoader = true;
        let globalDataSet = [];
        let labels = [];

        this.companyService
            .productionchart(this.queryString)
            .subscribe((res: any) => {

                this.productionLoader = false;
                if (res.success == 1) {
                    let data = res.data;
                    console.log("---", data);
                    if (this.selectedDurationTitle == 'Custom') {
                        for (let j = 0; j < data.length; j++) {
                            let dataSet = [];
                            for (let i = 0; i <= data[j].data.length - 1; i++) {
                                labels.push(data[0].data[i].date);

                                dataSet.push(data[j].data[i].count);
                            }
                            globalDataSet.push({
                                data: dataSet,
                                label: data[j].name,
                            })
                        }

                    } else {

                        for (let j = 0; j < data.length; j++) {
                            let dataSet = [];
                            for (let i = data[j].data.length - 1; i >= 0; i--) {
                                if (j == 0)
                                    labels.push(data[0].data[i].date);
                                if (data[j].data[i].count > 0) {
                                    dataSet.push(data[j].data[i].count);
                                } else {
                                    dataSet.push('');
                                }
                            }
                            globalDataSet.push({
                                data: dataSet,
                                label: data[j].name,
                            })
                        }
                    }

                    console.log(globalDataSet);

                    this.barChartData1 = globalDataSet;
                    this.barChartLabels1 = labels;
                } else {
                    this.productionLoader = false;
                }
            }),
            (err) => {
                console.log(err);
                this.productionLoader = false;
                this.barChartData1 = [];
                this.barChartLabels1 = [];
            };
    }
    // weekly consumption data end


    // Supply chain pie chart start
    supplychainchart() {
        this.supplyChainLoader = true;
        let dataSet = [];
        let labels = [];
        this.companyService.supplychain(this.queryString)
            .subscribe((res: any) => {
                this.supplyChainLoader = false;

                if (res.success == 1) {
                    let data = res.data;

                    for (let j = 0; j < data.length; j++) {
                        dataSet.push(data[j].count);
                        labels.push(data[j].name)
                    }

                    this.pieChartData = dataSet;
                    this.pieChartLabels = labels;
                }
            }),
            (err) => {
                this.supplyChainLoader = false;
                this.pieChartData.length = 0;
                this.pieChartLabels.length = 0;
                console.log(err);
            };
    }
    //Supply chain pie chart end
}
