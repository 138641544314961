import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../company/service/company.service';
declare var $ :any; 
import { OrderPipe } from 'ngx-order-pipe';
import { FlashMessagesService } from 'angular2-flash-messages';
import { Globals } from './../../globals';



@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {
  
  data : any;
  searchText  = "";
  sortedCollection: any[];
  order: string = 'result.id';
  reverse: boolean = false;
  result : any = [];
  arr : any = [];
  errors : any = '';
  deletedMsg: string = null;
  p =this.globals.p;
  currentLimit=this.globals.currentLimit;
  deleteId: any;
  pageLimits = this.globals.pageLimits;
  loader:boolean = false;
 

 constructor(private globals: Globals,private _flashMessagesService: FlashMessagesService,public companyService: CompanyService,private route: ActivatedRoute, private router: Router,private orderPipe: OrderPipe) {  this.sortedCollection = orderPipe.transform(this.result, 'result.id');
    console.log(this.sortedCollection); }

  ngOnInit() {
    this.categoryData();
  }


   categoryData(){
       this.loader=true;
      this.companyService.category_list().subscribe((res:any)=>{
         this.loader=false;
        console.log(res.data);
        this.result =res.data;
        
      }, (err) => {

         //this._flashMessagesService.show('Somethings Went Wrong!', { cssClass: 'alert-danger' } );

      });
    }

    categoryDelete(id){

      this.companyService.deleteCategory(id).subscribe((res:any)=>{
        console.log(res);

         this.result=res.data;

        if (res['success'] == 1 ) {
         this._flashMessagesService.show('Category deleted successfully!', { cssClass: 'alert-success' } );
         this.categoryData();
         
          setTimeout(() => {
            this.router.navigate(['/category']);
            this.categoryData();
          }, 2000); 

        } else {
          this.errors = res['message'];
          this._flashMessagesService.show(res['message'], { cssClass: 'alert-danger' } );
        }
        
      }, (err) => {

      });
    }

      setOrder(value: string) {
      if (this.order === value) {
        this.reverse = !this.reverse;
      }

      this.order = value;
    }
    
      opendeleteModal(id){
         this.deleteId = id;
        $('#exampleModal').modal('show');
        
    }

    
    endCount(p,currentLimit,reportCount){

      return Math.min((p * currentLimit), reportCount);
    }

}
