import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../../company/service/company.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import * as $ from "jquery";
import { Globals } from './../../../globals';

@Component({
  selector: 'app-addshipperqrcode',
  templateUrl: './addshipperqrcode.component.html',
  styleUrls: ['./addshipperqrcode.component.css']
})
export class AddshipperqrcodeComponent implements OnInit {

	public errors : any = '';
	public model: any = {};
	public result:any;
	public errorMessage:string="";
	public skuOptions:any;
	public batchOptions:any;
	public batchList:any;
	public skus: any;
	public skuData: any;

	numberOnly(event): boolean {

    	const charCode = (event.which) ? event.which : event.keyCode;
	    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
	      return false;
	    }
	    return true;

  	};
  	public loader:boolean = false;
  	constructor(
		private _flashMessagesService: FlashMessagesService, 
		public companyService: CompanyService,
		private route: ActivatedRoute, 
		private router: Router,
		private globals: Globals) { 
	}

	async ngOnInit() {
		this.skuOptions 	 = { placeholder: { id: '', text: 'Select SKU' } };
		this.batchOptions 	 = { placeholder: { id: '', text: 'Select Batch' } };
		await this.getSkuData();
	}

	async getSkuData(){
		await this.companyService.product_list().subscribe((res:any)=>{
			this.skuData = res.data;
			var rObj= [];
			rObj.push({"id":"","text":"select"});
			for(let i=0; i<res.data.length; i++){
				let sku = res.data[i].sku + " ("+res.data[i].name+")";
				rObj.push({"id":res.data[i].id, "text":sku});
			}			

			this.skus = rObj;
		});
	}

	setSkuId(event){
		if(event.value!="select"){
			this.model.sku_id = event.value;
			console.log(this.model.sku_id);
			//Start
			let skuId = this.model.sku_id;
			//var result = [];
			this.companyService.getSKUBatchData(skuId).subscribe((res:any)=>{
				this.result = res.data;
				console.log(this.result);
				var rObj= [];
				rObj.push({"id":"","text":""});
				for(let i=0; i<res.data.length; i++){

					rObj.push({"id":res.data[i].id, "text":res.data[i].batch_no});
				}
				this.batchList = rObj;

			}, (err) => {

			});
		}
	}

	batchlistId(event){
		if(event.value!="select"){
		 this.model.batch_id = event.value;
		 console.log(this.model.batch_id);
		}
	}

	/**
	 * [onSubmit description]
	 * @author Suresh Suthar
	 * @Created Date     2019-04-09
	 * @param   {NgForm} form       [description]
	 */
	onSubmit(form?:NgForm){

		let formData= form.value;
		console.log(formData);
		if(!this.errorMessage){
			this.loader=true;
			this.companyService.createShipperQrcode(formData).subscribe((res:any)=>{

				this.loader = false;

				if(res.success==1){

					this._flashMessagesService.show(res.message, { cssClass: 'alert-success' } );
	            
		            setTimeout(() => {
				            this.router.navigate(['/shipper_qrcode']);
				    }, 3000); 
				}
				else{

					this.errors = res.message
				}
			});
		}
	}
}
