import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../../company/service/company.service';
import { OrderPipe } from 'ngx-order-pipe';
import { NgForm } from "@angular/forms";
import { FlashMessagesService } from 'angular2-flash-messages';

@Component({
  selector: 'app-edit-productionline',
  templateUrl: './edit-productionline.component.html',
  styleUrls: ['./edit-productionline.component.css']
})
export class EditProductionlineComponent implements OnInit {
   result : any = [];
	resultList: any;
	sitedataList : any ;
	errors : any = '';
	Editid : any ;
	resultData : any;
	msg: string = null;
	public imagePath;
	imgURL: any = "/assets/images/placeholder.png";
	image: any;
	submit:boolean=false;
	public message: string;
	plantId:number;
	loader:boolean = false;

	  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

	constructor(private _flashMessagesService: FlashMessagesService,public companyService: CompanyService,private route: ActivatedRoute, private router: Router,private orderPipe: OrderPipe) {  }

  ngOnInit() {
  	this.route.params.subscribe(params => {
			console.log(params);
			this.Editid=params['id']; 
		  this.editproductionlineData();
		});
  }

    editproductionlineData(){
    	this.loader=true;
		this.companyService.productionline_edit(this.Editid).subscribe((res:any)=>{
			this.loader=false;
			this.result=res.data;
			this.plantId = res.data.plant_id;
			console.log(this.plantId);
			this.plantData(this.plantId);

		}, (err) => {

		});
	}

	onSubmit(form?:NgForm) {
		let f = form.value;
		 f.plant_id=this.result.plant_id;   
		
		this.companyService.productionline_update(this.Editid,f).subscribe((res: any)=>{	
			this.resultData = res;
			if (this.resultData.success == 1 ) {
				 this.submit=true;  
				  this._flashMessagesService.show('Line updated successfully', { cssClass: 'alert-success' } );

				setTimeout(() => {
					this.router.navigate(['/line']);
				}, 1000); 
			} else {
				this.errors = res.message;
				setTimeout(() => {
                 this.errors.productionLine_id = false;
               }, 3000);
			}

		}, (err) => {

			
		});

	}
	 async plantData(plantId){
      var result = [];
     await  this.companyService.production_list().subscribe((res:any)=>{
        result = res.data;
        console.log(result);
       this.resultList = result.map(function(obj) {
              var rObj = {};
              rObj['id'] = obj.id;
              rObj['text'] = obj.plant_name;
              rObj['selected'] = (obj.id==plantId) ? true : false;
              return rObj;
          });
        console.log(this.resultList);
      }, (err) => {

      });
    }
     plantChanged(event){
      console.log(event);
      this.result.plant_id=event.value;
   }

}
