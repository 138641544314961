import { Component, OnInit, Renderer2, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { CompanyService } from '../../../company/service/company.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Globals } from './../../../globals';

@Component({
  selector: "app-gift-claim",
  templateUrl: "./gift-claim.component.html",
  styleUrls: ["./gift-claim.component.css"]
})
export class GiftClaimComponent implements OnInit {
  pendingRequestList = [];
  registerForm: FormGroup;
  submitted = false;

  p = this.globals.p;
  currentLimit = this.globals.currentLimit;
  pageLimits = this.globals.pageLimits;
  order: string = 'rewardList.createdAt';
  reverse: boolean = true;
  searchText: string = '';
  loader: boolean = false;

  public requestAcceptopoverTitle = "Confirm";
  public requestAcceptPopoverMessage = "Do you want to accept request?";
  public requestAcceptConfirmClicked = false;
  public requestAcceptCancelClicked = false;

  selectedRecord: any;
  reasonRequired = false;

  constructor(
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    private formBuilder: FormBuilder,
    private apiService: CompanyService,
    private globals: Globals,
    public toastr: ToastrService,
  ) {
  }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      remark: ["", Validators.required]
    });
    this.loadPendingRequestList();
  }

  get f() {
    return this.registerForm.controls;
  }

  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }

  onResetRemarks() {
    this.registerForm.reset();
    this.submitted = false;
  }

  onRequestReject() {
    let reason = document.getElementById("txt-reject-reason");
    reason = reason["value"];
    if (!reason) {
      this.reasonRequired = true;
    } else {
      this.reasonRequired = false;
      this.upadteRequestStatus(this.selectedRecord.id, 2, reason);
    }
  }

  onRequestAccept() {
    this.upadteRequestStatus(this.selectedRecord.id, 1, null, this.registerForm.value.remark);
  }

  showToast(toastType, toastCaption, toastText) {
    if (toastType == "danger") {
      if (toastText)
        this.toastr.error(toastText, toastCaption);
      else
        this.toastr.error(toastCaption, toastText);
    } else {
      this.toastr.success(toastCaption);
    }
    /*this.toaster.open({
      text: toastText,
      caption: toastCaption,
      type: toastType,
      position: "top-right",
      component: CustomToasterComponent
    });*/
  }

  upadteRequestStatus(id, status, reason = null, remark = null) {
    this.loader = true;
    this.apiService
      .rewardsHistoryUpdate(id, { is_verified: status, verify_comments: reason, remarks: remark })
      .subscribe(
        (response: any) => {
          if (response.success == 1) {
            this.loader = false;
            this.showToast(
              "success",
              `Success - Request ${status == 1 ? "Approved" : "Rejected"}`,
              `Request has been ${status == 1 ? "approved" : "rejected"
              } successfully!`
            );

            if (status == 2) {
              document.getElementById('request-approval-modal').click();
            } else {
              document.getElementById('request-remarks-modal').click();
            }

            //window.location.reload();
            this.loadPendingRequestList();
          } else {
            this.loader = false;
            if (status == 2) {
              document.getElementById('request-approval-modal').click();
            } else {
              document.getElementById('request-remarks-modal').click();
            }
            this.showToast('danger', 'Failed - Request Approval', response.message.toString())
          }
        },
        err => {
          console.error(err);
          this.showToast('danger', 'Failed - Request Approval', 'Due to some reason you cannot take action now!')
        }
      );
  }

  loadDataTableScript(id) {
    let script = this._renderer2.createElement("script");
    script.type = `text/javascript`;
    script.text = `
    $(document).ready(function() {
      
        $('#${id}').DataTable({ 
          searching: true,
          paging: true,
          destroy: true,    
          retrive: true                  
        });
      });
    `;

    this._renderer2.appendChild(this._document.body, script);
  }

  onSelectedRow(obj) {
    this.selectedRecord = obj;
    this.reasonRequired = false;
  }

  loadPendingRequestList() {
    this.loader = true;
    this.apiService.rewardhistory().subscribe(
      (response: any) => {
        if (response.success == 1) {
          this.loader = false;
          // this.pendingRequestList = response.body.data;
          this.pendingRequestList = response.data.filter(item => {
            return item.is_verified == 0;
          });

          //this.loadDataTableScript("dataTables-example");
        }
      },
      err => {
        console.error(err);
      }
    );
  }
}
