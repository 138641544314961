import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { BusinessService } from '../../service/business.service';
import { FlashMessagesService } from 'angular2-flash-messages';

@Component({
  selector: 'app-add-role',
  templateUrl: './add-role.component.html',
  styleUrls: ['./add-role.component.css']
})
export class AddRoleComponent implements OnInit {

    resultList: any;
    resultRole: any;
    msg: string = null;
    errors: any = '';
    model: any = {};
    public imagePath;
    message: any;
    filedata: any;
    submit:boolean=false;
    roleOptions:any;
    result:any;


    constructor(private _flashMessagesService: FlashMessagesService, public businessService: BusinessService, private route: ActivatedRoute, private router: Router) { }

    ngOnInit() {
        this.roleOptions    = { multiple: true,placeholder: { id: '', text: 'Select Parent Role' } };
        this.roleData();
    }

    onSubmit(form?: NgForm) {
        
        let f = form.value;
        console.log(f);
       // return false;

        if(f.businessrole_parent_ids == 'select' || f.businessrole_parent_ids == ""){
         f.businessrole_parent_ids= "0";
        }   
         console.log(f);
         // return false;
        this.businessService.businessRole_add(f).subscribe((res: any) => {
            console.log(res);
            this.resultRole = res;

            if (this.resultRole.success == 1) {
                this.submit=true; 
                this._flashMessagesService.show(' Role created successfully', { cssClass: 'alert-success' });
                setTimeout(() => {
                    this.router.navigate(['/role']);
                }, 2000);

            } else {
                this.errors = res.message;
                 setTimeout(() => {
                this.errors.businessrole_id = false;
              }, 3000); 

            }

        }, (err) => {

        });
    }

     async  roleData() {
        var result = [];
        await this.businessService.businessRole_list().subscribe((res: any) => {
            this.result = res.data;
            var rObj= [];
            rObj.push({"id":"","text":"select"});
            for(let i=0; i<res.data.length; i++){

                rObj.push({"id":res.data[i].id, "text":res.data[i].name});
            }      
            this.resultList = rObj;

            }, (err) => {

            });
    }
     roleChanged(event) {
        console.log(event);
        this.model.businessrole_parent_ids = event.value.join();
        //let parentString=this.model.businessrole_parent_id.join();
        console.log(this.model.businessrole_parent_ids);
     }
}
