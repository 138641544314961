import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../../../company/service/company.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import * as $ from "jquery";
import { OrderPipe } from 'ngx-order-pipe';

@Component({
  selector: 'app-add-companyusers',
  templateUrl: './add-companyusers.component.html',
  styleUrls: ['./add-companyusers.component.css']
})
export class AddCompanyusersComponent implements OnInit {
   resultList: any;
    result: any;
    msg: string = null;
    errors: any = '';
    model: any = {};
    roleOptions:any;
    submit:boolean=false;
    plantOptions:any;
    numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;

    }

   constructor(private _flashMessagesService: FlashMessagesService, public companyService: CompanyService,private route: ActivatedRoute, private router: Router) { }


  ngOnInit() {
  	 this.roleOptions         = { placeholder: { id: '', text: 'Select Role' } };
  	 this.roleData();
  }

  onSubmit(form?: NgForm) {

        let f = form.value;
        console.log(f);

        this.companyService.companyuser_add(f).subscribe((res: any) => {
            console.log(res);

            this.result = res;

            if (this.result.success == 1) {
                this.submit=true;   
                this._flashMessagesService.show('User created successfully', { cssClass: 'alert-success' });

                setTimeout(() => {
                    this.router.navigate(['/companyuser']);
                }, 3000);

            } else {
                this.errors = res.message;
                   setTimeout(() => {
                    this.errors.employee_id = false;
                      }, 3000); 
              }
        }, (err) => {
        });

    }

     async  roleData() {
        var result = [];
        await this.companyService.companyrole_list().subscribe((res: any) => {
            this.result = res.data;
            var rObj= [];
            rObj.push({"id":"","text":"select"});
            for(let i=0; i<res.data.length; i++){

                rObj.push({"id":res.data[i].id, "text":res.data[i].name});
            }      
            this.resultList = rObj;
            }, (err) => {

            });
    }

    roleChanged(event) {
       if(event.value!='select'){
        console.log(event);
        this.model.role_id = event.value;
       }
     }

}
