import { Component, OnInit } from '@angular/core';

import { AdminService } from '../../service/admin.service';
import { Globals } from './../../globals';


@Component({
  selector: 'app-customerlist',
  templateUrl: './customerlist.component.html',
  styleUrls: ['./customerlist.component.css']
})
export class CustomerlistComponent implements OnInit {
	 data : any;
	  searchText  = "";
	  sortedCollection: any[];
	  order: string = 'data.id';
	  reverse: boolean = false;
	  arr : { [id: string]: any; } =[];
	  errors : any = '';
	  deletedMsg: string = null;
	  p =this.globals.p;
      currentLimit=this.globals.currentLimit;
      pageLimits = this.globals.pageLimits;

	result : any = [];
	loader:boolean = false;

	constructor(private globals: Globals,public adminService: AdminService) { }

	ngOnInit() {

		this.cutomerList();
	}

	cutomerList(){
		this.loader=true;
	  console.log("companyList");
	  this.adminService.customer_list().subscribe((res:any)=>{
	   this.loader=false;
	    console.log("res.data");
	    console.log(res.data);
	    this.result=res.data;
	    console.log(this.result);
	    
	  }, (err) => {
	      console.log(err);
	  });
	}

	setOrder(value: string) {
      if (this.order === value) {
        this.reverse = !this.reverse;
      }

      this.order = value;
    }

}


