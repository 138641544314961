import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../../company/service/company.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import * as $ from "jquery";
import { Globals } from './../../../globals';

@Component({
  selector: 'app-addqrcode',
  templateUrl: './addqrcode.component.html',
  styleUrls: ['./addqrcode.component.css']
})
export class AddqrcodeComponent implements OnInit {

	// public errors : any = '';
	public model: any = {};
	public result:any;
	public skuOptions:any;
	public skus: any;
	public skuData: any;
	public errorMessage:string="";
	numberOnly(event): boolean {

    	const charCode = (event.which) ? event.which : event.keyCode;
	    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
	      return false;
	    }
	    return true;

  	};
  	public loader:boolean = false;

	constructor(
		private _flashMessagesService: FlashMessagesService, 
		public companyService: CompanyService,
		private route: ActivatedRoute, 
		private router: Router,
		private globals: Globals) { 
	}

	/**
	 * [ngOnInit description]
	 * @author Suresh Suthar
	 * @Created Date 2019-04-04
	 */
	async ngOnInit() {

		this.skuOptions 	 = { placeholder: { id: '', text: 'Select SKU' } };
		await this.getSkuData();
	}

	/**
	 * [getSkuData description]
	 * @author Suresh Suthar
	 * @Created Date 2019-04-04
	 */
	async getSkuData(){

		await this.companyService.product_list().subscribe((res:any)=>{

			this.skuData = res.data;
			var rObj= [];
			rObj.push({"id":"","text":"select"});
			for(let i=0; i<res.data.length; i++){
				let sku = res.data[i].sku + " ("+res.data[i].name+")";
				rObj.push({"id":res.data[i].id, "text":sku});
			}			

			this.skus = rObj;
		});
	}

	/**
	 * [calculateRoleCount description]
	 * @author Suresh Suthar
	 * @Created Date 2019-04-04
	 */
	calculateRoleCount(event){

		if(this.model.qr_code_per_role>0 && this.model.total_qr_code>0){
			
			let remainingQrcode = this.model.total_qr_code%this.model.qr_code_per_role;
			this.errorMessage = (remainingQrcode>0)? "Total Qr code should be multiple of total role" : "";
		}
	}

	setSkuId(event){

		//this.errors.sku_id="";
		if(event.value!="select"){

			this.model.sku_id = event.value;
		}
	}
	/**
	 * [onSubmit description]
	 * @author Suresh Suthar
	 * @Created Date     2019-04-04
	 * @param   {NgForm} form       [description]
	 */
	onSubmit(form?:NgForm){

		let formData= form.value;
		formData.sku_id = this.model.sku_id;
		formData.qr_code_per_role = this.model.total_qr_code;
		formData.percentage_error = this.model.percentage_error;
		formData.invoice_no = this.model.invoice_no || 0;
		// console.log(formData);
		// return false;
		if(!this.errorMessage){
			this.loader=true;
			this.companyService.createSKUQrcode(formData).subscribe((res:any)=>{

				this.loader = false;
				this.result = res.data;

				if(res.success==1){

					this._flashMessagesService.show(res.message, { cssClass: 'alert-success' } );
	            
		            setTimeout(() => {
				            this.router.navigate(['/qrcode']);
				    }, 3000); 
				}
				else{

					this.errorMessage = res.message
					setTimeout(() => {
						this.errorMessage = null;
					}, 6000);
				}
			}, (err) => {
				this.loader=false;
			});
		}
	}
}
