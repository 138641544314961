import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../../company/service/company.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import * as $ from "jquery";

@Component({
  selector: 'app-editbatch',
  templateUrl: './editbatch.component.html',
  styleUrls: ['./editbatch.component.css']
})
export class EditbatchComponent implements OnInit {

  public skuData: any;
	public skus: any;
	public plantData:any;
	public plants:any;
	public model: any = {};
	public productDetails: any;
	public plantLineData:any;
	public plantlines:any;
	public msg: string = null;
    public errors : any = '';
    public submit:boolean=false;
    public batchData = {

    	name : "",
    	manufacturing_date:new Date(),
    	expiry_date : new Date(),
		batch_no : "",
		product_mrp : "",
		brand_id : "",
		varient_id : "",
		product_id : "",
		production_site_id : "",
		production_line_id : "",
		batch_size : "",
		printed_code : ""
    };
    public options:any;
    public loader:boolean = false;
    public minDate:object;

  	constructor(
  			private _flashMessagesService: FlashMessagesService, 
  			public companyService: CompanyService,
  			private route: ActivatedRoute, 
  			private router: Router) {
  	}
	

	async ngOnInit() {
		this.options = { placeholder: { id: '', text: 'Select an option' } };

		let batchId = this.route.snapshot.paramMap.get("id");
		await this.companyService.getBatchDetails(batchId).subscribe((res:any)=>{

			this.model = res.data;
			let mfgDate = new Date(res.data.manufacturing_date);
			this.model.mfg_date = {year:mfgDate.getFullYear(),month:mfgDate.getMonth(),day:mfgDate.getDate()};
			console.log(this.model);
			let expDate = new Date(res.data.expiry_date);
			this.model.exp_date = {year:expDate.getFullYear(),month:expDate.getMonth(),day:expDate.getDate()};
			let skuId = res.data.product_id;
			let plantId = res.data.production_site_id;
			this.getSkuData(skuId);
			this.getPlantName(plantId);
		});
	}

	/**
	 * [getSkuData description]
	 * @author Suresh Suthar
	 * @Created Date 2019-03-21
	 */
	async getSkuData(skuId){

		await this.companyService.product_list().subscribe((res:any)=>{

			this.skuData = res.data;

			this.skus = res.data.map(function(obj,index){

				var rObj = {};
	            rObj['id'] = obj.id;
	            rObj['text'] = obj.sku;
	            rObj['selected'] = (obj.id == skuId) ? true : false;
	            return rObj;
			});		
			console.log(this.skus);	
		});
	}

	/**
	 * [getProductDetails description]
	 * @author Suresh Suthar
	 * @Created Date     2019-03-21
	 * @param   {[type]} event      [description]
	 */
	getProductDetails(event){

		this.productDetails = this.skuData.filter(function(obj){

			return obj.id === event.value;
		});

		this.model.sku_id=this.productDetails[0].id;
		this.model.brand_name=this.productDetails[0].brand.brand_name;
		this.model.category_name=this.productDetails[0].category.category_name;
		this.model.variants_name=(this.productDetails[0].varient.varient_name)?this.productDetails[0].varient.varient_name :"";

		this.model.brand_id=this.productDetails[0].brand_id;
		this.model.category=this.productDetails[0].category_id;
		this.model.varient_id=this.productDetails[0].varient_id;
		
	}

	/**
	 * [getPlantName description]
	 * @author Suresh Suthar
	 * @Created Date 2019-03-21
	 */
	async getPlantName(plantId){

		await this.companyService.production_list().subscribe((res:any)=>{

			this.plantData = res.data;

			this.plants = res.data.map(function(obj){

				var rObj = {};
	            rObj['id'] = obj.id;
	            rObj['text'] = obj.site_name;
	            rObj['selected'] = (obj.id == plantId) ? true : false;
	            return rObj;
			});
		});
	}

	/**
	 * [getPlaneLines description]
	 * @author Suresh Suthar
	 * @Created Date 2019-03-21
	 */
	async getPlaneLines(event){

		let plantId = event.value;
		this.model.plant_id = plantId;
		await this.companyService.getPlantLine(plantId).subscribe((res:any)=>{

			this.plantLineData = res.data;
			this.plantlines = res.data.map(function(obj){

				var rObj = {};
				rObj['id'] = obj.id;
				rObj['text'] = obj.line_name;
				return  rObj;
			});
			this.model.plant_line_id = this.plantLineData[0].id;
		});
	}

	/**
	 * [onSubmit description]
	 * @author Suresh Suthar
	 * @Created Date 2019-03-22
	 */
	onSubmit(form?:NgForm){

		let batchId = this.route.snapshot.paramMap.get("id");
		this.loader=true;
		let f= form.value;
		console.log(f);
		let mfgDate = f.mfg_date.year + "-" + f.mfg_date.month + "-" + f.mfg_date.day; 
		let expDate = f.exp_date.year + "-" + f.exp_date.month + "-" + f.exp_date.day;
		console.log(mfgDate); 
		this.batchData.name = 'Batch1';
		this.batchData.manufacturing_date = new Date(mfgDate);
		this.batchData.expiry_date = new Date(expDate);
		this.batchData.batch_no = 'B1';
		this.batchData.product_mrp = this.model.product_mrp;
		this.batchData.brand_id = this.model.brand_id;
		this.batchData.varient_id = this.model.varient_id;
		this.batchData.product_id = f.sku_id;
		this.batchData.production_site_id = f.plant_id;
		this.batchData.production_line_id = f.plant_line_id;
		this.batchData.batch_size = f.batch_size;
		this.batchData.printed_code = "123";
		
		console.log(this.batchData);
		this.companyService.update_batch(batchId,this.batchData).subscribe((res:any)=>{
	       
	       	this.loader = false;
	        if (res.success == 1 ) {
	        	  this.submit=true; 
	              this._flashMessagesService.show(res.message, { cssClass: 'alert-success' } );
	            
	              setTimeout(() => {
			            this.router.navigate(['/batch']);
			      }, 3000); 

	       	} 
	       	else {
	         	this.errors = res.message;
	         	console.log(this.errors.varient_id.message);
	       	}
	      }, (err) => {
	    });

	}

	/**
	 * [setExpiryMinDate description]
	 * @author Suresh Suthar
	 * @Created Date 2019-03-29
	 */
	setExpiryMinDate(event){

		this.model.exp_date = "";
		this.minDate = event;
		console.log(event);
	}

}
