import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { BusinessService } from "../../service/business.service";
import { OrderPipe } from "ngx-order-pipe";
import { FlashMessagesService } from "angular2-flash-messages";
import { Globals } from "./../../../../globals";
import { ApiService } from "src/app/service/api/api.service";
declare var jQuery: any;
declare var moment: any;

@Component({
  selector: "app-list-consignment",
  templateUrl: "./list-consignment.component.html",
  styleUrls: ["./list-consignment.component.css"],
})
export class ListConsignmentComponent implements OnInit {
  data: any;
  searchText = "";
  sortedCollection: any[];
  order: string = "result.id";
  reverse: boolean = false;
  result: any = [];
  resultList: any;
  toList: any;
  arr: any = [];
  errors: any = "";
  deletedMsg: string = null;
  p = this.globals.p;
  currentLimit = this.globals.currentLimit;
  deleteId: any;
  pageLimits = this.globals.pageLimits;
  loader: boolean = false;
  minDate: any;
  submit: boolean = false;
  public model: any = {};
  public model1: any = {};
  fromOptions: any;
  toOptions: any;
  selectedFromUser: any;
  selectedToUser: any;

  constructor(
    private globals: Globals,
    private _flashMessagesService: FlashMessagesService,
    public businessService: BusinessService,
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    private orderPipe: OrderPipe
  ) {
    this.sortedCollection = orderPipe.transform(this.result, "result.id");
    console.log(this.sortedCollection);
  }

  ngOnInit() {
    this.ConsignmentData();
    this.fromOptions = { placeholder: { id: "", text: "Select From User" } };
    this.toOptions = { placeholder: { id: "", text: "Select To User" } };
    this.fromData();
    this.toData();
    this.loadDatePickerScript();
  }

  ConsignmentData() {
    this.loader = true;
    this.businessService.getBusinessConsignment(0, 25).subscribe(
      (res: any) => {
        this.loader = false;
        console.log(res.data);
        this.result = res.data;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  async downloadInvoice(id, ino) {
    this.loader = true;
    this.apiService
      .get(`consignment/invoice/download?id=${id}&ino=${ino}`, true, true)
      .subscribe(
        (response: any) => {
          this.loader = false;
          console.log("response::", response);
          if (response.status === 200) {
            console.log(response.headers.get("Content-disposition"));
            var contentDispositionHeader = response.headers.get(
              "Content-Disposition"
            );
            console.log("response->", response);
            console.log("contentDispositionHeader->", contentDispositionHeader);
            let fileName = contentDispositionHeader.split("=")[1];
            this.downloadPDF(response.body, "application/pdf", fileName);
          } else {
            this._flashMessagesService.show("PDF not found!", {
              cssClass: "alert-danger",
              timeout: 6000,
            });
          }
        },
        (err) => {
          console.error(err);
          this._flashMessagesService.show(
            "Due to issue in server you cannot download pdf!",
            {
              cssClass: "alert-danger",
              timeout: 6000,
            }
          );
          this.loader = false;
        }
      );
  }

  downloadPDF(data: any, type: string, filename) {
    console.log("download pdf process start");
    let blob = new Blob([data], { type: type });
    let url = window.URL.createObjectURL(blob);
    var fileLink = document.createElement("a");
    fileLink.href = url;

    fileLink.download = filename;
    fileLink.click();

    window.URL.revokeObjectURL(url);
    this.loader = false;
  }

  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    this.order = value;
  }

  selectedDate(model) {
    // if (!model.from_date) {
    //   let startDate =
    //     model.to_date.year +
    //     "-" +
    //     model.to_date.month +
    //     "-" +
    //     model.to_date.day;
    //   let endDate =
    //     model.to_date.year +
    //     "-" +
    //     model.to_date.month +
    //     "-" +
    //     model.to_date.day;
    //   this.model.from_date = startDate;
    //   this.model.to_date = startDate;
    // } else if (!model.to_date) {
    //   let endDate =
    //     model.from_date.year +
    //     "-" +
    //     model.from_date.month +
    //     "-" +
    //     model.from_date.day;
    //   let startDate =
    //     model.from_date.year +
    //     "-" +
    //     model.from_date.month +
    //     "-" +
    //     model.from_date.day;
    //   this.model.to_date = endDate;
    //   this.model.from_date = endDate;
    // } else {
    //   let startDate =
    //     model.from_date.year +
    //     "-" +
    //     model.from_date.month +
    //     "-" +
    //     model.from_date.day;
    //   let endDate =
    //     model.to_date.year +
    //     "-" +
    //     model.to_date.month +
    //     "-" +
    //     model.to_date.day;
    //   this.model.from_date = startDate;
    //   this.model.to_date = endDate;
    // }

    let filterStartDate = document.getElementById("startDate")["value"];
    let filterEndDate = document.getElementById("endDate")["value"];

    let startDate, endDate;
    if (filterStartDate && filterEndDate) {
      let splitedStartDate = filterStartDate.split("/");
      let splitedEndDate = filterEndDate.split("/");
      startDate =
        splitedStartDate[2] +
        "-" +
        splitedStartDate[1] +
        "-" +
        splitedStartDate[0];
      endDate =
        splitedEndDate[2] + "-" + splitedEndDate[1] + "-" + splitedEndDate[0];
    } else {
      startDate =
        new Date().getFullYear() +
        "-" +
        (new Date().getMonth() + 1) +
        "-" +
        new Date().getDate();
      endDate = startDate;
    }

    this.model.from_date = startDate;
    this.model.to_date = endDate;

    console.log(this.model);
    this.businessService.searchConsignment(0, 25, this.model).subscribe(
      (res: any) => {
        console.log(res);
        this.result = res.data;
        console.log(this.result);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  onResetFilter(){
    //   this.ngOnInit();
      window.location.reload();      
    // jQuery("#startDate").data('daterangepicker').startDate = new Date();
  }

  loadDatePickerScript() {
    jQuery(document).ready(function () {
      jQuery("#startDate").daterangepicker(
        {
          singleDatePicker: true,
          showDropdowns: true,
          locale: {
            format: "DD/MM/YYYY",
          },
          maxDate: new Date(),
          autoclose: true,
          minYear: 1901,
        },
        function (start, end, label) {
          console.log("s: ", start, "e: ", end, "l: ", label);

          jQuery("#endDate").daterangepicker(
            {
              singleDatePicker: true,
              showDropdowns: true,
              locale: {
                format: "DD/MM/YYYY",
              },
              maxDate: new Date(),
              minDate: start,
              autoclose: true,
              minYear: 1901,
            },
            function (start, end, label) {
              console.log(start, end, label);
            }
          );
        }
      );

      jQuery("#endDate").daterangepicker(
        {
          singleDatePicker: true,
          showDropdowns: true,
          locale: {
            format: "DD/MM/YYYY",
          },
          maxDate: new Date(),
          minDate: new Date(),
          autoclose: true,
          minYear: 1901,
        },
        function (start, end, label) {
          console.log(start, end, label);
        }
      );
    });
  }

  setExpiryMinDate(event) {
    this.model.to_date = "";
    this.minDate = event;
    console.log(event);
  }
  // From user data
  async fromData() {
    await this.businessService.getBusinessConsignment(0, 25).subscribe(
      (res: any) => {
        this.result = res.data;
        this.resultList = [];
        var rObj = [];
        let checker = [];
        rObj.push({ id: "null", text: "ALL" });
        for (let i = 0; i < res.data.length; i++) {
          if (!checker.includes(res.data[i].from_id)) {
            rObj.push({
              id: res.data[i].from_id,
              text: res.data[i].from_user_name,
            });
            checker.push(res.data[i].from_id);
          }
        }
        this.resultList = rObj;
        console.log(this.resultList);
      },
      (err) => {}
    );
  }
  fromChanged(event) {
    console.log(this.model.from_id);
    // if (event.value != "select") {
    console.log(event);
    if (event.value != "null") {
      this.model.from_id = event.value;
      this.selectedFromUser = event.value;
    } else {
      this.selectedFromUser = null;
    }
    // }
  }
  //From user finish

  //to user start
  async toData() {
    await this.businessService.getBusinessConsignment(0, 25).subscribe(
      (res: any) => {
        this.result = res.data;
        var rObj = [];
        rObj.push({ id: "null", text: "ALL" });
        let checker = [];
        for (let i = 0; i < res.data.length; i++) {
          if (!checker.includes(res.data[i].from_id)) {
            rObj.push({
              id: res.data[i].to_id,
              text: res.data[i].to_user_name,
            });
            checker.push(res.data[i].from_id);
          }
        }
        this.toList = rObj;
        console.log(this.toList);
      },
      (err) => {}
    );
  }
  toChanged(event) {
    // if (event.value != "select") {
    console.log(event);
    if (event.value != "null") {
      this.model.to_id = event.value;
      this.selectedToUser = event.value;
    } else {
      this.selectedToUser = null;
    }
  }
  //to user finish

  selecteduser(model1) {
    console.log(model1);
    if (model1.from_id != undefined && model1.to_id == undefined) {
      console.log("to id null");
      this.model1.from_id = model1.from_id;
      //this.model.to_id=model.from_id;
    } else if (model1.to_id != undefined && model1.from_id == undefined) {
      console.log("from id null");
      this.model.to_id = model1.to_id;
      //this.model.from_id=model.to_id;
    } else {
      this.model.from_id = model1.from_id;
      this.model.to_id = model1.to_id;
    }
    console.log(this.model1);
    this.businessService.searchConsignment(0, 25, this.model1).subscribe(
      (res: any) => {
        console.log(res);
        this.result = res.data;
        console.log(this.result);
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
