import { Component, OnInit } from "@angular/core";
import { ApiService } from "src/app/service/api/api.service";
import { Globals } from "src/app/globals";
import { OrderPipe } from "ngx-order-pipe";
declare var jQuery: any;
declare var $: any;
import {
  ChartDataSets,
  ChartType,
  RadialChartOptions,
  ChartOptions,
} from "chart.js";
import { Label } from "ng2-charts";
import * as pluginDataLabels from "chartjs-plugin-datalabels";
import { SwiperConfigInterface } from "ngx-swiper-wrapper";
import { Config } from '../../config';

@Component({
  selector: "app-counterfeit-dashboard",
  templateUrl: "./counterfeit-dashboard.component.html",
  styleUrls: ["./counterfeit-dashboard.component.css"],
})
export class CounterfeitDashboardComponent implements OnInit {
  complaintReportData = [];
  complaintReportType = "Complaints";

  public complaintChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      datalabels: {
        anchor: "end",
        align: "end",
      },
    },
  };
  public complaintChartLabels: Label[] = [];
  public complaintChartType: ChartType = "bar";
  public complaintLegends = true;
  public complaintChartPlugin = [pluginDataLabels];

  storeSelect;
  selectedMobileNumber;

  storeLoader = false;

  public images = [];

  public config: SwiperConfigInterface = {
    a11y: true,
    direction: 'horizontal',
    slidesPerView: 1,
    keyboard: true,
    mousewheel: true,
    allowTouchMove: true,
    scrollbar: false,
    pagination: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: true
    },    
    loop: true,
    // speed: 1000,
    navigation: true
  };

  // public complaintChartColors: Array<any> = [
  //   { // first color
  //     backgroundColor: 'rgba(225,10,24,0.2)',
  //     borderColor: 'rgba(225,10,24,0.2)',
  //     pointBackgroundColor: 'rgba(225,10,24,0.2)',
  //     pointBorderColor: '#fff',
  //     pointHoverBackgroundColor: '#fff',
  //     pointHoverBorderColor: 'rgba(225,10,24,0.2)'
  //   },
  //   { // second color
  //     backgroundColor: 'rgba(225,10,24,0.2)',
  //     borderColor: 'rgba(225,10,24,0.2)',
  //     pointBackgroundColor: 'rgba(225,10,24,0.2)',
  //     pointBorderColor: '#fff',
  //     pointHoverBackgroundColor: '#fff',
  //     pointHoverBorderColor: 'rgba(225,10,24,0.2)'
  //   }];

  public complaintChartDataSet: ChartDataSets[] = [
    {
      data: [],
      label: "Complaint",
      borderColor: "#e625ea",
      backgroundColor: "#e625ea90",
      hoverBackgroundColor: "#e625ea95",
      hoverBorderColor: "#e625ea99",
    },
    // {
    //   data: [],
    //   label: "Multiple Verified",
    //   borderColor: "#8226f5",
    //   backgroundColor: "#8226f590",
    //   hoverBackgroundColor: "#8226f595",
    //   hoverBorderColor: "#8226f599",
    // },
  ];

  public radarChartOptions: RadialChartOptions = {
    responsive: true,
  };
  public radarChartLabels: Label[] = [];

  public chartColors: Array<any> = [
    {
      // all colors in order
      backgroundColor: ["#c300ff"],
    },
  ];

  public radarChartData: ChartDataSets[] = [];
  public radarChartType: ChartType = "polarArea";

  private polarColors = [
    {
      backgroundColor: ["#8226f590", "#e625ea90", "#007bff90", "#00d0ff90"],
    },
  ];

  lat = 43.879078;
  lng = -103.4615581;
  selectedMarker;
  displayReport: any = {
    status: false,
    sameCodeDiffCity: false,
    sameNoDiffCode: false,
    sameLocationDiffCode: false,
    complaints: false,
  };
  selectedSameNo = null;
  selectedCodeIndex = 0;
  selectedCityIndex = 0;

  order: string = "createdAt";
  reverse: boolean = false;
  result = {};

  mobileNumber = [];

  p = this.globals.p;
  currentLimit = this.globals.currentLimit;
  pageLimits = this.globals.pageLimits;

  loader: boolean = false;

  showSameNumberLoader = false;

  selectedDurationTitle = "Weekly";
  appliedDuration;
  filterStartDate;
  filterEndDate;
  selectedFilter = "7d";
  queryString = "?duration=7d";

  markers = {
    sameCodeDiffCity: [],
    sameNoDiffCode: [],
    multipleCodeFromStore: []
  };
  selectedCity: any;
  showSameCodeDiffCityLoader: boolean = false;
  sameCodeDiffCityResult: any = [];
  sameLocationDiffCodeLoader: boolean;
  sameLocationDiffCodeResult: any = [];
  complaintLoader: boolean = false;
  complaintResult: any = [];

  storeData = [];
  storeNames = [];
  storeReportData = [];

  constructor(
    private apiService: ApiService,
    private orderPipe: OrderPipe,
    private globals: Globals
  ) {
    orderPipe.transform(this.result, this.order);

    this.displayReport = {
      status: false,
      sameCodeDiffCity: false,
      sameNoDiffCode: false,
      sameLocationDiffCode: false,
      complaints: false,
    };
  }

  ngOnInit() {
    this.loadCharts();
    this.loadSliderScript();
  }

  onSetComplainImage(frontImg, backImg, purchaseImg) {
    this.images.length = 0;

    this.images = [
      { src: Config.API_LOGIN_URL + 'reports/' + frontImg, caption: "Front Image" },
      { src: Config.API_LOGIN_URL + 'reports/' + backImg, caption: "Back Image" },
      { src: Config.API_LOGIN_URL + 'reports/' + purchaseImg, caption: "Purchase Bill Image" }
    ]
  }


  loadSliderScript() {
    $('span.next .slider1').click(function () {
      let current = $(this).siblings('img.active');
      let next = current.next('img');
      if (next.length != 0) {
        current.removeClass('active');
        next.addClass('active');
      }
    })
    $('span.prev .slider1').click(function () {
      let current = $(this).siblings('img.active');
      let prev = current.prev('img');
      if (prev.length != 0) {
        current.removeClass('active');
        prev.addClass('active');
      }
    })
  }

  loadDatePickerScript() {
    jQuery(document).ready(function () {
      jQuery("#startDate").daterangepicker(
        {
          singleDatePicker: true,
          showDropdowns: true,
          locale: {
            format: "DD/MM/YYYY",
          },
          maxDate: new Date(),
          autoclose: true,
          minYear: 1901,
        },
        function (start, end, label) {
          console.log("s: ", start, "e: ", end, "l: ", label);

          jQuery("#endDate").daterangepicker(
            {
              singleDatePicker: true,
              showDropdowns: true,
              locale: {
                format: "DD/MM/YYYY",
              },
              maxDate: new Date(),
              minDate: start,
              autoclose: true,
              minYear: 1901,
            },
            function (start, end, label) {
              console.log(start, end, label);
            }
          );
        }
      );

      jQuery("#endDate").daterangepicker(
        {
          singleDatePicker: true,
          showDropdowns: true,
          locale: {
            format: "DD/MM/YYYY",
          },
          maxDate: new Date(),
          minDate: new Date(),
          autoclose: true,
          minYear: 1901,
        },
        function (start, end, label) {
          console.log(start, end, label);
        }
      );
    });
  }

  onFilterChange(event) {
    if (event.target.value === "c") {
      this.loadDatePickerScript();
    }
  }

  onChangeCodeIndex(v) {
    console.log(this.sameCodeDiffCityResult);
    this.selectedCodeIndex = v;
    console.log(this.selectedCodeIndex)
  }

  onChartClick(v) {
    console.log(v);
    this.selectedCityIndex = v.active[0]._index;
    this.viewReport("sameLocationDiffCode");
  }

  onFilterClick() {
    console.log(this.appliedDuration, this.selectedFilter);
    this.selectedFilter = this.appliedDuration || "7d";
  }

  onApplyFilter() {
    this.appliedDuration = this.selectedFilter;

    if (this.selectedFilter === "7d") {
      this.selectedDurationTitle = "Weekly";
      this.queryString = `?duration=7d`;
    } else if (this.selectedFilter === "m") {
      this.selectedDurationTitle = "Monthly";
      this.queryString = `?duration=m`;
    } else if (this.selectedFilter === "y") {
      this.selectedDurationTitle = "Yearly";
      this.queryString = `?duration=y`;
    } else {
      this.selectedDurationTitle = "Custom";
      this.filterStartDate = document.getElementById("startDate")["value"];
      this.filterEndDate = document.getElementById("endDate")["value"];
      let splitedStartDate = this.filterStartDate.split("/");
      let splitedEndDate = this.filterEndDate.split("/");
      let startDate =
        splitedStartDate[2] +
        "-" +
        splitedStartDate[1] +
        "-" +
        splitedStartDate[0];
      let endDate =
        splitedEndDate[2] + "-" + splitedEndDate[1] + "-" + splitedEndDate[0];
      this.queryString = `?duration=c&start=${startDate}&end=${endDate}`;
    }
    this.displayReport.status = false;
    this.loadCharts();
    // this.ngAfterViewInit();
  }

  loadCharts() {
    this.loadSameNoMultipleCodes();
    this.loadSameCodeScannedInDiffCity();
    this.loadSameLocationDiffCode();
    this.loadComplaints();
    this.multpleCodesScannedFromStore();
  }

  onChangeMobileNo(num) {
    console.log(num);
    this.selectedSameNo = num;
  }

  loadSameLocationDiffCode() {
    this.sameLocationDiffCodeLoader = true;
    this.apiService
      .get(
        `counterfeit/multiple-codes-scanned-in-same-location${this.queryString}`,
        true
      )
      .subscribe(
        (response: any) => {
          this.sameLocationDiffCodeLoader = false;
          if (response.status == 200 && response.body.success == 1) {
            this.sameLocationDiffCodeResult = response.body.data;
            let d = [];
            this.radarChartData.length = 0;
            this.radarChartLabels.length = 0;
            this.sameLocationDiffCodeResult.forEach((element) => {
              // d.push(element.data.length);
              this.radarChartData.push(element.data.length);
              this.radarChartLabels.push(element.cityName);
            });

            // console.log("d: ", d);
            // this.radarChartData.push({ label: "city", data: d });
          }
          console.log(response);
        },
        (err) => {
          this.sameLocationDiffCodeLoader = false;
          console.error(err);
        }
      );
  }

  loadComplaints() {
    this.complaintLoader = true;
    this.apiService
      .get(`counterfeit/customer-complaints${this.queryString}`, true)
      .subscribe(
        (response: any) => {
          this.complaintLoader = false;
          if (response.status == 200 && response.body.success == 1) {
            this.complaintResult = response.body.data;

            let c = [];
            let v = [];
            this.complaintChartLabels.length = 0;
            for (let i = this.complaintResult.length - 1; i >= 0; i--) {
              this.complaintChartLabels.push(this.complaintResult[i].date);
              console.log(this.complaintResult[i].counts);
              // console.log(this.complaintResult[i].counts.verifiedErros.length);
              c.push(this.complaintResult[i].counts.complaints.length);
              // v.push(this.complaintResult[i].counts.verifiedErros.length);
            }

            this.complaintChartDataSet = [
              {
                data: c,
                label: "Complaint",
                borderColor: "#e625ea",
                backgroundColor: "#e625ea90",
                hoverBackgroundColor: "#e625ea95",
                hoverBorderColor: "#e625ea99",
              },
              // {
              //   data: v,
              //   label: "Multiple Verified",
              //   borderColor: "#8226f5",
              //   backgroundColor: "#8226f590",
              //   hoverBackgroundColor: "#8226f595",
              //   hoverBorderColor: "#8226f599",
              // },
            ];

            console.log("->", this.complaintChartDataSet);
          }
        },
        (err) => {
          this.complaintLoader = false;
          console.error(err);
        }
      );
  }

  loadSameCodeScannedInDiffCity() {
    this.showSameCodeDiffCityLoader = true;
    this.apiService
      .get(
        `counterfeit/same-code-scanned-diff-location${this.queryString}`,
        true
      )
      .subscribe(
        (response: any) => {
          this.showSameCodeDiffCityLoader = false;
          if (response.status == 200 && response.body.success == 1) {
            this.sameCodeDiffCityResult = response.body.data;
            this.markers.sameCodeDiffCity.length = 0;
            if (response.body.data.length > 0) {
              response.body.data[response.body.data.length - 1].data.forEach(
                (item) => {
                  this.markers.sameCodeDiffCity.push({
                    lat: Number(item.latitude),
                    lng: Number(item.longitude),
                    alpha: 1,
                    data: item,
                  });
                }
              );
            }
          }
          console.log(response);
        },
        (err) => {
          this.showSameCodeDiffCityLoader = false;
          console.error(err);
        }
      );
  }

  onChangeReportType(v) {
    console.log(v);
    this.complaintReportType = v;
    this.complaintReportData.length = 0;
    let tmp: any = {};
    for (let s = 0; s < this.complaintResult.length; s++) {
      if (v == "Complaints") {
        console.log(this.complaintResult[s].counts.complaints);
        this.complaintReportData = this.complaintReportData.concat(
          this.complaintResult[s].counts.complaints
        );
      } else {
        // for (let d = 0; d < this.complaintResult[s].counts.verifiedErros.length; d++) {
        //   for (let r = 0; r < this.complaintResult[s].counts.verifiedErros[d].verifiedData.length; r++) {
        //     tmp = this.complaintResult[s].counts.verifiedErros[d].verifiedData[r];
        //     tmp.regCellNo = this.complaintResult[s].counts.verifiedErros[d].cellnumber;
        //     tmp.code = this.complaintResult[s].counts.verifiedErros[d].code;
        //     tmp.regCity = this.complaintResult[s].counts.verifiedErros[d].city.city_name;
        //     tmp.regState = this.complaintResult[s].counts.verifiedErros[d].city.state.state_name;
        //     tmp.productName = this.complaintResult[s].counts.verifiedErros[d].product.name;
        //     this.complaintReportData = this.complaintReportData.concat(tmp);
        //   }
        // }

        console.log(this.complaintReportData)
      }
    }

    console.log(this.complaintReportData);
  }

  async viewReport(key) {
    this.selectedCodeIndex = 0;
    this.complaintReportData.length = 0;
    this.displayReport.status = true;
    this.displayReport[key] = true;

    Object.keys(this.displayReport).forEach((item) => {
      if (item != "status" && item != key) {
        this.displayReport[item] = false;
      }
    });


    if (key == "complaints") {
      this.complaintReportType = 'Complaints';
      this.onChangeReportType(this.complaintReportType);
    }
  }

  loadSameNoMultipleCodes() {
    this.showSameNumberLoader = true;
    this.apiService
      .get(
        `counterfeit/same-number-scan-multiple-codes${this.queryString}`,
        true
      )
      .subscribe(
        (response: any) => {
          this.showSameNumberLoader = false;
          if (response.status == 200 && response.body.success == 1) {
            this.result = response.body.data;
            this.markers.sameNoDiffCode.length = 0;
            Object.keys(response.body.data).forEach((item) => {
              Object.keys(response.body.data[item].data).forEach((subItem: any) => {
                // console.log(response.body.data[item].data[subItem])
                this.markers.sameNoDiffCode.push({
                  lat: Number(response.body.data[item].data[subItem][0].latitude),
                  lng: Number(response.body.data[item].data[subItem][0].longitude),
                  alpha: 1,
                  title: subItem,
                  data: item,
                });
              })
            });
          }
          console.log(response);
        },
        (err) => {
          this.showSameNumberLoader = false;
          console.error(err);
        }
      );
  }

  multpleCodesScannedFromStore() {
    this.storeLoader = true;
    this.apiService
      .get(
        `counterfeit/scanned-from-store${this.queryString}`,
        true
      )
      .subscribe(
        (response: any) => {
          this.storeLoader = false;
          if (response.status == 200 && response.body.success == 1) {
            this.result = response.body.data;
            console.log('--------------------', response.body.data.length);
            if (response.body.data.length > 0) {
              this.makeStoreReportData(response.body.data);
              this.markers.multipleCodeFromStore.length = 0;
              this.storeNames.length = 0;
              response.body.data.forEach((item) => {
                item.data.forEach((subItem) => {
                  console.log(subItem.data[0])
                  this.storeNames.push(subItem.data[0].store_name)
                  this.markers.multipleCodeFromStore.push({
                    lat: Number(subItem.data[0].store_lat),
                    lng: Number(subItem.data[0].store_lng),
                    alpha: 1,
                    data: subItem,
                    title: subItem.data[0].store_name
                  });
                });
              });
            } else {
              this.storeReportData = [];
            }

          }
          // console.log(response);
        },
        (err) => {
          this.storeLoader = false;
          console.error(err);
        }
      );
  }

  makeStoreReportData(data) {
    let storeData = [];

    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < data[i].data.length; j++) {
        for (let k = 0; k < data[i].data[j].data.length; k++) {
          if (i == 0 && j == 0 && k == 0) {
            storeData.push({
              storeLat: data[i].data[j].data[k].store_lat,
              storeLng: data[i].data[j].data[k].store_lng,
              storeName: data[i].data[j].data[k].store_name,
              storeAddress: data[i].data[j].data[k].store_address,
              storeType: data[i].data[j].data[k].store_type,
              data: [{
                customerId: data[i].data[j].customerId,
                customerName: data[i].data[j].name,
                gender: data[i].data[j].data[k].Customer.gender,
                mobileNo: data[i].data[j].data[k].Customer.cellnumber,
                code: data[i].data[j].data[k].code,
                createdAt: data[i].data[j].data[k].createdAt
              }]
            });
          } else {
            let foundFlag = true;
            for (let s = 0; s < storeData.length && foundFlag; s++) {
              if (storeData[s].storeLat == data[i].data[j].data[k].store_lat && storeData[s].storeLng == data[i].data[j].data[k].store_lng) {
                storeData[s].data.push({
                  customerId: data[i].data[j].customerId,
                  customerName: data[i].data[j].name,
                  gender: data[i].data[j].data[k].Customer.gender,
                  mobileNo: data[i].data[j].data[k].Customer.cellnumber,
                  code: data[i].data[j].data[k].code,
                  createdAt: data[i].data[j].data[k].createdAt
                });

                foundFlag = false;
              }

              if ((s == storeData.length - 1) && foundFlag) {
                storeData.push({
                  storeLat: data[i].data[j].data[k].store_lat,
                  storeLng: data[i].data[j].data[k].store_lng,
                  storeName: data[i].data[j].data[k].store_name,
                  storeAddress: data[i].data[j].data[k].store_address,
                  storeType: data[i].data[j].data[k].store_type,
                  data: [{
                    customerId: data[i].data[j].customerId,
                    customerName: data[i].data[j].name,
                    gender: data[i].data[j].data[k].Customer.gender,
                    mobileNo: data[i].data[j].data[k].Customer.cellnumber,
                    code: data[i].data[j].data[k].code,
                    createdAt: data[i].data[j].data[k].createdAt
                  }]
                });
              }
            }
          }
        }
      }
      console.log(i)
      if (i == data.length - 1) {
        this.storeReportData = storeData;
        console.log('**', this.storeReportData)
      }
    }
  }

  // addMarker(lat: number, lng: number) {
  //   this.markers.push({ lat, lng, alpha: 0.4, ch: 0 });
  // }

  // max(coordType: "lat" | "lng"): number {
  //   return Math.max(...this.markers.map((marker) => marker[coordType]));
  // }

  // min(coordType: "lat" | "lng"): number {
  //   return Math.min(...this.markers.map((marker) => marker[coordType]));
  // }

  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    this.order = value;
  }

  selectMarker(event, v, key, identity = null) {
    console.log(event, v);
    this.viewReport(key);

    this.selectedMarker = {
      lat: event.latitude,
      lng: event.longitude,
    };

    this.mobileNumber.length = 0;
    if (identity == "1") {
      this.mobileNumber = Object.keys(v);
    } else if (identity == "2") {
      let findFlag = true;

      for (let i = 0; i < this.storeReportData.length && findFlag; i++) {
        if (this.storeReportData[i].storeLat == event.latitude && this.storeReportData[i].storeLng == event.longitude) {
          this.selectedCodeIndex = i;
          console.log(this.selectedCodeIndex)
          this.storeSelect = this.selectedCodeIndex;
          findFlag = false;
        }
      }

    } else {
      this.mobileNumber = Object.keys(this.result[v].data);
      let index = this.mobileNumber.indexOf(event.title);
      console.log('----', this.mobileNumber, index)

      index = (index == -1) ? 0 : index;

      this.selectedMobileNumber = event.title;

      this.selectedCity = v;
      this.selectedSameNo = this.mobileNumber[index];
    }
  }
}
