import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Config } from './../../config';
import { Observable } from 'rxjs';


const TOKEN = 'TOKEN';

const httpOptions = {
	headers: new HttpHeaders({

		'x-key': localStorage.getItem('x-key'),
		'x-access-token': localStorage.getItem('access_token')
	})
};

@Injectable({
	providedIn: 'root'
})


export class CompanyService {
	apiURL: string = Config.API_URL + "company/";
	apiURL1: string = Config.API_URL + "plant/";
	apiURL2: string = Config.API_URL + "brand/";
	apiURL3: string = Config.API_URL + "line/";
	apiURL4: string = Config.API_URL + "category/";



	constructor(private httpClient: HttpClient) {
	}


	company_add(data) {
		return this.httpClient.post(`${Config.API_URL}company`, data, httpOptions);
	}

	customerCareDetail() {
		return this.httpClient.get(`${Config.API_URL}customer-care/details`, httpOptions);
	}

	customerCareUpdate(data) {
		return this.httpClient.put(`${Config.API_URL}customer-care/edit`, data, httpOptions);
	}

	otpVerify(data){
		return this.httpClient.post(`${Config.API_URL}verifyOtp`,data,httpOptions);
	}

	companyuser(emailId){
		return this.httpClient.get(`${Config.API_LOGIN_URL}getcompanyuser/${emailId}`,httpOptions);	
	}

	company_list() {
		return this.httpClient.get(`${Config.API_URL}company`, httpOptions);
	}

	deleteCompany(id) {
		return this.httpClient.delete(`${this.apiURL}${id}`, httpOptions);
	}

	company_local(id) {
		return this.httpClient.get(`${Config.API_URL}company/${id}`, httpOptions);
	}

	company_edit(id) {
		return this.httpClient.get(`${Config.API_URL}company/${id}`, httpOptions);
	}

	company_update(id, data) {
		return this.httpClient.put(`${Config.API_URL}company/${id}`, data, httpOptions);
	}

	//Plant As Production Unit
	production_add(data) {
		return this.httpClient.post(`${Config.API_URL}plant`, data, httpOptions);
	}

	production_list() {
		return this.httpClient.get(`${Config.API_URL}plant`, httpOptions);
	}

	deleteProduction(id) {
		return this.httpClient.delete(`${this.apiURL1}${id}`, httpOptions);
	}

	production_edit(id) {
		return this.httpClient.get(`${Config.API_URL}plant/${id}`, httpOptions);
	}

	production_update(id, data) {
		return this.httpClient.put(`${Config.API_URL}plant/${id}`, data, httpOptions);
	}

	brand_add(data) {
		return this.httpClient.post(`${Config.API_URL}brand`, data, httpOptions);
	}

	brand_list() {
		return this.httpClient.get(`${Config.API_URL}brand`, httpOptions);
	}

	deleteBrand(id) {
		return this.httpClient.delete(`${this.apiURL2}${id}`, httpOptions);
	}

	brand_edit(id) {
		return this.httpClient.get(`${Config.API_URL}brand/${id}`, httpOptions);
	}

	brand_update(id, data) {
		return this.httpClient.put(`${Config.API_URL}brand/${id}`, data, httpOptions);
	}

	//line as productionline
	productionline_add(data) {
		return this.httpClient.post(`${Config.API_URL}line`, data, httpOptions);
	}

	productionline_list() {
		return this.httpClient.get(`${Config.API_URL}line`, httpOptions);
	}

	deleteProductionline(id) {
		return this.httpClient.delete(`${this.apiURL3}${id}`, httpOptions);
	}

	productionline_edit(id) {
		return this.httpClient.get(`${Config.API_URL}line/${id}`, httpOptions);
	}

	productionline_update(id, data) {
		return this.httpClient.put(`${Config.API_URL}line/${id}`, data, httpOptions);
	}

	category_add(data) {
		return this.httpClient.post(`${Config.API_URL}category`, data, httpOptions);
	}

	category_list() {
		return this.httpClient.get(`${Config.API_URL}category`, httpOptions);
	}

	deleteCategory(id) {
		return this.httpClient.delete(`${this.apiURL4}${id}`, httpOptions);
	}

	category_edit(id) {
		return this.httpClient.get(`${Config.API_URL}category/${id}`, httpOptions);
	}

	category_update(id, data) {
		return this.httpClient.put(`${Config.API_URL}category/${id}`, data, httpOptions);
	}

	//category list based on brand id
	categorybrand_list(catBrand) {
		return this.httpClient.get(`${Config.API_URL}catbybrandid/${catBrand}`, httpOptions);
	}


	//
	variant_add(data) {

		return this.httpClient.post(`${Config.API_URL}varient`, data, httpOptions);
	}

	variant_list() {
		return this.httpClient.get(`${Config.API_URL}varient`, httpOptions);
	}

	deleteVariant(id) {
		return this.httpClient.delete(`${Config.API_URL}varient/${id}`, httpOptions);
	}

	variant_edit(id) {
		return this.httpClient.get(`${Config.API_URL}varient/${id}`, httpOptions);
	}

	variant_update(id, data) {
		return this.httpClient.put(`${Config.API_URL}varient/${id}`, data, httpOptions);
	}

	//variant By Category
	variantCat_list(varCat) {
		return this.httpClient.get(`${Config.API_URL}varientbycatid/${varCat}`, httpOptions);
	}


	//option replace with size
	option_add(data) {

		return this.httpClient.post(`${Config.API_URL}size`, data, httpOptions);
	}

	option_list() {
		return this.httpClient.get(`${Config.API_URL}size`, httpOptions);
	}

	deleteOption(id) {
		return this.httpClient.delete(`${Config.API_URL}size/${id}`, httpOptions);
	}

	option_edit(id) {
		return this.httpClient.get(`${Config.API_URL}size/${id}`, httpOptions);
	}

	option_update(id, data) {
		return this.httpClient.put(`${Config.API_URL}size/${id}`, data, httpOptions);
	}


	duration_add(data) {

		return this.httpClient.post(`${Config.API_URL}duration`, data, httpOptions);
	}

	duration_list() {
		return this.httpClient.get(`${Config.API_URL}duration`, httpOptions);
	}

	deleteDuration(id) {
		return this.httpClient.delete(`${Config.API_URL}duration/${id}`, httpOptions);
	}

	duration_edit(id) {
		return this.httpClient.get(`${Config.API_URL}duration/${id}`, httpOptions);
	}

	duration_update(id, data) {
		return this.httpClient.put(`${Config.API_URL}duration/${id}`, data, httpOptions);
	}

	//Packaging
	packaging_add(data) {

		return this.httpClient.post(`${Config.API_URL}packaging`, data, httpOptions);
	}

	packaging_list() {
		return this.httpClient.get(`${Config.API_URL}packaging`, httpOptions);
	}

	deletePackaging(id) {
		return this.httpClient.delete(`${Config.API_URL}packaging/${id}`, httpOptions);
	}

	packaging_edit(id) {
		return this.httpClient.get(`${Config.API_URL}packaging/${id}`, httpOptions);
	}

	packaging_update(id, data) {
		return this.httpClient.put(`${Config.API_URL}packaging/${id}`, data, httpOptions);
	}

	packaging_size(id) {
		return this.httpClient.get(`${Config.API_URL}sizebyvarientid/${id}`, httpOptions);
	}

	//Product
	product_add(data) {

		return this.httpClient.post(`${Config.API_URL}product`, data, httpOptions);
	}

	product_list() {
		return this.httpClient.get(`${Config.API_URL}product`, httpOptions);
	}

	deleteProduct(id) {
		return this.httpClient.delete(`${Config.API_URL}product/${id}`, httpOptions);
	}

	product_edit(id) {
		return this.httpClient.get(`${Config.API_URL}product/${id}`, httpOptions);
	}

	product_update(id, data) {
		return this.httpClient.put(`${Config.API_URL}product/${id}`, data, httpOptions);
	}

	product_packbysize(id) {
		return this.httpClient.get(`${Config.API_URL}packagingbysizeid/${id}`, httpOptions);
	}

	//Shipper Code
	shipper_add(data) {

		return this.httpClient.post(`${Config.API_URL}shipperqrcode`, data, httpOptions);
	}
	shipper_list() {

		return this.httpClient.get(`${Config.API_URL}shipperqrcode`, httpOptions);
	}

	deleteShipper(id) {
		return this.httpClient.delete(`${Config.API_URL}shipperqrcode/${id}`, httpOptions);
	}

	shipper_edit(id) {
		return this.httpClient.get(`${Config.API_URL}shipperqrcode/${id}`, httpOptions);
	}

	shipper_update(id, data) {
		return this.httpClient.put(`${Config.API_URL}shipperqrcode/${id}`, data, httpOptions);
	}



	country_list() {
		return this.httpClient.get(`${Config.API_URL}country`, httpOptions);
	}

	state_list(countryid) {
		return this.httpClient.get(`${Config.API_URL}state/${countryid}`, httpOptions);
	}

	city_list(stateid) {
		return this.httpClient.get(`${Config.API_URL}city/${stateid}`, httpOptions);
	}

	//Employee User Api Start

	employee_add(data) {
		return this.httpClient.post(`${Config.API_URL}employee`, data, httpOptions);
	}

	employee_list() {
		return this.httpClient.get(`${Config.API_URL}employee`, httpOptions);
	}

	deleteEmployee(id) {
		return this.httpClient.delete(`${Config.API_URL}employee/${id}`, httpOptions);
	}

	employee_edit(id) {
		return this.httpClient.get(`${Config.API_URL}employee/${id}`, httpOptions);
	}

	employee_update(id, data) {
		return this.httpClient.put(`${Config.API_URL}employee/${id}`, data, httpOptions);
	}


	//Company Role Api Start

	companyrole_add(data) {
		return this.httpClient.post(`${Config.API_URL}companyrole`, data, httpOptions);
	}

	companyrole_list() {
		return this.httpClient.get(`${Config.API_URL}companyrole`, httpOptions);
	}

	deleteCompanyrole(id) {
		return this.httpClient.delete(`${Config.API_URL}companyrole/${id}`, httpOptions);
	}

	companyrole_edit(id) {
		return this.httpClient.get(`${Config.API_URL}companyrole/${id}`, httpOptions);
	}

	companyrole_update(id, data) {
		return this.httpClient.put(`${Config.API_URL}companyrole/${id}`, data, httpOptions);
	}

	//Company User Api Start

	companyuser_add(data) {
		return this.httpClient.post(`${Config.API_URL}companyuser`, data, httpOptions);
	}

	companyuser_list() {
		return this.httpClient.get(`${Config.API_URL}companyuser`, httpOptions);
	}

	deleteCompanyuser(id) {
		return this.httpClient.delete(`${Config.API_URL}companyuser/${id}`, httpOptions);
	}

	companyuser_edit(id) {
		return this.httpClient.get(`${Config.API_URL}companyuser/${id}`, httpOptions);
	}

	companyuser_update(id, data) {
		return this.httpClient.put(`${Config.API_URL}companyuser/${id}`, data, httpOptions);
	}


	//Sku Details Data Api
	productDetails_edit(id) {
		return this.httpClient.get(`${Config.API_URL}productdetail/${id}`, httpOptions);
	}
	productDetails_add(id, data) {
		return this.httpClient.put(`${Config.API_URL}productdetail/${id}`, data, httpOptions);
	}
	productDetails(data) {
		return this.httpClient.post(`${Config.API_URL}productdetail`, data, httpOptions);
	}

	//Sku Details Data Api
	howtouse_edit(id) {
		return this.httpClient.get(`${Config.API_URL}productdetail/${id}`, httpOptions);
	}
	howtouse_add(data) {
		return this.httpClient.post(`${Config.API_URL}productdetail`, data, httpOptions);
	}
	//product details by id
	productbyId_details(id) {
		return this.httpClient.get(`${Config.API_URL}productbyid/${id}`, httpOptions);
	}

	getinvoiceno() {
		return this.httpClient.get(`${Config.API_URL}invoice`, httpOptions);
	}


	updateInvoice(data) {
		return this.httpClient.post(`${Config.API_URL}invoice`, data, httpOptions);
	}

	point_allocation_add(data) {
		return this.httpClient.post(`${Config.API_URL}point-allocation/add`, data, httpOptions);
	}

	point_allocation_delete(id) {
		return this.httpClient.delete(`${Config.API_URL}point-allocation/delete?configured_id=${id}`, httpOptions);
	}

	point_allocation() {
		return this.httpClient.get(`${Config.API_URL}point-allocation/master-details`, httpOptions);
	}

	point_allocation_list() {
		return this.httpClient.get(`${Config.API_URL}point-allocation/list`, httpOptions);
	}

	rewardhistory() {
		return this.httpClient.get(`${Config.API_URL}rewardhistory`, httpOptions);
	}

	exportCsvHistory() {
		return this.httpClient.get(`${Config.API_URL}exportCsvHistory`, httpOptions);
	}

	reward_list() {
		return this.httpClient.get(`${Config.API_URL}rewards/list`, httpOptions);
	}

	rewardDelete(id) {
		return this.httpClient.delete(`${Config.API_URL}reward/${id.id}`, httpOptions);
	}

	pointAllocationDelete(id) {
		return this.httpClient.delete(`${Config.API_URL}point-allocation/delete/${id.id}`, httpOptions);
	}

	rewards(data) {
		return this.httpClient.post(`${Config.API_URL}reward`, data, httpOptions);
	}

	updateRewards(id, data) {
		return this.httpClient.put(`${Config.API_URL}reward/${id}`, data, httpOptions);
	}

	rewardsHistoryUpdate(id, data) {
		return this.httpClient.put(`${Config.API_URL}rewardhistory/${id}`, data, httpOptions);
	}

	//Employee User Api Finish
	/**
	 * [Description: Api call to get ewarranty list]
	 * @author Suresh Suthar
	 * @Created Date 2019-03-07
	 */
	geteWarranty() {

		return this.httpClient.get(`${Config.API_URL}ewarranty`, httpOptions);
	}

	/**
	 * [updateeWarranty description]
	 * @author Suresh Suthar
	 * @Created Date     2019-03-07
	 * @param   {[type]} id         [description]
	 * @param   {[type]} data       [description]
	 */
	updateeWarranty(id, data) {

		return this.httpClient.put(`${Config.API_URL}ewarranty/${id}`, data, httpOptions);
	}

	/**
	 * [getBatchList description]
	 * @author Suresh Suthar
	 * @Created Date 2019-03-21
	 */
	getBatchList(id?) {

		return this.httpClient.get(`${Config.API_URL}product_batch/${id}`, httpOptions);
	}

	/**
	 * [getPlantLine description]
	 * @author Suresh Suthar
	 * @Created Date     2019-03-22
	 * @param   {[type]} id         [description]
	 */
	getPlantLine(id) {

		return this.httpClient.get(`${Config.API_URL}line/plant/${id}`, httpOptions);
	}

	/**
	 * [batch_add description]
	 * @author Suresh Suthar
	 * @Created Date     2019-03-29
	 * @param   {[type]} data       [description]
	 */
	batch_add(data) {

		return this.httpClient.post(`${Config.API_URL}product_batch`, data, httpOptions);
	}

	/**
	 * [getBatchDetails description]
	 * @author Suresh Suthar
	 * @Created Date 2019-03-29
	 */
	getBatchDetails(id) {

		return this.httpClient.get(`${Config.API_URL}product_batch/${id}`, httpOptions);
	}

	/**
	 * [update_batch description]
	 * @author Suresh Suthar
	 * @Created Date     2019-03-29
	 * @param   {[type]} id         [description]
	 * @param   {[type]} data       [description]
	 */
	update_batch(id, data) {

		return this.httpClient.put(`${Config.API_URL}product_batch/${id}`, data, httpOptions);
	}

	/**
	 * [getQrcodeList description]
	 * @author Suresh Suthar
	 * @Created Date 2019-03-29
	 */
	getQrcodeList(id) {

		return this.httpClient.get(`${Config.API_URL}qrcoderole/qrcode/${id}`, httpOptions);
	}

	/**
	 * [delete_batch description]
	 * @author Suresh Suthar
	 * @Created Date 2019-03-29
	 */
	delete_batch(id) {

		return this.httpClient.delete(`${Config.API_URL}product_batch/${id}`, httpOptions);
	}

	/**
	 * [createSKUQrcode description]
	 * @author Suresh Suthar
	 * @Created Date     2019-04-04
	 * @param   {[type]} data       [description]
	 */
	createSKUQrcode(data) {

		return this.httpClient.post(`${Config.API_URL}qrcoderole`, data, httpOptions);
	}

	/**
	 * [getSkURoles description]
	 * @author Suresh Suthar
	 * @Created Date     2019-04-05
	 * @param   {[type]} id         [description]
	 */
	getSkURoles(id) {

		return this.httpClient.get(`${Config.API_URL}qrcoderole/product_role/${id}`, httpOptions);
	}

	/**
 * [getSkURoles description]
 * @author Umang Kothari
 * @Created Date 2019-08-09
 * @param   {[type]} id         [description]
 */
	getSkUStartSubCode(id) {

		return this.httpClient.get(`${Config.API_URL}qrcoderole/product_subcode/${id}`, httpOptions);
	}

	/**
	 * [listQrcode description]
	 * @author Suresh Suthar
	 * @Created Date 2019-04-05
	 */
	listQrcode() {

		//return this.httpClient.get(`${Config.API_URL}qrcoderole/`,httpOptions);
		return this.httpClient.get(`${Config.API_URL}trustedqrcodeparent/`, httpOptions);
	}

	detailsQrcode(id) {

		return this.httpClient.get(`${Config.API_URL}getroles/${id}`, httpOptions);
	}

	/**
	 * [createPackagingQrcode description]
	 * @author Suresh Suthar
	 * @Created Date 2019-04-09
	 */
	createPackagingQrcode(data) {

		return this.httpClient.post(`${Config.API_URL}packaging_qrcode`, data, httpOptions);
	}

	/**
	 * [listPackagingQrcode description]
	 * @author Suresh Suthar
	 * @Created Date     2019-04-09
	 * @param   {[type]} data       [description]
	 */
	listPackagingQrcode() {

		//return this.httpClient.get(`${Config.API_URL}packaging_qrcode`,httpOptions);
		return this.httpClient.get(`${Config.API_URL}packagingqrcodewithcount`, httpOptions);
	}

	/**
	 * [getPackagingQrcodeList description]
	 * @author Suresh Suthar
	 * @Created Date     2019-04-09
	 * @param   {[type]} id         [description]
	 */
	getPackagingQrcodeList(id) {

		return this.httpClient.get(`${Config.API_URL}packaging_qrcode/${id}`, httpOptions);
	}

	/**
	 * [createShipperQrcode description]
	 * @author Suresh Suthar
	 * @Created Date     2019-04-09
	 * @param   {[type]} data       [description]
	 */
	createShipperQrcode(data) {
		return this.httpClient.post(`${Config.API_URL}shipper_qrcode`, data, httpOptions);
	}

	/**
	 * [listShipperQrcode description]
	 * @author Suresh Suthar
	 * @Created Date 2019-04-09
	 */
	listShipperQrcode() {

		return this.httpClient.get(`${Config.API_URL}shipper_qrcode`, httpOptions);
	}

	getShipperQrCodeWithCount() {
		return this.httpClient.get(`${Config.API_URL}shipperqrcodewithcount`, httpOptions);
	}
	getScannedShipperQrcodes(id) {
		return this.httpClient.get(`${Config.API_URL}getScannedShipperQrcodes/${id}`, httpOptions);
	}

	getScannedPackagingQrcodes(id) {
		return this.httpClient.get(`${Config.API_URL}getScannedPackagingQrcodes/${id}`, httpOptions);
	}

	/**
	 * [getShipperQrcodeList description]
	 * @author Suresh Suthar
	 * @Created Date     2019-04-09
	 * @param   {[type]} id         [description]
	 */
	getShipperQrcodeList(id) {
		return this.httpClient.get(`${Config.API_URL}shipper_qrcode/${id}`, httpOptions);
	}

	/**
	 * [getBatchAllDetails description]
	 * @author Suresh Suthar
	 * @Created Date 2019-04-11
	 */
	getBatchAllDetails(id) {

		return this.httpClient.get(`${Config.API_URL}product_batch/all_details/${id}`, httpOptions);
	}

	/**
	 * [getBatchQRCode description]
	 * @author Suresh Suthar
	 * @Created Date     2019-04-12
	 * @param   {[type]} id         [description]
	 */
	getBatchQRCode(id) {
		return this.httpClient.get(`${Config.API_URL}qrcoderole/all_qrcode/${id}`, httpOptions);
	}

	/**
	 * [inventoryList description]
	 * @author Suresh Suthar
	 * @Created Date 2019-04-22
	 */
	inventoryList() {
		return this.httpClient.get(`${Config.API_URL}inventory`, httpOptions);
	}

	/**
	 * [getSKUBatchStock description]
	 * @author Suresh Suthar
	 * @Created Date 2019-04-24
	 */
	getSKUBatchStock(id) {
		return this.httpClient.get(`${Config.API_URL}inventory/batch/${id}`, httpOptions);
	}
	generate_stockReport(data) {
		return this.httpClient.post(`${Config.API_URL}inventory/batch/report`, data, httpOptions);
	}

	trackandtrace_list() {
		return this.httpClient.get(`${Config.API_URL}traceshipper`, httpOptions);
	}

	supplychainRoleList() {
		return this.httpClient.get(`${Config.API_URL}roleList`, httpOptions);
	}

	distriList() {
		return this.httpClient.get(`${Config.API_URL}getDistributions`, httpOptions);
	}

	//Supply Chain Service Start
	getParentBasedRole(id) {
		return this.httpClient.get(`${Config.API_URL}getParentByRoleParentIds/${id}`, httpOptions);
	}

	supplychain_add(data) {

		return this.httpClient.post(`${Config.API_URL}addChainUnit`, data, httpOptions);
	}
	supplychain_edit(id) {

		return this.httpClient.get(`${Config.API_URL}editChainUnit/${id}`, httpOptions);
	}
	supplychain_update(id, data) {

		return this.httpClient.put(`${Config.API_URL}updateChainUnit/${id}`, data, httpOptions);
	}
	//Supply Chain Service Finish

	import_SKU(data) {
		return this.httpClient.post(`${Config.API_URL}importSKU`, data, httpOptions);
	}

	import_ShipperQrcode(data) {
		return this.httpClient.post(`${Config.API_URL}importShipperQrcode`, data, httpOptions);
	}

	getMenus() {
		return this.httpClient.get(`${Config.API_URL}menu`, httpOptions);
	}
	addMenu(data) {
		return this.httpClient.post(`${Config.API_URL}menu`, data, httpOptions);
	}
	editMenu(id) {
		return this.httpClient.get(`${Config.API_URL}menu/${id}`, httpOptions);
	}
	updateMenu(id, data) {
		return this.httpClient.put(`${Config.API_URL}menu/${id}`, data, httpOptions);
	}
	deleteMenu(id) {
		return this.httpClient.delete(`${Config.API_URL}menu/${id}`, httpOptions);
	}
	getNonParentMenus() {
		return this.httpClient.get(`${Config.API_URL}getNonParentMenus`, httpOptions);
	}

	getRoleMenuPermission(id) {
		return this.httpClient.get(`${Config.API_URL}rolemenupermissions/${id}`, httpOptions);
	}
	addRoleMenuPermission(data) {
		return this.httpClient.post(`${Config.API_URL}rolemenupermissions`, data, httpOptions);
	}
	editRoleMenuPermission(id) {
		return this.httpClient.get(`${Config.API_URL}editRoleMenuPermission/${id}`, httpOptions);
	}
	updateRoleMenuPermission(id, data) {
		return this.httpClient.put(`${Config.API_URL}rolemenupermissions/${id}`, data, httpOptions);
	}
	deleteRoleMenuPermission(id) {
		return this.httpClient.delete(`${Config.API_URL}rolemenupermissions/${id}`, httpOptions);
	}

	getconsignmentDetais(id1, id2) {
		return this.httpClient.get(`${Config.API_URL}getBusinessConsignmentShippers/${id1}/${id2}`, httpOptions);
	}

	getSKUBatchData(id) {
		return this.httpClient.get(`${Config.API_URL}product_batch/batchbysku/${id}`, httpOptions);
	}

	getSkUExpiryDate(id) {
		return this.httpClient.get(`${Config.API_URL}product_batch/expDateBySKU/${id}`, httpOptions);
	}

	//Sku qrcode download
	getSkuList(id) {

		return this.httpClient.get(`${Config.API_URL}qrcoderole/parentQrcode/${id}`, httpOptions);
	}


	// GRAPH API START
	getAgewiseData() {
		return this.httpClient.get(`${Config.API_URL}agewiseData/`, httpOptions);
	}

	productionchart(param) {
		return this.httpClient.get(`${Config.API_URL}productionchart${param}`, httpOptions);
	}

	monthlyshortage() {
		return this.httpClient.get(`${Config.API_URL}monthlyShortage`, httpOptions);
	}

	monthlydamage() {
		return this.httpClient.get(`${Config.API_URL}monthlyDamage/`, httpOptions);

	}

	supplychain(param) {
		return this.httpClient.get(`${Config.API_URL}supplychainslipage${param}`, httpOptions);

	}



	weeklyconsumption() {
		return this.httpClient.get(`${Config.API_URL}weeklyconsumption/`, httpOptions);
	}

	brandbasedchart() {
		return this.httpClient.get(`${Config.API_URL}monthlyscan/`, httpOptions);
	}

	geofacing() {

		return this.httpClient.get(`${Config.API_URL}geofencing/`, httpOptions);
	}

	// GRAPH API ENDS
	registerByApp() {
		return this.httpClient.get(`${Config.API_URL}customerProductRegister`, httpOptions);
	}

	scanByApp() {
		return this.httpClient.get(`${Config.API_URL}customerProductscan`, httpOptions);
	}


}




