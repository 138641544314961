import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BusinessService } from '../../service/business.service';
import { OrderPipe } from 'ngx-order-pipe';
import { NgForm } from "@angular/forms";
import { FlashMessagesService } from 'angular2-flash-messages';
import {
    FormBuilder,
    FormGroup,
    Validators,
    FormControl,
} from "@angular/forms";
import { ApiService } from 'src/app/service/api/api.service';
declare var jQuery: any;

@Component({
    selector: 'app-edit-user',
    templateUrl: './edit-user.component.html',
    styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit {

    result: any = [];
    resultList: any;
    errors: any = '';
    Editid: any;
    resultData: any;
    msg: string = null;
    public message: string;
    roleId: number;
    parentId: number;
    submit: boolean = false;
    loader: boolean = false;
    parentList: any;
    parentOptions: any;

    stateList = [];
    cityList = [];

    gender = [{
        id: 'M', text: 'Male'
    }, {
        id: 'F', text: 'Female'
    }]

    registerForm: FormGroup;
    submitted = false;

    constructor(private _flashMessagesService: FlashMessagesService,
        private formBuilder: FormBuilder,
        public businessService: BusinessService,
        private router: Router,
        private route: ActivatedRoute,
        private apiService: ApiService) {
    }

    ngOnInit() {
        this.registerForm = this.formBuilder.group({
            firstName: ["", [Validators.required, Validators.maxLength(100)]],
            lastName: ["", [Validators.required, Validators.maxLength(100)]],
            email: [""],
            phoneNo: [""],
            roleId: [""],
        });

        this.loadDatePickerScript();

        this.roleData();

        this.route.params.subscribe(params => {
            // console.log(params);
            this.Editid = params['id'];
            this.editUserData();
        });

        this.getStateList(101);
    }

    getStateList(countryId) {
        this.apiService.get("state/" + countryId, true).subscribe(
            (response: any) => {
                if (response.status == 200 && response.body.success == 1) {
                    this.stateList = response.body.data;
                }
            },
            (err) => {
                console.error(err);
            }
        );
    }

    getCityList() {
        this.apiService.get("city/" + this.registerForm.value.stateId, true).subscribe(
            (response: any) => {
                if (response.status == 200 && response.body.success == 1) {
                    this.cityList = response.body.data;
                }
            },
            (err) => {
                console.error(err);
            }
        );
    }

    get f() {
        return this.registerForm.controls;
    }

    removeFormValidator() {
        this.registerForm.removeControl("stateId");
        this.registerForm.removeControl("cityId");
        this.registerForm.removeControl("gender");
        this.registerForm.removeControl("pincode");
        this.registerForm.removeControl("dob");
    }

    // onAddReset() {
    //     this.registerForm.reset();
    //     this.submitted = false;
    //     this.removeFormValidator();
    // }


    editUserData() {
        this.loader = true;
        this.businessService.businessUser_edit(this.Editid).subscribe((res: any) => {
            this.loader = false;

            if (res.data.role_id == 3) {
                this.registerForm.addControl(
                    "stateId",
                    new FormControl("", [Validators.required])
                );
                this.registerForm.addControl(
                    "cityId",
                    new FormControl("", [Validators.required])
                );
                this.registerForm.addControl(
                    "gender",
                    new FormControl("", [Validators.required])
                );
                this.registerForm.addControl(
                    "pincode",
                    new FormControl("", [Validators.required, Validators.pattern("^(0)?[0-9]{6}$")])
                );

                this.registerForm.addControl(
                    "dob",
                    new FormControl("", [Validators.required])
                );
            }

            this.registerForm.patchValue({
                firstName: res.data.first_name,
                lastName: res.data.last_name,
                email: res.data.email,
                phoneNo: res.data.mobile_no,
                roleId: res.data.role_id
            });

            if (res.data.role_id == 3) {
                $("#dob").data('daterangepicker').setStartDate(new Date(res.data.dob));
                $("#dob").data('daterangepicker').setEndDate(new Date(res.data.dob));
                this.registerForm.patchValue({
                    stateId: res.data.stateId
                });
                this.getCityList();

                this.registerForm.patchValue({
                    gender: res.data.gender,
                    pincode: res.data.pincode,
                    cityId: res.data.cityId
                });
            }
            // this.result = res.data;
            this.roleId = res.data.role_id;
            this.parentId = res.data.businessuser_parent_id;
            console.log(this.result);

        }, (err) => {


        });
    }

    onSubmit() {
        this.submitted = true;

        let dob;
        if (this.registerForm.value.roleId == 3) {
            dob = document.getElementById('dob')['value'];
            this.registerForm.patchValue({
                dob: dob
            });
        }

        if (this.registerForm.invalid) {
            return;
        }
        this.loader = true;

        this.businessService.businessUser_update(this.Editid, this.registerForm.value).subscribe((res: any) => {

            this.resultData = res;
            this.loader = false;
            if (this.resultData.success == 1) {

                this.router.navigate(['/user']);

            } else {
                this.errors = res['message'];
            }

        }, (err) => {
            this.loader = false;

        });

    }

    loadDatePickerScript() {

        jQuery(document).ready(function () {
            jQuery("#dob").daterangepicker(
                {
                    singleDatePicker: true,
                    showDropdowns: true,
                    locale: {
                        format: "DD/MM/YYYY",
                    },
                    maxDate: new Date(),
                    autoclose: true,
                    minYear: 1901,
                },
                function (start, end, label) {
                    console.log("s: ", start, "e: ", end, "l: ", label);
                }
            );
        });
    }

    async roleData() {
        await this.businessService.businessRole_list().subscribe((res: any) => {
            this.result = res.data;
        }, (err) => {

        });
    }

    //Role Changed Value function
    roleChanged(event, parentId) {
        // console.log(event);
        this.result.role_id = event.value;
        console.log(this.result.role_id);
        let parentuserId = this.result.role_id;
        console.log(parentuserId);
        var result = [];
        this.businessService.businessRole_parent(parentuserId).subscribe((res: any) => {
            result = res.data;
            console.log(res.data);


            if (this.parentId == 0 || this.parentId == undefined || this.parentId == null) {
                console.log("Empty Data Here");
                var rObj = [];
                rObj.push({ "id": "", "text": "select" });
                for (let i = 0; i < res.data.length; i++) {

                    rObj.push({ "id": res.data[i].id, "text": res.data[i].first_name });
                }
                this.parentList = rObj;
                console.log(this.parentList);


            }
            else {
                let parentuserData = res.data.filter(function (obj) {

                    return obj.role_id = parentuserId;
                });

                console.log(parentuserData);
                this.parentList = parentuserData.map(function (obj) {
                    var rObj = {};
                    rObj['id'] = obj.id;
                    rObj['text'] = obj.first_name;
                    rObj['selected'] = (obj.id == parentId) ? true : false;
                    return rObj;
                });
                console.log(this.parentList);
            }

        }, (err) => {

        });
    }
}
