import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../company/service/company.service';
import { OrderPipe } from 'ngx-order-pipe';
import { NgForm } from "@angular/forms";
import { FlashMessagesService } from 'angular2-flash-messages';



@Component({
  selector: 'app-edit-company',
  templateUrl: './edit-company.component.html',
  styleUrls: ['./edit-company.component.css']
})
export class EditCompanyComponent implements OnInit {
  result: any;
  errors: any = '';
  Editid: any;
  resultData: any;
  msg: string = null;
  public imagePath;
  imgURL: any = "/assets/images/placeholder.png";
  image: any;
  public message1: string;
  resultCountry: any;
  resultState: any;
  resultCity: any;
  companyId: number;
  logo: any;
  filedata: any;
  countryId: any;
  stateId: any;
  cityId: any;
  loader: boolean = false;

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }


  constructor(private _flashMessagesService: FlashMessagesService, public companyService: CompanyService, private route: ActivatedRoute, private router: Router, private orderPipe: OrderPipe) { }

  ngOnInit() {

    this.route.params.subscribe(params => {
      console.log(params);
      this.Editid = params['id'];
      this.editData();
    });

  }

  editData() {
    this.loader = true;
    this.companyService.company_edit(this.Editid).subscribe((res: any) => {
      this.loader = false;
      this.result = res.data;
      this.imgURL = res.data.logo;
      console.log(this.result);
      this.countryId = this.result.country_id;
      console.log(this.countryId);
      this.stateId = this.result.state_id;
      this.cityId = this.result.city_id;
      this.countryData(this.countryId);

    }, (err) => {

    });
  }

  onSubmit(form?: NgForm) {
    let f = form.value;
    f.country_id = this.result.country_id;
    f.state_id = this.result.state_id;
    f.city_id = this.result.city_id;
    let formdata = new FormData();
    console.log(formdata);
    formdata.append("logo", this.filedata);
    formdata.append("name", f.name);
    formdata.append("city_id", f.city_id);
    formdata.append("state_id", f.state_id);
    formdata.append("country_id", f.country_id);
    formdata.append("address1", f.address1);
    formdata.append("contact1", f.contact1);
    formdata.append("email1", f.email1);
    formdata.append("email2", f.email2);
    formdata.append("password", f.password);
    formdata.append("contact2", f.contact2);
    formdata.append("address2", f.address2);
    formdata.append("website", f.website);

    this.companyService.company_update(this.Editid, formdata).subscribe((res: any) => {
      //console.log(res);

      this.resultData = res;

      if (this.resultData.success == 1) {
        this._flashMessagesService.show('Company updated successfully', { cssClass: 'alert-success' });

        setTimeout(() => {
          this.router.navigate(['/companies']);
        }, 2000);

      } else {
        this.errors = res.message;
        setTimeout(() => {
          this.errors.company_id = false;
        }, 3000);
      }

    }, (err) => {

    });
  }

  companyList() {
    this.companyService.company_list().subscribe((res: any) => {
      //console.log(res.data);
      this.result = res.data;
      console.log(this.result);

    }, (err) => {


    });
  }

  async countryData(countryId) {
    var result = [];
    await this.companyService.country_list().subscribe((res: any) => {
      result = res.data;
      this.resultCountry = result.map(function (obj) {
        var rObj = {};
        rObj['id'] = obj.id;
        rObj['text'] = obj.country_name;
        rObj['selected'] = (obj.id == countryId) ? true : false;

        return rObj;
      });
      console.log(this.resultCountry);
    }, (err) => {

    });
  }

  countryChanged(event, stateId) {
    console.log(event);
    this.result.state_id = event.value;

    let varCat = this.result.state_id;
    var result = [];
    this.companyService.state_list(varCat).subscribe((res: any) => {
      result = res.data;

      let categoryData = res.data.filter(function (obj) {

        return obj.state_id = varCat;
      });

      console.log(categoryData);
      this.resultState = categoryData.map(function (obj) {
        var rObj = {};
        rObj['id'] = obj.id;
        rObj['text'] = obj.state_name;
        rObj['selected'] = (obj.id == stateId) ? true : false;
        return rObj;
      });
      console.log(this.resultState);
    }, (err) => {

    });

  }


  stateChanged(event, cityId) {
    console.log(event);
    this.result.state_id = event.value;

    let varCat = this.result.state_id;
    var result = [];
    this.companyService.city_list(varCat).subscribe((res: any) => {
      result = res.data;

      let categoryData = res.data.filter(function (obj) {

        return obj.state_id = varCat;
      });

      console.log(categoryData);
      this.resultCity = categoryData.map(function (obj) {
        var rObj = {};
        rObj['id'] = obj.id;
        rObj['text'] = obj.city_name;
        rObj['selected'] = (obj.id == cityId) ? true : false;
        return rObj;
      });
      console.log(this.resultCity);
    }, (err) => {

    });

  }

  cityChanged(event) {
    console.log(event);
    this.result.city_id = event.value;
  }

  // countryData(countryId){
  //      this.companyService.country_list().subscribe((res:any)=>{
  //        //console.log(res.data);
  //        this.resultCountry =res.data;
  //       // console.log(this.resultCountry);
  //        this.onSelect(countryId);      
  //      }, (err) => {                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    

  //      });
  //    }

  // onSelect(countryid) {

  //   this.companyService.state_list(countryid).subscribe((res:any)=>{
  //   this.resultState =res.data;
  //   console.log(this.resultState);
  //     this.onsateSelect(this.stateId);
  //   }, (err) => {                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    

  //   });
  // }

  //  onsateSelect(stateid) {
  //  // console.log("countryid");
  //   this.companyService.city_list(stateid).subscribe((res:any)=>{
  //     this.resultCity =res.data;
  //     console.log(this.resultCity);

  //   }, (err) => {                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    

  //   });
  // }



  onFileChange(e) {
    this.filedata = e.target.files[0];
    console.log("this.filedata");
    console.log(this.filedata);
  }

  preview(files) {
    if (files.length === 0)
      return;

    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message1 = "Only images are supported.";
      return;
    }

    var reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    }
  }

}
