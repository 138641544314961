import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BusinessService } from '../../service/business.service';
import { OrderPipe } from 'ngx-order-pipe';
import { NgForm } from "@angular/forms";
import { FlashMessagesService } from 'angular2-flash-messages';

@Component({
  selector: 'app-edit-role',
  templateUrl: './edit-role.component.html',
  styleUrls: ['./edit-role.component.css']
})
export class EditRoleComponent implements OnInit {

    result: any = [];
    resultList: any;
    errors: any = '';
    Editid: any;
    resultData: any;
    msg: string = null;
    public message: string;
    filedata: any;
    roleId:any;
    submit:boolean=false;
    loader:boolean = false;
    roleOptions:any;


    constructor(private _flashMessagesService: FlashMessagesService, public businessService: BusinessService, private route: ActivatedRoute, private router: Router, private orderPipe: OrderPipe) { }


    ngOnInit() {
        this.route.params.subscribe(params => {
            console.log(params);
            this.Editid = params['id'];
            this.editRoleData();     

        });
    }

    editRoleData() {
        this.loader=true;
        this.businessService.businessRole_edit(this.Editid).subscribe((res: any) => {
            this.loader=false;
            this.result = res.data;
            console.log(this.result);
            this.roleId = res.data.businessrole_parent_ids.split(',');
            console.log(this.roleId);
            if(this.roleId == 0 || this.roleId == undefined || this.roleId == null){
               this.roleOptions    = {multiple: true, placeholder: { id: '', text: 'Select Parent Role' } };     
            }

            else{
               this.roleOptions    = {multiple: true };

            }            
            this.roleData(this.roleId);
            console.log(this.result);
            console.log(this.roleId);

        }, (err) => {


        });
    }

    onSubmit(form?: NgForm) {
        let f = form.value;
        console.log(f);
     
      if( f.businessrole_parent_ids == ""){
         f.businessrole_parent_ids= "0";
        }
       

        this.businessService.businessRole_update(this.Editid, f).subscribe((res: any) => {

            this.resultData = res;

            if (this.resultData.success == 1) {
                 this.submit=true; 
                this._flashMessagesService.show('Role updated successfully', { cssClass: 'alert-success' });

                setTimeout(() => {
                    this.router.navigate(['/role']);
                }, 3000);

            } else {
                this.errors = res.message;
                  setTimeout(() => {
                this.errors.companyrole_id = false;
              }, 3000); 
            }

        }, (err) => {

        });

    }

     roleChanged(event) {
       // console.log(event);
        this.result.businessrole_parent_ids = event.value.join();
        console.log(this.result.businessrole_parent_ids);
    }

    async roleData(roleId) {
        let result:any;
        await this.businessService.businessRole_list().subscribe((res: any) => {
            result = res.data;
            console.log(result);

            if (this.roleId ==  0 || this.roleId ==  undefined || this.roleId ==  null){

                console.log("Empty Data Here");
                var rObj= [];
                  rObj.push({"id":"","text":"select"});
                  for(let i=0; i<result.length; i++){

                    rObj.push({"id":result[i].id, "text":result[i].name});
                  }      

                  this.resultList = rObj;
                    console.log(this.resultList);

            }
            else{

                console.log("Selected  Data Here");
                 this.resultList = result.map(function (obj) {
                var rObj = {};

                rObj['id'] = obj.id;
                rObj['text'] = obj.name;

                let id = obj.id.toString();
                console.log(roleId.indexOf(id));
                    
                rObj['selected'] = roleId.indexOf(id)>-1 ? true : false;
                
                

                return rObj;
            });
            console.log(this.resultList);
            }

        }, (err) => {

        });
    }
}
