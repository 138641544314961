import { Component, OnInit, Renderer2, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { CompanyService } from '../../../company/service/company.service';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Config } from "src/app/config";
import { ToastrService } from 'ngx-toastr';
import { Globals } from './../../../globals';

@Component({
  selector: "app-reward-managment",
  templateUrl: "./reward-managment.component.html",
  styleUrls: ["./reward-managment.component.css"]
})
export class RewardManagmentComponent implements OnInit {
  registerForm: FormGroup;
  updateForm: FormGroup;

  p =this.globals.p;
  currentLimit=this.globals.currentLimit;
  pageLimits = this.globals.pageLimits;
  order: string = 'rewardList.createdAt';
  reverse: boolean = true;
  searchText: string = '';
  loader: boolean = false;

  updateSubmitted = false;
  updateFile: any;

  submitted = false;
  file: any;
  rewardImage: any = null;
  imageServerPath = Config.API_LOGIN_URL;

  public popoverTitle = "Confirm";
  public popoverMessage = "Do you want to delete configuration?";
  public confirmClicked = false;
  public cancelClicked = false;
  selectedRecord: any;

  constructor(
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    private apiService: CompanyService,
    private formBuilder: FormBuilder,
    private globals: Globals,
    public toastr: ToastrService,
  ) { }

  rewardList;

  ngOnInit() {
    this.getRewardList();

    this.registerForm = this.formBuilder.group({
      name: ["", [Validators.required, this.noWhitespaceValidator, Validators.maxLength(40)]],
      uniqueName: ["", [Validators.required, this.noWhitespaceValidator, Validators.maxLength(40)]],
      point: ["", [Validators.required, Validators.min(1), Validators.max(10000000),  Validators.pattern("^[0-9]*$")]],
      stock: ["", [Validators.required, Validators.min(1), Validators.max(100000),  Validators.pattern("^[0-9]*$")]]
    });

    this.updateForm = this.formBuilder.group({
      name: ["", [Validators.required, this.noWhitespaceValidator, Validators.maxLength(40)]],
      point: ["", [Validators.required,  Validators.min(1), Validators.max(10000000), Validators.pattern("^[0-9]*$")]],
      stock: ["", [Validators.required, Validators.max(100000), Validators.pattern("^[0-9]*$")]]
    });
  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control && control.value && control.value.toString() || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
}


  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }

  getRewardList() {
    this.loader = true;
    this.apiService.reward_list().subscribe(
      (response: any) => {
        if (response.success == 1) {
          this.loader = false;
          this.rewardList = response.data;
          //this.loadDataTableScript("dataTables-example");
        }
      },
      err => {
        console.error(err);
      }
    );
  }

  showToast(toastType, toastCaption, toastText) {
    if(toastType == "danger"){
      this.toastr.error(toastText,toastCaption);
    }else {
      this.toastr.success(toastText,toastCaption);
    }
    /*this.toaster.open({
      text: toastText,
      caption: toastCaption,
      type: toastType,
      position: "top-right",
      component: CustomToasterComponent
    });*/
  }

  get f() {
    return this.registerForm.controls;
  }

  get uf() {
    return this.updateForm.controls;
  }

  addReward() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }

    if (!this.rewardImage) {
      return;
    }

    let formdata = new FormData();
    formdata.append("rewardImage", this.rewardImage);
    formdata.append("reward_name", this.registerForm.value.name);
    formdata.append("reward_stock", this.registerForm.value.stock);
    formdata.append("reward_points", this.registerForm.value.point);
    formdata.append("uniqueName", this.registerForm.value.uniqueName);
    this.loader = true;
    this.apiService.rewards(
      formdata
    ).subscribe(
      (response: any) => {
        this.loader = false;
        if (response.success == 1) {
          //window.location.reload();
          this.submitted = false;
          this.getRewardList();
          this.showToast(
            "success",
            "Success - Reward Configured",
            "Reward has been configured successfully"
          );
          this.rewardImage = null;
          $('#txt-reward-image').val('');
          // this.registerForm.patchValue({
          //   name: '',
          //   stock: '',
          //   point: ''
          // })
          this.onReset();
          this.rewardImage = null;
          // this.getRewardList();
          // this.onReset();
        } else {
          this.showToast(
            "danger",
            "Failed - Reward Configuration",
            response.message
          );

          this.onReset();
        }
      },
      err => {
        this.loader = false;
        console.error(err);
        this.showToast(
          "danger",
          "Failed - Reward Configuration",
          "Due to some reason reward has not configured!"
        );

        this.onReset();
      }
    );
  }

  onReset() {
    this.registerForm.reset();
    this.submitted = false;
  }

  onUpdateReset() {
    this.updateForm.reset();
    this.updateSubmitted = false;
  }

  onSelectedRow(obj) {
    this.selectedRecord = obj;
    $('#txt-reject-reason').val('');
    this.rewardImage = null;
    this.updateForm.patchValue({ name: obj.reward_name, point: obj.reward_points, stock: obj.reward_stock })
  }

  onDeleteConfiguration(detail) {
    this.loader = true;
    this.apiService.rewardDelete(detail).subscribe(
      (response: any) => {
        this.loader = false;
        if (response.success == 1) {
          this.showToast('success', 'Success - Reward Delete Configuration', 'Reward has deleted successfully!')
           this.getRewardList();
          //window.location.reload();
        } else {          
          this.showToast(
            "danger",
            "Failed - Reward Delete Configuration",
           response.message
          );
        }
      },
      err => {
        this.showToast(
          "danger",
          "Failed - Reward Delete Configuration",
          "Due to some reason reward has not deleted!"
        );
      }
    );
  }

  onUpdate() {
    this.updateSubmitted = true;

    // stop here if form is invalid
    if (this.updateForm.invalid) {
      return;
    }
    console.log(this.updateForm.value)
    let formdata = new FormData();
    if(this.rewardImage){
      formdata.append("rewardImage", this.rewardImage);
    }
    formdata.append("reward_name", this.updateForm.value.name);
    formdata.append("reward_stock",  this.updateForm.value.stock);
    formdata.append("reward_points", this.updateForm.value.point);
    this.loader = true;
    this.apiService.updateRewards(this.selectedRecord.id, formdata).subscribe(
      (response: any) => {
        if (response.success == 1) {
          this.loader = false;
          $('#txt-reject-reason').val('');
          this.showToast(
            "success",
            "Success - Reward Configured Updated",
            "Reward has been updated successfully"
          );
          document.getElementById('request-approval-modal').click();
          //window.location.reload();
           this.onUpdateReset();
           this.getRewardList();
        } else {
          this.showToast(
            "danger",
            "Failed - Reward Configuration Update",
            "Due to some reason reward has not updated!"
          );

          //this.onUpdateReset();
        }
      },
      err => {
        console.error(err);
        this.showToast(
          "danger",
          "Failed - Reward Configuration Update",
          "Due to some reason reward has not updated!"
        );

        //this.onUpdateReset();
      }
    );


  }

  loadDataTableScript(id) {
    let script = this._renderer2.createElement("script");
    script.type = `text/javascript`;
    script.text = `
    $(document).ready(function() {
      
        $('#${id}').DataTable({ 
          searching: true,
          paging: true,
          destroy: true,    
          retrive: true                  
        });
      });
    `;

    this._renderer2.appendChild(this._document.body, script);
  }

  onChangeFile(event){
    if (event.target.files.length > 0) {
      console.log(event.target.files[0]);
      
      const splitedName = event.target.files[0].name.split(".")
      const extention = splitedName[splitedName.length-1];

      if (!String(event.target.files[0].type).includes("image/") || !['png', 'jpg', 'jpeg', 'PNG', 'JPG', 'JPEG'].includes(extention)) {
        this.toastr.error("Only allow PNG and JPG image!");
        $('#txt-reject-reason').val('');
        $('#txt-reward-image').val('');
        return;
      }

      if (event.target.files[0].size > 512000) {
        this.toastr.error("Please select file less then 500 kb");
        $('#txt-reward-image').val('');
        $('#txt-reject-reason').val('');
        return;
      } else {
        this.rewardImage = event.target.files[0];
      }
    }else {
      this.rewardImage = null;
    }
  }
}
