import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../../company/service/company.service';
declare var $: any;
import { OrderPipe } from 'ngx-order-pipe';
import { FlashMessagesService } from 'angular2-flash-messages';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv';
import { Globals } from './../../../globals';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-skubatchlist',
  templateUrl: './skubatchlist.component.html',
  styleUrls: ['./skubatchlist.component.css']
})
export class SkubatchlistComponent implements OnInit {

  data: any;
  searchText = "";
  sortedCollection: any[];
  order: string = 'result.id';
  reverse: boolean = false;
  result: any = [];
  arr: any = [];
  errors: any = '';
  deletedMsg: string = null;
  p = this.globals.p;
  currentLimit = this.globals.currentLimit;
  deleteId: any;
  pageLimits = this.globals.pageLimits;

  csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: true,
    showTitle: false,
    title: 'Qrcode List :',
    useBom: true,
    noDownload: false,
    // headers: []  
    headers: ["QrCode", "SKU ID", "Batch No.", "Mfg. Date", "Exp. Date", "MRP"]
  };
  skuId: string;
  loader: boolean = false;

  constructor(private globals: Globals, private _flashMessagesService: FlashMessagesService, public companyService: CompanyService, private route: ActivatedRoute, private router: Router, private orderPipe: OrderPipe,public datePipe:DatePipe) {
    this.sortedCollection = orderPipe.transform(this.result, 'result.id');
    console.log(this.sortedCollection);
  }

  async ngOnInit() {
    this.loader = true;
    await this.route.params.subscribe(params => {
      this.skuId = params['id'];
    });
    await this.companyService.getSKUBatchStock(this.skuId).subscribe((res: any) => {
      console.log(res);

      this.loader = false;
      this.result = res;
      this.result.invetory = res.invetory.filter((item) => {
        return item.cases != 0;
      });
    });
  }

  /**
  * [setOrder description]
  * @author Suresh Suthar
  * @Created Date     2019-03-22
  * @param   {string} value      [description]
  */
  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    this.order = value;
  }


  exportQrcode(batchId, batchNo, mfg, exp) {
    this.loader = true;
    let data = {
      u_id: this.result.u_id,
      batch_id: batchId,
      SKU: this.result.sku_name,
      batch_no: batchNo,
      mfg_date: this.datePipe.transform(mfg, 'MMM dd, yyyy'),
      exp_date: this.datePipe.transform(exp, 'MMM dd, yyyy'),
      MRP: this.result.product_mrp,
    }
    this.companyService.generate_stockReport(data).subscribe((res: any) => {
      this.loader = false;
      console.log(res.data);
      // return false;
      if (res.success == 1) {

        let qrCode = res.data;
        let random = Math.random().toString(36).substring(7);
        let fileName = this.result.sku_name + '_' + batchNo;
        new AngularCsv(qrCode, fileName, this.csvOptions);
      }
      else {
        this._flashMessagesService.show(res.message, { cssClass: 'alert-danger' });
      }
    }, (err) => {
      this.loader = false;
    });
  }

}
