import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../company/service/company.service';
declare var $ :any; 
import { OrderPipe } from 'ngx-order-pipe';
import { FlashMessagesService } from 'angular2-flash-messages';
import { Globals } from './../../globals';



@Component({
  selector: 'app-production',
  templateUrl: './production.component.html',
  styleUrls: ['./production.component.css']
})
export class ProductionComponent implements OnInit {
  data : any;
  searchText  = "";
  sortedCollection: any[];
  order: string = 'result.id';
  reverse: boolean = false;
  result : any = [];
  arr : any = [];
  errors : any = '';
  deletedMsg: string = null;
  deleteId: any;
  p =this.globals.p;
  currentLimit=this.globals.currentLimit;
  pageLimits = this.globals.pageLimits;
  loader:boolean = false;

    constructor(private globals: Globals,private _flashMessagesService: FlashMessagesService,public companyService: CompanyService,private route: ActivatedRoute, private router: Router,private orderPipe: OrderPipe) {  this.sortedCollection = orderPipe.transform(this.result, 'result.id');
    console.log(this.sortedCollection); }
    ngOnInit() {
    	this.productionData();
    }

  productionData(){
       this.loader=true;
      this.companyService.production_list().subscribe((res:any)=>{
         this.loader=false;
        this.result =res.data;
        console.log(this.result);    
      }, (err) => {
        
      });
    }


  productionDelete(id){

      this.companyService.deleteProduction(id).subscribe((res:any)=>{
        console.log(res);
         this.result=res.data;

        if (res['success'] == 1 ) {
         this._flashMessagesService.show('Plant deleted successfully', { cssClass: 'alert-success' } );
         this.productionData();
         
          setTimeout(() => {
            this.router.navigate(['/plant']);
            this.productionData();
          }, 2000); 

        } else {
          this.errors = res['message'];
        }
        
      }, (err) => {

      });

    }
      setOrder(value: string) {
      if (this.order === value) {
        this.reverse = !this.reverse;
      }
      this.order = value;
    }

     opendeleteModal(id){
         this.deleteId = id;
        $('#exampleModal').modal('show');        
    }

}
