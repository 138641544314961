import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RouterModule } from '@angular/router';
import { CompanyService } from '../service/company.service';
declare var $: any;
import { OrderPipe } from 'ngx-order-pipe';
import { ToastrService } from 'ngx-toastr';
import { FlashMessagesService } from 'angular2-flash-messages';
import { Globals } from '../../globals';


@Component({
  selector: 'app-company',
  templateUrl: './company-dashboard.component.html',
  styleUrls: ['./company-dashboard.component.css']
})
export class CompanyDashboardComponent implements OnInit {

  data: any;
  searchText = "";
  sortedCollection: any[];
  order: string = 'data.id';
  reverse: boolean = false;
  result: any = [];
  arr: { [id: string]: any; } = [];
  errors: any = '';
  deletedMsg: string = null;
  deleteId: any;
  companyDetails: any;
  loader: boolean = false;
  p = this.globals.p;
  currentLimit = this.globals.currentLimit;
  pageLimits = this.globals.pageLimits;

  constructor(private globals: Globals, private _flashMessagesService: FlashMessagesService, public toastr: ToastrService, public companyService: CompanyService, private route: ActivatedRoute, private router: Router, private orderPipe: OrderPipe) {
  this.sortedCollection = orderPipe.transform(this.result, 'data.id');
    console.log(this.sortedCollection);
  }

  ngOnInit() {
    this.arr = JSON.parse(localStorage.getItem('company_name'));
    this.companyList();
  }

  companyList() {
    this.loader = true;
    console.log("companyList");
    this.companyService.company_list().subscribe((res: any) => {
      this.loader = false;
      console.log("res.data");
      console.log(res.data);
      this.result = res.data;
      console.log(this.result);

    }, (err) => {
      console.log(err);
    });

  }

  companyDelete(id) {

    this.companyService.deleteCompany(id).subscribe((res: any) => {
      console.log(res);

      this.result = res.data;

      if (res['success'] == 1) {
        this._flashMessagesService.show('Company deleted successfully', { cssClass: 'alert-success' });
        this.companyList();

        setTimeout(() => {
          this.router.navigate(['/companies']);
          this.companyList();
        }, 2000);

      } else {
        this._flashMessagesService.show('Something Went Wrong!', { cssClass: 'alert-danger' });
        this.errors = res['message'];
      }

    }, (err) => {


    });

  }

  companyLocal(id) {

    let adminId = (localStorage.getItem('id')) ? localStorage.getItem('id') : "";
    localStorage.clear();
    this.arr = JSON.parse(localStorage.getItem('company_name'));

    if (this.arr == null || this.arr == undefined) {
      this.arr = {};
    }
    localStorage.setItem('id', "-1");
    this.companyService.company_local(id).subscribe((res: any) => {

      this.arr['company_' + res.data['id']] = res.data;
      localStorage.setItem('company_name', JSON.stringify(this.arr));


      localStorage.setItem('id', res.data.id);
      localStorage.setItem('a_id', adminId);
      localStorage.setItem('companyRandom_id', res.data.random_id);
      localStorage.setItem('x-key', res.data.random_id);
      localStorage.setItem('phone_number', res.data.contact1);
      localStorage.setItem('status', res.data.status);
      localStorage.setItem('name', res.data.name);
      localStorage.setItem('access_token', res.access_token);
      localStorage.setItem('role_id', '2');
      window.location.href = "dashboard";
      //this.router.navigate(['/dashboard']);

    }, (err) => {


    });

  }

  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }

  opendeleteModal(id) {
    this.deleteId = id;
    $('#exampleModal').modal('show');

  }

}
