import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../../../company/service/company.service';
import { OrderPipe } from 'ngx-order-pipe';
import { NgForm } from "@angular/forms";
import { FlashMessagesService } from 'angular2-flash-messages';

@Component({
  selector: 'app-edit-companyusers',
  templateUrl: './edit-companyusers.component.html',
  styleUrls: ['./edit-companyusers.component.css']
})
export class EditCompanyusersComponent implements OnInit {
   
    result: any = [];
    resultList: any;
    errors: any = '';
    Editid: any;
    resultData: any;
    msg: string = null;
    public message: string;
    roleId: number;
    submit:boolean=false; 
    numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

   constructor(private _flashMessagesService: FlashMessagesService, public companyService: CompanyService,private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
  	 this.route.params.subscribe(params => {
          // console.log(params);
            this.Editid = params['id'];
            this.editUserData();
        });
  }


     editUserData() {
        this.companyService.companyuser_edit(this.Editid).subscribe((res: any) => {
            this.result = res.data;
            this.roleId = res.data.role_id;
            this.roleData(this.roleId);
            console.log(this.result);

        }, (err) => {


        });
    }

      onSubmit(form?: NgForm) {
        let f = form.value;

        this.companyService.companyuser_update(this.Editid, f).subscribe((res: any) => {

            this.resultData = res;

            if (this.resultData.success == 1) {
                this.submit=true;   
                this._flashMessagesService.show('User updated successfully', { cssClass: 'alert-success' });

                setTimeout(() => {
                    this.router.navigate(['/companyuser']);
                }, 3000);

            } else {
                this.errors = res['message'];
            }

        }, (err) => {


        });

    }
     roleChanged(event) {
       // console.log(event);
        this.result.role_id = event.value;
    }

    async roleData(roleId) {
        var result = [];
        await this.companyService.companyrole_list().subscribe((res: any) => {
            result = res.data;
            this.resultList = result.map(function (obj) {
                var rObj = {};

                rObj['id'] = obj.id;
                rObj['text'] = obj.name;
                rObj['selected'] = (obj.id == roleId) ? true : false;
                return rObj;
            });
            console.log(this.resultList);

        }, (err) => {

        });
    }

}
