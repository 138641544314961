import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../../company/service/company.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as $ from "jquery";
import { Globals } from './../../../globals';


@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.css']
})
export class AddProductComponent implements OnInit {

  model : any = {};
  resultList: any;
  msg: string = null;
  errors : any = '';
  variantdataList : any;
  variantList: any;
  unitData: any;
  resultProduct:any;
  brandOptions:any;
  categoryOptions:any;
  variantOptions:any;
  unitOptions:any;
  result:any;
  sizeList:any;
  submit:boolean=false;

  constructor(private globals: Globals,private _flashMessagesService: FlashMessagesService, public companyService: CompanyService,private route: ActivatedRoute, private router: Router) { }

  async ngOnInit() {
    this.brandOptions    = { placeholder: { id: '', text: 'Select Brand' } };
    this.categoryOptions = { placeholder: { id: '', text: 'Select Category' } };
    this.variantOptions  = { placeholder: { id: '', text: 'Select Variant' } };
    this.unitOptions    = { placeholder: { id: '', text: 'Select size' } };
    await  this.brandData();
    this.unitData = this.globals.unitData;

      }

            onSubmit(form?:NgForm) {

              let f =  form.value;
              f.category_id=this.model.category_id;
              f.brand_id=this.model.brand_id;
              f.varient_id=this.model.varient_id;
              f.size_id=this.model.size_id;
              console.log(f);

              this.companyService.packaging_add(f).subscribe((res : any)=>{
                console.log(res);

                this.resultProduct = res;
                if (this.resultProduct.success == 1 ) {
                  this._flashMessagesService.show('Packaging created successfully', { cssClass: 'alert-success' } );

                  setTimeout(() => {
                    this.router.navigate(['/packaging']);
                  }, 2000); 

                } else {
                  this.errors = res.message;
                   setTimeout(() => {
                    this.errors.packaging_id = false;
                  }, 3000); 
                }

              }, (err) => {

              });

            }

            //Brand Data Start
            brandChanged(event){
              console.log(event);
              if(event.value!='select'){
                this.model.brand_id=event.value;
                let catBrand = this.model.brand_id;

                var result = [];
                this.companyService.categorybrand_list(catBrand).subscribe((res:any)=>{
                  this.result = res.data;
                  var rObj= [];
                  rObj.push({"id":"","text":""});
                  for(let i=0; i<res.data.length; i++){

                    rObj.push({"id":res.data[i].id, "text":res.data[i].name});
                  }      
                  this.resultList = rObj;
                }, (err) => {

                });
              }
            }

            async brandData(){
              await this.companyService.brand_list().subscribe((res:any)=>{
                this.result = res.data;
                var rObj= [];
                rObj.push({"id":"","text":"select"});
                for(let i=0; i<res.data.length; i++){

                  rObj.push({"id":res.data[i].id, "text":res.data[i].name});
                }      
                this.variantdataList = rObj;

                console.log(this.variantdataList);
              }, (err) => {

              });
            }
            //Brand Data Finish


            categoryChanged(event){
              console.log(event);
              if(event.value!='select'){
                this.model.category_id=event.value;

                let catVar = this.model.category_id;

                var result = [];
                this.companyService.variantCat_list(catVar).subscribe((res:any)=>{

                  this.result = res.data;
                  var rObj= [];
                  rObj.push({"id":"","text":""});
                  for(let i=0; i<res.data.length; i++){

                    rObj.push({"id":res.data[i].id, "text":res.data[i].name});
                  }      
                  this.variantList = rObj;
                }, (err) => {

                });

              }

            }
            //Categoty Data Finish

            //Variant Data Start
            variantChanged(event){
              console.log(event);
              if(event.value!='select'){
                this.model.varient_id=event.value;

                let catVar = this.model.varient_id;

                var result = [];
                this.companyService.packaging_size(catVar).subscribe((res:any)=>{

                  this.result = res.data;
                  var rObj= [];
                  rObj.push({"id":"","text":""});
                  for(let i=0; i<res.data.length; i++){

                    let unitName;
                    for(let j=0; j< this.unitData.length; j++){
                        if(res.data[i].unit==this.unitData[j].id){
                            unitName = this.unitData[j].text;
                        }
                    }

                    rObj.push(
                                {
                                  "id":res.data[i].id, 
                                  "text":res.data[i].quantity+" "+unitName
                                }
                             );
                  }      
                  this.sizeList = rObj;
                }, (err) => {

                });

              }

            }

              //Unit Changed Event
            unitChanged(event){
              console.log(event);
              this.model.size_id=event.value;
            }


          }
