import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as $ from "jquery";



@Component({
  selector: 'app-company-header',
  templateUrl: './company-header.component.html',
  styleUrls: ['./company-header.component.css']
})
export class CompanyHeaderComponent implements OnInit {

  currentCompany = '';
  localStorageData = JSON.parse(localStorage.getItem('company_name')); 

  //console.log(localStorageData)
  curruntCompanyData : any = [];
  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
      
  }

  getCompanyData(key){
    this.localStorageData = JSON.parse(localStorage.getItem('company_name')); 
      //console.log(this.localStorageData);
      //console.log(this.localStorageData.company_3);
    
    let splitted = this.router.url.split("/", 3);
    //console.log(splitted);
    this.currentCompany = splitted['2'];
    console.log(this.currentCompany);
    this.curruntCompanyData = this.localStorageData['company_'+this.currentCompany];
    console.log(this.curruntCompanyData);
    //console.log(this.currentCompany, this.curruntCompanyData);
    
    console.log(this.curruntCompanyData[key]); 
    return this.curruntCompanyData[key];
  
  }

  
  Logout(){
    localStorage.clear();
    this.router.navigate(['/login']);
  }



}
