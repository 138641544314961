import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../../company/service/company.service';
import { OrderPipe } from 'ngx-order-pipe';
import { NgForm } from "@angular/forms";
import { FlashMessagesService } from 'angular2-flash-messages';

@Component({
  selector: 'app-edit-employeeuser',
  templateUrl: './edit-employeeuser.component.html',
  styleUrls: ['./edit-employeeuser.component.css']
})
export class EditEmployeeuserComponent implements OnInit {
  
    result: any = [];
    resultList: any;
    errors: any = '';
    Editid: any;
    resultData: any;
    msg: string = null;
    public message: string;
    unitId: number;
    submit:boolean=false;
    loader:boolean = false;

    numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

   constructor(private _flashMessagesService: FlashMessagesService, public companyService: CompanyService,private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
  	 this.route.params.subscribe(params => {
          // console.log(params);
            this.Editid = params['id'];
            this.editEmployeeData();
        });
  }

   editEmployeeData() {
        this.loader=true;
        this.companyService.employee_edit(this.Editid).subscribe((res: any) => {
            this.loader=false;
            this.result = res.data;
            this.unitId = res.data.unit_id;
            this.unitData(this.unitId);
            console.log(this.result);

        }, (err) => {

        });
    }

    onSubmit(form?: NgForm) {
        let f = form.value;

        this.companyService.employee_update(this.Editid, f).subscribe((res: any) => {

            this.resultData = res;

            if (this.resultData.success == 1) {
                this.submit=true;   
                this._flashMessagesService.show('User Updated Successfully!', { cssClass: 'alert-success' });

                setTimeout(() => {
                    this.router.navigate(['/employeeuser']);
                }, 3000);

            } else {
                this.errors = res.message;
                 setTimeout(() => {
                    this.errors.employee_id = false;
                      }, 3000); 
            }

        }, (err) => {


        });

    }

    unitChanged(event) {
       // console.log(event);
        this.result.unit_id = event.value;
    }

    async unitData(unitId) {
        var result = [];
        await this.companyService.production_list().subscribe((res: any) => {
            result = res.data;
            this.resultList = result.map(function (obj) {
                var rObj = {};

                rObj['id'] = obj.id;
                rObj['text'] = obj.plant_name;
                rObj['selected'] = (obj.id == unitId) ? true : false;
                return rObj;
            });
            console.log(this.resultList);

        }, (err) => {

        });
    }

}
