import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpRequest } from '@angular/common/http';
import { Config } from './../config';

const TOKEN = 'TOKEN';

const httpOptions = {
  headers: new HttpHeaders({

	'x-key': localStorage.getItem('x-key'),
	'x-access-token':localStorage.getItem('access_token')
  })
};

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private httpClient: HttpClient) { }

  customer_list() {
		return this.httpClient.get(`${Config.API_URL}customer`,httpOptions);
	}
  customer_complain_list(){
  		return this.httpClient.get(`${Config.API_URL}customerComplaints`,httpOptions);
  }
  customer_complain_edit(id){
    return this.httpClient.get(`${Config.API_URL}customerComplaints/${id}`,httpOptions);
  }	
  Company_totalSku(){
    return this.httpClient.get(`${Config.API_URL}dashboardTotalSku`,httpOptions);
  }
  Company_totalSkuQrcode(){
    return this.httpClient.get(`${Config.API_URL}dashboardTotalSkuQrcode`,httpOptions);
  }
  Company_totalShipperQrcode(){
    return this.httpClient.get(`${Config.API_URL}dashboardTotalShipperRrcodes`,httpOptions);
  }
  Company_totalProductBatches(){
    return this.httpClient.get(`${Config.API_URL}dashboardTotalProductBatches`,httpOptions);
  }

}
