import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { BusinessService } from '../../service/business.service';
import { FlashMessagesService } from 'angular2-flash-messages';

import {
    FormBuilder,
    FormGroup,
    Validators,
    FormControl,
} from "@angular/forms";
import { ApiService } from 'src/app/service/api/api.service';
declare var jQuery: any;

@Component({
    selector: 'app-add-user',
    templateUrl: './add-user.component.html',
    styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {
    result: any;
    msg: string = null;
    errMsg: string = '';
    model: any = {};
    roleOptions: any;

    loader = false;

    stateList = [];
    cityList = [];

    gender = [{
        id: 'M', text: 'Male'
    }, {
        id: 'F', text: 'Female'
    }]

    registerForm: FormGroup;
    submitted = false;

    constructor(private _flashMessagesService: FlashMessagesService,
        private formBuilder: FormBuilder,
        public businessService: BusinessService,
        private router: Router,
        private apiService: ApiService) {
    }

    async ngOnInit() {

        this.registerForm = this.formBuilder.group({
            firstName: ["", [Validators.required, Validators.maxLength(100), this.noWhitespaceValidator]],
            lastName: ["", [Validators.required, Validators.maxLength(100), this.noWhitespaceValidator]],
            email: [
                "",
                [
                    Validators.required,
                    Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
                ],
            ],
            phoneNo: [
                "",
                [Validators.required, Validators.pattern("^(0)?[0-9]{10}$")],
            ],
            roleId: ["", Validators.required],
        });
        this.loadDatePickerScript()
        await this.roleData();
        this.getStateList(101);
    }

    noWhitespaceValidator(control: FormControl) {
        const isWhitespace = (control && control.value && control.value.toString() || '').trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': true };
    }


    loadDatePickerScript() {

        jQuery(document).ready(function () {
            jQuery("#dob").daterangepicker(
                {
                    singleDatePicker: true,
                    showDropdowns: true,
                    locale: {
                        format: "DD/MM/YYYY",
                    },
                    maxDate: new Date(),
                    autoclose: true,
                    minYear: 1901,
                },
                function (start, end, label) {
                    console.log("s: ", start, "e: ", end, "l: ", label);
                }
            );
        });
    }

    getStateList(countryId) {
        this.apiService.get("state/" + countryId, true).subscribe(
            (response: any) => {
                if (response.status == 200 && response.body.success == 1) {
                    this.stateList = response.body.data;
                }
            },
            (err) => {
                console.error(err);
            }
        );
    }

    getCityList() {
        this.apiService.get("city/" + this.registerForm.value.stateId, true).subscribe(
            (response: any) => {
                if (response.status == 200 && response.body.success == 1) {
                    this.cityList = response.body.data;
                }
            },
            (err) => {
                console.error(err);
            }
        );
    }

    get f() {
        return this.registerForm.controls;
    }

    onChangeRole() {
        let roleId = this.registerForm.value.roleId;
        console.log('roleId: ', roleId)
        if (roleId == 3) {
            this.registerForm.addControl(
                "stateId",
                new FormControl("", [Validators.required])
            );
            this.registerForm.addControl(
                "cityId",
                new FormControl("", [Validators.required])
            );
            this.registerForm.addControl(
                "gender",
                new FormControl("", [Validators.required])
            );
            this.registerForm.addControl(
                "pincode",
                new FormControl("", [Validators.required, Validators.pattern("^(0)?[0-9]{6}$")])
            );
            this.registerForm.addControl(
                "dob",
                new FormControl("", [Validators.required])
            );
        } else {
            this.removeFormValidator();
        }
    }

    removeFormValidator() {
        this.registerForm.removeControl("stateId");
        this.registerForm.removeControl("cityId");
        this.registerForm.removeControl("gender");
        this.registerForm.removeControl("pincode");
        this.registerForm.removeControl("dob");

    }

    onAddReset() {
        this.registerForm.reset();
        this.submitted = false;
        this.removeFormValidator();
        this.loadDatePickerScript();
    }

    onSubmit() {

        this.submitted = true;
        let dob;
        if (this.registerForm.value.roleId == 3) {
            dob = document.getElementById('dob')['value'];
            this.registerForm.patchValue({
                dob: dob
            });
        }

        if (this.registerForm.invalid) {
            return;
        }
        this.loader = true;

        this.businessService.businessUser_add(this.registerForm.value).subscribe((res: any) => {
            console.log(res);

            if (res.success == 1) {
                this.router.navigate(['/user']);
            } else {
                this.errMsg = res.message;
                console.log(this.errMsg)
                setTimeout(() => {
                    this.errMsg = null;
                }, 7000);
                this.loader = false;

            }
        }, (err) => {
            this.errMsg = err.message;
            setTimeout(() => {
                this.errMsg = null;
            }, 7000);
            this.loader = false;
        });

    }

    async roleData() {
        await this.businessService.businessRole_list().subscribe((res: any) => {
            this.result = res.data;
        }, (err) => {

        });
    }

}

