import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ApiService } from 'src/app/service/api/api.service';


@Component({
  selector: 'app-luckydraw-rewards-add',
  templateUrl: './luckydraw-rewards-add.component.html',
  styleUrls: ['./luckydraw-rewards-add.component.css']
})
export class LuckydrawRewardsAddComponent implements OnInit {
  msg: string = null;
  errors: any = "";
  loader = false;
  registerForm: FormGroup;
  submitted: Boolean = false;
  user_types = [
    {
      id: 0,
      name: "Customer"
    },
    {
      id: 1,
      name: "Distributor"
    },
    {
      id: 2,
      name: "Wholesaler"
    },
    {
      id: 3,
      name: "Retailer"
    }
  ];
  selectedUsertype = "";
  productImage;
  productImage_name;
  constructor(private formBuilder: FormBuilder,
    private apiService: ApiService,
    private router: Router) { }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      // user_type: ["", [Validators.required]],
      reward_id:["",[Validators.required,Validators.maxLength(20)]],
      name: ["", [Validators.required, Validators.pattern(".*\\S.*"), Validators.maxLength(20)]],
      // points: ["", [Validators.required, Validators.pattern("^[0-9]*$")]],
      stock: ["", [Validators.required, Validators.min(1), Validators.max(100000), Validators.pattern("^[0-9]*$")]],
      image: ["", Validators.required],
      // is_luckydraw_reward: [false, Validators.required]
    });
  }

  get f() {
    return this.registerForm.controls;
  }

  onSave() {
    this.submitted = true;

    if(this.productImage == null) {
      this.msg = "Please select reward image!";
      return
    }

    if (this.registerForm.invalid) {
      return;
    }

    this.loader = true;
    console.log(">>>", this.registerForm.value);

    let formdata = new FormData();
    formdata.append(
      "image",
      this.productImage,
      this.productImage.name
    );
    formdata.append("reward_id", this.registerForm.value.reward_id);
    formdata.append("name", this.registerForm.value.name);
    // formdata.append("points", this.registerForm.value.points);
    formdata.append("stock", this.registerForm.value.stock);
    formdata.append("is_luckydraw_reward", "true")
    this.apiService
      .post("rewards/add", formdata, true)
      .subscribe(
        (response: any) => {
          this.loader = false;
          console.log("res",response)
          if (response.status == 200 && response.body.success == 1) {
            this.router.navigate(["/luckydraw-rewards"]);
          } else {
            this.msg = response.body.message;
          }
        },
        (error) => {
          console.error(error);
          this.loader = false;
          this.msg =
            "Due to server side issue you cannot add rewards!";
        }
      );
  }

  onImageChange(event) {
    if (event.target.files[0]) {
      if (event.target.files[0].type.includes("image")) {
        if (event.target.files[0].size <= 500000) {
          this.productImage = event.target.files[0];
          this.productImage_name = event.target.files[0].name;
        } else {
          this.productImage_name = "Upload Image"
          this.productImage = null;
          $('#rewardImg').val('');
          this.msg = "Reward Image File must be less than 500KB";
        }
      } else {
        this.productImage_name = "Upload Image"
        this.productImage = null;
        $('#rewardImg').val('');
        this.msg = "Reward image file must be image";
      }
    }
  }

  onRegisterFormReset() {
    this.submitted = false;
    this.registerForm.reset();
  }


}
