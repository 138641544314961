import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../company/service/company.service';
declare var $ :any; 
import { OrderPipe } from 'ngx-order-pipe';
import { FlashMessagesService } from 'angular2-flash-messages';
import { Globals } from './../../globals';



@Component({
	selector: 'app-variant',
	templateUrl: './variant.component.html',
	styleUrls: ['./variant.component.css']
})
export class VariantComponent implements OnInit {
	data : any;
	searchText  = "";
	sortedCollection: any[];
	order: string = 'result.id';
	reverse: boolean = false;
	result : any = [];
	arr : any = [];
	errors : any = '';
	deletedMsg: string = null;
	p =this.globals.p;
    currentLimit=this.globals.currentLimit;
	deleteId: any;
	pageLimits = this.globals.pageLimits;
	loader:boolean = false;

	constructor(private globals: Globals,private _flashMessagesService: FlashMessagesService,public companyService: CompanyService,private route: ActivatedRoute, private router: Router,private orderPipe: OrderPipe) {  this.sortedCollection = orderPipe.transform(this.result, 'result.id');
	console.log(this.sortedCollection); }


	ngOnInit() {
		this.variantData();
	}


	variantData(){
		 this.loader=true;
		this.companyService.variant_list().subscribe((res:any)=>{
			 this.loader=false;
			console.log(res.data);
			this.result =res.data;
			console.log(this.result);

		}, (err) => {

		});
	}

	variantDelete(id){
		this.companyService.deleteVariant(id).subscribe((res:any)=>{
			console.log(res);
			this.result=res.data;
			if (res['success'] == 1 ) {
				this._flashMessagesService.show('Variant  deleted successfully', { cssClass: 'alert-success' } );
				this.variantData();

				setTimeout(() => {
					this.router.navigate(['/variant']);
					this.variantData();
				}, 2000); 

			} else {
				this.errors = res['message'];
			}

		}, (err) => {


		});

	}
	setOrder(value: string) {
		if (this.order === value) {
			this.reverse = !this.reverse;
		}

		this.order = value;
	}

	  opendeleteModal(random_id){
         this.deleteId = random_id;
         console.log(this.deleteId);
        $('#exampleModal').modal('show');
        
    }
}
