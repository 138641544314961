import { Component, OnInit } from '@angular/core';
import { Globals } from 'src/app/globals';
import { ApiService } from 'src/app/service/api/api.service';
import * as XLSX from 'xlsx';
import * as moment from "moment";

@Component({
  selector: 'app-luckydraw-reward-history',
  templateUrl: './luckydraw-reward-history.component.html',
  styleUrls: ['./luckydraw-reward-history.component.css']
})
export class LuckydrawRewardHistoryComponent implements OnInit {
  loader = false;
  msg: string = "test";
  errors: any = "";
  submitted: boolean = false;
  reverse: boolean = false;
  p = this.globals.p;
  currentLimit = this.globals.currentLimit;
  pageLimits = this.globals.pageLimits;
  order: string = "createdAt";
  reedemData =[];
  constructor(private globals: Globals, private apiService: ApiService,) { }

  ngOnInit() {
    this.getRewardHistory();
  }

  getRewardHistory(){

    this.apiService
    .get("rewards/history?for=ld", true)
    .subscribe(
      (response: any) => {
        this.loader = false;
        if (response.status == 200 && response.body.success == 1) {
          console.log("ressponse bofy history",response.body);
          this.reedemData = response.body.data
        } else {            
          this.msg = response.body.message;            
        }
      },
      (error) => {
        console.error(error);
        this.loader = false;
        this.msg =
          "Server Error!";
      }
    );
  }

  exportReedemData(){
    console.log("reeedemexport",this.reedemData);
    
    let data = this.reedemData.map((item) => {
      return {
        "Consumer Name": item.consumer.name,
        "Mobile": item.phone,
        "Reward": item.reward.name,
        "Status": (item.is_verified==0)?"In Progress":(item.is_verified==1?"Rejected":(item.is_verified==2?"On Its Way":"Delivered")),
        "Transaction ID": item.transaction_id?item.transaction_id:"-",
        "Reason": item.verify_comments?item.verify_comments:"-",
        "Redeemed On":`${moment(item.createdAt)
          .format("DD-MM-yyyy hh:mm a")
          .toUpperCase()}`
      };
    });
    let today = new Date();
    let fileName = today.getDate()+'-'+(new Date().getMonth() + 1).toString()+'_'+'redeem_history.xlsx';

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    ws['!cols'] = [{ width: 40 }, { width: 10 }, { width: 20 }, { width: 20 }, { width: 10 }, { width: 10 }, { width: 10 }]
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, fileName);
  }

  setOrder(value: string) {
    console.log(value);
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    this.order = value;
  }

}
