import { Component, OnInit } from '@angular/core';
import { Login } from './login';
import { LoginService } from './service/login.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from "@angular/forms";
import * as $ from "jquery";
declare var Swiper :any;   // not required
import { SwiperComponent, SwiperDirective, SwiperConfigInterface,
  SwiperScrollbarInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';
  import { FlashMessagesService } from 'angular2-flash-messages';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  model: any = {};
  result : any = {};
  errors : any = '';
  
  currentUserId : any = localStorage.getItem('id');

   public images = [  { 
                'src':'../kc/assets/img/banner.png',
            },
            { 
                'src':'../kc/assets/img/banner2.png',
            },
            
        ];
  public config: SwiperConfigInterface = {
    a11y: true,
    direction: 'horizontal',
    slidesPerView: 1,
    keyboard: true,
    mousewheel: true,
    scrollbar: false,
    pagination: true,
    autoplay: true,
    speed: 800,
    
    //navigation: true,
  };

  private scrollbar: SwiperScrollbarInterface = {
    el: '.swiper-scrollbar',
    hide: false,
    draggable: true
  };

   private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true,
    hideOnClick: false
  };
  constructor(private _flashMessagesService: FlashMessagesService,public loginService: LoginService,private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    if (this.currentUserId != undefined && this.currentUserId != null ) {
      this.router.navigate(['/dashboard']);
    }    
  }

  onSubmit(form?:NgForm) {
    let f = form.value;

    this.loginService.login(f).subscribe((res : any)=>{
    //  console.log(res);
      this.result = res.data;
      console.log(this.result);
      if (res['success'] == 1 ) {
        localStorage.setItem('id', this.result.id);
        localStorage.setItem('x-key', this.result.id);
        localStorage.setItem('role_id', this.result.role_id);
        localStorage.setItem('phone_number', this.result.phone_number);
        localStorage.setItem('status', this.result.status);
        localStorage.setItem('name', this.result.name);
        localStorage.setItem('username', this.result.username);
        localStorage.setItem('email', this.result.email);
        localStorage.setItem('main_image', this.result.main_image);
        localStorage.setItem('thumb_image', this.result.thumb_image);
        localStorage.setItem('is_deleted', this.result.is_deleted);
        localStorage.setItem('access_token', res.access_token);
        window.location.href="dashboard";
        //this.router.navigate(['/dashboard']);
      } else {
        this.errors = res['message'];
         //sthis._flashMessagesService.show(res['message'], { cssClass: 'alert-danger' } );
      }


    }, (err) => {

      
    });

  }


}
