import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../../company/service/company.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import * as $ from "jquery";


@Component({
  selector: 'app-addbatch',
  templateUrl: './addbatch.component.html',
  styleUrls: ['./addbatch.component.css']
})
export class AddbatchComponent implements OnInit {
	public current = new Date();
	public skuData: any;
	//public startValue:any;
	public skus: any;
	public plantData:any;
	public plants:any;
	public model = {
		sku_id:"",
		batch_no:"",
		category_name:"",
		variants_name:"",
		plant_id:"",
		plant_line_id:"",
		start:"",
		end:"",
		start1:"",
		end1:"",
		start2:"",
		end2:"",
		start3:"",
		end3:"",
		//exp_date:{},
		// exp_date:{
		// 	year: this.current.getFullYear(),
	    // 	month: this.current.getMonth() + 1,
		// 	day: this.current.getDate() 
		// },
		exp_date:  { year: this.current.getFullYear() + 3, month: this.current.getMonth() + 1, day: this.current.getDate()  }, 
		mfg_date : {
	    	year: this.current.getFullYear(),
	    	month: this.current.getMonth() + 1,
			day: this.current.getDate()  
		},
		qr_code_role:[]
	};
	public productDetails: any;
	public plantLineData:any;
	public plantLineOption:any;
	public plantlines:any;
	public msg: string = null;
    /*public errors = {

    	product_id :  "",
    	product_mrp : "",
    	production_line_id:""
    };*/
    public errors : any = '';
    public batchData = {

    	name : "",
    	manufacturing_date:new Date(),
    	expiry_date : new Date(),
		batch_no : "",
		product_id : "",
		production_site_id : "",
		production_line_id : "",
		qr_code_role : "",
		start:"",
		end:"",
		start1:"",
		end1:"",
		start2:"",
		end2:"",
		start3:"",
		end3:"",
    };
    public skuOptions:any;
    public plantNameOption:any;
    public loader:boolean = false;
    public minDate:any;
    public product_required:string;
    public submit:boolean=false;
    public  minimumDate =undefined;


    numberOnly(event): boolean {

    	const charCode = (event.which) ? event.which : event.keyCode;
	    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
	      return false;
	    }
	    return true;

  	};
  	public roleOptions:any;
  	public qrcodeRole:any;
  	

  	constructor(
  			private _flashMessagesService: FlashMessagesService, 
  			public companyService: CompanyService,
  			private route: ActivatedRoute, 
  			private router: Router) {

  		const current = new Date();
  		this.minimumDate = {
	    year: current.getFullYear(),
	    month: current.getMonth() + 1,
	    day: current.getDate()  };
  	}
	

	async ngOnInit() {

		this.skuOptions 	 = { placeholder: { id: '', text: 'Select SKU' } };
		this.plantNameOption = { placeholder: { id: '', text: 'Select Plant Name' } };
		this.plantLineOption = { placeholder: { id: '', text: 'Select Plant Line' } };
		this.roleOptions     = { multiple: true, placeholder: { id: '', text: 'Select Rolls' }}

		await this.getSkuData();
		await this.getPlantName();
	}

	/**
	 * [getSkuData description]
	 * @author Suresh Suthar
	 * @Created Date 2019-03-21
	 */
	async getSkuData(){

		await this.companyService.product_list().subscribe((res:any)=>{

			this.skuData = res.data;
			var rObj= [];
			rObj.push({"id":"","text":"select"});
			for(let i=0; i<res.data.length; i++){
				var displaySKU = res.data[i].sku + " (" + res.data[i].name+")";
				rObj.push({ "id": res.data[i].id, "text": displaySKU});
			}			

			this.skus = rObj;
		});
	}

	/**
	 * [getProductDetails description]
	 * @author Suresh Suthar
	 * @Created Date     2019-03-21
	 * @param   {[type]} event      [description]
	 */
	async getSkURoles(event){

		if(event.value!='select'){

			let skuId = this.model.sku_id = event.value;

/* 			await this.companyService.getSkUStartSubCode(skuId).subscribe((res:any)=>{
				//console.log(res.data.qrcode_role_id);
				if (res.success == "1" ) {
				console.log("if"); 
					this.model.start = res.data.qrcode_role_id;
                 } else {
                 	console.log("else");
          		this.model.start = '';
             }
			}); */

			await this.companyService.getSkUExpiryDate(skuId).subscribe((res:any)=>{
				let year = this.current.getFullYear() + res.data.duration_id;
				let month =this.current.getMonth() + 1;
				let day = this.current.getDate() +3;

				this.model.exp_date={ year: year, month: month, day: day };  
				//console.log(this.model.exp_date);
			});
		}
		
	}

	/**
	 * [setRoleId description]
	 * @author Suresh Suthar
	 * @Created Date     2019-04-05
	 * @param   {[type]} event      [description]
	 */
	setRoleId(event){

		this.model.qr_code_role = event.value;
	}
	/**
	 * [getPlantName description]
	 * @author Suresh Suthar
	 * @Created Date 2019-03-21
	 */
	async getPlantName(){

		await this.companyService.production_list().subscribe((res:any)=>{

			this.plantData = res.data;

			var rObj= [];
			rObj.push({"id":"","text":"select"});
			for(let i=0; i<res.data.length; i++){

				rObj.push({"id":res.data[i].id, "text":res.data[i].plant_name});
			}

			this.plants = rObj;
		});
	}

	/**
	 * [getPlaneLines description]
	 * @author Suresh Suthar
	 * @Created Date 2019-03-21
	 */
	async getPlaneLines(event){

		//this.errors.production_line_id = (typeof this.errors.production_line_id !=="undefined")?"":"";
		if(event.value!='select'){

			let plantId = event.value;
			this.model.plant_id = plantId;
			console.log(this.model.plant_id);
			await this.companyService.getPlantLine(plantId).subscribe((res:any)=>{

				this.plantLineData = res.data;

				this.plantlines = res.data.map(function(obj){

					var rObj = {};
					rObj['id'] = obj.id;
					rObj['text'] = obj.line1_details;
					return  rObj;
				});
				this.model.plant_line_id = this.plantLineData[0].id;
			});
		}
	}

	/**
	 * [onSubmit description]
	 * @author Suresh Suthar
	 * @Created Date 2019-03-22
	 */
	onSubmit(form?:NgForm){
		let f= form.value;
		//console.log(f);
		this.loader=true;
		let mfgDate = f.mfg_date.year + "-" + f.mfg_date.month + "-" + f.mfg_date.day; 
		let expDate = f.exp_date.year + "-" + f.exp_date.month + "-" + f.exp_date.day;
		this.batchData.name = 'Batch1';
		this.batchData.manufacturing_date = new Date(mfgDate);
		this.batchData.expiry_date = new Date(expDate);
		this.batchData.batch_no = f.batch_no;
		this.batchData.product_id = f.sku_id;
		this.batchData.production_site_id = f.plant_id;
		this.batchData.production_line_id = f.plant_line_id;
		this.batchData.start = f.start;
		this.batchData.end = f.end;
		this.batchData.start1 = f.start1;
		this.batchData.end1 = f.end1;
		this.batchData.start2 = f.start2;
		this.batchData.end2 = f.end2;
		this.batchData.start3 = f.start3;
		this.batchData.end3 = f.end3;
		
/* 		console.log(this.batchData);
		return false; */
	
 		this.companyService.batch_add(this.batchData).subscribe((res:any)=>{
	       
	       	this.loader = false;
	        if (res.success == 1 ) {
	        	  this.submit=true; 
	              this._flashMessagesService.show(res.message, { cssClass: 'alert-success' } );
	             
	              setTimeout(() => {
			            this.router.navigate(['/batch']);
			      }, 3000); 

	       	} 
	       	else {
	         	
	         	this.errors = res.message;
	         	
	       	}
	      }, (err) => {
	    }); 

	}

	/**
	 * [setExpiryMinDate description]
	 * @author Suresh Suthar
	 * @Created Date 2019-03-29
	 */
	setExpiryMinDate(event){

		//this.model.exp_date = undefined;
		this.minDate = event;
		event.year = parseInt(event.year) + 3;
		//this.model.exp_date = this.minDate;
	}
	
}
